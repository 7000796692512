<template>
  <v-container fluid :style="cssVars">
    <v-toolbar dense flat>
      <v-toolbar-title class="text-h6" v-if="!$vuetify.breakpoint.mobile">
        <span v-if="title_en && locale === 'en'" class="text-h6">
          {{ title_en }}
        </span>
        <span v-else-if="title_fr && locale === 'fr'" class="text-h6">
          {{ title_fr }}
        </span>
        <span v-else class="text-h6">{{ $t(title) }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn-toggle v-model="viewMode" mandatory active-class="selected">
        <v-btn
          value="calendar"
          small
          style="min-width: unset; padding: 18px 8px"
        >
          <v-icon style="color: inherit">mdi-calendar</v-icon>
        </v-btn>
        <v-btn
          value="list"
          small
          style="min-width: unset; padding: 18px 8px"
        >
          <v-icon style="color: inherit">mdi-format-list-bulleted</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-toolbar>
    <v-row>
      <v-col cols="2" v-if="!$vuetify.breakpoint.mobile">
        <span class="text-subtitle-1 font-weight-bold">{{
          $t('common.Filters')
        }}</span>
        <v-select
          :items="timeFilter"
          :label="$t('session.time')"
          outlined
          hide-details
          class="mb-2"
          clearable
          v-model="selectedTime"
        ></v-select>
        <v-select
          :items="typeFilter"
          :label="$t('session.type')"
          outlined
          hide-details
          class="mb-2"
          clearable
          v-model="selectedType"
        ></v-select>
        <v-select
          :items="themeFilter"
          :label="$t('session.theme')"
          outlined
          hide-details
          class="mb-2"
          item-text="theme"
          item-value="theme"
          clearable
          v-model="selectedTheme"
        ></v-select>
        <v-select
          :items="roomFilter"
          :label="$t('session.Room')"
          outlined
          hide-details
          class="mb-2"
          item-text="roomName"
          item-value="roomName"
          clearable
          v-model="selectedRoom"
        ></v-select>
        <div v-if="themeFilter.length > 0">
          <v-divider class="my-1"></v-divider>
          <span class="text-subtitle-1 font-weight-bold">
            {{ $t('common.Legend') }}
          </span>
          <div v-for="(t, m) in themeFilter" :key="m">
            <v-icon small :color="t.colour"> mdi-square </v-icon>
            <span class="text-caption ml-1">{{ t.theme }}</span>
          </div>
          <v-divider class="my-1"></v-divider>
        </div>
      </v-col>
      <v-divider vertical v-if="!$vuetify.breakpoint.mobile"></v-divider>
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 10">
        <v-row>
          <v-spacer></v-spacer>
          <v-col v-if="$vuetify.breakpoint.mobile" cols="6">

            <v-select
              label="Date :"
              v-model="selectedDate"
              :items="dateFilter"
            >
            </v-select>
          </v-col>

          <v-col v-else cols="12" class="mb-2">
            <v-btn-toggle
              active-class="selected"
              v-model="selectedDate"
              mandatory
              dense
              :color="defaultButtonColour ? defaultButtonColour : '#1976d2'"
            >
              <v-btn
                v-for="d in dateFilter"
                :key="d"
                class="mr-2 date-btn"
                :value="d"
              >
                {{ d }}
              </v-btn>
            </v-btn-toggle>
          </v-col>


        </v-row>
        <div v-if="!loaded">Loading...</div>
        <div v-else-if="!sessionItems">No Sessions</div>
        <div v-else>
          <v-calendar
            class="overflow-x-auto scroller overflow-y-hidden"
            v-if="viewMode === 'calendar' && showNewCalendarView"
            ref="calendar"
            :categories="getCategories(filteredSessionItems)"
            color="primary"
            event-color="white"
            :events="filteredSessionItems"
            :first-interval="firstInterval(filteredSessionItems)"
            :interval-count="intervalCount(filteredSessionItems)"
            :interval-height="calendarIntervalHeight"
            interval-minutes="15"
            :short-intervals="false"
            :value="filteredDate"
            type="category"
            category-show-all
            @click:event="eventSelected"
          >
            <template #event="{ event }">
              <v-card
                :color="getEventColor2(event)"
                :style="{
                  display: event.filtered ? 'none' : 'block',
                  borderRadius: '0px',
                  border: `1px solid ${event.colour} !important`,
                }"
                flat
                :class="event.filtered ? 'hide' : 'ma-0 pa-0'"
                height="100%"
              >
                <v-row class="ma-0 pa-0" :style="{height: '100%'}">
                  <v-col
                    class="ma-0 pa-0 mr-1"
                    cols="1"
                    :style="{
                      'background-color': event.colour,
                      maxWidth: '10px',
                      marginLeft: '-1px !important'
                    }"
                  >
                  </v-col>
                  <v-col :cols="event.isLive ? 9 : 11" class="ma-0 pa-0">
                    <div
                      class="subtitle-2 text-wrap py-1"
                      :style="{
                        color: '#000000',
                        display: '-webkit-box',
                        '-webkit-line-clamp': maxLines(event),
                        '-webkit-box-orient': 'vertical',
                        overflow: 'hidden',
                      }"
                    >
                      {{ event.title }}
                    </div>
                  </v-col>
                  <v-col
                    :cols="event.isLive ? 2 : 0"
                    class="ma-0 pa-0"
                    v-if="event.isLive"
                  >
                    <v-chip
                      color="red"
                      text-color="white"
                      pill
                      x-small
                      class="pa-0 px-2 live-chip"
                    >
                      LIVE
                    </v-chip>
                  </v-col>
                </v-row>
                <v-icon
                  v-if="event.translation"
                  class="translation"
                  x-small
                  :color="event.textColour"
                >
                  mdi-headphones
                </v-icon>
              </v-card>
            </template>
          </v-calendar>
          <v-calendar
            class="overflow-x-auto scroller overflow-y-hidden"
            v-else-if="viewMode === 'calendar' && !showNewCalendarView"
            ref="calendar"
            :categories="getCategories(filteredSessionItems)"
            color="primary"
            :event-color="getEventColor"
            :events="filteredSessionItems"
            :first-interval="firstInterval(filteredSessionItems)"
            :interval-count="intervalCount(filteredSessionItems)"
            :interval-height="calendarIntervalHeight"
            interval-minutes="15"
            :short-intervals="false"
            :value="filteredDate"
            type="category"
            category-show-all
            @click:event="eventSelected"
          >
            <template #event="{ event }">
              <v-card
                color="transparent"
                flat
                tile
                class="ma-0 pa-3"
                height="100%"
              >
                <v-row class="ma-0 pa-0">
                  <v-col :cols="event.isLive ? 10 : 12" class="ma-0 pa-0">
                    <div
                      class="subtitle-2 text-wrap"
                      :style="{
                        color: event.textColour,
                        display: '-webkit-box',
                        '-webkit-line-clamp': maxLines(event),
                        '-webkit-box-orient': 'vertical',
                        overflow: 'hidden',
                      }"
                    >
                      {{ event.title }}
                    </div>
                  </v-col>
                  <v-col
                    :cols="event.isLive ? 2 : 0"
                    class="ma-0 pa-0"
                    v-if="event.isLive"
                  >
                    <v-chip
                      color="red"
                      text-color="white"
                      pill
                      x-small
                      class="pa-0 px-2 live-chip"
                    >
                      LIVE
                    </v-chip>
                  </v-col>
                </v-row>
                <v-icon
                  v-if="event.translation"
                  class="translation"
                  x-small
                  :color="event.textColour"
                >
                  mdi-headphones
                </v-icon>
              </v-card>
            </template>
          </v-calendar>
          <div v-else>
            <div v-for="(event, i) in filteredSessionItems" :key="i">
              <v-card
                v-if="!event.filtered"
                class="mb-2"
                :style="{
                  borderRadius: '0px',
                  border: `1px solid ${event.colour}`,
                }"
                flat
                @click="sessionSelected(event)"
              >
                <v-row class="ma-0 pa-0">
                  <v-col
                    class="ma-0 pa-0"
                    cols="1"
                    :style="{
                      'background-color': event.colour,
                      maxWidth: '10px',
                      marginLeft: '-1px !important',
                    }"
                  >
                  </v-col>
                  <v-col class="ma-0 pa-0 ml-1 mr-auto" cols="9">
                    <v-col cols="12" class="ma-1 pa-0">
                      <span class="font-weight-bold">{{ event.title }}</span>
                      <v-icon v-if="event.translation" small class="ml-2"
                        >mdi-headphones</v-icon
                      >
                    </v-col>
                    <v-col cols="12" class="ma-1 pa-1">
                      <v-icon>mdi-calendar-month</v-icon>
                      <span class="ml-1 mr-5">
                        {{ formatDate(event.start) }}
                      </span>
                      <v-icon>mdi-clock-time-eight-outline</v-icon>
                      <span class="ml-1 mr-5">
                        {{ formatStartEnd(event.start, event.end) }}
                      </span>
                      <v-icon>mdi-map-marker</v-icon>
                      <span class="ml-1">
                        {{ event.roomName }}
                      </span>
                    </v-col>
                  </v-col>
                  <v-col cols="2" class="text-right">
                    <v-btn
                      v-if="!$vuetify.breakpoint.mobile && event.isLive"
                      outlined
                      color="primary"
                      @click.stop="viewSession(event)"
                    >
                      <v-icon color="red" class="live-dot">mdi-circle</v-icon>
                      Join
                    </v-btn>

                    <!-- TODO(David): Fix -->
                    <v-btn
                      v-if="$vuetify.breakpoint.mobile && event.isLive"
                      @click.stop=""
                      icon
                    >
                      <v-icon>mdi-eye-arrow-right-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        :max-width="$vuetify.breakpoint.mobile ? '90%' : '50%'"
        :width="$vuetify.breakpoint.mobile ? '90%' : '50%'"
        content-class="dialog-flat"
      >
        <v-card v-if="selectedSession" tile>
          <v-toolbar
            v-if="selectedSession"
            :color="selectedSession.colour"
            flat
            height="40"
            class="ma-0 pa-0"
            dense
          >
            <v-toolbar-title
              class="text-overline ml-5"
              :style="{ color: selectedSession.textColour }"
            >
              <span>{{ $t('entities.session') }}</span>
            </v-toolbar-title>
            <v-chip
              v-if="selectedSession.isLive"
              color="red"
              text-color="white"
              pill
              x-small
              class="pa-0 px-1 mx-2 live-chip-dialog"
            >
              LIVE
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
              v-if="isAuthenticated"
              class="ma-0 pa-0"
              :color="selectedSession.colour"
              :style="{
                color: selectedSession.textColour,
                width: '40px',
                height: '40px',
              }"
              small
              tile
              elevation="0"
              @click.stop="
                if (selectedSession) {
                  bookmarkExecute(selectedSession);
                }
              "
            >
              <v-icon v-if="isABookmark(selectedSession.id)">
                mdi-bookmark-check
              </v-icon>
              <v-icon v-else> mdi-bookmark-plus-outline </v-icon>
            </v-btn>
            <v-btn
              class="ma-0 pa-0"
              color="whitesmoke"
              :style="{
                color: 'gainsboro',
                width: '40px',
                height: '40px',
              }"
              small
              tile
              elevation="0"
              @click.stop="dialog = false"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="ma-0 pa-3">
            <v-col cols="12" class="ma-0 pa-0">
              <span class="text-body-2 font-weight-bold">{{
                selectedSession.title
              }}</span>
              <v-icon v-if="selectedSession.translation" small class="ml-2">
                mdi-headphones
              </v-icon>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <span class="text-caption font-weight-bold text--disabled">
                {{ formatLongDate(selectedSession.start, selectedSession.end) }}
              </span>
              -
              <span class="text-caption font-weight-bold text--disabled">
                {{ selectedSession.roomName }}
              </span>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-3 pt-0" v-if="selectedSession.moderatorLine1">
            <v-col cols="12" class="ma-0 pa-0">
              <span class="text-overline text--disabled">
                {{ $t('session.moderators') }}
              </span>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <div class="text-body-2">
                {{ selectedSession.moderatorLine1 }}
              </div>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-3 pt-0" v-if="selectedSession.theme">
            <v-col cols="12" class="ma-0 pa-0">
              <span class="text-overline text--disabled">
                {{ $t('session.theme') }}
              </span>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0" v-if="selectedSession.theme">
              <v-icon :color="selectedSession.colour"> mdi-circle </v-icon>
              <span class="text-body-2">
                {{ selectedSession.theme }}
              </span>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-3 pt-0" v-if="selectedSession.type">
            <v-col cols="12" class="ma-0 pa-0">
              <span class="text-overline text--disabled">
                {{ $t('session.type') }}</span
              >
            </v-col>
            <v-col cols="12" class="ma-0 pa-0" v-if="selectedSession.type">
              <span class="text-body-2">
                {{ selectedSession.type }}
              </span>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-3 pt-0" v-if="selectedSession.description">
            <v-col cols="12" class="ma-0 pa-0">
              <span class="text-overline text--disabled">
                {{ $t('session.description') }}</span
              >
            </v-col>
            <v-col
              cols="12"
              class="ma-0 pa-0"
              v-if="selectedSession.description"
            >
              <span class="text-body-2">
                {{ selectedSession.description }}
              </span>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-3 pt-0" v-if="selectedSession.isLive">
            <v-col cols="12" class="ma-0 pa-0">
              <v-btn
                outlined
                color="primary"
                @click.stop="viewSession(selectedSession)"
              >
                Join
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-3 pt-0" v-else-if="selectedSession.hasContent">
            <v-col cols="12" class="ma-0 pa-0">
              <v-btn
                outlined
                color="primary"
                @click.stop="viewSession(selectedSession)"
              >
                {{ $t('session.View') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-expansion-panels class="ma-0 pa-0">
            <v-expansion-panel class="ma-0 pa-0">
              <v-expansion-panel-header
                height="40"
                color="#c3ced6"
                class="ma-0 py-0 px-5 text-overline primary--text"
                :style="{
                  height: '40px',
                  minHeight: '40px',
                  borderRadius: '0px',
                  marginLeft: '0px !important',
                }"
              >
                {{ $t('entities.Presentations') }} ({{
                  selectedSession.presentations
                    ? selectedSession.presentations.length
                    : 0
                }})
              </v-expansion-panel-header>
              <v-expansion-panel-content
                class="presentations ma-0 pa-0 my-2"
                v-if="selectedSession.presentations"
              >
                <v-row
                  class="ma-0 pa-0"
                  v-for="(pres, p) in selectedSession.presentations"
                  :key="p"
                >
                  <v-col class="ma-0 pa-0 pl-3" :cols="pres.start ? 10 : 12">
                    <span class="text-body-2 font-weight-bold"
                          :style="canNavigateToPresentation(pres) ? { cursor: 'pointer' } : {}"
                          @click="navigateToPresentation(pres)">
                      {{ pres.title }}
                    </span>
                    <v-btn
                      v-if="pres.description"
                      x-small
                      color="primary"
                      @click.stop="viewPresentationDescripion(pres)"
                    >
                      {{ $t('presentation.description') }}
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="pres.start && showPresentationDurationInProgramme"
                    class="ma-0 pa-0 pr-3 text-caption font-italic text-end"
                    cols="2"
                  >
                    <span>
                      {{ formatStartEnd(pres.start, pres.end) }}
                    </span>
                  </v-col>
                  <v-col class="ma-0 pa-0 pl-3" :cols="pres.start ? 10 : 12">
                    <span
                      class="text-caption font-italic font-weight-black"
                      :style="speakersModuleEnabled && !pres.speakerNameOverride ? { cursor: 'pointer' } : {}"
                      @click="navigateToSpeaker(pres)"
                    >
                      {{ pres.speakerNameOverride ? pres.speakerNameOverride : pres.speakerName }}
                    </span>
                    <span
                      v-if="pres.coAuthors"
                      class="text-caption font-italic">
                      , {{ pres.coAuthors }}
                    </span>
                  </v-col>
                  <v-col
                    v-if="pres.start && showPresentationDurationInProgramme"
                    class="ma-0 pa-0 pr-3 text-caption font-italic text-end"
                    cols="2"
                  >
                    {{ startEndTimeDifference(pres.start, pres.end) }} mins
                  </v-col>
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-divider
                      v-if="
                        selectedSession &&
                        selectedSession.presentations &&
                        p < selectedSession.presentations.length - 1
                      "
                      class="my-2"
                    ></v-divider>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- <v-img
            v-if="selectedSession && selectedSession.thumbnailUri"
            :aspect-ratio="16 / 9"
            width="100%"
            :src="selectedSession.thumbnailUri"
          /> -->
          <!-- <div class="dialog-title">
              <v-btn
                v-if="selectedSession.isLive"
                dark
                arge
                color="green"
                class="ma-1"
                @click.stop=""
              >
                <v-icon dark left> mdi-play </v-icon>
                Join
              </v-btn>
            </div> -->
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog2" width="100%" content-class="dialog-flat">
        <v-card v-if="selectedPresentation">
          <v-toolbar height="40" class="ma-0 pa-0" dense flat>
            <v-toolbar-title class="text-overline ml-5">
              {{ selectedPresentation.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-0 pa-0"
              color="whitesmoke"
              :style="{
                color: 'gainsboro',
                width: '40px',
                height: '40px',
              }"
              small
              tile
              elevation="0"
              @click.stop="dialog2 = false"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="ma-0 pa-3">
            {{ selectedPresentation.description }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import uniqueFilter from '@/filters/unique.filter';
import { AuthGetters } from '@/store/auth/types';
import { PlatformGetters } from '@/store/platform/types';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import {
  ProgrammeData,
  ProgrammePresentationItem,
  ProgrammeSessionItem,
} from '../models/Programme';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { isLight, isDark, lightOrDark } from '../utils/lightordark';

import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '../store/bookmark/types';

const authNamespace = 'auth';
const bookmarkNamespace = 'bookmark';
const platformNamespace = 'platform';
const testColour = "#FF00FF";

@Component({
  components: {},
})
export default class NewProgramme extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public userProgramme!: boolean;

  /* PRIVATE PROPERTIES */

  private loaded: boolean = false;
  private noUserSessions: boolean = false;
  private selectedSessionItem: ProgrammeSessionItem | null = null;
  private viewMode: string = 'calendar';
  private sessionItems: ProgrammeSessionItem[] = [];
  private title: string = '';
  private title_en: string = '';
  private title_fr: string = '';
  private calendarIntervalHeight = 48;
  private dialog = false;
  private dialog2 = false;

  private dateFilter: string[] = [];
  private timeFilter: string[] = [];
  private typeFilter: string[] = [];
  private themeFilter: { theme: string; colour: string }[] = [];
  private roomFilter: {
    roomName: string;
    roomOrder: number;
  }[] = [];

  private selectedDate: string = '';
  private selectedRoom: string = '';
  private selectedTime: string = '';
  private selectedTheme: string = '';
  private selectedType: string = '';
  private filteredDate: string = '';



  private filteredSessionItems: ProgrammeSessionItem[] = [];
  private selectedSession: ProgrammeSessionItem | null = null;
  private selectedPresentation: ProgrammePresentationItem | null = null;

  /* VUEX GETTERS */

  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Bookmark Getters
  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string) => boolean;

  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  // Platform Getters
  @Getter(PlatformGetters.SHOW_PRESENTATION_DURATION_IN_PROGRAMME, {
    namespace: platformNamespace,
  })
  private showPresentationDurationInProgramme!: boolean;

  @Getter(PlatformGetters.SHOW_NEW_CALENDAR_VIEW, {
    namespace: platformNamespace,
  })
  private showNewCalendarView!: boolean;

  @Getter(PlatformGetters.PROGRAMME_LIST_VIEW_DEFAULT, {
    namespace: platformNamespace,
  })
  private programmeListViewDefault!: boolean;

  @Getter(PlatformGetters.DEFAULT_BUTTON_COLOUR, {
    namespace: platformNamespace,
  })
  private defaultButtonColour!: string;

  @Getter(PlatformGetters.SPEAKERS_MODULE_ENABLED, {
    namespace: platformNamespace,
  })
  private speakersModuleEnabled;

  /* VUEX ACTIONS */
  // Bookmarks Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  /* WATCHES */
  @Watch('selectedDate', { immediate: true })
  private selectedDateChanged(value: string) {
    if (this.selectedDate)
    {
      this.setupFilters()
    }
  }

  @Watch('selectedRoom', { immediate: true })
  private selectedRoomChanged(value: string) {
    this.applyFilters();
  }

  @Watch('selectedTheme', { immediate: true })
  private selectedThemeChanged(value: string) {
    this.applyFilters();
  }

  @Watch('selectedTime', { immediate: true })
  private selectedTimeChanged(value: string) {
    this.applyFilters();
  }

  @Watch('selectedType', { immediate: true })
  private selectedTypeChanged(value: string) {
    this.applyFilters();
  }

  @Watch('$route.query', { immediate: false, deep: true })
  private async onQueryChange() {
    await this.loadSessions();
  }

  /* LOCAL GETTERS/SETTERS */
  get locale(): string {
    return this.$i18n.locale;
  }

  get cssVars(): unknown {
    return {
      '--default-button-colour': `${this.defaultButtonColour ? this.defaultButtonColour : '#1976d2'}`,
      '--default-button-text-colour': `${this.defaultButtonColour ? isDark(this.defaultButtonColour) ? 'white' : 'black' : 'white'}`
    };
  }
  private dark(color: string): boolean {
    return isDark(color);
  }

  private light(color: string): boolean {
    return isLight(color);
  }

  private lightOrDark(color: string): string {
    return lightOrDark(color);
  }

  private blackOrWhite(color: string): string {
    return isLight(color) ? 'black' : 'white';
  }

  private test(event) {
    console.log(event);
    return '';
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private async mounted() {
    await this.loadSessions();

    this.viewMode = this.programmeListViewDefault ? 'list' : 'calendar';
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/
  private bookmarkExecute(s: ProgrammeSessionItem) {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const bi = this.getBookmark(s.id);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      const bm: BookmarkItem = {
        end: s.end,
        entityId: s.id,
        entityType: BookmarkEntityType.SESSION,
        type: BookmarkType.VIDEO,
        entityUri: `/${eventCode}/session/${s.id}`,
        start: s.start,
        title: s.title!,
      };
      this.bookmarkItem(bm);
    }
  }

  private applyFilters() {
    this.filteredSessionItems.forEach((s) => {
      s.filtered = false;
      if (this.selectedRoom) {
        if (this.selectedRoom !== s.roomName) {
          s.filtered = true;
        }
      }
      if (this.selectedTime) {
        if (this.selectedTime !== moment(s.start).format('HH:mm')) {
          s.filtered = true;
        }
      }

      if (this.selectedTheme) {
        if (this.selectedTheme !== s.theme) {
          s.filtered = true;
        }
      }

      if (this.selectedType) {
        if (this.selectedType !== s.type) {
          s.filtered = true;
        }
      }
    });

    if (this.viewMode === 'calendar') {
      this.filteredSessionItems.sort((a, b) => {
        return (a.roomOrder ?? 0) - (b.roomOrder ?? 0);
      });
    } else {
      this.filteredSessionItems.sort((a, b) => {
        if (a.start < b.start) {
          return -1;
        }
        if (a.start > b.start) {
          return 1;
        }
        return 0;
      });
    }
  }

  private createFilters() {

    // Date Filter
    const dates = this.sessionItems.map((s) => {
      return moment(s.start)
    });

    const uniqueDatesAsArray = [
      ...new Set(dates.map((date) => date.format("YYYY-MM-DD"))),
    ].map((s) => moment(s))

    uniqueDatesAsArray.sort((a, b) => {
      return a.diff(b);
    });

    this.dateFilter = uniqueDatesAsArray.map(d => d.format('DD/MM/YYYY'));
    this.selectedDate = '';
    uniqueDatesAsArray.forEach(d => {
      if (moment().isSame(d, 'day'))
      {
        this.selectedDate = d.format('DD/MM/YYYY')
        this.setupFilters();
      }
    });

    if (!this.selectedDate) {
      this.selectedDate = this.dateFilter[0];
      this.setupFilters();
    }

    // Theme Filter
    var unique = [];
    const themes = this.sessionItems.map((i) => {
      return { theme: i.theme ?? '', colour: i.colour ?? '' };
    });
    for (let i = 0; i < themes.length; i++) {
      if (themes[i].theme && !unique[themes[i].theme]) {
        this.themeFilter.push(themes[i]);
        unique[themes[i].theme] = 1;
      }
    }
    this.themeFilter.sort((a, b) => {
      const nameA = a.theme?.toUpperCase();
      const nameB = b.theme?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    // Type Filter
    unique = [];
    const types = this.sessionItems.map((i) => {
      return i.type ?? '';
    });
    for (let i = 0; i < types.length; i++) {
      if (types[i] && !unique[types[i]]) {
        this.typeFilter.push(types[i]);
        unique[types[i]] = 1;
      }
    }
    this.typeFilter.sort();

    // Room Filter
    unique = [];
    const rooms = this.sessionItems.map((i) => {
      return { roomName: i.roomName ?? '', roomOrder: i.roomOrder ?? 0 };
    });
    for (let i = 0; i < rooms.length; i++) {
      if (!unique[rooms[i].roomName]) {
        this.roomFilter.push(rooms[i]);
        unique[rooms[i].roomName] = 1;
      }
    }
    this.roomFilter.sort(function (a, b) {
      return a.roomOrder - b.roomOrder;
    });

    // Time Filter TODO: Only show times for currently selected date?
    unique = [];
    const times = this.sessionItems.map((s) => {
      return moment(s.start).format('HH:mm');
    });
    this.timeFilter = times.filter(uniqueFilter);
    this.timeFilter.sort();
  }

  private setupFilters()
  {
    this.filteredSessionItems = this.filterSessionsByDate(this.selectedDate);
    this.filteredDate = moment(this.selectedDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.applyFilters();
  }

  private async eventSelected(ev: any): Promise<void> {
    await this.sessionSelected(ev.event);
  }

  private filterSessionsByDate(date: string): ProgrammeSessionItem[] {
    return this.sessionItems.filter((s) => {
      return moment(s.start).format('DD/MM/YYYY') === date;
    });
  }

  private firstInterval(sessions: ProgrammeSessionItem[]): number {
    const starts = sessions.map((s) => moment(s.start));
    const earliest = moment.min(starts);
    const nearestPastQtr = this.nearestPastMinutes(15, moment(earliest));
    const startMidnight = nearestPastQtr.clone().startOf('day');
    const diffStartMinutes = nearestPastQtr.diff(startMidnight, 'minutes');
    const startInterval = Math.floor(diffStartMinutes / 15);
    return startInterval;
  }

  private formatDate(start: Date) {
    let s = '';
    if (start) {
      s = moment(start).format('DD/MM');
    }
    return s;
  }

  private formatDateWithYear(date: Date)
  {
    let s = '';
    if (date) {
      s = moment(date).format('DD/MM/YYYY');
    }
    return s;
  }

  private formatStartEnd(start: Date, end: Date) {
    let s = '';
    let e = '';
    if (start) {
      s = moment(start).format('HH:mm');
    }
    if (end) {
      e = moment(end).format('HH:mm');
    }
    return `${s}-${e}`;
  }

  private startEndTimeDifference(start: Date, end: Date) {
    if (start && end) {
      return moment(end).diff(moment(start), 'minutes');
    }
  }

  private formatLongDate(start: Date, end: Date) {
    let s = '';
    let e = '';
    if (start) {
      s = moment(start).locale(this.$i18n.locale).format('ddd DD MMM HH:mm');
    }

    if (end) {
      e = moment(end).locale(this.$i18n.locale).format('HH:mm');
    }
    return `${s} - ${e}`;
  }

  private getCategories(
    sessions: ProgrammeSessionItem[],
  ): (string | undefined)[] {
    const categories = [...new Set(sessions.map((s) => s.category))];
    return categories;
  }

  private getEventColor(event: ProgrammeSessionItem): string {
    var colour = '';
    if (event.colour) {
      colour = event.colour;
    } else {
      colour = '#0000FF';
    }

    if (colour.length === 9) {
      if (event.filtered) {
        colour = colour.slice(0, -2) + '80';
      }
    } else {
      if (event.filtered) {
        colour += '80';
      } else {
        colour += 'FF';
      }
    }
    return colour;
  }

  private getEventColor2(event: ProgrammeSessionItem): string {
    var colour = '';
    if (event.colour) {
      colour = event.colour;
    } else {
      colour = '#0000FF';
    }

    if (colour.length === 9) {
        colour = colour.slice(0, -2) + '20';
    } else {
        colour += '20';
    }
    return colour;
  }

  private intervalCount(sessions: ProgrammeSessionItem[]): number {
    const starts = sessions.map((s) => moment(s.start));
    const ends = sessions.map((s) => moment(s.end));
    const earliest = moment.min(starts);
    const latest = moment.max(ends);
    const nearestPastQtr = this.nearestPastMinutes(15, moment(earliest));
    const midnight = nearestPastQtr.clone().startOf('day');
    const diffStartMinutes = nearestPastQtr.diff(midnight, 'minutes');
    const startInterval = Math.floor(diffStartMinutes / 15);
    const nearestFutureQtr = this.nearestFutureMinutes(15, moment(latest));
    const diffEndMinutes = nearestFutureQtr.diff(midnight, 'minutes');
    const endInterval = Math.floor(diffEndMinutes / 15);
    const temp = endInterval - startInterval;
    return temp;
  }

  private async LoadSelectedSession(sessionId: string) {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      let url = `/api/v2/platform/${platformId}/programme/${sessionId}`;
      const res = await Vue.$http.get<ProgrammeSessionItem>(url);
      if (res.data) {
        this.selectedSession = res.data;
        if (
          this.selectedSession.presentations &&
          this.selectedSession.presentations.length > 0
        ) {
          this.selectedSession.presentations.sort((a, b) => {
            return a.order - b.order;
          });
        }
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    } catch (error) {
      console.log(error);
      this.dialog = false;
    }
  }

  private async loadSessions() {
    const platformId = sessionStorage.getItem('platformId') ?? '';

    try {
      let url = `/api/v2/platform/${platformId}/programme/${window.location.search}`;
      const pData = await Vue.$http.get<ProgrammeData>(url);
      if (pData.data) {
        this.title = pData.data.title;
        this.title_en = pData.data.title_en;
        this.title_fr = pData.data.title_fr;
        this.calendarIntervalHeight = pData.data.calendarIntervalHeight ?? 48;
        if (this.userProgramme) {
          const userSessions = pData.data.sessions.filter((s) => {
            return this.isABookmark(s.id);
          });
          if (userSessions.length < 1) {
            this.noUserSessions = true;
          } else {
            this.noUserSessions = false;
          }
          this.sessionItems = userSessions;
        } else {
          this.sessionItems = pData.data.sessions;
        }
      }
      this.createFilters();

      //this.selectedDate = moment().format('DD/MM/YYYY');
      (this.$refs.calendar as any);
      this.loaded = true;
    } catch (e: any) {
      AppInsightsLogger.logError('Programme - created failed', undefined, true);
      AppInsightsLogger.logException(e, false);
    }
  }

  private maxLines(ev: ProgrammeSessionItem): number {
    const s = moment(ev.start);
    const e = moment(ev.end);
    const time = e.diff(s, 'minutes');
    const maxLines = Math.floor(time / 10);
    return maxLines;
  }

  private navigateToSpeaker(pres: ProgrammePresentationItem) {
    if (pres.speakerNameOverride) return;

    if (!this.speakersModuleEnabled) return;

    if (!pres.speakerId) return;

    this.$router.push({
      name: 'speakers',
      query: {
        search: pres.speakerName,
        id: pres.speakerId,
        speaker: 'true',
      },
    });
  }

  private async navigateToPresentation(pres: ProgrammePresentationItem) {
    if (!pres.id || !pres.sessionId) return;

    if (!pres.isEventPortalPresentation && !(pres.isPresentation || pres.isAPoster)) return;

    await this.$router.push({
          name: 'presentation',
          params: { sessionId: pres.sessionId, presentationId: pres.id },
        });
  }

  private canNavigateToPresentation(pres: ProgrammePresentationItem): boolean {
    if (!pres.id || !pres.sessionId) return false;

    if (!(pres.isPresentation || pres.isAPoster)) return false;

    if (!pres.isEventPortalPresentation) return false;

    return true
  }

  private nearestFutureMinutes(
    interval: number,
    someMoment: moment.Moment,
  ): moment.Moment {
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  }

  private nearestPastMinutes(
    interval: number,
    someMoment: moment.Moment,
  ): moment.Moment {
    const roundedMinutes =
      Math.floor(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  }

  private async openNewTab(url: string) {
    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push(url);
    }
  }

  private async sessionSelected(session: ProgrammeSessionItem): Promise<void> {
    if (session.externalContentUri) {
      this.openNewTab(session.externalContentUri);
    } else {
      await this.LoadSelectedSession(session.id);
    }
  }

  private viewPresentationDescripion(
    presentation: ProgrammePresentationItem,
  ): void {
    if (presentation.description) {
      this.selectedPresentation = presentation;
      this.dialog2 = true;
    } else {
      this.dialog2 = false;
      this.selectedPresentation = null;
    }
  }

  private hidePresentationDescription(): void {
    this.dialog2 = false;
    this.selectedPresentation = null;
  }

  private async viewSession(
    session: ProgrammeSessionItem | null,
  ): Promise<void> {
    if (session && session.hasContent) {
      if (session.externalContentUri) {
        this.openNewTab(session.externalContentUri);
      } else {
        this.$router.push({
          name: 'session',
          params: { sessionId: session.id },
        });
      }
    }
  }
}
</script>

<style scoped>

.date-btn {
  border-radius: 4px !important;
  border: none !important;
}

.hide {
  display: none;
}

.selected {
  background-color: var(--default-button-colour) !important;
  color: var(--default-button-text-colour) !important;
}

.live-chip {
  border: 1px solid white !important;
  position: absolute;
  right: 5px;
  top: 5px;
}
.live-chip-dialog {
  border: 1px solid white !important;
}

.live-dot {
  position: absolute;
  right: -23px;
  top: -19px;
}

.scroller {
  width: 100%;
  font-size: 12px;
}

.scroller::-webkit-scrollbar {
  width: 10px;
}

.scroller::-webkit-scrollbar-track {
  background: #b0b0b050;
  border-left: 0;
  border-radius: 7px;
}

.scroller::-webkit-scrollbar-thumb {
  background: #b0b0b090;
  border: 0;
  border-radius: 7px;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #00000030;
}

.scroller::-webkit-scrollbar-thumb:active {
  background: #00000090;
}

.dialog-flat {
  border-radius: 0px !important;
}

.dialog-flat .v-toolbar__content,
.dialog-flat .v-toolbar__extension {
  padding: 0;
}

.presentations > .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-calendar-daily__intervals-head,
.v-calendar-daily__intervals-body {
  background-color: white;
}

.translation {
  position: absolute !important;
  bottom: 2px;
  right: 2px;
}
</style>
