<template>
  <v-dialog v-model="dialog" width="600px">
    <template #activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn icon>
            <v-icon
              :color="navBarTextColour"
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
            >
              mdi-account-voice
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('entities.SpeakerMod') }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ platformUser.userName }}
      </v-card-title>
      <div v-if="loading" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-card-text v-if="!loading" class="pt-5">
        <span
          class="text-overline text-decoration-underline"
          v-if="presentations && presentations.length > 0"
        >
          {{ $t('entities.Presentations') }}
        </span>
        <v-row
          dense
          class="ma-0 pa-0"
          v-for="(pres, p) in presentations"
          :key="p"
        >
          <v-col class="ma-0 pa-0" cols="12">
            <span class="font-italic">
              {{ pres.session.title }}
            </span>
          </v-col>
          <v-col class="ma-0 pa-0" cols="12">
            <span class="font-italic">
              {{ formatStartEnd(pres.start, pres.end) }} -
              {{ pres.session.roomName }}
            </span>
          </v-col>
          <v-col cols="12" class="ma-0 pa-0 pl-3">
            <span class="text-body-2 font-weight-bold">
              {{ pres.title }}
            </span>
          </v-col>

          <v-col cols="12" class="ma-0 pa-0">
            <v-divider
              v-if="presentations && p < presentations.length - 1"
              class="my-2"
            ></v-divider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="!loading" class="pt-5">
        <span
          class="text-overline text-decoration-underline"
          v-if="sessions && sessions.length > 0"
        >
          {{ $t('entities.SessionModerator') }}
        </span>
        <v-row class="ma-0 pa-0" v-for="(sess, s) in sessions" :key="s">
          <v-col class="ma-0 pa-0" cols="12">
            <span class="font-italic">
              {{ formatStartEnd(sess.start, sess.end) }} -
              {{ sess.roomName }}
            </span>
          </v-col>
          <v-col cols="12" class="ma-0 pa-0 pl-3">
            <span class="text-body-2 font-weight-bold">
              {{ sess.title }}
            </span>
          </v-col>

          <v-col cols="12" class="ma-0 pa-0">
            <v-divider
              v-if="sessions && s < sessions.length - 1"
              class="my-2"
            ></v-divider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Avatar from '@/components/Avatar.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PlatformGetters } from '@/store/platform/types';
import { PlatformUserItem } from '@/store/platformUser/types';
import { Getter } from 'vuex-class';
import { AppInsightsLogger } from '@/services/appInsightsLogger';
import { PresentationItem, SessionItem } from '@/store/session/types';
import moment from 'moment';
const platformNamespace = 'platform';

@Component({
  components: {
    Avatar,
  },
})
export default class PresentationDialog extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public platformUser!: PlatformUserItem;
  /* PRIVATE PROPERTIES */
  private dialog = false;
  private loading = false;
  private presentations: PresentationItem[] = [];
  private sessions: SessionItem[] = [];

  /* VUEX GETTERS */
  // Platform Getters
  @Getter(PlatformGetters.NAV_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarTextColour!: string;

  @Getter(PlatformGetters.NAV_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarBackgroundColour!: string;
  /* VUEX ACTIONS */
  /* WATCHES */
  @Watch('dialog')
  private async onDialogChange(val: boolean) {
    if (this.dialog) {
      await this.loadUserDetails();
    }
  }
  /* LOCAL GETTERS/SETTERS */
  /* PRIVATE METHODS*/
  private formatStartEnd(start: Date, end: Date) {
    const s = moment(start).format('DD/MM/YYYY HH:mm');
    const e = moment(end).format('HH:mm');
    return `${s}-${e}`;
  }

  private async loadUserDetails() {
    this.loading = true;
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/users/${this.platformUser.userId}/?speaker=true`,
      );

      if (res.data) {
        this.presentations = res.data.presentations;
        this.sessions = res.data.sessions;
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PresentationDialog - loadUserDetails failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.loading = false;
  }
}
</script>

<style scoped></style>
