<template>
  <div class="fill-height">
    <background v-if="!$vuetify.breakpoint.mobile" :overlay="true" />

    <v-container
      class="fill-height"
      v-if="live && (!this.liveOrUpcomingSessions || this.liveOrUpcomingSessions.length < 1)"
      fluid
      pa-0
    >
      <v-row align="center" justify="center" class="fill-height" dense>
        <v-col
          class="fill-height d-flex flex-column justify-center align-center"
        >
          <v-card class="justify-center align-center">
            <v-card-text class="text-overline">{{
              $t('session.noLiveSessions')
            }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div v-else>
      <v-tooltip
        v-if="!$vuetify.breakpoint.mobile && sessions && sessions.length > 0"
        left
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="mb-10 mr-8"
            fab
            small
            absolute
            bottom
            right
            v-bind="attrs"
            v-on="on"
            @click="scrollTop()"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('common.Return') }}</span>
      </v-tooltip>

      <SessionFilters
        v-if="!(live || comingup)"
        class="pa-5"
        :poster="poster"
      ></SessionFilters>

      <BannerAd
        v-if="!(live || comingup) && ads && ads.length > 0"
        :ads="ads"
        :poster="poster"
      />

      <div v-if="live && liveOrUpcomingSessions && liveOrUpcomingSessions.length > 0" class="pa-5"><h2>{{ $t("session.LiveNow") }}</h2></div>

      <div v-else-if="comingup && liveOrUpcomingSessions && liveOrUpcomingSessions.length > 0" class="pa-5"><h2>{{ $t("session.ComingUp") }}</h2></div>

      <div
        :class="
          $vuetify.breakpoint.mobile
            ? 'ma-0 pa-0'
            : 'overflow-y-auto scroller px-5 ma-0 py-0'
        "
        :style="{ height: scollerHeight + 'px' }"
        v-if="(sessions && sessions.length > 0) || (liveOrUpcomingSessions && liveOrUpcomingSessions.length > 0)"
      >
        <div id="top"></div>

        <div class="d-flex flex-wrap justify-space-around">
          <SessionAd
            v-if="!(live || comingup) && ads && ads.length > 0"
            key="slot1"
            :ads="ads"
            :poster="poster"
            position="slot1"
            class="mb-5"
          />

          <SessionAd
            v-if="!(live || comingup) && ads && ads.length > 0"
            key="slot2"
            :ads="ads"
            :poster="poster"
            position="slot2"
            class="mb-5"
          />

            <SessionCard
              v-for="s in liveOrUpcomingSessions"
              :key="s.id"
              :session="s"
              :listView="listView"
              class="mb-5 pa-0"
              :style="{
                cursor: 'pointer',
                width: listView ? '100%' : '',
              }"
              @nocontent="dialog = true"
              @posterSelected="viewPosterDescription"
            />

            <SessionCard
              v-for="s in sessions"
              :key="s.id"
              :session="s"
              :listView="listView"
              class="mb-5 pa-0"
              :style="{
                cursor: 'pointer',
                width: listView ? '100%' : '',
              }"
              @nocontent="dialog = true"
              @posterSelected="viewPosterDescription"
            />



          <!-- Hack to get the last row of cols inline -->
          <SessionCard v-if="!$vuetify.breakpoint.mobile" class="mb-10 pa-0" />
          <SessionCard v-if="!$vuetify.breakpoint.mobile" class="mb-10 pa-0" />
          <SessionCard v-if="!$vuetify.breakpoint.mobile" class="mb-10 pa-0" />
          <SessionCard v-if="!$vuetify.breakpoint.mobile" class="mb-10 pa-0" />
          <SessionCard v-if="!$vuetify.breakpoint.mobile" class="mb-10 pa-0" />
          <SessionCard v-if="!$vuetify.breakpoint.mobile" class="mb-10 pa-0" />
          <SessionCard v-if="!$vuetify.breakpoint.mobile" class="mb-10 pa-0" />
          <SessionCard v-if="!$vuetify.breakpoint.mobile" class="mb-10 pa-0" />
          <SessionCard v-if="!$vuetify.breakpoint.mobile" class="mb-10 pa-0" />
          <SessionCard v-if="!$vuetify.breakpoint.mobile" class="mb-10 pa-0" />

          <div v-intersect.quiet="intersected" class="searchlp" />
        </div>
      </div>

      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-text class="pt-5">
            <p class="ma-0 pa-0 text-center subtitle-1">
              {{ $t('session.noContent') }}
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="dialog = false">
              {{ $t('common.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog2" width="100%" content-class="dialog-flat">
        <v-card v-if="selectedPoster">
          <v-toolbar height="40" class="ma-0 pa-0" dense flat>
            <v-toolbar-title class="text-overline ml-5">
              {{ selectedPoster.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-0 pa-0"
              color="whitesmoke"
              :style="{
                color: 'gainsboro',
                width: '40px',
                height: '40px',
              }"
              small
              tile
              elevation="0"
              @click.stop="dialog2 = false"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="ma-0 pa-3">
            {{ selectedPoster.description }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import Background from '@/components/Background.vue';
import BannerAd from '@/components/BannerAd.vue';
import SessionAd from '@/components/SessionAd.vue';
import SessionCard from '@/components/SessionCard.vue';
import SessionFilters from '@/components/SessionFilters.vue';
import { session } from '@/store/session';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AdItem } from '../models/AdItem';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { PlatformGetters } from '../store/platform/types';
import {
  SessionActions,
  SessionGetters,
  SessionItem,
} from '../store/session/types';
const sessionNamespace = 'session';
const platformNamespace = 'platform';

Component.registerHooks(['beforeRouteUpdate']);

@Component({
  components: {
    Background,
    BannerAd,
    SessionAd,
    SessionCard,
    SessionFilters,
  },
})
export default class Sessions extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public live!: boolean;

  @Prop()
  public poster!: boolean;

  @Prop()
  public comingup!: boolean;

  /* PRIVATE PROPERTIES */
  private PAGINATION_LENGTH = 25;
  private ads: AdItem[] = [];
  private dialog = false;
  private dialog2 = false;
  private selectedPoster: SessionItem | null = null;
  private liveOrUpcomingSessions: SessionItem[] = [];

  /* VUEX GETTERS */
  // Platform Getters
  @Getter(PlatformGetters.PORTAL_BACKGROUND_URI, {
    namespace: platformNamespace,
  })
  private portalBackgroundUri!: string;

  // Session Getters
  @Getter(SessionGetters.CURRENT_PAGE, { namespace: sessionNamespace })
  private currentPage!: number;

  @Getter(SessionGetters.LOADING_SESSIONS, { namespace: sessionNamespace })
  private loading!: boolean;

  @Getter(SessionGetters.SESSIONS, { namespace: sessionNamespace })
  private sessions!: SessionItem[];

  @Getter(SessionGetters.TOTAL_SESSION_COUNT, { namespace: sessionNamespace })
  private totalSessionCount!: number;

  @Getter(SessionGetters.LIST_VIEW, { namespace: sessionNamespace })
  private listView!: boolean;

  /* VUEX ACTIONS */

  // Session Actions
  @Action(SessionActions.APPLY_INITIAL_QUERY_FILTERS, {
    namespace: sessionNamespace,
  })
  private applyInitialQueryFilters!: () => void;

  @Action(SessionActions.SET_SESSION_DATA, { namespace: sessionNamespace })
  private setSessionData!: (
    data: { items: SessionItem[]; totaItemsCount: number } | undefined,
  ) => void;

  @Action(SessionActions.SET_CURRENT_PAGE, { namespace: sessionNamespace })
  private setCurrentPage!: (page: number) => Promise<void>;

  @Action(SessionActions.SET_LOADING_SESSIONS, { namespace: sessionNamespace })
  private setLoadingSessions!: (val: boolean) => void;

  /* WATCHES */
  @Watch('$route.query', { immediate: false, deep: true })
  private async onQueryChange() {
    this.setLoadingSessions(true);
    this.setCurrentPage(1);
    this.clearSessionItems();
    this.applyInitialQueryFilters();
    await this.loadData();
    this.setLoadingSessions(false);
  }

  /* LOCAL GETTERS/SETTERS */
  get cssVars(): unknown {
    return {
      '--bg-image': `url('${this.portalBackgroundUri}')`,
    };
  }
  get paginationLength(): number {
    return Math.ceil(this.totalSessionCount / 12);
  }

  get scollerHeight(): number | string {
    if (this.live || this.comingup) {
      return "auto"
    }
    let height = window.innerHeight - 245;
    if (
      this.ads &&
      this.ads.length > 0 &&
      this.ads.filter((a) => a.type.includes('Banner')).length > 0
    ) {
      height = height - 110;
    }
    return height;
  }

  get page(): number {
    return this.currentPage;
  }
  set page(value: number) {
    this.setCurrentPage(value);
  }

  /* PRIVATE METHODS*/
  private async beforeMount() {
    this.setLoadingSessions(true);
    this.clearSessionItems();
    this.setCurrentPage(1);
    await this.loadData();
    this.setLoadingSessions(false);
  }

  private clearSessionItems() {
    this.setSessionData(undefined);
  }

  private async intersected() {
    if (
      !this.loading &&
      this.currentPage * this.PAGINATION_LENGTH < this.totalSessionCount
    ) {
      this.setCurrentPage(this.currentPage + 1);
      await this.loadData();
    }
  }

  private async loadData(): Promise<void> {
    this.setLoadingSessions(true);
    const platformId = sessionStorage.getItem('platformId') ?? '';

    let queryString = '?page=' + this.currentPage;
    if (this.live) {
      queryString = queryString + '&live=true';
    } else if (this.comingup) {
      queryString = queryString + `&comingup=true`;
    } else if (this.poster) {
      queryString = queryString + `&poster=true`;
    }

    if (window.location.search) {
      queryString = queryString + '&' + window.location.search.substring(1); // remove ?
    }

    let adsQueryString = '';
    if (window.location.search) {
      adsQueryString = `${window.location.search}&datype=vod`;
    } else {
      adsQueryString = '?datype=vod';
    }

    try {
      if (!(this.live || this.comingup)) {
        const res = await Vue.$http.get<AdItem[]>(
          `/api/platform/${platformId}/sponsors/sda${adsQueryString}`,
        );
        this.ads = res.data;
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Sessions - loadSessionData failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }

    try {
      const sessionData = await Vue.$http.get(
        `/api/platform/${platformId}/sessions${queryString}`,
      );
      if (this.live || this.comingup) {
        this.liveOrUpcomingSessions.push(...sessionData.data.items);
      } else {
        this.setSessionData(sessionData.data);
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Sessions - loadSessionData failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.setLoadingSessions(false);
  }

  private scrollTop() {
    const e = document.getElementById('top');
    if (e) {
      e.scrollIntoView();
    }
  }

  private viewPosterDescription(poster: SessionItem): void {
    if (poster.description) {
      this.selectedPoster = poster;
      this.dialog2 = true;
    } else {
      this.dialog2 = false;
      this.selectedPoster = null;
    }
  }

  private hidePosterDescription(): void {
    this.dialog2 = false;
    this.selectedPoster = null;
  }
}
</script>

<style scoped>
.bg {
  width: 100%;
  background-image: var(--bg-image);
  background-size: cover;
  position: fixed;
  z-index: -1;
}
.bg::before {
  content: '';
  background-color: rgb(249, 249, 249);
  opacity: 0.7;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}

.scroller {
  width: calc(100% - 20px);
  font-size: 12px;
  overflow-x: hidden;
  z-index: 9999;
}

.scroller::-webkit-scrollbar {
  width: 10px;
}

.scroller::-webkit-scrollbar-track {
  background: #b0b0b050;
  border-left: 0;
}

.scroller::-webkit-scrollbar-thumb {
  background: #b0b0b090;
  border: 0;
  border-radius: 7px;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #00000030;
}

.scroller::-webkit-scrollbar-thumb:active {
  background: #00000090;
}
</style>
