import { GetterTree } from 'vuex';
import { RootState } from '../types';
import {
  SignalrGetters,
  SignalrGroup,
  SignalrMessage,
  SignalrState,
} from './types';

export const getters: GetterTree<SignalrState, RootState> = {
  [SignalrGetters.SIGNALR_CONNECTED](state: SignalrState): boolean {
    return state.signalrConnected;
  },

  [SignalrGetters.USER_CONNECTED](state: SignalrState): boolean {
    return state.userConnected;
  },

  [SignalrGetters.CURRENT_GROUP](
    state: SignalrState,
  ): SignalrGroup | undefined {
    return state.currentGroup;
  },

  [SignalrGetters.CURRENT_GROUP_MESSAGES](
    state: SignalrState,
  ): SignalrMessage[] {
    return state.currentGroupMessages;
  },

  [SignalrGetters.CURRENT_MESSAGE_BY_ID]: (state: SignalrState) => (
    id: string,
  ): SignalrMessage | undefined => {
    return state.currentGroupMessages.find((m) => m.id === id);
  },
};
