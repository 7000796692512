<template>
  <v-container fluid>
    <v-card flat class="pa-3">
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
        v-if="editItem && loaded"
      >
        <v-form ref="form" :lazy-validation="true" @submit.prevent="submit">
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
              <validation-provider
                v-slot="{ errors }"
                name="Title"
                rules="required"
              >
                <v-text-field
                  v-model="editItem.title"
                  ref="ptitle"
                  label="Title"
                  :error-messages="errors"
                  required
                  @change="dirty = true"
                  append-outer-icon="mdi-content-copy"
                  @click:append-outer="copySessionTitle"
                >
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
              <validation-provider
                v-slot="{ errors }"
                name="Custom Code"
                rules="max:50"
              >
                <v-text-field
                  v-model="editItem.customCode"
                  label="Custom code"
                  :error-messages="errors"
                  @change="dirty = true"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
              <validation-provider
                v-slot="{ errors }"
                name="Order"
                rules="required"
              >
                <v-text-field
                  v-model="editItem.order"
                  label="Order"
                  :error-messages="errors"
                  @change="dirty = true"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
              <validation-provider
                v-slot="{ errors }"
                name="Session"
                rules="required"
              >
                <v-autocomplete
                  v-model="editItem.sessionId"
                  :items="sessions"
                  item-text="title"
                  item-value="id"
                  label="Session"
                  persistent-hint
                  single-line
                  :error-messages="errors"
                  @change="handleSessionChange"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
              <validation-provider v-slot="{ errors }" name="Speaker">
                <v-autocomplete
                  v-model="editItem.speakerId"
                  :items="participants"
                  item-text="fullName"
                  item-value="id"
                  label="Speaker"
                  persistent-hint
                  single-line
                  :error-messages="errors"
                  @change="dirty = true"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
              <validation-provider v-slot="{ errors }" name="Type">
                <v-combobox
                  v-model="editItem.type"
                  :items="types"
                  label="Type"
                  :error-messages="errors"
                  @change="dirty = true"
                >
                </v-combobox>
              </validation-provider>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
              <validation-provider v-slot="{ errors }" name="Theme">
                <v-combobox
                  v-model="editItem.theme"
                  :items="themes"
                  label="Theme"
                  :error-messages="errors"
                  @change="dirty = true"
                >
                </v-combobox>
              </validation-provider>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
              <validation-provider
                v-slot="{ errors }"
                name="Start Time"
                rules="required"
              >
                <v-menu
                  v-model="startTimePicker"
                  ref="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startTimeOnly"
                      label="Start time"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors"
                      @change="dirty = true"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="startTimePicker"
                    v-model="startTimeOnly"
                    full-width
                    format="24hr"
                    :allowed-minutes="allowedStep"
                    @click:minute="startTimePicker = false"
                  >
                  </v-time-picker>
                </v-menu>
              </validation-provider>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
              <validation-provider
                v-slot="{ errors }"
                name="End time"
                rules="required"
              >
                <v-menu
                  v-model="endTimePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endTimeOnly"
                      label="End time"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors"
                      @change="dirty = true"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="editItem && endTimePicker"
                    v-model="endTimeOnly"
                    full-width
                    format="24hr"
                    :allowed-minutes="allowedStep"
                    @click:minute="endTimePicker = false"
                  >
                  </v-time-picker>
                </v-menu>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <validation-provider v-slot="{ errors }" name="Keywords">
                <v-text-field
                  v-model="editItem.keywords"
                  label="Keywords"
                  :error-messages="errors"
                  @change="dirty = true"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <validation-provider v-slot="{ errors }" name="CoAuthors">
                <v-text-field
                  v-model="editItem.coAuthors"
                  label="CoAuthors"
                  :error-messages="errors"
                  @change="dirty = true"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
              <v-checkbox
                class="ma-0 pa-0"
                v-model="editItem.isEventPortalPresentation"
                label="Show in portal"
                color="primary"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
              <v-checkbox
                class="ma-0 pa-0"
                v-model="editItem.isAPoster"
                label="Is a poster"
                color="primary"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <validation-provider v-slot="{ errors }" name="Description">
                <v-textarea
                  v-model="editItem.description"
                  label="Description"
                  :error-messages="errors"
                  @change="dirty = true"
                ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-btn class="mr-4" @click="handleCancel"> Cancel </v-btn>
            <v-btn class="mr-4" type="submit" :disabled="invalid"> Save </v-btn>
          </v-row>
        </v-form>
      </validation-observer>
      <v-card v-else flat class="d-flex align-center justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import {
  ProgrammeParticipantItem,
  ProgrammePresentationItem,
  ProgrammePresentationItemEditData,
  ProgrammeRoomItem,
  ProgrammeSessionItem,
} from '@/models/Programme';
import { PresentationItem, SessionItem } from '@/store/session/types';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  extend,
} from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class AddEditPresentation extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public item!: PresentationItem;

  /* PRIVATE PROPERTIES */
  private editItem: ProgrammePresentationItem = {
    id: '00000000-0000-0000-0000-000000000000',
    start: new Date(),
    end: new Date(),
    order: 0,
  };

  private allowedStep = (m) => m % 5 === 0;
  private dirty = false;
  private endTimeOnly: string = '';
  private endTimePicker = false;
  private eventStart: Date = new Date();
  private loaded = false;
  private participants: ProgrammeParticipantItem[] = [];
  private sessions: ProgrammeSessionItem[] = [];
  private startDateOnly: string = '';
  private startTimeOnly: string = '';
  private startTimePicker = false;
  private themes: string[] = [];
  private types: string[] = [];
  private sessionChanged = false;

  /* VUEX GETTERS */
  /* VUEX ACTIONS */

  /* WATCHES */
  @Watch('item')
  private async itemChange(val: ProgrammeSessionItem) {
    if (val) {
      this.loaded = false;
      await this.loadItem();
      this.loaded = true;
    }
  }
  /* LOCAL GETTERS/SETTERS */
  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  // private mounted() {}
  private async mounted() {
    await this.loadItem();
    this.loaded = true;
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/
  private copySessionTitle() {
    var session = this.sessions.find((s) => s.id === this.editItem.sessionId);
    if (session) {
      this.$set(this.editItem, 'title', session.title);
    }
  }

  private async handleCancel() {
    if (this.dirty) {
      const res = await this.$dialog.confirm({
        title: 'Discard changes?',
        text: 'Are you sure you want to discard your changes?',
        actions: {
          false: 'Cancel',
          true: 'Discard',
        },
      });

      if (res) {
        if (this.item.id || this.item.sessionId) {
          this.$emit('cancel');
        } else {
          this.$router.push({ name: 'sessionlist' });
        }
      }
    } else {
      if (this.item.id || this.item.sessionId) {
        this.$emit('cancel');
      } else {
        this.$router.push({ name: 'sessionlist' });
      }
    }
  }

  private handleSessionChange(id: string) {
    this.dirty = true;
    this.sessionChanged = true;
    const session = this.sessions.find((s) => s.id === id);

    this.startDateOnly =
      session && session.start
        ? moment(session.start).format('YYYY-MM-DD')
        : moment(this.eventStart).format('YYYY-MM-DD');
    this.startTimeOnly =
      session && session.start
        ? moment(session.start).format('HH:mm')
        : '07:00';
    this.endTimeOnly =
      session && session.end ? moment(session.end).format('HH:mm') : '08:00';
  }

  private async loadItem() {
    this.sessionChanged = false;
    const platformId = sessionStorage.getItem('platformId') ?? '';

    if (this.item.sessionId) {
      this.editItem.sessionId = this.item.sessionId;
    }

    if (this.item.start) {
      this.editItem.start = this.item.start;
    }

    if (this.item.end) {
      this.editItem.end = this.item.end;
    }

    try {
      let url = `/api/v2/platform/${platformId}/programme/presentations/${
        this.item.id ? this.item.id : this.editItem.id
      }`;
      const pData = await Vue.$http.get<ProgrammePresentationItemEditData>(url);
      if (pData.data) {
        this.editItem = pData.data.item ?? this.editItem;

        this.startDateOnly = this.item.start
          ? moment(this.editItem.start).format('YYYY-MM-DD')
          : moment(pData.data.eventStart).format('YYYY-MM-DD');
        this.startTimeOnly = this.item.start
          ? moment(this.editItem.start).format('HH:mm')
          : '07:00';
        this.endTimeOnly = this.item.end
          ? moment(this.editItem.end).format('HH:mm')
          : '08:00';

        this.sessions = pData.data.sessions;
        this.participants = pData.data.participants;
        this.themes = pData.data.themes;
        this.types = pData.data.types;
        this.eventStart = pData.data.eventStart;
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async submit() {
    if (this.$refs.observer) {
      var res = await (this.$refs.observer as any).validate();
      if (!res) return;

      this.editItem.start = moment
        .utc(`${this.startDateOnly} ${this.startTimeOnly}`)
        .toDate();
      this.editItem.end = moment
        .utc(`${this.startDateOnly} ${this.endTimeOnly}`)
        .toDate();

      const platformId = sessionStorage.getItem('platformId') ?? '';

      try {
        let url = `/api/v2/platform/${platformId}/programme/presentations`;
        const res = await Vue.$http.post<ProgrammePresentationItem>(
          url,
          this.editItem,
        );
        if (res.data) {
          if (this.item.id || this.item.sessionId) {
            if (this.sessionChanged) {
              this.$emit('close', { item: res.data, refresh: true });
            }
            this.$emit('close', { item: res.data, refresh: false });
          } else {
            this.$router.push({ name: 'sessionlist' });
          }
        }
      } catch (error) {
        this.$dialog.message.error('Save failed', {
          position: 'bottom-right',
        });
        console.error(error);
      }
    }
  }
}
</script>

<style scoped></style>
