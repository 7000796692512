<template>
  <div>
    <v-menu
      v-if="!$vuetify.breakpoint.mobile"
      v-model="bookmarksOpen"
      left
      bottom
      offset-y
      transition="slide-y-transition"
      open-on-hover
      :close-on-content-click="false"
      close-delay="50"
    >
      <template #activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon :style="{ color: bannerBarTextColour }"
            >mdi-bookmark-multiple-outline</v-icon
          >
        </v-btn>
      </template>
      <v-card class="dropbox">
        <v-toolbar dense flat light color="grey lighten-2">
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('bannerBar.myBookmarks') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn tile icon>
            <v-icon @click="bookmarksOpen = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card flat class="ma-0 pa-0 list-height">
          <v-card
            v-if="bookmarks.length < 1"
            flat
            class="ma-0 pa-0 list-height"
            align-content="center"
            justify="center"
          >
            <v-container class="ma-0 pa-0 fill-height">
              <v-row dense justify="center">
                <v-col cols="10">
                  <p class="ma-0 pa-0 text-subtitle-2 text-center">
                    {{ $t('common.noBookmarks') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-container v-else class="overflow-y-auto light-chat ma-0 pa-0">
            <v-list>
              <v-list-group
                v-for="(bookmarks, key) in filteredGroupedBookmarks"
                :key="key"
                :prepend-icon="getTypeIcon(key)"
                no-action
              >
                <template #activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ getTypeName(key) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="bi in bookmarks"
                  :key="bi.id"
                  @click="openNewTab(bi.entityUri)"
                >
                  <v-list-item-content>
                    <span class="text-caption">{{ bi.title }}</span>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click.stop="unbookmarkExecute(bi)">
                      <v-icon>mdi-bookmark-minus</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-container>
        </v-card>
      </v-card>
    </v-menu>
    <v-card v-else flat class="ma-0 pa-0">
      <v-container
        v-if="bookmarks.length < 1"
        class="overflow-y-auto light-chat ma-0 pa-0"
      >
        <v-row dense justify="center">
          <v-col cols="10">
            <p class="ma-0 pa-0 text-subtitle-2 text-center">
              {{ $t('common.noBookmarks') }}
            </p>
          </v-col>
        </v-row>
      </v-container>

      <v-list v-else>
        <v-list-group
          v-for="(bookmarks, key) in filteredGroupedBookmarks"
          :key="key"
          :prepend-icon="getTypeIcon(key)"
          no-action
        >
          <template #activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ getTypeName(key) }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="bi in bookmarks"
            :key="bi.id"
            @click="openNewTab(bi.entityUri)"
          >
            <v-list-item-content>
              <span class="text-caption">{{ bi.title }}</span>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click.stop="unbookmarkExecute(bi)">
                <v-icon>mdi-bookmark-minus</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  BookmarkActions,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '@/store/bookmark/types';
import { PlatformGetters } from '@/store/platform/types';

const bookmarkNamespace = 'bookmark';
const platformNamespace = 'platform';

@Component
export default class MyBookmarks extends Vue {
  /* PUBLIC PROPERTIES */

  /* PRIVATE PROPERTIES */
  private bookmarksSeachText = '';
  private bookmarksOpen = false;

  /* VUEX GETTERS */
  // Bookmark getters
  @Getter(BookmarkGetters.GET_BOOKMARKS, {
    namespace: bookmarkNamespace,
  })
  private bookmarks!: BookmarkItem[];

  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  // Platform Getters
  @Getter(PlatformGetters.BANNER_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private bannerBarTextColour!: string;

  /* VUEX ACTIONS */
  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get filteredGroupedBookmarks(): { [key: string]: BookmarkItem[] } {
    const filtered = this.bookmarks.filter((bm) => {
      return bm.title.includes(this.bookmarksSeachText);
    });
    return this.getGroupsByEntityType(filtered);
  }

  /* PRIVATE METHODS*/
  private unbookmarkExecute(bi: BookmarkItem) {
    this.unbookmarkItem(bi);
  }

  private getGroupsByEntityType(b: BookmarkItem[]) {
    b = b.sort((a, b) => a.type.localeCompare(b.type));
    const groups = b.reduce<{ [key: string]: BookmarkItem[] }>((acc, bm) => {
      acc[bm.type] = [...(acc[bm.type] || []), bm];
      return acc;
    }, {});
    return groups;
  }

  private getTypeName(type: string) {
    switch (type) {
      case BookmarkType.DOCUMENT:
        return 'Documents';
      case BookmarkType.EXHIBITION:
        return this.$t('menuItems.exhibition');
      case BookmarkType.POSTER:
        return this.$t('menuItems.posters');
      case BookmarkType.VIDEO:
        return this.$t('menuItems.vod');
      default:
        return '';
    }
  }

  private getTypeIcon(type: string) {
    switch (type) {
      case BookmarkType.DOCUMENT:
        return 'mdi-file-document-multiple-outline';
      case BookmarkType.EXHIBITION:
        return 'store_mall_directory';
      case BookmarkType.POSTER:
        return 'mdi-billboard';
      case BookmarkType.VIDEO:
        return 'video_library';
      default:
        return '';
    }
  }

  private async openNewTab(url: string | undefined) {
    console.log('here');
    this.$emit("close");
    if (url) {
      if (url.startsWith('https:') || url.startsWith('http:')) {
        const win = window.open(url, '_blank');
        if (win) {
          win.focus();
        }
      } else {
        this.$router.push(url);
      }

    }
  }
}
</script>

<style scoped>
.title {
  font-family: Ubuntu !important;
  font-size: 1.8rem !important;
  font-weight: 500;
}

.dropbox {
  width: 400px;
  height: 60vh;
}

.list-height {
  height: calc(60vh - 100px);
}

.light-chat {
  font-size: 12px;
  height: 100%;
}

.light-chat::-webkit-scrollbar {
  width: 5px;
}

.light-chat::-webkit-scrollbar-track {
  background: transparent;
  border-left: 0;
}

.light-chat::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: 0;
  border-radius: 7px;
}

.light-chat::-webkit-scrollbar-thumb:hover {
  background: black;
}
</style>
