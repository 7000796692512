<template>
  <div
    :style="cssVars"
    :class="overlay ? 'fill-height bg-overlay' : 'fill-height bg'"
  ></div>
</template>

<script lang="ts">
import { PlatformGetters } from '@/store/platform/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
const platformNamespace = 'platform';

@Component
export default class Background extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public colour!: string;

  @Prop()
  public overlay!: boolean;

  @Prop()
  public override!: string;
  /* PRIVATE PROPERTIES */

  /* VUEX GETTERS */
  @Getter(PlatformGetters.PORTAL_BACKGROUND_URI, {
    namespace: platformNamespace,
  })
  private portalBackgroundUri!: string;
  /* VUEX ACTIONS */
  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get cssVars(): unknown {
    return {
      '--bg-image': `
        url('${this.override ? this.override : this.portalBackgroundUri}')
      `,
      '--bg-colour': `${this.colour ? this.colour : '#141414'}`,
    };
  }
  /* PRIVATE METHODS*/
}
</script>

<style scoped>
.bg {
  width: 100%;
  background-color: var(--bg-colour);
  background-image: var(--bg-image);
  background-size: cover;
  position: fixed;
  z-index: -1;
}

.bg-overlay {
  width: 100%;
  background-color: var(--bg-colour);
  background-image: var(--bg-image);
  background-size: cover;
  position: fixed;
  z-index: -1;
}

.bg-overlay::before {
  content: '';
  background-color: rgb(249, 249, 249);
  opacity: 0.7;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
</style>
