<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="11" md="6">
        <v-text-field
          min-height="36px"
          height="36px"
          hide-details
          prepend-inner-icon="mdi-account-search-outline"
          solo
          dense
          clear-icon="mdi-close-circle"
          clearable
          label="Search"
          :suffix="userCountString()"
          type="text"
          :value="keywords"
          :loading="loading"
          @input="debounceSearch"
          @click:clear="clearSearch"
        >
          <template #label>
            <span ref="searchl" class="searchl">
              {{ $t('common.search') }}
              {{ poster ? $t('entities.posters') : $t('menuItems.vod') }}
            </span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="1" class="pl-0">
        <v-dialog
          v-model="dialog"
          max-width="600px"
          transition="dialog-bottom-transition"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :icon="$vuetify.breakpoint.smAndDown"
              :outlined="$vuetify.breakpoint.mdAndUp"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-filter</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp">{{
                $t('common.moreFilters')
              }}</span>
              <span v-if="$vuetify.breakpoint.mdAndUp && filterCount">
                •{{ filterCount }}
              </span>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t('common.moreFilters') }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-combobox
                      v-model="theme"
                      :label="isEao ? $t('session.topic') : $t('session.theme')"
                      outlined
                      hide-details
                      prepend-inner-icon="mdi-map-marker-path"
                      :items="themes"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12">
                    <v-combobox
                      v-model="type"
                      :label="$t('session.type')"
                      outlined
                      hide-details
                      prepend-inner-icon="mdi-shape"
                      :items="types"
                      item-value="type"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" v-if="poster && sessionFilterEnabled">
                    <v-combobox
                      v-model="posterSession"
                      :label="$t('entities.Session')"
                      outlined
                      hide-details
                      prepend-inner-icon="mdi-format-list-text"
                      :items="posterSessions"
                      item-text="title"
                      item-value="id"
                    ></v-combobox>
                  </v-col>
                  <v-col v-if="!poster && !isEao" cols="12">
                    <v-combobox
                      v-model="room"
                      :label="$t('session.room')"
                      outlined
                      hide-details
                      prepend-inner-icon="mdi-calendar-range"
                      :items="rooms"
                      item-text="name"
                      item-value="id"
                    ></v-combobox>
                  </v-col>
                  <v-col v-if="!poster && isEao" cols="12">
                    <v-combobox
                      v-model="selectedKeywordItems"
                      :label="$t('session.keywords')"
                      @change="keywordSelected"
                      outlined
                      hide-details
                      prepend-inner-icon="mdi-key"
                      :items="keywordItems"
                      multiple
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="blue darken-1"
                text
                @click.stop="clearFiltersExecute"
              >
                <span>{{ $t('common.clear') }}</span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                {{ $t('common.close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { PlatformGetters } from '@/store/platform/types';
import {
  PosterSessionItem,
  RoomItem,
  SessionActions,
  SessionGetters,
} from '@/store/session/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';

const platformNamespace = 'platform';
const sessionNamespace = 'session';

@Component
export default class SessionFilters extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  poster!: boolean;

  /* PRIVATE PROPERTIES */
  private debounce: number | undefined;
  private searchText: string | null = '';
  private mounting = false;
  private dialog = false;
  private selectedKeywordItems = [];

  /* VUEX GETTERS */
  @Getter(PlatformGetters.ENABLE_SESSION_FILTER_IN_POSTER_MODULE, {
    namespace: platformNamespace,
  })
  private sessionFilterEnabled!: boolean;

  @Getter(PlatformGetters.SHOW_POSTER_COUNT, {
    namespace: platformNamespace,
  })
  private showPosterCount!: boolean;

  @Getter(PlatformGetters.SHOW_VOD_COUNT, {
    namespace: platformNamespace,
  })
  private showVodCount!: boolean;

  @Getter(SessionGetters.KEYWORDS, { namespace: sessionNamespace })
  private keywords!: string;

  @Getter(SessionGetters.LOADING_SESSIONS, { namespace: sessionNamespace })
  private loading!: boolean;

  @Getter(SessionGetters.ROOMS, { namespace: sessionNamespace })
  private rooms!: string[];

  @Getter(SessionGetters.POSTER_SESSIONS, { namespace: sessionNamespace })
  private posterSessions!: PosterSessionItem[];

  @Getter(SessionGetters.SELECTED_POSTER_SESSION, {
    namespace: sessionNamespace,
  })
  private selectedPosterSession!: PosterSessionItem;

  @Getter(SessionGetters.SELECTED_ROOM, { namespace: sessionNamespace })
  private selectedRoom!: RoomItem;

  @Getter(SessionGetters.SELECTED_THEME, { namespace: sessionNamespace })
  private selectedTheme!: string;

  @Getter(SessionGetters.SELECTED_TYPE, { namespace: sessionNamespace })
  private selectedType!: string;

  @Getter(SessionGetters.THEMES, { namespace: sessionNamespace })
  private themes!: string[];

  @Getter(SessionGetters.KEYWORD_ITEMS, { namespace: sessionNamespace })
  private keywordItems!: string[];

  @Getter(SessionGetters.TOTAL_SESSION_COUNT, { namespace: sessionNamespace })
  private totalSessionCount!: number;

  @Getter(SessionGetters.TYPES, { namespace: sessionNamespace })
  private types!: string[];

  /* VUEX ACTIONS */
  @Action(SessionActions.APPLY_INITIAL_QUERY_FILTERS, {
    namespace: sessionNamespace,
  })
  private applyInitialQueryFilters!: () => void;

  @Action(SessionActions.SET_FILTER_ITEMS, { namespace: sessionNamespace })
  private setFilterItems!: ({
    rooms,
    themes,
    types,
    keywordItems,
    posterSessions,
  }: {
    rooms: RoomItem[];
    themes: string[];
    types: string[];
    keywordItems: string[];
    posterSessions: PosterSessionItem[];
  }) => void;

  @Action(SessionActions.SET_KEYWORDS, { namespace: sessionNamespace })
  private setKeywords!: (keywords: string) => Promise<void>;

  @Action(SessionActions.SET_SELECTED_POSTER_SESSION, {
    namespace: sessionNamespace,
  })
  private setSelectedPosterSession!: (
    posterSession: PosterSessionItem,
  ) => Promise<void>;

  @Action(SessionActions.SET_SELECTED_ROOM, { namespace: sessionNamespace })
  private setSelectedRoom!: (room: RoomItem) => Promise<void>;

  @Action(SessionActions.SET_SELECTED_THEME, { namespace: sessionNamespace })
  private setSelectedTheme!: (theme: string) => Promise<void>;

  @Action(SessionActions.SET_SELECTED_TYPE, { namespace: sessionNamespace })
  private setSelectedType!: (type: string) => Promise<void>;

  @Action(SessionActions.CLEAR_FILTERS, { namespace: sessionNamespace })
  private clearFilters!: (push?: boolean) => Promise<void>;

  /* WATCHES */
  @Watch('searchText')
  private async searchTextChanged() {
    if (this.mounting) {
      return;
    }
    // Do search if more than 3 or more characters
    if (this.searchText && this.searchText.length >= 3) {
      this.setKeywords(this.searchText);
    } else if (!this.searchText) {
      this.setKeywords('');
    }
  }

  /* LOCAL GETTERS/SETTERS */
  get filterCount(): number {
    let count = 0;
    if (this.theme) count++;
    if (this.type) count++;
    if (this.room) count++;
    return count;
  }

  get isEao() {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    return (
      eventCode.toLowerCase() === '21eao' ||
      eventCode.toLowerCase() === '22eaovirtual' ||
      eventCode.toLowerCase() === '23eao-online' ||
      eventCode.toLowerCase() === '24eao-online'
    );
  }

  get keyword(): string {
    return this.keywords;
  }
  set keyword(value: string) {
    this.setKeywords(value);
  }

  get theme(): string {
    return this.selectedTheme;
  }
  set theme(value: string) {
    this.setSelectedTheme(value);
  }

  get type(): string {
    return this.selectedType;
  }
  set type(value: string) {
    this.setSelectedType(value);
  }

  get room(): RoomItem {
    return this.selectedRoom;
  }
  set room(value: RoomItem) {
    this.setSelectedRoom(value);
  }

  get posterSession(): PosterSessionItem {
    return this.selectedPosterSession;
  }
  set posterSession(value: PosterSessionItem) {
    this.setSelectedPosterSession(value);
  }

  /* PRIVATE METHODS*/
  private async beforeMount() {
    this.mounting = true;
    this.clearFilters();
    await this.loaddata();
    this.mounting = false;
  }

  private clearSearch() {
    this.searchText = '';
    this.setKeywords('');
    this.selectedKeywordItems = [];
  }

  private clearFiltersExecute() {
    this.clearFilters(true);
    this.selectedKeywordItems = [];
  }

  private debounceSearch(event: string) {
    if (event === null || event === undefined) {
      return;
    }

    if (this.debounce) {
      clearTimeout(this.debounce);
    }
    this.debounce = window.setTimeout(() => {
      this.searchText = event;
    }, 600);
  }

  private keywordSelected(value: string[]) {
    if (value) {
      console.log('Value ' + value);
      const temp = value.join(' ');
      console.log('New value ' + value);
      this.setKeywords(temp);
    } else {
      this.setKeywords('');
    }
  }

  private async loaddata() {
    const platformId = sessionStorage.getItem('platformId') ?? '';

    try {
      let uri = `/api/platform/${platformId}/sessions/filters`;
      if (this.poster) {
        uri = `${uri}?poster=true`;
      }
      const filters = await Vue.$http.get(uri);
      const { rooms, themes, types, keywordItems, posterSessions } =
        filters.data;
      this.setFilterItems({
        rooms,
        themes,
        types,
        keywordItems,
        posterSessions,
      });
      this.applyInitialQueryFilters();
    } catch (e: any) {
      AppInsightsLogger.logError('Sessions - loaddata failed', undefined, true);
      AppInsightsLogger.logException(e, false);
    }
  }

  private userCountString() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return '';
    }
    if (this.poster && this.showPosterCount) {
      if (this.totalSessionCount === 1) {
        return `${this.totalSessionCount} ${this.$t(
          'entities.poster',
        )} ${this.$t('common.found')}`;
      } else {
        return `${this.totalSessionCount} ${this.$t(
          'entities.posters',
        )} ${this.$t('common.founds')}`;
      }
    } else if (this.showVodCount) {
      if (this.totalSessionCount === 1) {
        return `${this.totalSessionCount} ${this.$t('entities.item')}
        ${this.$t('common.found')}`;
      } else {
        return `${this.totalSessionCount} ${this.$t(
          'entities.items',
        )} ${this.$t('common.founds')}`;
      }
    } else {
      return '';
    }
  }
}
</script>

<style scoped></style>
