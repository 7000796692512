<template>
  <div style="height: 100%">
    <component :is="dynamicComponent" v-if="customHtml && loaded"></component>
    <new-programme v-else-if="loaded" :user-programme="false" />
  </div>
</template>

<script lang="ts">
import Programme from '@/components/Programme.vue';
import NewProgramme from '@/components/NewProgramme.vue';
import { LogActions } from '@/store/log/log';
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';

const logNamespace = 'log';

@Component({
  components: {
    Programme,
    NewProgramme,
  },
})
export default class EventProgramme extends Vue {
  /* PUBLIC PROPERTIES */

  /* PRIVATE PROPERTIES */
  private loaded = false;
  private customHtml = '';

  /* VUEX GETTERS */
  /* VUEX ACTIONS */
  // Log Actions
  @Action(LogActions.SET_TRACE_LOG_STATE, { namespace: logNamespace })
  private setTraceLogState!: ({
    entityId1,
    entityId2,
    page,
    title,
  }: {
    entityId1?: string;
    entityId2?: string;
    page: string;
    title: string;
  }) => void;
  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get dynamicComponent(): Vue.Component {
    return {
      template: `${this.customHtml}`,
      data() {
        return {
          tabs: null,
        };
      },
    };
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}

  private async created() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(`/api/platform/${platformId}/programme`);
      this.customHtml = res.data;
      this.loaded = true;
    } catch (e: any) {
      AppInsightsLogger.logError('Vod - created failed', undefined, true);
      AppInsightsLogger.logException(e, false);
    }
  }

  // private beforeMount() {}

  private mounted() {
    this.setTraceLogState({ page: 'Programme', title: 'Programme' });
  }

  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/
}
</script>
