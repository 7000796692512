<template>
  <div v-if="!pageLoaded || usersLoading" class="pa-0 ma-0 fill-height">
    <v-container class="fill-height">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col cols="6">
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div v-else class="fill-height pa-0 ma-0">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="overline">
          {{ $t('entities.participants') }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div class="fill-height">
      <v-text-field
        v-model="userFilter"
        append-icon="mdi-magnify"
        :label="$t('common.search')"
        outlined
        single-line
        hide-details
        class="px-5 pt-1"
      ></v-text-field>

      <div class="overflow-y-auto light-chat mx-0 px-0">
        <v-list-item
          v-for="item in filteredRoomUsers"
          :id="item.userId"
          :key="item.userId"
        >
          <v-list-item-content>
            <span class="text-subtitle-1">{{ item.userName }}</span>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click.stop="showUser(item.userId, $event)">
              <v-icon color="black">mdi-eye</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </div>
      <PlatformUserDetails />
    </div>
  </div>
</template>

<script lang="ts">
import PlatformUserDetails from '@/components/PlatformUserDetails.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  PlatformUserActions,
  PlatformUserGetters,
  PlatformUserItem,
} from '../store/platformUser/types';
import { SignalrGetters, SignalrGroup } from '../store/signalr/types';
const platformUserNamespace = 'platformUser';
const signalrNamespace = 'signalr';

@Component({
  components: {
    PlatformUserDetails,
  },
})
export default class SessionUsers extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public isActive!: boolean;

  @Prop()
  public pageLoaded!: boolean;

  /* PRIVATE PROPERTIES */
  private usersLoading = false;
  private userFilter = '';
  private showUserDetails = false;

  /* VUEX GETTERS */
  @Getter(SignalrGetters.CURRENT_GROUP, { namespace: signalrNamespace })
  private currentGroup!: SignalrGroup;

  @Getter(PlatformUserGetters.PLATFORM_USER_CARD_VISIBLE, {
    namespace: platformUserNamespace,
  })
  private platformUserCardVisible!: boolean;

  @Getter(PlatformUserGetters.VISIBLE_PLATFORM_USER_LIST, {
    namespace: platformUserNamespace,
  })
  private visiblePlatformUserList!: PlatformUserItem[];

  /* VUEX ACTIONS */

  @Action(PlatformUserActions.LOAD_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private loadPlatformUser!: (userId: string) => Promise<void>;

  @Action(PlatformUserActions.LOAD_PLATFORM_USERS_FOR_GROUP, {
    namespace: platformUserNamespace,
  })
  private loadPlatformUsersForGroup!: ({
    groupId,
    groupType,
  }: {
    groupId: string;
    groupType: string;
  }) => Promise<void>;

  @Action(PlatformUserActions.SET_PLATFORM_USER_CARD_POSITION, {
    namespace: platformUserNamespace,
  })
  private setPlatformUserCardPosition!: ({
    x,
    y,
  }: {
    x: number;
    y: number;
  }) => void;

  @Action(PlatformUserActions.SET_PLATFORM_USER_CARD_VISIBILITY, {
    namespace: platformUserNamespace,
  })
  private setPlatformUserCardVisibility!: (visible: boolean) => void;

  /* WATCHES */
  @Watch('isActive')
  private isActiveChanged(value: string) {
    if (value) {
      this.loadUsers();
    }
    this.userFilter = '';
  }

  get filteredRoomUsers(): PlatformUserItem[] {
    return this.visiblePlatformUserList.filter((r) => {
      if (!this.userFilter) {
        return true;
      }
      let filter = this.userFilter;
      filter = filter.trim().toLowerCase();
      filter = filter.replace(/\s+/g, '-');
      filter = filter.replace(/\W/g, '');
      if (!filter || filter.length < 2) {
        return true;
      }
      return r.userName.toLowerCase().includes(filter);
    });
  }

  private async loadUsers() {
    this.usersLoading = true;
    await this.loadPlatformUsersForGroup({
      groupId: this.currentGroup.groupId,
      groupType: this.currentGroup.groupType,
    });
    this.usersLoading = false;
  }

  private async showUser(userId: string, e: MouseEvent) {
    if (this.platformUserCardVisible) {
      this.setPlatformUserCardVisibility(false);
    }
    const x = e.clientX;
    const y = e.clientY + 15;
    this.setPlatformUserCardPosition({ x, y });
    await this.loadPlatformUser(userId);
  }

  private mounted() {
    this.loadUsers();
  }
}
</script>

<style scoped>
.light-chat {
  font-size: 12px;
  height: calc(100% - 140px);
}

.light-chat::-webkit-scrollbar {
  width: 5px;
}

.light-chat::-webkit-scrollbar-track {
  background: transparent;
  border-left: 0;
}

.light-chat::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: 0;
  border-radius: 7px;
}

.light-chat::-webkit-scrollbar-thumb:hover {
  background: black;
}
</style>
