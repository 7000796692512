<template>
  <v-dialog
    v-model="dialogState"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
    :retain-focus="false"
  >
    <v-card>
      <v-toolbar :color="bannerBarBackgroundColour">
        <v-btn
          v-if="
            (getCurrentPlatformUser && (
              getCurrentPlatformUser.accepted === false ||
              getCurrentPlatformUser.accepted === true)) &&
            menu
          "
          icon
          :color="bannerBarTextColour"
          @click.stop="dialogState = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn
          v-if="
            (getCurrentPlatformUser && (
              getCurrentPlatformUser.accepted === false ||
              getCurrentPlatformUser.accepted === true)) &&
            !menu
          "
          icon
          :color="bannerBarTextColour"
          @click.stop="showPage('menu')"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span
          class="text-subtitle-1 font-weight-bold"
          :style="{ color: bannerBarTextColour, lineHeight: '48px' }"
        >
          {{ dialogTitle }}
        </span>
        <v-spacer></v-spacer>
      </v-toolbar>

      <BannerDialogEditProfile
        v-if="!$vuetify.breakpoint.mobile"
        @saved="dialogState = false"
      />

      <v-container v-else class="ma-0 pa-0">
        <v-fade-transition leave-absolute>
          <v-list v-if="menu" flat>
            <!-- Language -->
            <v-list-item @click.stop="showPage('language')">
              <v-list-item-icon>
                <v-icon>mdi-earth</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t('bannerBar.language')
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />

            <!-- My agenda -->
            <v-list-item @click.stop="showPage('myAgenda')">
              <v-list-item-icon>
                <v-icon>mdi-calendar-star</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t('bannerBar.myProgramme')
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />

            <!-- My bookmarks -->
            <v-list-item @click.stop="showPage('myBookmarks')">
              <v-list-item-icon>
                <v-icon>mdi-bookmark-multiple-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t('bannerBar.myBookmarks')
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />

            <!-- My private messages -->
            <!-- <v-list-item @click.stop="showPage('privateMessages')">
              <v-list-item-icon>
                <v-icon>mdi-message-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t('bannerBar.privateMessages')
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider /> -->

            <!-- Edit profile -->
            <v-list-item @click.stop="showPage('editProfile')">
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t('bannerBar.editProfile')
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />

            <!-- Log out -->
            <v-list-item @click="logOut">
              <v-list-item-icon>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t('bannerBar.logOut')
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-fade-transition>
        <v-fade-transition leave-absolute>
          <LocaleChanger v-if="language" />
        </v-fade-transition>
        <v-fade-transition leave-absolute>
          <Programme v-if="myAgenda" :userProgramme="true" />
        </v-fade-transition>
        <v-fade-transition leave-absolute>
          <MyBookmarks @close="closeDialog" v-if="myBookmarks" />
        </v-fade-transition>
        <v-fade-transition leave-absolute>
          <v-row v-if="privateMessages">
            <v-col>Private messages</v-col>
          </v-row>
        </v-fade-transition>
        <v-fade-transition leave-absolute>
          <BannerDialogEditProfile
            v-if="editProfile"
            @saved="dialogState = false"
          />
        </v-fade-transition>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import BannerDialogEditProfile from '@/components/BannerDialogEditProfile.vue';
import LocaleChanger from '@/components/LocaleChanger.vue';
import MyBookmarks from '@/components/MyBookmarks.vue';
import Programme from '@/components/Programme.vue';
import { HeartbeatResponse } from '@/models/HeartbeatResponse';
import { Scan } from '@/models/Scan';
import { ScanEntityTypes } from '@/models/ScanEntityTypes';
import { ScanTypes } from '@/models/ScanTypes';
import { AuthService } from '@/services/auth/authService';
import { NavigationActions, NavigationGetters } from '@/store/navigation/types';
import { PlatformActions, PlatformGetters } from '@/store/platform/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  PlatformUserActions,
  PlatformUserGetters,
  PlatformUserItem,
} from '../store/platformUser/types';

const navigationNamespace = 'navigation';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component({
  components: {
    BannerDialogEditProfile,
    LocaleChanger,
    MyBookmarks,
    Programme,
  },
})
export default class BannerDialog extends Vue {
  /* PUBLIC PROPERTIES */
  /* PRIVATE PROPERTIES */
  private dialogTitle = '';

  // Pages
  private menu = true;
  private language = false;
  private myAgenda = false;
  private myBookmarks = false;
  private privateMessages = false;
  private editProfile = false;

  /* VUEX GETTERS */
  // Navigation Getters
  @Getter(NavigationGetters.PROFILE_DIALOG, {
    namespace: navigationNamespace,
  })
  private dialog!: boolean;

  // Platform Getters
  @Getter(PlatformGetters.BANNER_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private bannerBarBackgroundColour!: string;

  @Getter(PlatformGetters.BANNER_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private bannerBarTextColour!: string;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private getCurrentPlatformUser!: PlatformUserItem;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  @Action(PlatformUserActions.LOAD_CURRENT_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private loadCurrentPlatformUser!: () => Promise<void>;

  /* VUEX ACTIONS */
  // Navigation Action
  @Action(NavigationActions.PROFILE_INVERT_DIALOG, {
    namespace: navigationNamespace,
  })
  private invertDialog!: () => void;

  // Platform Actions
  @Action(PlatformActions.LOG_SCAN, { namespace: platformNamespace })
  private scanLog!: (scan: Scan) => Promise<void | HeartbeatResponse>;

  // Platfor User Action
  @Action(PlatformUserActions.SET_CURRENT_PLATFORM_USER_PHOTO_URI, {
    namespace: platformUserNamespace,
  })
  private setCurrentPlatformUserPhotoUri!: (uri: string) => void;

  /* WATCHES */

  /* WATCHES */
  @Watch('dialog', { immediate: true })
  private async onDialogChanged(newVal: boolean): Promise<void> {
    if (this.$vuetify.breakpoint.mobile && newVal) {
      if (
        this.getCurrentPlatformUser &&
        this.getCurrentPlatformUser.userId &&
        (this.getCurrentPlatformUser.accepted === undefined ||
          this.getCurrentPlatformUser.accepted === null)
      ) {
        this.showPage('editProfile');
      }
    }
  }

  /* LOCAL GETTERS/SETTERS */
  get dialogState(): boolean {
    return this.dialog;
  }
  set dialogState(state) {
    if (state !== this.dialog) {
      this.invertDialog();
    }
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private mounted() {
    if (!this.$vuetify.breakpoint.mobile) {
      this.dialogTitle = this.$t('myProfile.title') as string;
    }
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private closeDialog()
  {
    console.log('here2');
    this.showPage('menu')
    this.dialogState = false;
  }

  private async logOut() {
    const userId = localStorage.getItem('userId') ?? '';
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const scan: Scan = {
      entityId: platformId,
      scanEntityType: ScanEntityTypes.EVENT_PLATFORM,
      scanType: ScanTypes.LOGOUT,
      userId,
    };
    await this.scanLog(scan);
    await AuthService.logOut().finally(() => {
      // this.$appInsights.clearAuthenticatedUserContext();
      const eventCode = sessionStorage.getItem('eventCode');
      window.location.href = `/${eventCode}`;
    });
  }

  private showPage(page: string) {
    switch (page) {
      case 'language':
        this.menu = false;
        this.language = true;
        this.myAgenda = false;
        this.myBookmarks = false;
        this.privateMessages = false;
        this.editProfile = false;
        this.dialogTitle = this.$t('bannerBar.language') as string;
        break;

      case 'myAgenda':
        this.menu = false;
        this.language = false;
        this.myAgenda = true;
        this.myBookmarks = false;
        this.privateMessages = false;
        this.editProfile = false;
        this.dialogTitle = this.$t('bannerBar.myProgramme') as string;
        break;

      case 'myBookmarks':
        this.menu = false;
        this.language = false;
        this.myAgenda = false;
        this.myBookmarks = true;
        this.privateMessages = false;
        this.editProfile = false;
        this.dialogTitle = this.$t('bannerBar.myBookmarks') as string;
        break;

      case 'privateMessages':
        this.menu = false;
        this.language = false;
        this.myAgenda = false;
        this.myBookmarks = false;
        this.privateMessages = true;
        this.editProfile = false;
        break;

      case 'editProfile':
        this.menu = false;
        this.language = false;
        this.myAgenda = false;
        this.myBookmarks = false;
        this.privateMessages = false;
        this.editProfile = true;
        break;

      default:
        this.menu = true;
        this.language = false;
        this.myAgenda = false;
        this.myBookmarks = false;
        this.privateMessages = false;
        this.editProfile = false;
        this.dialogTitle = '';
    }
  }
}
</script>

<style scoped></style>
