<template>
  <v-menu
    v-model="listVisibility"
    :close-on-content-click="false"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
    min-width="400"
    max-width="400"
  >
    <v-card>
      <v-system-bar class="primary">
        <span class="white--text">{{ $t('lounge.roomUsers') }}</span>
      </v-system-bar>
      <v-toolbar>
        <v-text-field
          v-model="userFilter"
          append-icon="mdi-magnify"
          :label="$t('common.search')"
          single-line
          hide-details
        ></v-text-field>
        <v-progress-linear
          :active="platformUserLoading"
          :indeterminate="platformUserLoading"
          absolute
          bottom
          color="primary"
        ></v-progress-linear>
      </v-toolbar>

      <v-virtual-scroll
        :items="filteredRoomUsers"
        :item-height="30"
        height="300"
      >
        <template #default="{ item }">
          <v-list-item>
            <v-list-item-content>
              <span>{{ item.userName }}</span>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                depressed
                small
                @click.stop="showUser(item.userId, $event)"
              >
                {{ $t('lounge.viewUser') }}
                <v-icon color="orange darken-4" right>mdi-open-in-new</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-virtual-scroll>
      <PlatformUserDetails />
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import PlatformUserDetails from '@/components/PlatformUserDetails.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  PlatformUserActions,
  PlatformUserGetters,
  PlatformUserItem,
} from '../store/platformUser/types';
const platformUserNamespace = 'platformUser';

@Component({
  components: {
    PlatformUserDetails,
  },
})
export default class RoomUserList extends Vue {
  private userFilter = '';

  @Getter(PlatformUserGetters.PLATFORM_USER_LIST_VISIBLE, {
    namespace: platformUserNamespace,
  })
  private platformUserListVisible!: boolean;

  @Getter(PlatformUserGetters.PLATFORM_USER_CARD_VISIBLE, {
    namespace: platformUserNamespace,
  })
  private platformUserCardVisible!: boolean;

  @Getter(PlatformUserGetters.PLATFORM_USER_LOADING, {
    namespace: platformUserNamespace,
  })
  private platformUserLoading!: boolean;

  @Getter(PlatformUserGetters.PLATFORM_USER_LIST_X_POS, {
    namespace: platformUserNamespace,
  })
  private x!: number;

  @Getter(PlatformUserGetters.PLATFORM_USER_LIST_Y_POS, {
    namespace: platformUserNamespace,
  })
  private y!: number;

  @Getter(PlatformUserGetters.VISIBLE_PLATFORM_USER_LIST, {
    namespace: platformUserNamespace,
  })
  private visiblePlatformUserList!: PlatformUserItem[];

  @Action(PlatformUserActions.SET_PLATFORM_USER_LIST_VISIBILITY, {
    namespace: platformUserNamespace,
  })
  private setPlatformUserListVisibility!: (visible: boolean) => void;

  @Action(PlatformUserActions.SET_PLATFORM_USER_CARD_VISIBILITY, {
    namespace: platformUserNamespace,
  })
  private setPlatformUserCardVisibility!: (visisble: boolean) => void;

  @Action(PlatformUserActions.LOAD_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private loadPlatformUser!: (userId: string) => Promise<void>;

  @Action(PlatformUserActions.SET_PLATFORM_USER_CARD_POSITION, {
    namespace: platformUserNamespace,
  })
  private setPlatformUserCardPosition!: ({
    x,
    y,
  }: {
    x: number;
    y: number;
  }) => void;

  get listVisibility(): boolean {
    return this.platformUserListVisible;
  }

  set listVisibility(visible: boolean) {
    this.setPlatformUserListVisibility(visible);
  }

  get filteredRoomUsers(): PlatformUserItem[] {
    return this.visiblePlatformUserList.filter((r) => {
      if (!this.userFilter) {
        return true;
      }
      let filter = this.userFilter;
      filter = filter.trim().toLowerCase();
      filter = filter.replace(/\s+/g, '-');
      filter = filter.replace(/\W/g, '');
      if (!filter || filter.length < 2) {
        return true;
      }
      return r.userName.toLowerCase().includes(filter);
    });
  }

  private async showUser(userId: string, e: MouseEvent) {
    if (this.platformUserCardVisible) {
      this.setPlatformUserCardVisibility(false);
    }
    const x = this.x;
    const y = e.clientY + 15;
    this.setPlatformUserCardPosition({ x, y });
    await this.loadPlatformUser(userId);
  }
}
</script>

<style scoped></style>
