<template>
  <v-dialog v-model="dialog" width="600px">
    <template #activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn icon>
            <v-icon
              :color="navBarTextColour"
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
            >
              mdi-text-account
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('participant.Biography') }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="headline grey lighten-2">
        <Avatar
          size="36"
          :src="platformUser.photoUri"
          :alt="platformUser.userName + ' avatar'"
          default="mdi-account-circle"
          class="mr-2"
        />
        {{ platformUser.userName }} -
        {{ $t('participant.Biography') }}
      </v-card-title>
      <v-card-text class="pt-5">
        <p v-html-safe:true="platformUser.biography"></p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Avatar from '@/components/Avatar.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PlatformGetters } from '@/store/platform/types';
import { PlatformUserItem } from '@/store/platformUser/types';
import { Getter } from 'vuex-class';
const platformNamespace = 'platform';

@Component({
  components: {
    Avatar,
  },
})
export default class BiographyDialog extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public platformUser!: PlatformUserItem;
  /* PRIVATE PROPERTIES */
  private dialog = false;
  /* VUEX GETTERS */
  // Platform Getters
  @Getter(PlatformGetters.NAV_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarTextColour!: string;

  @Getter(PlatformGetters.NAV_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarBackgroundColour!: string;
  /* VUEX ACTIONS */
  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */
  /* PRIVATE METHODS*/
}
</script>

<style scoped></style>
