<template>
  <div style="height: 100%">
    <v-card class="pa-4">
      <v-card-title>
        <h1 class="text-h5">{{ $t('menuItems.reportItems.uniqueUsers') }}</h1>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="12" md="5" sm="12">
              <v-select
                v-model="type"
                clearable
                :items="menuItems"
                item-text="title"
                item-value="type"
                label="Type"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="typeSummary">
            <v-col cols="12" md="3" sm="6">
              <v-card>
                <v-card-title class="justify-center text-subtitle-1 mb-0 pb-0">
                  Unique views
                </v-card-title>
                <v-card-text
                  class="text-center mt-0 pt-0 text-h6 primary--text"
                >
                  {{ typeSummary.uniqueViews }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-card>
                <v-card-title class="justify-center text-subtitle-1 mb-0 pb-0">
                  All unique views
                </v-card-title>
                <v-card-text
                  class="text-center mt-0 pt-0 text-h6 primary--text"
                >
                  {{ typeSummary.allUniqueViews }}
                </v-card-text>
                <v-card-actions style="margin-top: -50px">
                  <v-spacer></v-spacer>
                  <v-btn
                    tile
                    icon
                    :loading="allEntityDownloadloading"
                    :disabled="allEntityDownloadloading"
                    @click="downloadAllEntityItems"
                  >
                    <v-icon>mdi-cloud-download-outline</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-card>
                <v-card-title class="justify-center text-subtitle-1 mb-0 pb-0">
                  All views
                </v-card-title>
                <v-card-text
                  class="text-center mt-0 pt-0 text-h6 primary--text"
                >
                  {{ typeSummary.allViews }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-card>
                <v-card-title class="justify-center text-subtitle-1 mb-0 pb-0">
                  Average view time
                </v-card-title>
                <v-card-text
                  class="text-center mt-0 pt-0 text-h6 primary--text"
                >
                  {{ typeSummary.averageCulmativeTimeSpent }} mins
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="selectedType && selectedType !== 'event'">
            <v-col cols="12" md="8" sm="12">
              <v-autocomplete
                v-model="entity"
                :items="typeItems"
                :loading="typeItemsLoading"
                item-text="title"
                item-value="id"
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-if="entitySummary">
            <v-col cols="12" md="3" sm="6">
              <v-card>
                <v-card-title class="justify-center text-subtitle-1 mb-0 pb-0">
                  Unique views:
                </v-card-title>
                <v-card-text
                  class="text-center mt-0 pt-0 text-h6 primary--text"
                >
                  {{ entitySummary.uniqueViews }}
                </v-card-text>
                <v-card-actions style="margin-top: -50px">
                  <v-spacer></v-spacer>
                  <v-btn tile icon @click="downloadEntityItems">
                    <v-icon>mdi-cloud-download-outline</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-card>
                <v-card-title class="justify-center text-subtitle-1 mb-0 pb-0">
                  All views:
                </v-card-title>
                <v-card-text
                  class="text-center mt-0 pt-0 text-h6 primary--text"
                >
                  {{ entitySummary.allViews }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-card>
                <v-card-title class="justify-center text-subtitle-1 mb-0 pb-0">
                  View time:
                </v-card-title>
                <v-card-text
                  class="text-center mt-0 pt-0 text-h6 primary--text"
                >
                  {{ entitySummary.averageCulmativeTimeSpent }} mins
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card
        v-if="
          selectedEntity &&
          entityData &&
          entityData.headers &&
          entityData.items &&
          !sponsorSelected
        "
      >
        <v-card-title>
          <v-text-field
            v-model="entitySearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn tile icon @click="downloadEntityItems"
            ><v-icon>mdi-cloud-download-outline</v-icon></v-btn
          >
        </v-card-title>
        <v-data-table
          :headers="entityData.headers"
          :items="entityData.items"
          :search="entitySearch"
          :loading="entityItemsLoading"
        ></v-data-table>
      </v-card>
      <v-card
        v-if="
          selectedEntity &&
          entityData &&
          entityData.headers &&
          entityData.items &&
          sponsorSelected
        "
      >
        <v-card-title>
          <v-text-field
            v-model="entitySearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn tile icon @click="downloadEntityItems"
            ><v-icon>mdi-cloud-download-outline</v-icon></v-btn
          >
        </v-card-title>
        <v-data-table
          :headers="entityData.headers"
          :items="entityData.items"
          :search="entitySearch"
          :loading="entityItemsLoading"
        ></v-data-table>
      </v-card>
      <div
        v-if="
          selectedEntity &&
          entityData &&
          sponsorSelected &&
          entityData.downloadSummary &&
          entityData.downloadSummary.items &&
          entityData.downloadSummary.items.length > 0
        "
      >
        <div class="mt-5 mb-3">
          <h1 class="text-h5">Document Download Count</h1>
        </div>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn tile icon @click="downloadDocumentCount"
              ><v-icon>mdi-cloud-download-outline</v-icon></v-btn
            >
          </v-card-title>
          <v-data-table
            :headers="entityData.downloadSummary.headers"
            :items="entityData.downloadSummary.items"
            :loading="entityItemsLoading"
          ></v-data-table>
        </v-card>
      </div>
      <div
        v-if="
          selectedEntity &&
          entityData &&
          sponsorSelected &&
          entityData.downloadUniqueUsers &&
          entityData.downloadUniqueUsers.items &&
          entityData.downloadUniqueUsers.items.length > 0
        "
      >
        <div class="mt-5 mb-3">
          <h1 class="text-h5">Document Download Unique Users</h1>
        </div>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="downloadUniqueSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-btn tile icon @click="downloadDocumentUniqueUsers"
              ><v-icon>mdi-cloud-download-outline</v-icon></v-btn
            >
          </v-card-title>
          <v-data-table
            :headers="entityData.downloadUniqueUsers.headers"
            :items="entityData.downloadUniqueUsers.items"
            :search="downloadUniqueSearch"
            :loading="entityItemsLoading"
          ></v-data-table>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import xlsx from 'xlsx';
import { AppInsightsLogger } from '../../services/appInsightsLogger';
import {
  ReportGetters,
  ReportType,
  UniqueUserReportMenuItem,
} from '../../store/report/types';
import {
  DataTable,
  ViewsSummary,
  UniqueUserItem,
} from '@/models/Reports/DataTable';
import { EntityListItem } from '@/models/Reports/EntityListItem';
const reportNamespace = 'report';

@Component({})
export default class UniqueUsers extends Vue {
  private loaded = false;

  private selectedType = '';
  private typeItems: EntityListItem[] = [];
  private typeSummary: ViewsSummary | null = null;
  private typeItemsLoading = false;

  private selectedEntity = '';
  private entitySummary: ViewsSummary | null = null;
  private entityData: DataTable<UniqueUserItem> | null = null;
  private entityItemsLoading = false;
  private entitySearch = '';
  private downloadUniqueSearch = '';

  private allEntityDownloadloading = false;

  get type(): string {
    return this.selectedType;
  }
  set type(value: string) {
    this.selectedType = value;
    this.selectedTypeChanged();
  }

  get entity(): string {
    return this.selectedEntity;
  }
  set entity(value: string) {
    this.selectedEntity = value;
    this.selectedEntityChanged();
  }

  get sponsorSelected(): boolean {
    return this.selectedType === ReportType.UNIQUE_USER_SPONSOR;
  }

  @Getter(ReportGetters.UNIQUE_USER_MENU_ITEMS, { namespace: reportNamespace })
  private menuItems!: UniqueUserReportMenuItem[];

  private async created() {
    this.typeItemsLoading = false;
    this.typeItems = [];
    this.typeSummary = null;
    this.selectedEntity = '';
    this.entityItemsLoading = false;
    this.entityData = null;
    this.entitySummary = null;
    this.entitySearch = '';
    this.downloadUniqueSearch = '';
  }

  private downloadDocumentCount() {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const temp = this.typeItems.find(
      (ti: EntityListItem) => ti.id === this.selectedEntity,
    );
    if (!temp) {
      return;
    }
    const title = `${eventCode}_downloadcount_${temp.title}`;
    if (
      title &&
      this.entityData &&
      this.entityData.downloadSummary &&
      this.entityData.downloadSummary.items
    ) {
      const fileName = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      const workSheet = xlsx.utils.json_to_sheet(
        this.entityData.downloadSummary.items,
        { cellDates: true },
      );
      const wb = xlsx.utils.book_new();

      xlsx.utils.book_append_sheet(wb, workSheet, 'export');
      xlsx.writeFile(wb, `${fileName}.xlsx`);
    }
  }

  private downloadDocumentUniqueUsers() {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const temp = this.typeItems.find(
      (ti: EntityListItem) => ti.id === this.selectedEntity,
    );
    if (!temp) {
      return;
    }
    const title = `${eventCode}_downloaduniqueusers_${temp.title}`;
    if (
      title &&
      this.entityData &&
      this.entityData.downloadUniqueUsers &&
      this.entityData.downloadUniqueUsers.items
    ) {
      const fileName = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      const workSheet = xlsx.utils.json_to_sheet(
        this.entityData.downloadUniqueUsers.items,
        { cellDates: true },
      );
      const wb = xlsx.utils.book_new();

      xlsx.utils.book_append_sheet(wb, workSheet, 'export');
      xlsx.writeFile(wb, `${fileName}.xlsx`);
    }
  }

  private downloadEntityItems() {
    try {
      const eventCode = sessionStorage.getItem('eventCode') ?? '';
      let title = '';
      if (this.selectedType === ReportType.UNIQUE_USER_EVENT) {
        title = `${eventCode}_uniqueusers`;
      } else {
        const temp = this.typeItems.find(
          (ti: EntityListItem) => ti.id === this.selectedEntity,
        );
        if (!temp) {
          return;
        }
        title = `${eventCode}_uniqueusers_${temp.title}`;
      }

      if (
        title &&
        this.entityData &&
        (this.entityData.items || this.entityData.items)
      ) {
        const fileName = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        const items = this.entityData.items ?? this.entityData.items;
        const parsed = this.parseDates(items);
        const workSheet = xlsx.utils.json_to_sheet(parsed, {
          cellDates: true,
          dateNF: 'dd/mm/yyyy hh:mm',
        });
        const wb = xlsx.utils.book_new();

        xlsx.utils.book_append_sheet(wb, workSheet, 'export');
        xlsx.writeFile(wb, `${fileName}.xlsx`);
      }
    } catch (e: any) {
      console.log(e);
      AppInsightsLogger.logError(
        'Player - downloadEntityItems failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.allEntityDownloadloading = false;
  }

  private async downloadAllEntityItems() {
    try {
      this.allEntityDownloadloading = true;
      const platformId = sessionStorage.getItem('platformId') ?? '';
      const eventCode = sessionStorage.getItem('eventCode') ?? '';
      const res = await Vue.$http.get<DataTable<UniqueUserItem>>(
        `/api/platform/${platformId}/report/alluniqueusers?type=${this.selectedType}`,
      );
      const fileName = `${eventCode}_uniqueusers_${this.selectedType}s`;
      const allEntityItems = res.data.items;
      const parsed = this.parseDates(allEntityItems);
      const workSheet = xlsx.utils.json_to_sheet(parsed, {
        cellDates: true,
        dateNF: 'dd/mm/yyyy hh:mm',
      });
      const wb = xlsx.utils.book_new();

      xlsx.utils.book_append_sheet(wb, workSheet, 'export');
      xlsx.writeFile(wb, `${fileName}.xlsx`);
    } catch (e: any) {
      console.log(e);
      AppInsightsLogger.logError(
        'Player - downloadAllEntityItems failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.allEntityDownloadloading = false;
  }

  private async selectedEntityChanged() {
    this.entityItemsLoading = true;
    this.entityData = null;
    this.entitySummary = null;
    this.entitySearch = '';
    this.downloadUniqueSearch = '';
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<DataTable<UniqueUserItem>>(
        `/api/platform/${platformId}/report/${this.selectedEntity}/uniqueusertable?type=${this.selectedType}`,
      );
      this.entityData = res.data;
      this.entitySummary = res.data.summary;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - selectedEntityChanged failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.entityItemsLoading = false;
  }

  private parseDates(arr: UniqueUserItem[]): UniqueUserItem[] {
    const newArr = arr.map((obj: UniqueUserItem) => {
      const newObj: UniqueUserItem = {
        ...obj,
        firstConnection:
          typeof obj.firstConnection === 'string'
            ? moment.utc(obj.firstConnection).toDate()
            : '',
        lastConnection:
          typeof obj.lastConnection === 'string'
            ? moment.utc(obj.lastConnection).toDate()
            : '',
        start:
          typeof obj.start === 'string'
            ? obj.start === '0001-01-01T00:00:00'
              ? ''
              : moment.tz(obj.start, 'Europe/Paris').toDate()
            : '',
      };

      return newObj;
    });
    return newArr;
  }

  private async selectedTypeChanged() {
    this.typeItemsLoading = true;
    this.typeItems = [];
    this.typeSummary = null;
    this.selectedEntity = '';
    this.entityItemsLoading = false;
    this.entityData = null;
    this.entitySummary = null;
    this.entitySearch = '';
    this.downloadUniqueSearch = '';
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      if (this.selectedType === ReportType.UNIQUE_USER_EVENT) {
        this.selectedEntity = platformId;
        await this.selectedEntityChanged();
      } else if (this.selectedType === ReportType.UNIQUE_USER_SESSION) {
        const res = await Vue.$http.get<DataTable<EntityListItem>>(
          `/api/platform/${platformId}/report/sessions`,
        );
        this.typeItems = res.data.items;
        this.typeSummary = res.data.summary;
      } else if (this.selectedType === ReportType.UNIQUE_USER_POSTER) {
        const res = await Vue.$http.get(
          `/api/platform/${platformId}/report/posters`,
        );
        this.typeItems = res.data.items;
        this.typeSummary = res.data.summary;
      } else if (this.selectedType === ReportType.UNIQUE_USER_SPONSOR) {
        this.typeItemsLoading = true;
        const res = await Vue.$http.get(
          `/api/platform/${platformId}/report/sponsors`,
        );
        this.typeItems = res.data.items;
        this.typeSummary = res.data.summary;
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - loadSelectedSession failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.typeItemsLoading = false;
  }
}
</script>

<style scoped>
.smaller-title {
  font-size: 1rem;
}
</style>
