<template>
  <Participants :only-speakers="true" />
</template>

<script lang="ts">
import Participants from '@/components/Participants.vue';
import { LogActions } from '@/store/log/log';
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

const logNamespace = 'log';

@Component({
  components: {
    Participants,
  },
})
export default class Speakers extends Vue {
  /* PUBLIC PROPERTIES */
  /* PRIVATE PROPERTIES */
  /* VUEX GETTERS */

  /* VUEX ACTIONS */
  // Log Actions
  @Action(LogActions.SET_TRACE_LOG_STATE, { namespace: logNamespace })
  private setTraceLogState!: ({
    entityId1,
    entityId2,
    page,
    title,
  }: {
    entityId1?: string;
    entityId2?: string;
    page: string;
    title: string;
  }) => void;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}

  private mounted() {
    this.setTraceLogState({
      page: 'Speakers',
      title: 'Speakers',
    });
  }

  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/
}
</script>

<style scoped></style>
