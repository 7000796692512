<template>
  <div>
    <v-card
      v-if="!$vuetify.breakpoint.mobile && listView"
      class="mb-1"
      :style="{
        width: '100%',
        borderRadius: '0px',
        border: '1px solid gainsboro !important',
      }"
      flat
      @click.stop="sessionSelected(session)"
    >
      <v-row class="ma-0 pa-0">
        <v-col class="ma-3 pa-0" cols="1">
          <v-img
            contain
            :aspect-ratio="16 / 9"
            :src="bannerImage"
            @error="handleImageError()"
          >
          </v-img>
        </v-col>
        <v-col class="ma-0 pa-0 ml-1">
          <v-card-text>
            <div class="font-weight-bold">{{ session.title }}</div>
            <div v-if="session.isSession">
              <div class="mb-1">
                <v-icon class="mr-1">mdi-calendar-clock</v-icon>
                <span class="subheading mr-2">{{
                  formatStartEnd(session.start, session.end)
                }}</span>
              </div>
              <div>
                <v-icon class="mr-1">mdi-google-classroom</v-icon>
                <span class="subheading mr-2">{{ session.roomName }}</span>
              </div>
            </div>
            <div
              v-else-if="session.isPresentation && session.activeSpeakerName"
            >
              <div>
                <v-icon class="mr-1">mdi-account-voice</v-icon>
                <span
                  :style="speakersModuleEnabled ? { cursor: 'pointer' } : {}"
                  class="subheading mr-2"
                  @click.stop="navigateToSpeaker(session)">
                    {{ session.activeSpeakerName }}
                </span>
              </div>
            </div>
            <div v-if="showKeywords && session.keywords">
              <v-icon class="mr-1">mdi-key-variant</v-icon>
              <span class="subheading mr-2">{{ session.keywords }}</span>
            </div>
          </v-card-text>
        </v-col>
        <v-col cols="auto" class="text-right" align-self="center">
          <v-btn
            v-if="session.isLive"
            outlined
            color="primary"
            @click.stop="sessionSelected(session)"
          >
            <v-icon color="red" class="live-dot">mdi-circle</v-icon>
            Join
          </v-btn>

          <v-btn
            v-else-if="session.isAPoster && session.description"
            outlined
            color="primary"
            @click.stop="$emit('posterSelected', session)"
          >
            {{ $t('presentation.description') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-else
      color="transparent"
      :height="session ? 'auto' : 0"
      :width="cardMinWidth"
      max-width="414"
      :min-width="cardMinWidth"
      flat
      tile
    >
      <v-hover v-if="!$vuetify.breakpoint.mobile && session && !related">
        <template #default="{ hover }">
          <v-card class="blur" color="transparent" hover flat>
            <v-chip
              v-if="session.isLive"
              small
              color="pink"
              label
              text-color="white"
              class="live mt-1 mr-1"
            >
              <span>LIVE</span>
              <v-icon right class="blink-me" color="whitee">
                mdi-record
              </v-icon>
            </v-chip>
            <v-img
              contain
              :aspect-ratio="16 / 9"
              :src="bannerImage"
              @error="handleImageError()"
            >
              <v-expand-transition>
                <v-card
                  v-if="hover"
                  color="rgba(255, 255, 255, .85)"
                  style="height: 100%"
                  flat
                  :class="
                    sessionActive(session)
                      ? ' transition-fast-in-fast-out v-card--reveal display-3 pointer blur'
                      : 'transition-fast-in-fast-out v-card--reveal display-3 not-allowed blur'
                  "
                  @click.stop="sessionSelected(session)"
                >
                  <v-tooltip bottom max-width="300">
                    <template #activator="{ on }">
                      <v-card-text class="pb-0 mb-0">
                        <span class="session-title" v-on="on">{{
                          session.title
                        }}</span>
                      </v-card-text>
                    </template>
                    <p class="ma-0 pa-0 text-center">{{ session.title }}</p>
                    <p
                      v-if="!sessionActive(session)"
                      class="ma-0 pa-0 text-center"
                    >
                      <br />
                      {{ $t('session.noContent') }}
                    </p>
                  </v-tooltip>

                  <v-card-text>
                    <div class="subtitle-1">{{ session.type }}</div>
                    <div v-if="session.isSession">
                      <div class="mb-1">
                        <v-icon class="mr-1">mdi-calendar-clock</v-icon>
                        <span class="subheading mr-2">{{
                          formatStartEnd(session.start, session.end)
                        }}</span>
                      </div>
                      <div>
                        <v-icon class="mr-1">mdi-google-classroom</v-icon>
                        <span class="subheading mr-2">{{
                          session.roomName
                        }}</span>
                      </div>
                    </div>
                    <div v-else-if="session.isPresentation">
                      <div>
                        <v-icon class="mr-1">mdi-account-voice</v-icon>
                        <span class="subheading mr-2">{{
                          session.activeSpeakerName
                        }}</span>
                      </div>
                    </div>
                    <div v-if="showKeywords && session.keywords">
                      <v-icon class="mr-1">mdi-key-variant</v-icon>
                      <span class="subheading mr-2">{{
                        session.keywords
                      }}</span>
                    </div>
                  </v-card-text>
                  <!-- <v-btn
                  class="bottom"
                  icon
                  @click.stop="bookmarkExecute(session)"
                >
                  <v-icon v-if="isABookmark(session.id)">
                    mdi-bookmark-check
                  </v-icon>
                  <v-icon v-else> mdi-bookmark-plus-outline </v-icon>
                </v-btn> -->
                </v-card>
              </v-expand-transition>
              <template #placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </template>
      </v-hover>
      <v-card
        v-else-if="session"
        class="blur"
        color="transparent"
        hover
        flat
        @click.stop="sessionSelected(session)"
      >
        <v-chip
          v-if="session.isLive"
          small
          color="pink"
          label
          text-color="white"
          class="live mt-1 mr-1"
        >
          <span>LIVE</span>
          <v-icon right class="blink-me" color="whitee"> mdi-record </v-icon>
        </v-chip>
        <v-img
          contain
          :aspect-ratio="16 / 9"
          :src="bannerImage"
          @error="handleImageError()"
        >
        </v-img
      ></v-card>

      <div v-if="session"
           :class="
                    sessionActive(session)
                      ? 'pointer'
                      : 'not-allowed'
                  "

      >
        <v-container class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="11" class="ma-0 pa-0">
              <v-card-text class="mt-0 pt-2 pb-0 mb-0">
                <span :style="{ color: textColour }" class="session-title">{{
                  session.title
                }}</span>
              </v-card-text>
              <v-card-text class="pt-0 mt-0">
                <span v-if="showViews" :style="{ color: textColour }">
                  {{ session.views }}
                </span>
                <span v-if="showViews" :style="{ color: textColour }">
                  {{
                    session.views > 0 ? $t('session.views') : $t('session.view')
                  }}
                </span>
                <span
                  v-if="showViews && showRatings"
                  :style="{ color: textColour }"
                  class="mx-1"
                  >•</span
                >
                <span v-if="showRatings">
                  <v-rating
                    class="d-inline"
                    :value="session.rating"
                    :background-color="textColour"
                    color="yellow accent-4"
                    dense
                    half-increments
                    readonly
                    size="14"
                  ></v-rating>

                  {{ session.rating.toFixed(1) }} ({{ session.numOfRatings }})
                </span>
              </v-card-text>
            </v-col>
            <v-col cols="1" class="ma-0 pa-0" v-if="isAuthenticated">
              <v-btn icon @click.stop="bookmarkExecute(session)">
                <v-icon v-if="isABookmark(session.id)">
                  mdi-bookmark-check
                </v-icon>
                <v-icon v-else> mdi-bookmark-plus-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '@/store/bookmark/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { PlatformGetters } from '../store/platform/types';
import { SessionItem } from '../store/session/types';
import { AuthGetters } from '@/store/auth/types';

const authNamespace = 'auth';
const bookmarkNamespace = 'bookmark';
const platformNamespace = 'platform';

@Component({})
export default class SessionCard extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public session!: SessionItem;

  @Prop()
  public textColourOverride!: string;

  @Prop()
  public clickOverride!: boolean;

  @Prop()
  public homeVod!: boolean;

  @Prop()
  public related!: boolean;

  @Prop()
  public listView!: boolean;

  /* PRIVATE PROPERTIES */
  private thumbnailError = false;
  private defaultThumbnailError = false;

  /* VUEX GETTERS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Bookmark Getters
  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string) => boolean;

  // Platform Getters
  @Getter(PlatformGetters.NAV_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarTextColour!: string;

  @Getter(PlatformGetters.NAV_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarBackgroundColour!: string;
  @Getter(PlatformGetters.SHOW_VIEWS, {
    namespace: platformNamespace,
  })
  private showViews!: boolean;

  @Getter(PlatformGetters.SHOW_RATINGS, {
    namespace: platformNamespace,
  })
  private showRatings!: boolean;

  @Getter(PlatformGetters.SHOW_LIKES, {
    namespace: platformNamespace,
  })
  private showLikes!: boolean;

  @Getter(PlatformGetters.SHOW_KEYWORDS, {
    namespace: platformNamespace,
  })
  private showKeywords!: boolean;

  @Getter(PlatformGetters.ALWAYS_USER_DEFAULT_THUMBNAIL, {
    namespace: platformNamespace,
  })
  private alwaysUseDefaultThumbnail!: boolean;

  @Getter(PlatformGetters.DEFAULT_SESSION_THUMBNAIL_URI, {
    namespace: platformNamespace,
  })
  private defaultSessionThumbnailUri!: string;

  @Getter(PlatformGetters.SPEAKERS_MODULE_ENABLED, {
    namespace: platformNamespace,
  })
  private speakersModuleEnabled;



  /* VUEX ACTIONS */
  // Bookmark Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get bannerImage(): string {
    if (
      this.homeVod &&
      !this.thumbnailError &&
      this.session &&
      this.session.homeVodThumbnailUri &&
      this.session.homeVodThumbnailUri.trim()
    ) {
      return this.session.homeVodThumbnailUri;
    } else if (
      !this.thumbnailError &&
      !this.alwaysUseDefaultThumbnail &&
      this.session &&
      this.session.thumbnailUri &&
      this.session.thumbnailUri.trim()
    ) {
      return this.session.thumbnailUri;
    } else if (!this.defaultThumbnailError && this.defaultSessionThumbnailUri) {
      return this.defaultSessionThumbnailUri;
    } else {
      return 'https://slidetotal.azureedge.net/slidetotal/defaultsession.png';
    }
  }

  get cardMinWidth(): number {
      let w = 414;
      if (this.homeVod && this.$vuetify.breakpoint.mobile)
      {
        return window.innerWidth * 0.75;
      } else {
        return window.innerWidth < 414 ? window.innerWidth * 0.95 : 414;
      }
  }

  /* PRIVATE METHODS*/
  private bookmarkExecute(s: SessionItem) {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const bi = this.getBookmark(s.id);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      let t = '';
      let et = '';
      let eu = '';
      if (s.isAPoster) {
        t = BookmarkType.POSTER;
        et = BookmarkEntityType.POSTER;
        eu = `/${eventCode}/session/${s.sessionId}/presentation/${s.id}`;
      } else if (s.isPresentation) {
        t = BookmarkType.VIDEO;
        et = BookmarkEntityType.PRESENTATION;
        eu = `/${eventCode}/session/${s.sessionId}/presentation/${s.id}`;
      } else {
        t = BookmarkType.VIDEO;
        et = BookmarkEntityType.SESSION;
        eu = `/${eventCode}/session/${s.id}`;
      }

      const bm: BookmarkItem = {
        end: s.end,
        entityId: s.id,
        entityType: et,
        type: t,
        entityUri: eu,
        start: s.start,
        title: s.title,
      };
      this.bookmarkItem(bm);
    }
  }

  // '25/05/20 09:00-10:00',
  private formatStartEnd(start: Date, end: Date) {
    const s = moment(start).format('DD/MM/YYYY HH:mm');
    const e = moment(end).format('HH:mm');
    return `${s}-${e}`;
  }

  private handleImageError() {
    if (!this.thumbnailError) {
      this.thumbnailError = true;
    } else if (!this.defaultThumbnailError) {
      this.defaultThumbnailError = true;
    }
  }

  private navigateToSpeaker(pres: SessionItem) {
    if (!pres.activeSpeakerName) return;

    if (!this.speakersModuleEnabled) return;

    if (!pres.activeSpeakerId) return;

    this.$router.push({
      name: 'speakers',
      query: {
        search: pres.activeSpeakerName,
        id: pres.activeSpeakerId,
        speaker: 'true',
      },
    });
  }

  private async navigateToPresentation(pres: SessionItem) {
    if (!pres.id || !pres.sessionId) return;

    if (!pres.isEventPortalPresentation && !(pres.isPresentation || pres.isAPoster)) return;

    await this.$router.push({
          name: 'presentation',
          params: { sessionId: pres.sessionId, presentationId: pres.id },
        });
  }

  private canNavigateToPresentation(pres: SessionItem): boolean {
    if (!pres.id || !pres.sessionId) return false;

    if (!pres.isEventPortalPresentation && !(pres.isPresentation || pres.isAPoster)) return false;

    return true
  }

  private async openNewTab(url: string | undefined) {
    if (url && (url.startsWith('https:') || url.startsWith('http:'))) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else if (url) {
      await this.$router.push(url);
    }
  }

  private sessionActive(session: SessionItem): boolean {
    if (!session) return false;
    if (
      this.validVideoIFrameUri(session.videoIFrameUri) ||
      (session.isExternalContentSession && session.externalContentUri) ||
      (session.isAPoster && session.posterUploadUri) ||
      (session.isAPoster &&
        session.isExternalContentSession &&
        session.externalContentUri)
    ) {
      return true;
    }
    return false;
  }

  private async sessionSelected(session: SessionItem) {
    if (!session || this.clickOverride) return;
    if (session.isExternalContentSession && session.externalContentUri) {
      this.openNewTab(session.externalContentUri);
    } else if (this.sessionActive(session)) {
      if (session.isSession) {
        const sessionId = session.id;
        await this.$router.push({
          name: 'session',
          params: { sessionId },
        });
      } else if (session.isPresentation) {
        const presentationId = session.id;
        const sessionId = session.sessionId;
        await this.$router.push({
          name: 'presentation',
          params: { sessionId, presentationId },
        });
      }
    } else {
      this.$emit('nocontent');
    }
  }

  private validVideoIFrameUri(uri?: string): boolean {
    if (uri && uri.toLowerCase().startsWith('https:')) {
      return true;
    }

    return false;
  }

  get textColour(): string {
    if (this.textColourOverride) {
      return this.textColourOverride;
    }
    return '#030303';
  }
}
</script>

<style scoped>
.session-title {
  color: rgb(3, 3, 3);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  font-size: 1.3em;
  font-weight: 500;
  line-height: 1.4em;
  max-height: 4.4rem;
}
.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed !important;
}

.blink-me {
  animation: blinker 1.25s linear infinite;
}

.bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}

.live {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

@keyframes blinker {
  50% {
    opacity: 0.25;
  }
}
</style>
