<template>
  <div style="height: 100%">
    <component :is="dynamicComponent" v-if="customHtml && loaded"></component>
    <RoomListDrawer
      v-if="!customHtml && loaded"
      :is-mod="isMod"
      :sponsor-id="sponsorId"
      :sponsor-lounge="sponsorLounge"
    />
    <v-main
      v-if="!customHtml && loaded"
      class="ma-0 pa-0 bg"
      style="height: 100%"
    >
      <div class="chat-wrapper" style="height: 100%">
        <v-btn
          align="center"
          justify="end"
          class="float-right"
          :class="drawer ? 'roomList-close-button' : 'roomList-open-button'"
          icon
          @click.stop="invertDrawer"
        >
          <v-icon>{{ drawer ? 'chevron_right' : 'chevron_left' }}</v-icon>
        </v-btn>

        <div v-if="currentGroup">
          <LoungeRoomDetails :is-mod="isMod" />

          <v-divider></v-divider>

          <v-container
            v-mutate.child="onNewMessage"
            class="overflow-y-auto light-chat"
          >
            <v-list-item
              v-for="cm in currentGroupMessages"
              :id="cm.id"
              :key="cm.id"
              dense
            >
              <v-list-item-avatar>
                <Avatar
                  size="48"
                  color="primary"
                  :src="cm.userPhotoUri"
                  :alt="cm.userName + ' avatar'"
                  default="mdi-account-circle-outline"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ cm.userName }}</v-list-item-title>
                <v-list-item-subtitle>{{ cm.message }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="isMod">
                  <v-btn
                    text
                    x-small
                    @click.stop="removeChatMessageExecute(cm)"
                  >
                    <v-icon size="12">mdi-delete-circle</v-icon>
                    {{ $t('common.delete') }}
                  </v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div id="bottom" />
          </v-container>

          <v-container fluid py-0>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="newChatMessageText"
                  filled
                  prepend-icon="mdi-comment-text"
                  :label="$t('lounge.postMessage')"
                  :counter="500"
                  required
                  @keydown.enter="postChatMessageExecute"
                >
                  <template #append-outer>
                    <v-btn
                      type="submit"
                      depressed
                      class="white--text"
                      color="primary"
                      @click.stop="postChatMessageExecute"
                      >Chat</v-btn
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <v-container v-else fill-height fluid>
          <v-row align="center" justify="center">
            <v-card max-width="500" class="mx-auto">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="headline">{{
                    welcome()
                  }}</v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <div class="display-1"></div>
              <v-img :src="entryImage()"></v-img>

              <v-card-text>{{ $t('lounge.selectRoom') }}</v-card-text>
            </v-card>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </div>
</template>

<script lang="ts">
import Avatar from '@/components/Avatar.vue';
import LoungeRoomDetails from '@/components/LoungeRoomDetails.vue';
import RoomListDrawer from '@/components/RoomListDrawer.vue';
import { LogActions } from '@/store/log/log';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { SponsorLounge } from '../models/SponsorLounge';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { LoungeActions, LoungeGetters } from '../store/lounge/types';
import { PlatformGetters } from '../store/platform/types';
import {
  PlatformUserActions,
  PlatformUserGetters,
  PlatformUserItem,
} from '../store/platformUser/types';
import {
  SignalrActions,
  SignalrGetters,
  SignalrGroup,
  SignalrMessage,
  SignalrMessageTypes,
} from '../store/signalr/types';

const logNamespace = 'log';
const loungeNamespace = 'lounge';
const signalrNamespace = 'signalr';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component({
  components: {
    Avatar,
    LoungeRoomDetails,
    RoomListDrawer,
  },
})
export default class Lounge extends Vue {
  @Prop()
  public sponsorId!: string;
  private newChatMessageText = '';
  private isMod = false;

  private loaded = false;
  private customHtml = '';
  private sponsorLounge: SponsorLounge | null = null;

  get dynamicComponent(): { template: string } {
    return {
      template: `${this.customHtml}`,
    };
  }

  /* VUEX GETTERS */
  // Lounge Getters
  @Getter(LoungeGetters.DRAWER, { namespace: loungeNamespace })
  private drawer!: boolean;

  // Platform Getter
  @Getter(PlatformGetters.LOUNGE_IMAGE_URI, { namespace: platformNamespace })
  private loungeImageUri!: string;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private currentUser!: PlatformUserItem;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME, {
    namespace: platformUserNamespace,
  })
  private currentUserFullName!: string;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  // Signalr Getters
  @Getter(SignalrGetters.CURRENT_GROUP, { namespace: signalrNamespace })
  private currentGroup!: SignalrGroup;

  @Getter(SignalrGetters.CURRENT_GROUP_MESSAGES, {
    namespace: signalrNamespace,
  })
  private currentGroupMessages!: SignalrMessage[];

  @Getter(`${SignalrGetters.USER_CONNECTED}`, { namespace: signalrNamespace })
  private userConnected!: boolean;

  /* VUEX ACTIONS */
  // Log Actions
  @Action(LogActions.SET_TRACE_LOG_STATE, { namespace: logNamespace })
  private setTraceLogState!: ({
    entityId1,
    entityId2,
    page,
    title,
  }: {
    entityId1?: string;
    entityId2?: string;
    page: string;
    title: string;
  }) => void;

  // Lounge Actions
  @Action(LoungeActions.INVERT_DRAWER, { namespace: loungeNamespace })
  private invertDrawer!: () => void;

  // Platform User Actions
  @Action(PlatformUserActions.IS_LOUNGE_MODERATOR, {
    namespace: platformUserNamespace,
  })
  private isLoungeModerator!: () => Promise<boolean>;

  @Action(PlatformUserActions.IS_SPONSOR_LOUNGE_MODERATOR, {
    namespace: platformUserNamespace,
  })
  private isSponsorLoungeModerator!: (sponsorId: string) => boolean;

  // Signalr Actions
  @Action(SignalrActions.POST_CHAT_MESSAGE, { namespace: signalrNamespace })
  private postChatMessage!: (msg: SignalrMessage) => void;

  @Action(SignalrActions.REMOVE_CHAT_MESSAGE, { namespace: signalrNamespace })
  private removeChatMessage!: (msg: SignalrMessage) => void;

  /* LOCAL GETTERS */

  /* PRIVATE METHODS*/

  private entryImage() {
    if (this.sponsorLounge) {
      return this.sponsorLounge.sponsorLoungeImageUri;
    } else if (this.loungeImageUri) {
      return this.loungeImageUri;
    }
    return 'https://slidetotal.azureedge.net/slidetotal/lounge.jpg';
  }

  private welcome() {
    if (this.sponsorLounge) {
      return this.$t('lounge.sponsorWelcome', this.$i18n.locale, [
        this.sponsorLounge.title,
      ]);
    } else {
      return this.$t('lounge.welcome', this.$i18n.locale);
    }
  }

  private onNewMessage() {
    const e = document.getElementById('bottom');
    if (e) {
      e.scrollIntoView();
    }
  }

  private postChatMessageExecute() {
    if (this.currentGroup) {
      if (this.newChatMessageText.length > 1) {
        const msg: SignalrMessage = this.createBaseMessage(
          SignalrMessageTypes.POST_CHAT,
        );
        msg.message = this.newChatMessageText;

        this.postChatMessage(msg);
        this.newChatMessageText = '';
      }
    } else {
      console.error('Not currently in a room');
    }
  }

  private removeChatMessageExecute(msg: SignalrMessage) {
    if (this.currentGroup) {
      msg.messageType = SignalrMessageTypes.REMOVE_CHAT;
    }
    this.removeChatMessage(msg);
  }

  private async created() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    if (this.sponsorId) {
      try {
        const res = await Vue.$http.get<SponsorLounge>(
          `/api/platform/${platformId}/sponsors/${this.sponsorId}/lounge`,
        );
        this.sponsorLounge = res.data;
        this.isMod = await this.isSponsorLoungeModerator(this.sponsorId);
        this.loaded = true;
      } catch (e: any) {
        AppInsightsLogger.logError('Lounge - created failed', undefined, true);
        AppInsightsLogger.logException(e, false);
        return false;
      }
    } else {
      try {
        const res = await Vue.$http.get(`/api/platform/${platformId}/lounge`);
        this.customHtml = res.data;

        if (this.customHtml) {
          this.loaded = true;
        } else {
          this.isMod = await this.isLoungeModerator();
          this.loaded = true;
        }
      } catch (e: any) {
        AppInsightsLogger.logError('Lounge - created failed', undefined, true);
        AppInsightsLogger.logException(e, false);
        return false;
      }
    }
  }

  private mounted() {
    this.setTraceLogState({ page: 'Lounge', title: 'Lounge' });
  }

  private createBaseMessage(messageType: SignalrMessageTypes): SignalrMessage {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const eventId: string = sessionStorage.getItem('platformId') ?? '';
    const groupId = this.currentGroup.groupId;
    const group = this.currentGroup.group;
    const groupType = this.currentGroup.groupType;
    let parentId = '';

    if (this.sponsorId) {
      parentId = this.sponsorId;
    } else {
      parentId = eventId;
    }

    const msg = {
      eventCode,
      eventId,
      group,
      groupId,
      groupType,
      isMod: this.isMod,
      messageType,
      parentId,
      userId: this.currentUserId,
      userName: this.currentUserFullName,
      userPhotoUri: this.currentUser.photoUri,
    };

    return msg;
  }
}
</script>

<style scoped>
.light-chat {
  height: calc(100vh - 300px);
}

.light-chat::-webkit-scrollbar {
  width: 5px;
}

.light-chat::-webkit-scrollbar-track {
  background: transparent;
  border-left: 0;
}

.light-chat::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: 0;
  border-radius: 7px;
}

.light-chat::-webkit-scrollbar-thumb:hover {
  background: black;
}

.roomList-close-button {
  visibility: hidden;
}
.roomList-open-button {
  margin-top: 10px;
  position: fixed;
  z-index: 9999;
  right: 0;
}
.room-stats {
  font-size: 10px;
  padding: 0;
  margin: 0;
}
</style>
