<template>
  <div>
    <v-card
      color="transparent"
      :height="pu ? cardHeight : 0"
      :width="cardWidth"
    >
      <flipping-card v-if="pu" :flipped="flipped">
        <template #front>
          <v-card
            flat
            tile
            class="blur ma-0 pa-0"
            color="rgba(255, 255, 255, .92)"
            :height="cardHeight"
            :width="cardWidth"
          >
            <v-card-title class="justify-center mb-0 pb-0">
              <Avatar
                :size="avatarSize"
                :src="pu.photoUri"
                :alt="pu.userName + ' avatar'"
                default="mdi-account-circle"
              />
            </v-card-title>
            <v-card-title class="justify-center mb-0 pb-0">
              {{ pu.title }} {{ pu.userName }}
            </v-card-title>
            <v-card-text class="text-center my-0 py-0" :style="{ fontSize: '12px' }">
              {{ pu.organisation }}</v-card-text>
            <v-card-text class="text-center my-0 py-0" :style="{ fontSize: '12px' }">
              {{ pu.town }}
              <span v-if="pu.town && pu.country">, </span>
              {{ pu.country }}
            </v-card-text>
          </v-card>
        </template>
        <template #back>
          <v-card
            flat
            tile
            class="blur ma-0 pa-0 fill-height"
            color="rgba(255, 255, 255, .92)"
            :height="cardHeight"
            :width="cardWidth"
          >
            <v-img
              :height="imageLoaded ? 'auto' : 1"
              :src="pu.businessCardImageUri"
              @load="delayImageDisplay"
            ></v-img>
          </v-card>
        </template>
      </flipping-card>
    </v-card>
    <v-toolbar
      v-if="pu"
      flat
      :color="navBarBackgroundColour"
      width="100%"
      :style="{ color: navBarTextColour }"
    >
      <presentations-dialog
        v-if="pu.isSpeaker || pu.isModerator"
        :platform-user="pu"
      />
      <v-spacer v-if="pu.businessCardImageUri"></v-spacer>
      <!-- <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn v-if="pu.biography" icon>
            <v-icon :color="navBarTextColour" v-bind="attrs" v-on="on">
              mdi-text-account
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('participant.Biography') }}</span>
      </v-tooltip> -->
      <biography-dialog v-if="pu.biography" :platform-user="pu" />
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn v-if="pu && pu.businessCardImageUri" icon>
            <v-icon
              :color="navBarTextColour"
              v-bind="attrs"
              v-on="on"
              @click="flipped = !flipped"
            >
              mdi-redo
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('common.Flip') }}</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-tooltip bottom v-if="isAuthenticated">
        <template #activator="{ on, attrs }">
          <v-btn icon @click="openNewConverstation(pu)">
            <v-icon :color="navBarTextColour" v-bind="attrs" v-on="on">
              mdi-send
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('chat.sendMessage') }}</span>
      </v-tooltip>
    </v-toolbar>
  </div>
</template>

<script lang="ts">
import Avatar from '@/components/Avatar.vue';
import BiographyDialog from '@/components/BiographyDialog.vue';
import PresentationsDialog from '@/components/PresentationsDialog.vue';
import FlippingCard from '@/components/FlippingCard.vue';
import { AppInsightsLogger } from '@/services/appInsightsLogger';
import { AuthGetters } from '@/store/auth/types';
import {
  ConversationActions,
  ConversationGetters,
  ConversationItem,
} from '@/store/conversation/types';
import { PlatformGetters } from '@/store/platform/types';
import {
  PlatformUserGetters,
  PlatformUserItem,
} from '@/store/platformUser/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
const conversationNamespace = 'conversation';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';
const authNamespace = 'auth';

@Component({
  components: {
    Avatar,
    BiographyDialog,
    FlippingCard,
    PresentationsDialog,
  },
})
export default class PlatformUserCard extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public pu: PlatformUserItem | undefined;

  /* PRIVATE PROPERTIES */
  private flipped = false;
  private imageLoaded = false;

  private delayImageDisplay() {
    window.setTimeout(() => {
      this.imageLoaded = true;
    }, 1000);
  }

  /* VUEX GETTERS */
  // Authencation Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Conversation Getters
  @Getter(ConversationGetters.CONVERSATION_IS_OPEN, {
    namespace: conversationNamespace,
  })
  private conversationIsOpen!: (id: string) => boolean;

  // Platform Getters
  @Getter(PlatformGetters.NAV_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarTextColour!: string;

  @Getter(PlatformGetters.NAV_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarBackgroundColour!: string;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  /* VUEX ACTIONS */
  // Converstation Actions
  @Action(ConversationActions.OPEN_CONVERSATION, {
    namespace: conversationNamespace,
  })
  private openConversation!: (conversation: ConversationItem) => void;

  @Action(ConversationActions.VERIFY_CONVERSATION_VISIBLE, {
    namespace: conversationNamespace,
  })
  private verifyConversationVisible!: (id: string) => void;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get cardWidth(): number {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 320;
    } else {
      return 400;
    }
  }
  get cardHeight(): number {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 179;
    } else {
      return 224;
    }
  }

  get avatarSize(): number {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 60;
    } else {
      return 110;
    }
  }
  /* PRIVATE METHODS*/

  private async openNewConverstation(
    user: PlatformUserItem | undefined,
  ): Promise<void> {
    if (!user) return;
    const user2 = user.userId;
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const user1 = this.currentUserId;
    try {
      const convo = { eventId: platformId, user1, user2 };
      const res = await Vue.$http.post(
        `/api/platform/${platformId}/conversations/new`,
        convo,
      );
      const conversationId = res.data.conversationId;
      if (this.conversationIsOpen(conversationId)) {
        this.verifyConversationVisible(conversationId);
        return;
      }
      const item = await Vue.$http.get<ConversationItem>(
        `/api/platform/${platformId}/conversations/${conversationId}`,
      );
      this.openConversation(item.data);
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PlatformUserCard - openNewConverstation failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }
}
</script>

<style scoped></style>
