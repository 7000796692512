<template>
  <div>
    <v-navigation-drawer
      v-model="drawerState"
      :mini-variant="miniVariant"
      light
      :persistent="!$vuetify.breakpoint.mobile"
      right
      :clipped="!$vuetify.breakpoint.mobile"
      enable-resize-watcher
      mobile-breakpoint="0"
      :width="$vuetify.breakpoint.mobile ? '95%' : 340"
      :mini-variant-width="$vuetify.breakpoint.mobile ? 0 : 50"
      fixed
      app
    >
      <ActivityDrawerTabs
        :isMod="isMod"
        :isSession="isSession"
        :isSponsor="isSponsor"
        :sponsor="sponsor"
        :pageLoaded="pageLoaded"
      />
    </v-navigation-drawer>
    <v-btn
      v-if="
        $vuetify.breakpoint.mobile &&
        miniVariant &&
        (isSession || sponsor.enableStandChat)
      "
      fixed
      bottom
      right
      fab
      color="primary"
      @click="invertMiniVariant"
    >
      <v-icon>mdi-forum-outline</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import ActivityDrawerTabs from '@/components/ActivityDrawerTabs.vue';

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { SponsorItem } from '../models/Sponsor';

import {
  NavigationActions,
  NavigationGetters,
} from '../store/navigation/types';

const navigationNamespace = 'navigation';

@Component({
  components: {
    ActivityDrawerTabs,
  },
})
export default class ActivityDrawer extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public isMod!: boolean;

  @Prop()
  public isSession!: boolean;

  @Prop()
  public isSponsor!: boolean;

  @Prop()
  public sponsor!: SponsorItem;

  @Prop()
  public pageLoaded!: boolean;

  /* PRIVATE PROPERTIES */

  /* VUEX GETTERS */
  // Navigation Getters
  @Getter(NavigationGetters.ACTIVITY_DRAWER, { namespace: navigationNamespace })
  private drawer!: boolean;

  @Getter(NavigationGetters.ACTIVITY_MINI_VARIANT, {
    namespace: navigationNamespace,
  })
  private miniVariant!: boolean;

  /* VUEX ACTIONS */
  // Navigation Actions

  @Action(NavigationActions.ACTIVITY_INVERT_MINI_VARIANT, {
    namespace: navigationNamespace,
  })
  private invertMiniVariant!: () => void;

  @Action(NavigationActions.ACTIVITY_SET_DRAWER, {
    namespace: navigationNamespace,
  })
  private setDrawerState!: (value: boolean) => void;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get drawerState(): boolean {
    return this.drawer;
  }
  set drawerState(value: boolean) {
    this.setDrawerState(value);
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private mounted() {
    if (this.$vuetify.breakpoint.mobile && !this.miniVariant) {
      this.invertMiniVariant();
    }
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/

  /* PRIVATE METHODS*/
}
</script>
<style scoped></style>
