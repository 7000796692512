import { render, staticRenderFns } from "./Stand.vue?vue&type=template&id=392a6532&scoped=true"
import script from "./Stand.vue?vue&type=script&lang=ts"
export * from "./Stand.vue?vue&type=script&lang=ts"
import style0 from "./Stand.vue?vue&type=style&index=0&id=392a6532&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "392a6532",
  null
  
)

export default component.exports