<template>
  <ContentVideoPlayer :is-presentation="false" :session-id="sessionId" />
</template>

<script lang="ts">
import ContentVideoPlayer from '@/components/ContentVideoPlayer.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    ContentVideoPlayer,
  },
})
export default class Session extends Vue {
  @Prop()
  public sessionId!: string;
}
</script>

<style scoped></style>
