import { render, staticRenderFns } from "./NavDrawer.vue?vue&type=template&id=563e1b48&scoped=true"
import script from "./NavDrawer.vue?vue&type=script&lang=ts"
export * from "./NavDrawer.vue?vue&type=script&lang=ts"
import style0 from "./NavDrawer.vue?vue&type=style&index=0&id=563e1b48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563e1b48",
  null
  
)

export default component.exports