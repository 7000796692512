<template>
  <v-menu
    v-model="visible"
    :close-on-content-click="false"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
    min-width="300"
    max-width="300"
  >
    <v-card>
      <v-list two-line subheader>
        <v-list-item>
          <v-list-item-avatar color="primary">
            <v-img v-if="user.photoUri" :src="user.photoUri"></v-img>
            <v-icon v-else color="white">mdi-account-circle-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="user.userName"></v-list-item-title>
            <v-list-item-subtitle
              v-text="user.organisation"
            ></v-list-item-subtitle>
            <v-list-item-subtitle v-text="user.country"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click.stop="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-btn
            v-if="user.userId !== currentUserId && !$vuetify.breakpoint.mobile"
            small
            tile
            color="primary"
            @click="openNewConverstation(user)"
          >
            <v-icon left>mdi-message</v-icon>Message
          </v-btn>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import {
  ConversationActions,
  ConversationGetters,
  ConversationItem,
} from '../store/conversation/types';
import {
  PlatformUserActions,
  PlatformUserGetters,
  PlatformUserItem,
} from '../store/platformUser/types';
const platformUserNamespace = 'platformUser';
const conversationNamespace = 'conversation';

@Component({})
export default class PlatformUserDetails extends Vue {
  /* VUEX GETTERS */
  // Conversation Getters
  @Getter(ConversationGetters.CONVERSATION_IS_OPEN, {
    namespace: conversationNamespace,
  })
  private conversationIsOpen!: (id: string) => boolean;

  // Platform User Getters
  @Getter(PlatformUserGetters.PLATFORM_USER_CARD_VISIBLE, {
    namespace: platformUserNamespace,
  })
  private platformUserCardVisible!: boolean;

  @Getter(PlatformUserGetters.PLATFORM_USER_CARD_X_POS, {
    namespace: platformUserNamespace,
  })
  private x!: boolean;

  @Getter(PlatformUserGetters.PLATFORM_USER_CARD_Y_POS, {
    namespace: platformUserNamespace,
  })
  private y!: boolean;

  @Getter(PlatformUserGetters.VISIBLE_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private user!: PlatformUserItem;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  /* VUEX ACTIONS */
  @Action(PlatformUserActions.SET_PLATFORM_USER_CARD_VISIBILITY, {
    namespace: platformUserNamespace,
  })
  private setPlatformUserCardVisibility!: (visible: boolean) => void;

  @Action(ConversationActions.OPEN_CONVERSATION, {
    namespace: conversationNamespace,
  })
  private openConverstation!: (conversation: ConversationItem) => void;

  @Action(ConversationActions.VERIFY_CONVERSATION_VISIBLE, {
    namespace: conversationNamespace,
  })
  private verifyConversationVisible!: (id: string) => void;

  get visible(): boolean {
    return this.platformUserCardVisible;
  }

  set visible(visible: boolean) {
    this.setPlatformUserCardVisibility(visible);
  }

  private async openNewConverstation(user: PlatformUserItem): Promise<void> {
    if (!user) return;
    const user2 = user.userId;
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const user1 = this.currentUserId;
    try {
      const convo = { eventId: platformId, user1, user2 };
      const res = await Vue.$http.post(
        `/api/platform/${platformId}/conversations/new`,
        convo,
      );
      const conversationId = res.data.conversationId;
      if (this.conversationIsOpen(conversationId)) {
        this.verifyConversationVisible(conversationId);
        return;
      }
      const item = await Vue.$http.get<ConversationItem>(
        `/api/platform/${platformId}/conversations/${conversationId}`,
      );
      this.openConverstation(item.data);
    } catch (e: any) {
      AppInsightsLogger.logError(
        'PlatformUserDetails - openNewConverstation failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private close() {
    this.setPlatformUserCardVisibility(false);
  }
}
</script>
