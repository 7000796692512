<template>
  <div>
    <v-menu
      v-if="!$vuetify.breakpoint.mobile"
      open-on-hover
      bottom
      offset-y
      left
      transition="slide-y-transition"
      close-delay="50"
    >
      <template #activator="{ on }">
        <v-btn class="btnLocaleActivation mx-1" icon v-on="on">
          <v-icon :color="textColour" class="mr-1">mdi-earth</v-icon>
          <span :style="{ color: textColour }">{{ displayLocale }}</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, i) in langs"
          :key="`Lang${i}`"
          active-class="white--text"
          :value="item.lang"
          :class="[item.class]"
          class="'d-inline-flex"
          @click="setLocale(item.lang)"
        >
          <country-flag :country="item.flag" style="width: 105px" />
          <v-list-item-title class="ml-3">
            {{ item.lang.toUpperCase() }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-container v-else>
      <v-radio-group class="ma-0 pa-0" column v-model="displayLocale">
        <v-radio
          v-for="(item, i) in langs"
          :key="i"
          :value="item.lang"
          @click="setLocale(item.lang)"
          class="ma-5"
        >
          <template #label>
            <country-flag :country="item.flag" />
            <span class="pl-3">
              {{ item.fullName }}
            </span>
          </template>
        </v-radio>
      </v-radio-group>
    </v-container>
  </div>
</template>

<script lang="ts">
import CountryFlag from 'vue-country-flag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { I18nActions } from '../store/i18n/types';
const i18nNamespace = 'i18n';

@Component({
  components: {
    CountryFlag,
  },
})
export default class LocaleChanger extends Vue {
  @Prop()
  public textColour!: string;

  private langs = [
    {
      class: 'btnEN',
      flag: 'gb',
      lang: 'en',
      fullName: 'English',
    },
    {
      class: 'btnFR',
      flag: 'fr',
      lang: 'fr',
      fullName: 'Français',
    },
  ];

  @Action(I18nActions.SET_LOCALE, { namespace: i18nNamespace })
  private setLocale!: (locale: string) => void;

  get displayLocale(): string {
    return this.$i18n.locale;
  }
  set displayLocale(local: string) {}
}
</script>

<style scoped></style>
