<template>
  <v-card
    style="text-align: center"
    :flat="flat == undefined || flat == false ? false : true"
  >
    <v-card-text>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        :disabled="!loaded"
        @click.native="playing ? pause() : play()"
      >
        <v-icon v-if="!playing || paused">mdi-play</v-icon>
        <v-icon v-else>mdi-pause</v-icon>
      </v-btn>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        :disabled="!loaded"
        @click.native="stop()"
      >
        <v-icon>mdi-stop</v-icon>
      </v-btn>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        :disabled="!loaded"
        @click.native="mute()"
      >
        <v-icon v-if="!isMuted">mdi-volume-high</v-icon>
        <v-icon v-else>mdi-volume-mute</v-icon>
      </v-btn>
      <v-btn
        v-if="!loaded"
        outlined
        icon
        class="ma-2"
        :color="color"
        @click.native="loaded ? download() : reload()"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn
        v-if="loaded && downloadable"
        outlined
        icon
        class="ma-2"
        :color="color"
        @click.native="loaded ? download() : reload()"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-progress-linear
        v-model="percentage"
        height="5"
        style="margin-top: 15px; margin-bottom: 15px"
        :disabled="!loaded"
        @click.native="setPosition()"
      ></v-progress-linear>
      <p>{{ currentTime }} / {{ duration }}</p>
    </v-card-text>
    <audio
      id="player"
      ref="player"
      :src="file"
      @ended="ended"
      @canplay="canPlay"
    ></audio>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AudioPlayer extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop({ type: Boolean, default: false })
  public flat!: boolean;

  @Prop({ type: Boolean, default: false })
  public autoPlay!: boolean;

  @Prop({ type: String, default: null })
  public file!: string;

  @Prop({
    type: Function,
    default: () => {
      return;
    },
  })
  public ended!: () => void;

  @Prop({
    type: Function,
    default: () => {
      return;
    },
  })
  public canPlay!: () => boolean;

  @Prop({ type: String, default: null })
  public color!: string;

  @Prop({ type: Boolean, default: false })
  public downloadable!: boolean;

  /* PRIVATE PROPERTIES */
  private firstPlay = true;
  private isMuted = false;
  private loaded = false;
  private playing = false;
  private paused = false;
  private percentage = 0;
  private currentTime = '00:00:00';
  private audio: HTMLAudioElement | undefined = undefined;
  private totalDuration = 0;

  get duration(): string {
    return this.formatTime(this.totalDuration);
  }

  private mounted() {
    this.audio = this.$refs.player as HTMLAudioElement;
    if (this.audio) {
      this.audio.addEventListener('timeupdate', this.handlePlayingUI);
      this.audio.addEventListener('loadeddata', this.handleLoaded);
      this.audio.addEventListener('pause', this.handlePlayPause);
      this.audio.addEventListener('play', this.handlePlayPause);
      this.audio.addEventListener('ended', this.handleEnded);
    }
  }

  private beforeDestroy() {
    if (this.audio) {
      this.audio.removeEventListener('timeupdate', this.handlePlayingUI);
      this.audio.removeEventListener('loadeddata', this.handleLoaded);
      this.audio.removeEventListener('pause', this.handlePlayPause);
      this.audio.removeEventListener('play', this.handlePlayPause);
      this.audio.removeEventListener('ended', this.handleEnded);
    }
  }

  private formatTime(second: number): string {
    return new Date(second * 1000).toISOString().substr(11, 8);
  }

  private setPosition(): void {
    if (this.audio) {
      this.audio.currentTime = Math.round(
        (this.audio.duration / 100) * this.percentage,
      );
    }
  }

  private stop(): void {
    if (this.audio) {
      this.audio.pause();
      this.paused = true;
      this.playing = false;
      this.audio.currentTime = 0;
    }
  }

  private play() {
    if (this.audio) {
      if (this.playing) return;
      this.audio.play().then(() => (this.playing = true));
      this.paused = false;
    }
  }

  private pause() {
    if (this.audio) {
      this.paused = !this.paused;
      this.paused ? this.audio.pause() : this.audio.play();
    }
  }

  private download() {
    if (this.audio) {
      this.audio.pause();
      window.open(this.file, 'download');
    }
  }

  private mute() {
    if (this.audio) {
      this.isMuted = !this.isMuted;
      this.audio.muted = this.isMuted;
    }
  }

  private reload() {
    if (this.audio) {
      this.audio.load();
    }
  }

  private handleLoaded() {
    if (!this.audio) return;
    if (this.audio.readyState >= 2) {
      if (this.audio.duration === Infinity) {
        // Fix duration for streamed audio source or blob based
        // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
        this.audio.currentTime = 1e101;
        this.audio.ontimeupdate = () => {
          if (!this.audio) return;
          this.audio.ontimeupdate = () => {
            return;
          };
          this.audio.currentTime = 0;
          this.totalDuration = Math.floor(this.audio.duration);
          this.loaded = true;
        };
      } else {
        if (!this.audio) return;
        this.totalDuration = Math.floor(this.audio.duration);
        this.loaded = true;
      }

      if (this.autoPlay) this.audio.play();
    } else {
      throw new Error('Failed to load sound file');
    }
  }

  private handlePlayingUI(): void {
    if (!this.audio) return;
    this.percentage = (this.audio.currentTime / this.audio.duration) * 100;
    this.currentTime = this.formatTime(this.audio.currentTime);
    this.playing = true;
  }

  private handlePlayPause(e: Event): void {
    if (!this.audio) return;
    if (e.type === 'play' && this.firstPlay) {
      // in some situations, audio.currentTime is the end one on chrome
      this.audio.currentTime = 0;
      if (this.firstPlay) {
        this.firstPlay = false;
      }
    }
    if (e.type === 'pause' && this.paused === false && this.playing === false) {
      this.currentTime = '00:00:00';
    }
  }

  private handleEnded() {
    this.paused = this.playing = false;
  }
}
</script>
<style scoped></style>
