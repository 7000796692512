<template>
  <v-app-bar
    app
    prominent
    clipped-left
    clipped-right
    :color="bannerBarBackgroundColour"
    :height="$vuetify.breakpoint.mobile ? 55 : 125"
  >
    <!-- Image background -->
    <template #img="{ props }">
      <v-img
        v-if="!$vuetify.breakpoint.mobile"
        v-bind="props"
        :src="portalBannerUri"
        position="left center"
        :max-height="$vuetify.breakpoint.mobile ? 50 : 125"
      ></v-img>
    </template>

    <!-- Mobile menu hamburger button -->
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mobile"
      color="transaparent"
      @click.stop="invertDrawer"
    >
      <template slot="default">
        <v-icon normal :color="bannerBarTextColour">mdi-menu</v-icon>
      </template>
    </v-app-bar-nav-icon>

    <span
      v-if="$vuetify.breakpoint.mobile && mobilePageTitle"
      class="text-subtitle-1 font-weight-bold"
      :style="{ color: bannerBarTextColour, lineHeight: '48px' }"
    >
      {{ mobilePageTitle }}
    </span>

    <v-spacer></v-spacer>

    <!-- Login button -->
    <v-btn
      v-if="!isAuthenticated"
      :color="bannerBarTextColour"
      class="mx-1"
      :to="toLogin()"
      icon
    >
      <v-icon>mdi-login</v-icon>
      <span :style="{ color: bannerBarTextColour }">
        {{ $t('common.login') }}
      </span>
    </v-btn>

    <!-- My Programme -->
    <v-tooltip v-if="!$vuetify.breakpoint.mobile && isAuthenticated && showMyProgramme" bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          :color="bannerBarTextColour"
          icon
          :aria-label="$t('bannerBar.myProgramme')"
          v-bind="attrs"
          @click="toMyProgramme()"
          v-on="on"
        >
          <v-icon>mdi-calendar-star</v-icon>
        </v-btn>
      </template>
      <span>
        {{ $t('bannerBar.myProgramme') }}
      </span>
    </v-tooltip>

    <!-- My Bookmarks -->
    <MyBookmarks
      v-if="!$vuetify.breakpoint.mobile && isAuthenticated && showBookmarks"
    ></MyBookmarks>

    <!-- Conversations -->
    <ConversationsList v-if="!$vuetify.breakpoint.mobile && isAuthenticated && showMessaging" />

    <!-- Locale changer -->
    <LocaleChanger
      :text-colour="bannerBarTextColour"
      v-if="!$vuetify.breakpoint.mobile && isAuthenticated"
    />

    <!-- Help button -->
    <v-btn
      v-if="
        !$vuetify.breakpoint.mobile && portalHelpDocumentUri && isAuthenticated
      "
      :color="bannerBarTextColour"
      icon
      @click="openNewTab(portalHelpDocumentUri)"
    >
      <v-icon>mdi-help-circle-outline</v-icon>
    </v-btn>

    <!-- Welcome messagee and signalr status -->
    <div class="welcome" v-if="!$vuetify.breakpoint.mobile && isAuthenticated">
      <v-icon
        v-if="!$vuetify.breakpoint.mobile"
        :color="userConnected ? 'green' : 'red'"
        >mdi-circle</v-icon
      >
      <span :style="{ color: bannerBarTextColour }" class="ml-1 mr-2 subtitle-1"
        >{{ $t('bannerBar.welcome') }},
        {{ getCurrentUserPlatformFirstName }}</span
      >
    </div>

    <!-- User Menu non mobile -->
    <v-menu
      v-if="!$vuetify.breakpoint.mobile && isAuthenticated && currentUserId"
      v-model="menuOpen"
      left
      bottom
      offset-y
      transition="slide-y-transition"
      open-on-hover
      close-delay="50"
    >
      <template #activator="{ on }">
        <v-btn icon v-on="on">
          <Avatar
            :colour="bannerBarTextColour"
            size="36"
            :src="currentUser.photoUri"
            :alt="currentUser.userName + ' avatar'"
            default="mdi-account-circle"
          />
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click.stop="invertDialog">
          <v-list-item-title>
            <v-icon left>mdi-pencil</v-icon>
            {{ $t('bannerBar.editProfile') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="logOut">
          <v-list-item-title>
            <v-icon left>mdi-logout-variant</v-icon>
            {{ $t('bannerBar.logOut') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- User Menu mobile -->
    <v-btn
      v-else-if="isAuthenticated && currentUserId"
      icon
      @click.stop="invertDialog"
    >
      <Avatar
        v-if="currentUser"
        :colour="bannerBarTextColour"
        size="30"
        :src="currentUser.photoUri"
        :alt="currentUser.lastName + ' avatar'"
        default="mdi-account-circle"
      />
    </v-btn>

    <!-- Full screen profile editor -->
    <BannerDialog />
  </v-app-bar>
</template>

<script lang="ts">
import Avatar from '@/components/Avatar.vue';
import MyBookmarks from '@/components/MyBookmarks.vue';
import ConversationsList from '@/components/ConversationsList.vue';
import LocaleChanger from '@/components/LocaleChanger.vue';
import BannerDialog from '@/components/BannerDialog.vue';
import { HeartbeatResponse } from '@/models/HeartbeatResponse';
import { BookmarkActions } from '@/store/bookmark/types';
import {
  PlatformUserGetters,
  PlatformUserActions,
  PlatformUserItem,
} from '@/store/platformUser/types';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, namespace } from 'vuex-class';
import { Scan } from '../models/Scan';
import { ScanEntityTypes } from '../models/ScanEntityTypes';
import { ScanTypes } from '../models/ScanTypes';
import { AuthService } from '../services/auth/authService';
import { AuthGetters } from '../store/auth/types';
import {
  ConversationActions,
  ConversationItem,
} from '../store/conversation/types';
import {
  NavigationActions,
  NavigationGetters,
} from '../store/navigation/types';
import { PlatformActions, PlatformGetters } from '../store/platform/types';
import { SignalrGetters } from '../store/signalr/types';
import { LogActions, LogGetters } from '../store/log/log';

const authNamespace = 'auth';
const bookmarkNamespace = 'bookmark';
const conversationNamespace = 'conversation';
const logNamespace = 'log';
const navigationNamespace = 'navigation';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';
const signalrNamespace = 'signalr';

@Component({
  components: {
    Avatar,
    ConversationsList,
    LocaleChanger,
    MyBookmarks,
    BannerDialog,
  },
})
export default class BannarBar extends Vue {
  /* PUBLIC PROPERTIES */
  private menuOpen = false;
  private heartBeat!: Scan;
  private heartBeatInterval = 0;
  private traceLogInterval = 0;
  private showBookmarks = false;
  private showMyProgramme = false;
  private showMessaging = false;

  get eventCode(): string {
    return sessionStorage.getItem('eventCode') ?? '';
  }

  /* PRIVATE PROPERTIES */

  /* VUEX GETTERS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Log Getters
  @Getter(LogGetters.TRACE_LOG_FLAG, { namespace: logNamespace })
  private traceLogFlag!: boolean;

  @Getter(LogGetters.TRACE_LOG_TITLE, { namespace: logNamespace })
  private traceLogTitle!: boolean;

  // Navigation Getters
  @Getter(NavigationGetters.NAVIGATION_DRAWER, {
    namespace: navigationNamespace,
  })
  private drawer!: boolean;

  @Getter(NavigationGetters.MOBILE_PAGE_TITLE, {
    namespace: navigationNamespace,
  })
  private mobilePageTitle!: string;

  // Platform Getters
  @Getter(PlatformGetters.BANNER_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private bannerBarBackgroundColour!: string;

  @Getter(PlatformGetters.BANNER_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private bannerBarTextColour!: string;

  @Getter(PlatformGetters.NAV_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarBackgroundColour!: string;

  @Getter(PlatformGetters.NAV_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarTextColour!: string;

  @Getter(PlatformGetters.PORTAL_BANNER_URI, { namespace: platformNamespace })
  private portalBannerUri!: string;

  @Getter(PlatformGetters.PORTAL_HELP_DOCUMENT_URI, {
    namespace: platformNamespace,
  })
  private portalHelpDocumentUri!: string;


  @Getter(PlatformGetters.PROGRAMME_MODULE_ENABLED, {
    namespace: platformNamespace,
  })
  private programmeModuleEnabled;

  @Getter(PlatformGetters.VOD_MODULE_ENABLED, {
    namespace: platformNamespace,
  })
  private vodModuleEnabled;

  @Getter(PlatformGetters.POSTER_MODULE_ENABLED, {
    namespace: platformNamespace,
  })
  private posterModuleEnabled;

  @Getter(PlatformGetters.SPEAKERS_MODULE_ENABLED, {
    namespace: platformNamespace,
  })
  private speakersModuleEnabled;

  @Getter(PlatformGetters.ALL_PARTICIPANTS_MODULE_ENABLED, {
    namespace: platformNamespace,
  })
  private allParticipantsModuleEnabled;

  @Getter(PlatformGetters.EXHIBITION_MODULE_ENABLED, {
    namespace: platformNamespace,
  })
  private exhibitionModuleEnabled;

  @Getter(PlatformGetters.LIVE_SESSION_MODULE_ENABLED, {
    namespace: platformNamespace,
  })
  private liveSessModuleEnabled;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private currentUser!: PlatformUserItem;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_FIRST_NAME, {
    namespace: platformUserNamespace,
  })
  private getCurrentUserPlatformFirstName!: string;

  // Signalr Getters
  @Getter(`${SignalrGetters.USER_CONNECTED}`, { namespace: signalrNamespace })
  private userConnected!: boolean;

  /* VUEX ACTIONS */
  // Bookmark Actions
  @Action(BookmarkActions.LOAD_BOOKMARKS, {
    namespace: bookmarkNamespace,
  })
  private loadBookmarks!: () => Promise<void>;

  // Conversation Actions
  @Action(ConversationActions.OPEN_CONVERSATION, {
    namespace: conversationNamespace,
  })
  private openConversation!: (conversation: ConversationItem) => void;

  // Log Actions
  @Action(LogActions.SEND_TRACE_LOG, {
    namespace: logNamespace,
  })
  private sendTraceLog!: () => Promise<void>;

  // Navigation Actions
  @Action(NavigationActions.NAVIGATION_INVERT_DRAWER, {
    namespace: navigationNamespace,
  })
  private invertDrawer!: () => void;

  @Action(NavigationActions.PROFILE_INVERT_DIALOG, {
    namespace: navigationNamespace,
  })
  private invertDialog!: () => void;

  // Platform Actions
  @Action(PlatformActions.LOG_SCAN, { namespace: platformNamespace })
  private scanLog!: (scan: Scan) => Promise<void | HeartbeatResponse>;

  // Platform User Actions
  @Action(PlatformUserActions.IS_PLATFORM_MODERATOR, {
    namespace: platformUserNamespace,
  })
  private isPlatformModerator!: () => Promise<boolean>;

  /* WATCHES */
  @Watch('traceLogFlag')
  private async logStateChanged(value: string) {
    if (this.traceLogTitle) {
      await this.sendTraceLog();
    }
  }

  /* LIFECYCLE METHODS */
  private beforeCreate() {
    this.$vuetify.breakpoint.mobileBreakpoint = 960;
  }
  // private created() {}
  // private beforeMount() {}
  private async mounted() {
    if (this.isAuthenticated) {
      const platformId = sessionStorage.getItem('platformId') ?? '';

      this.heartBeat = {
        entityId: platformId,
        scanEntityType: ScanEntityTypes.EVENT_PLATFORM,
        scanType: ScanTypes.HEARTBEAT,
        userId: this.currentUserId,
      };

      const enter = {
        entityId: platformId,
        scanEntityType: ScanEntityTypes.EVENT_PLATFORM,
        scanType: ScanTypes.ENTER_EVENT_LOG,
        userId: this.currentUserId,
      };

      this.heartBeat = {
        entityId: platformId,
        scanEntityType: ScanEntityTypes.EVENT_PLATFORM,
        scanType: ScanTypes.HEARTBEAT,
        userId: this.currentUserId,
      };

      this.heartBeatInterval = window.setInterval(
        async () => await this.handleHeartBeat(),
        300000,
      );

      this.traceLogInterval = window.setInterval(
        async () => await this.handleTraceLog(),
        60000,
      );

      await this.showIcons()
      await this.loadBookmarks();
      await this.scanLog(enter);
    }
 }

 private async showIcons() {
  const isMod = await this.isPlatformModerator();
  if (isMod)
  {
    this.showBookmarks = true;
    this.showMyProgramme = true;
    this.showMessaging = true;
  } else {
    if (this.programmeModuleEnabled)
    {
      this.showMyProgramme= true;
    }
    if (this.liveSessModuleEnabled)
    {
      this.showMessaging = true;
    }
    if (this.vodModuleEnabled ||
        this.posterModuleEnabled ||
        this.programmeModuleEnabled ||
        this.speakersModuleEnabled ||
        this.allParticipantsModuleEnabled ||
        this.exhibitionModuleEnabled ||
        this.liveSessModuleEnabled)
        {
          this.showBookmarks = true;
        }
  }

 }

  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}

  private async beforeDestroy() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const leave = {
      entityId: platformId,
      scanEntityType: ScanEntityTypes.EVENT_PLATFORM,
      scanType: ScanTypes.LEAVE_EVENT_LOG,
      userId: this.currentUserId,
    };
    await this.scanLog(leave);
    window.clearInterval(this.heartBeatInterval);
    window.clearInterval(this.traceLogInterval);
    this.heartBeatInterval = 0;
    this.traceLogInterval = 0;
  }

  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private async handleHeartBeat() {
    await this.scanLog(this.heartBeat);
  }

  private async handleTraceLog() {
    if (this.traceLogTitle) {
      await this.sendTraceLog();
    }
  }

  private async logOut() {
    const userId = localStorage.getItem('userId') ?? '';
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const scan: Scan = {
      entityId: platformId,
      scanEntityType: ScanEntityTypes.EVENT_PLATFORM,
      scanType: ScanTypes.LOGOUT,
      userId,
    };
    await this.scanLog(scan);
    await AuthService.logOut().finally(() => {
      // this.$appInsights.clearAuthenticatedUserContext();
      const eventCode = sessionStorage.getItem('eventCode');
      window.location.href = `/${eventCode}`;
    });
  }

  private async openNewTab(url: string | undefined) {
    if (url && (url.startsWith('https:') || url.startsWith('http:'))) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else if (url) {
      await this.$router.push(url);
    }
  }

  private toLogin() {
    const res = `/${sessionStorage.getItem('eventCode')}/login`;
    return res;
  }

  private async toMyProgramme() {
    const platformCode = sessionStorage.getItem('platformCode') ?? '';
    await this.$router.push({
      name: 'myprogramme',
      params: { userProgramme: 'true', platformCode },
    });
  }


}
</script>

<style scoped>
.welcome {
  font-family: Ubuntu !important;
  font-size: 1.7rem !important;
  font-weight: 500;
  justify-content: flex-start !important;
  height: 48px;
}

.light-chat {
  font-size: 12px;
  height: calc(100% - 44px);
}

.light-chat::-webkit-scrollbar {
  width: 5px;
}

.light-chat::-webkit-scrollbar-track {
  background: transparent;
  border-left: 0;
}

.light-chat::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: 0;
  border-radius: 7px;
}

.light-chat::-webkit-scrollbar-thumb:hover {
  background: black;
}
</style>
