<template>
  <div class="observer" />
</template>

<script lang="ts">
/* eslint-disable */
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Observer extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public options!: IntersectionObserverInit | undefined;

  /* PRIVATE PROPERTIES */
  private observer?: IntersectionObserver;

  /* PRIVATE METHODS */
  private mounted() {
    const options = this.options || {};
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit('intersect');
      }
    }, options);

    this.observer.observe(this.$el);
  }

  private destroyed() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
</script>
