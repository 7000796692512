<template>
  <v-container
    :style="{
      backgroundImage: `url('${loginBackgroundImageUri}')`,
      backgroundSize: 'cover',
    }"
    fluid
    fill-height
  >
    <v-layout align-center justify-center>
      <v-flex class="login">
        <v-card
          class="mx-auto"
          raised
          :loading="loading"
          :disabled="loading"
          max-width="400px"
        >
          <div class="pa-5">
            <v-img
              class="white--text aligned end"
              :src="loginCardBannerImageUri"
              @click="openNewTab(loginCardBannerClientUri)"
            ></v-img>
            <v-card-text class="ma-0 pa-0">
              <v-row v-if="!open">
                <v-col cols="12" class="my-5">
                  <p v-if="closedText" class="text-center">
                    {{ closedText }}
                  </p>
                  <p v-else class="text-subtitle-1 text-center">
                    {{ $t('common.platformClosed') }}
                  </p>
                </v-col>
              </v-row>
              <v-form v-if="open">
                <v-row no-gutters>
                  <v-col cols="12" class="my-5">
                    <v-text-field
                      class="ma-0 pa-0"
                      v-model="username"
                      :label="$t('login.email')"
                      type="Email"
                      hide-details
                      single-line
                      outlined
                      @keyup.enter="submitLogin"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="mb-5">
                    <v-text-field
                      class="ma-0 pa-0"
                      v-model="password"
                      type="Password"
                      :label="$t('login.password')"
                      name="password"
                      hide-details
                      single-line
                      outlined
                      @keyup.enter="submitLogin"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="loginError" class="mb-5" cols="12">
                    <p class="text-center ma-0 pa-0 red--text">
                      {{ loginError }}
                    </p>
                  </v-col>
                  <v-col cols="12" class="mb-5">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="rememberMe"
                      :label="$t('login.rememberMe')"
                    ></v-checkbox>
                  </v-col>
                  <v-col class="mb-5" cols="12">
                    <v-btn
                      class="ma-0 pa-0"
                      x-large
                      block
                      :dark="defaultButtonColour ? dark(defaultButtonColour) : true"
                      :light="defaultButtonColour ? light(defaultButtonColour): false"
                      :color="defaultButtonColour ? defaultButtonColour : '#1976d2'"
                      @click.stop="submitLogin"
                    >
                    <span>
                      {{ $t('login.loginButton') }}
                    </span>
                    </v-btn>
                  </v-col>
                  <v-col class="pa-0 ma-0" v-if="open">
                    <a
                      style="color: rgba(0, 0, 0, 0.6)"
                      v-if="true || sendForgottenPassowrdUri"
                      @click="openDialog"
                    >
                      {{ $t('login.forgotPassword.link') }}
                    </a>
                    <a
                      style="color: rgba(0, 0, 0, 0.6)"
                      v-else-if="externalForgotPasswordUri"
                      @click="openNewTab(externalForgotPasswordUri)"
                    >
                      {{ $t('login.forgotPassword.link') }}
                    </a>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </div>
        </v-card>
        <v-card
          color="transparent"
          class="mx-auto"
          flat
          v-if="!loading"
          max-width="400px"
        >
          <v-row v-if="open" class="mt-2 mb-0 pb-0 text-center">
            <v-col cols="12">
              <span v-if="externalCreateAccountUri" style="color: white">{{
                $t('login.noAccount')
              }}</span>
              <v-btn
                v-if="externalCreateAccountUri"
                x-large
                outlined
                block
                color="white"
                class="mb-5"
                @click="openNewTab(externalCreateAccountUri)"
                >{{ $t('login.create') }}</v-btn
              >
              <a
                class="text-caption"
                style="color: rgba(255, 255, 255, 0.6)"
                href="mailto:support@openslideservices.com"
              >
                support@openslideservices.com
              </a>
            </v-col>
          </v-row>
        </v-card>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">{{
                $t('login.forgotPassword.title')
              }}</span>
            </v-card-title>
            <v-card-text v-if="!sent">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="username"
                      :disabled="sendingRequest"
                      :label="$t('login.forgotPassword.placeholder')"
                      hide-details
                      required
                      single-line
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>{{ $t('common.requiredField') }}</small>
            </v-card-text>
            <v-card-text v-if="sent">
              <span style="white-space: pre-line">{{
                $t('login.forgotPassword.sentEmail')
              }}</span>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!sent"
                :disabled="sendingRequest"
                color="blue darken-1"
                text
                @click="dialog = false"
                >{{ $t('common.cancel') }}</v-btn
              >
              <v-btn
                v-if="!sent"
                :disabled="sendingRequest"
                color="blue darken-1"
                text
                @click="sendRequest"
                >{{ $t('common.send') }}</v-btn
              >
              <v-btn v-if="sent" color="blue darken-1" text @click="close">{{
                $t('common.close')
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { HeartbeatResponse } from '@/models/HeartbeatResponse';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Scan } from '../models/Scan';
import { ScanEntityTypes } from '../models/ScanEntityTypes';
import { ScanTypes } from '../models/ScanTypes';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { AuthService } from '../services/auth/authService';
import { AuthActions, AuthGetters } from '../store/auth/types';
import { PlatformActions, PlatformGetters } from '../store/platform/types';
import { isLight, isDark, lightOrDark } from '../utils/lightordark';
const platformNamespace = 'platform';
const authNamespace = 'auth';

@Component({})
export default class Login extends Vue {
  private username = '';
  private password = '';
  private rememberMe = false;
  private loading = false;
  private message = '';
  private dirtyEmail: Element | null = null;
  private dirtyPassword: Element | null = null;

  private sendingRequest = false;
  private sent = false;
  private dialog = false;

  // Platform Getters
  @Getter(PlatformGetters.DEFAULT_BUTTON_COLOUR, {
    namespace: platformNamespace,
  })
  private defaultButtonColour!: string;

  @Getter(PlatformGetters.PORTAL_CLOSED_TEXT, {
    namespace: platformNamespace,
  })
  private closedText!: string;

  @Getter(PlatformGetters.LOGIN_BACKGROUND_IMAGE_URI, {
    namespace: platformNamespace,
  })
  private loginBackgroundImageUri!: string;

  @Getter(PlatformGetters.LOGIN_CARD_BANNER_IMAGE_URI, {
    namespace: platformNamespace,
  })
  private loginCardBannerImageUri!: string;

  @Getter(PlatformGetters.LOGIN_CARD_BANNER_CLIENT_URI, {
    namespace: platformNamespace,
  })
  private loginCardBannerClientUri!: string;

  @Getter(PlatformGetters.EXTERNAL_CREATE_ACCOUNT_URI, {
    namespace: platformNamespace,
  })
  private externalCreateAccountUri!: string;

  @Getter(PlatformGetters.EXTERNAL_FORGOT_PASSWORD_URI, {
    namespace: platformNamespace,
  })
  private externalForgotPasswordUri!: string;

  @Getter(PlatformGetters.PORTAL_OPEN, {
    namespace: platformNamespace,
  })
  private open!: boolean;

  @Getter(PlatformGetters.SEND_FORGOTTEN_PASSWORD_URI, {
    namespace: platformNamespace,
  })
  private sendForgottenPassowrdUri!: string;

  @Getter(AuthGetters.LOGIN_IN_ERROR, {
    namespace: authNamespace,
  })
  private loginError!: string;

  // Platform Actions
  @Action(PlatformActions.LOG_SCAN, { namespace: platformNamespace })
  private scanLog!: (scan: Scan) => Promise<void | HeartbeatResponse>;

  @Action(AuthActions.SET_LOGIN_IN_ERROR, { namespace: authNamespace })
  private setLoginError!: (error: string) => void;

  private dark(color: string): boolean {
    return isDark(color);
  }

  private light(color: string): boolean {
    return isLight(color);
  }

  private async submitLogin() {
    this.loading = true;
    const eventCode = sessionStorage.getItem('eventCode');
    try {
      await AuthService.logIn(
        this.username.trim(),
        this.password.trim(),
        this.rememberMe,
      );
      const userId = localStorage.getItem('userId') ?? '';
      const platformId = sessionStorage.getItem('platformId') ?? '';
      // this.$appInsights.setAuthenticatedUserContext(
      //   this.username,
      //   userId,
      //   true,
      // );

      const scan: Scan = {
        entityId: platformId,
        scanEntityType: ScanEntityTypes.EVENT_PLATFORM,
        scanType: ScanTypes.LOGIN,
        userId,
      };
      await this.scanLog(scan);

      this.$router.replace({
        path: `/${eventCode}/`,
      });
    } catch (e: any) {
      this.setLoginError(
        this.$t('errors.loginFailed', this.$i18n.locale) as string,
      );
    }
    this.loading = false;
  }

  private mounted() {
    this.checkAutofill();
  }

  private openDialog() {
    this.dialog = true;
  }

  private async sendRequest() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    if (!this.username) {
      return;
    }
    this.sendingRequest = true;

    try {
      const url = `/api/platform/${platformId}/users/forgot?email=${this.username}`;
      Vue.$http.get(url);
      this.sent = true;
    } catch (e: any) {
      AppInsightsLogger.logError('Login - sendRequest failed', undefined, true);
      AppInsightsLogger.logException(e, false);
      return false;
    }
    this.sendingRequest = false;
  }

  private openNewTab(url: string) {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
    }
  }

  private checkAutofill() {
    let times = 0;
    const interval = setInterval(() => {
      times += 1;
      if (this.dirtyEmail && this.dirtyPassword) {
        const emailLabel = document.querySelector(
          `label[for="${this.dirtyEmail.id}"]`,
        );
        if (emailLabel) {
          emailLabel.classList.add('v-label--active');
        }
        const passwordLabel = document.querySelector(
          `label[for="${this.dirtyPassword.id}"]`,
        );
        if (passwordLabel) {
          passwordLabel.classList.add('v-label--active');
        }
        clearInterval(interval);
      } else if (times >= 20) {
        clearInterval(interval);
      } else {
        this.dirtyEmail = document.querySelector(
          'input[type="email"]:-webkit-autofill',
        );
        this.dirtyPassword = document.querySelector(
          'input[type="password"]:-webkit-autofill',
        );
      }
    }, 100);
  }

  private close() {
    this.sendingRequest = false;
    this.sent = false;
    this.dialog = false;
  }
}
</script>

<style>
.login {
  width: calc(100vw - 24px);
  max-width: 400px;
}
.v-text-field .v-label--active {
  -webkit-transform: translateY(-22px) scale(0.75) !important;
  transform: translateY(-22px) scale(0.75) !important;
}

a:hover {
  text-decoration: underline;
}
</style>
