<template>
  <div v-if="!pageLoaded" class="pa-0 ma-0 fill-height">
    <v-container class="fill-height">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col cols="6">
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div v-else class="pa-0 ma-0">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="overline">
          {{ $t('session.recommendation') }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div class="overflow-y-auto scroller px-2 my-0 py-0">
      <v-row dense>
        <v-col>
          <SessionCard
            v-for="s in related"
            :key="s.id"
            :session="s"
            :related="true"
            class="mb-5 pa-0"
            style="'{cursor: crosshair;}"
            @nocontent="dialog = true"
          />
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-text class="pt-5">
          <p class="ma-0 pa-0 text-center subtitle-1">
            {{ $t('session.noContent') }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import SessionCard from '@/components/SessionCard.vue';
import { SessionGetters, SessionItem } from '@/store/session/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

const sessionNamespace = 'session';

@Component({
  components: {
    SessionCard,
  },
})
export default class SessionRelatedSide extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public isActive!: boolean;

  @Prop()
  public pageLoaded!: boolean;

  /* PRIVATE PROPERTIES */
  private dialog = false;

  /* VUEX GETTERS */
  // Session Getters
  @Getter(SessionGetters.RELATED_SESSIONS, { namespace: sessionNamespace })
  private related!: SessionItem[];

  /* VUEX ACTIONS */
  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */

  /* PRIVATE METHODS*/
}
</script>

<style scoped>
.scroller {
  height: calc(100vh - 200px);
  width: calc(100% - 20px);
  font-size: 12px;
  overflow-x: hidden;
  z-index: 9999;
}

.scroller::-webkit-scrollbar {
  width: 10px;
}

.scroller::-webkit-scrollbar-track {
  background: #b0b0b050;
  border-left: 0;
}

.scroller::-webkit-scrollbar-thumb {
  background: #b0b0b090;
  border: 0;
  border-radius: 7px;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #00000030;
}

.scroller::-webkit-scrollbar-thumb:active {
  background: #00000090;
}
</style>
