export interface SessionState {
  currentPage: number;
  keywordItems: string[];
  keywords: string;
  listView: boolean;
  loadingSessions: boolean;
  posterSessions: PosterSessionItem[];
  relatedSessions: SessionItem[];
  rooms: RoomItem[];
  selectedPosterSession?: PosterSessionItem;
  selectedRoom?: RoomItem;
  selectedSession?: SessionItem;
  selectedSessionAutoApproveQuestions: boolean;
  selectedSessionQuestions: SessionQuestionItem[];
  selectedTheme: string;
  selectedType: string;
  sessions: SessionItem[];
  themes: string[];
  totalSessionCount: number;
  types: string[];
}

export interface HomeVodItems {
  live: SessionItem[];
  pinnedSession: SessionItem;
  pinnedSessionImageUri: string;
  backgroundImageUri: string;
  backgroundColour: string;
  textColour: string;
  rooms: TopRoomSessionItems[];
  themes: TopThemeSessionItems[];
  types: TopTypeSessionItems[];
}

export interface TopRoomSessionItems {
  roomId: string;
  roomName: string;
  roomSessions: SessionItem[];
}

export interface TopTypeSessionItems {
  typeName: string;
  typeSessions: SessionItem[];
}

export interface TopThemeSessionItems {
  themeName: string;
  themeSessions: SessionItem[];
}

export interface  SessionItem {
  abstractFileUri?: string;
  activeSpeakerName: string;
  activeSpeakerId?: string;
  audioFileUri?: string;
  description?: string;
  downloadItems?: string[];
  enableAuthorisationForComments: boolean;
  enableChat: boolean;
  enableComments: boolean;
  end: Date;
  externalContentUri?: string;
  externalMeetingUri?: string;
  externalTranslationUri?: string;
  externalVotingUri?: string;
  homeVodThumbnailUri: string;
  id: string;
  internalCode: number;
  isAPoster?: boolean;
  isExternalContentSession?: boolean;
  isEventPortalSession?: boolean;
  isEventPortalPresentation?: boolean;
  isLive: boolean;
  isPresentation?: boolean;
  isSession?: boolean;
  keywords: string;
  coAuthors: string;
  moderators?: string[];
  numOfRatings: number;
  posterUploadUri?: string;
  presentationFileUri?: string;
  presentationItems: PresentationItem[];
  rating: number;
  roomAlias: string;
  roomId: string;
  roomName: string;
  sessionId: string;
  sessionQuestionItems: SessionQuestionItem[];
  sponsorThumbnailLinkUri?: string;
  sponsorThumbnailUri?: string;
  start: Date;
  theme: string;
  thumbnailUri: string;
  title: string;
  type: string;
  videoIFrameUri?: string;
  videoUploadUri?: string;
  views: number;
}

export interface RoomItem {
  id: string;
  name: string;
  alias: string;
}

export interface PresentationItem {
  abstractFileUri: string;
  coAuthors?: string;
  id: string;
  internalCode: number;
  isPresentation: boolean;
  isAPoster: boolean;
  isEventPortalPresentation: boolean;
  numOfRatings: number;
  order: number;
  posterUploadUri: string;
  presentationFileUri: string;
  rating: number;
  speakerId: string;
  sessionId: string;
  speakerName: string;
  speakerNameOverride: string;
  speakerPhotoUri: string;
  start: Date;
  end: Date;
  thumbnailUri: string;
  title: string;
  videoIFrameText: string;
  videoUploadUri: string;
  views: number;
  session: SessionItem;
}

export interface SessionQuestionItem {
  eventId: string;
  id?: string;
  isApproved: boolean;
  roleName?: string;
  room: string;
  sessionId: string;
  parentId: string;
  text: string;
  timeStamp?: string;
  userId: string;
  userName: string;
  userPhotoUri?: string;
  replies: SessionQuestionItem[];
}

export interface PosterSessionItem {
  id: string;
  title: string;
}

export const EmptySession: SessionItem = {
  activeSpeakerName: '',
  enableAuthorisationForComments: false,
  enableChat: false,
  enableComments: false,
  end: new Date(),
  externalContentUri: '',
  externalMeetingUri: '',
  externalTranslationUri: '',
  externalVotingUri: '',
  homeVodThumbnailUri: '',
  id: '',
  internalCode: 0,
  isExternalContentSession: false,
  isEventPortalSession: false,
  isEventPortalPresentation: false,
  isLive: false,
  isSession: true,
  keywords: '',
  coAuthors: '',
  numOfRatings: 0,
  presentationItems: [],
  rating: 0,
  roomAlias: '',
  roomId: '',
  roomName: '',
  sessionId: '',
  sessionQuestionItems: [],
  sponsorThumbnailLinkUri: '',
  sponsorThumbnailUri: '',
  start: new Date(),
  theme: '',
  thumbnailUri: '',
  title: '',
  type: '',
  videoIFrameUri: '',
  videoUploadUri: '',
  views: 0,
};

export enum SessionActions {
  ADD_NEW_QUESTION = 'ADD_NEW_QUESTION',
  ADD_NEW_QUESTION_REPLY = 'ADD_NEW_QUESTION_REPLY',
  APPLY_INITIAL_QUERY_FILTERS = 'APPLY_INITIAL_QUERY_FILTERS',
  APPROVE_QUESTION = 'APPROVE_QUESTION',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  CLEAR_SELECTED_SESSION = 'CLEAR_SELECTED_SESSION',
  HIDE_QUESTION = 'HIDE_QUESTION',
  LOAD_SELECTED_SESSION = 'LOAD_SELECTED_SESSION',
  POST_APPROVE_QUESTION = 'POST_APPROVE_QUESTION',
  POST_HIDE_QUESTION = 'POST_HIDE_QUESTION',
  POST_NEW_QUESTION = 'POST_NEW_QUESTION',
  POST_QUESTION_REPLY = 'POST_QUESTION_REPLY',
  RESET_SESSION_QUESTION_USER_PHOTO_URI = 'RESET_SESSION_QUESTION_USER_PHOTO_URI',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_FILTER_ITEMS = ' SET_FILTER_ITEMS',
  SET_KEYWORDS = 'SET_KEYWORDS',
  SET_KEYWORD_ITEMS = 'SET_KEYWORD_ITEMS',
  SET_LIST_VIEW = 'SET_LIST_VIEW',
  SET_LOADING_SESSIONS = 'SET_LOADING_SESSIONS',
  SET_RELATED_SESSION_DATA = 'SET_RELATED_SESSION_DATA',
  SET_SELECTED_POSTER_SESSION = 'SET_SELECTED_POSTER_SESSION',
  SET_SELECTED_PRESENTATION = 'SET_SELECTED_PRESENTATION',
  SET_SELECTED_ROOM = 'SET_SELECTED_ROOM',
  SET_SELECTED_SESSION = 'SET_SELECTED_SESSION',
  SET_SELECTED_SESSION_AUTO_APPROVE_QUESTIONS = 'SET_SELECTED_SESSION_AUTO_APPROVE_QUESTIONS',
  SET_SELECTED_SESSION_QUESTIONS = 'SET_SELECTED_SESSION_QUESTIONS',
  SET_SELECTED_THEME = 'SET_SELECTED_THEME',
  SET_SELECTED_TYPE = 'SET_SELECTED_TYPE',
  SET_SESSION_DATA = 'SET_SESSION_DATA',
  UPDATE_SELECTED_SESSION_RATING = 'UPDATE_SELECTED_SESSION_RATING',
}

export enum SessionGetters {
  CURRENT_PAGE = 'CURRENT_PAGE',
  CURRENT_SESSION_QUESTIONS = 'CURRENT_SESSION_QUESTIONS',
  CURRENT_SESSION_QUESTION_BY_ID = 'CURRENT_SESSION_QUESTION_BY_ID',
  KEYWORDS = 'KEYWORDS',
  KEYWORD_ITEMS = 'KEYWORD_ITEMS',
  LIST_VIEW = 'LIST_VIEW',
  LOADING_SESSIONS = 'LOADING_SESSIONS',
  POSTER_SESSIONS = 'POSTER_SESSIONS',
  RELATED_SESSIONS = 'RELATED_SESSIONS',
  ROOMS = 'ROOMS',
  SELECTED_POSTER_SESSION = 'SELECTED_POSTER_SESSION',
  SELECTED_ROOM = 'SELECTED_ROOM',
  SELECTED_SESSION = 'SELECTED_SESSION',
  SELECTED_SESSION_AUTO_APPROVE_QUESTIONS = 'SELECTED_SESSION_AUTO_APPROVE_QUESTIONS',
  SELECTED_THEME = 'SELECTED_THEME',
  SELECTED_TYPE = 'SELECTED_TYPE',
  SESSIONS = 'SESSIONS',
  THEMES = 'THEMES',
  TOTAL_SESSION_COUNT = 'TOTAL_SESSION_COUNT',
  TYPES = 'TYPES',
}

export enum SessionMutations {
  ADD_NEW_QUESTION = 'ADD_NEW_QUESTION',
  ADD_NEW_QUESTION_REPLY = 'ADD_NEW_QUESTION_REPLY',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_KEYWORDS = 'SET_KEYWORDS',
  SET_KEYWORD_ITEMS = 'SET_KEYWORD_ITEMS',
  SET_LIST_VIEW = 'SET_LIST_VIEW',
  SET_LOADING_SESSIONS = 'SET_LOADING_SESSIONS',
  SET_POSTER_SESSIONS = 'SET_POSTER_SESSIONS',
  SET_RELATED_SESSION_DATA = 'SET_RELATED_SESSION_DATA',
  SET_ROOMS = 'SET_ROOMS',
  SET_SELECTED_POSTER_SESSION = 'SET_SELECTED_POSTER_SESSION',
  SET_SELECTED_POSTER_SESSION_BY_ID = 'SET_SELECTED_POSTER_SESSION_BY_ID',
  SET_SELECTED_PRESENTATION = 'SET_SELECTED_PRESENTATION',
  SET_SELECTED_ROOM = 'SET_SELECTED_ROOM',
  SET_SELECTED_ROOM_BY_ID = 'SET_SELECTED_ROOM_BY_ID',
  SET_SELECTED_SESSION = 'SET_SELECTED_SESSION',
  SET_SELECTED_SESSION_AUTO_APPROVE_QUESTIONS = 'SET_SELECTED_SESSION_AUTO_APPROVE_QUESTIONS',
  SET_SELECTED_SESSION_QUESTIONS = 'SET_SELECTED_SESSION_QUESTIONS',
  SET_SELECTED_THEME = 'SET_SELECTED_THEME',
  SET_SELECTED_TYPE = 'SET_SELECTED_TYPE',
  SET_SESSION_DATA = 'SET_SESSION_DATA',
  SET_THEMES = 'SET_THEMES',
  SET_TYPES = 'SET_TYPES',
  UPDATE_QUESTION = 'UPDATE_QUESTION',
  UPDATE_SELECTED_SESSION_RATING = 'UPDATE_SELECTED_SESSION_RATING',
}
