<template>
  <div class="text-center">
    <v-dialog
      v-model="videoDialog"
      :width="$vuetify.breakpoint.mobile ? mobileWidth(item) : item.vidWidth"
      @input="(v) => v || stopVideoPlayer()"
      :content-class="$vuetify.breakpoint.mobile ? 'ma-0 pa-0' : ''"
    >
      <template #activator="{ on: dialog, attrs }">
        <v-list-item v-if="text" v-on="{ ...dialog }">
          <v-list-item-avatar>
            <v-icon size="32" color="#007C00">mdi-play-box</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-left">
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle class="font-italic"> </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-tooltip v-else left>
          <template #activator="{ on: tooltip }">
            <a
              v-bind="attrs"
              :style="`position:absolute; top: ${item.posTop};
                                     left: ${item.posLeft};
                                   height: ${item.mapHeight};
                                    width: ${item.mapWidth};`"
              v-on="{ ...tooltip, ...dialog }"
            ></a>
          </template>
          <span>{{ item.title }}</span>
        </v-tooltip>
      </template>

      <v-card
        flat
        dense
        class="pa-0"
        :height="
          $vuetify.breakpoint.mobile ? mobileHeight(item) : item.vidHeight
        "
        :width="$vuetify.breakpoint.mobile ? mobileWidth(item) : item.vidWidth"
      >
        <youtube
          v-if="item.type === YOUTUBE_TYPE"
          ref="youtube"
          :video-id="item.itemContent1"
          :player-vars="youtubePlayerVars"
          :height="
            $vuetify.breakpoint.mobile ? mobileHeight(item) : item.vidHeight
          "
          :width="
            $vuetify.breakpoint.mobile ? mobileWidth(item) : item.vidWidth
          "
          @ended="videoEnded"
        ></youtube>
        <!-- else if Vimeo -->
        <vue-vimeo-player
          v-if="item.type === VIMEO_TYPE"
          ref="vimeoplayer"
          :video-url="item.itemContent1"
          video-id=""
          :player-height="
            $vuetify.breakpoint.mobile ? mobileHeight(item) : item.vidHeight
          "
          :player-width="
            $vuetify.breakpoint.mobile ? mobileWidth(item) : item.vidWidth
          "
          autoplay="true"
          @ended="videoEnded"
        ></vue-vimeo-player>
        <!-- else if other iframe -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { DownloadLog } from '@/models/DownloadLog';
import { SponsorMapItem } from '@/models/Sponsor';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { Youtube } from 'vue-youtube';

@Component({
  components: {
    Youtube,
    vueVimeoPlayer,
  },
})
export default class SessionDetails extends Vue {
  @Prop()
  public item!: SponsorMapItem;

  @Prop()
  public text!: boolean;

  private YOUTUBE_TYPE = 'Youtube';
  private VIMEO_TYPE = 'Vimeo';
  private IFRAME_TYPE = 'IFrame';
  private videoDialog = 0;
  private youtubePlayerVars = { controls: 0, showInfo: 0, autoplay: 1 };

  private videoEnded() {
    this.videoDialog = 0;
  }

  private mobileWidth(item: SponsorMapItem) {
    return window.innerWidth;
  }

  private mobileHeight(item: SponsorMapItem): number {
    const w1 = Number.parseInt(item.vidWidth);
    const h1 = Number.parseInt(item.vidHeight);
    const w2 = window.innerWidth;
    const h2: number = Math.trunc((h1 / w1) * w2);
    return h2;
  }

  private stopVideoPlayer() {
    if (this.item.type === this.YOUTUBE_TYPE) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this as any).$refs.youtube.player.stopVideo();
    } else if (this.item.type === this.VIMEO_TYPE) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this as any).$refs.vimeoplayer.pause();
    }
  }
}
</script>

<style scoped></style>
