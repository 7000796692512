<template>
  <div style="height: 100%">
    <v-card class="pa-4">
      <v-card-title>
        <h1 class="text-h5">{{ $t('menuItems.reportItems.adClicks') }}</h1>
      </v-card-title>
      <v-divider />

      <v-card v-if="itemData">
        <v-card-title>
          <v-text-field
            v-model="itemSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn tile icon @click="downloadItems"
            ><v-icon>mdi-cloud-download-outline</v-icon></v-btn
          >
        </v-card-title>
        <v-data-table
          :headers="itemData.headers"
          :items="itemData.items"
          :search="itemSearch"
          :loading="itemsLoading"
        ></v-data-table>
      </v-card>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import xlsx from 'xlsx';
import { AppInsightsLogger } from '../../services/appInsightsLogger';
import { AdClickSummary } from '@/models/Reports/AdClickSummary';
import { DataTable } from '@/models/Reports/DataTable';

@Component({})
export default class UniqueUsers extends Vue {
  private loaded = false;

  private itemData: DataTable<AdClickSummary> | null = null;
  private itemsLoading = false;
  private itemSearch = '';

  private async created() {
    this.itemsLoading = false;
    this.itemData = null;
    this.itemSearch = '';
  }

  private downloadItems() {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const title = `${eventCode}_adclicks`;

    if (title && this.itemData && this.itemData.items) {
      const fileName = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      const workSheet = xlsx.utils.json_to_sheet(this.itemData.items);
      const wb = xlsx.utils.book_new();

      xlsx.utils.book_append_sheet(wb, workSheet, 'export');
      xlsx.writeFile(wb, `${fileName}.xlsx`);
    }
  }

  private async mounted() {
    this.itemsLoading = true;
    this.itemData = null;
    this.itemSearch = '';
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<DataTable<AdClickSummary>>(
        `/api/platform/${platformId}/report/adclicks`,
      );
      this.itemData = res.data;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Reports - get ad clicks data failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.itemsLoading = false;
  }
}
</script>

<style scoped>
.smaller-title {
  font-size: 1rem;
}
</style>
