<template>
  <add-edit-presentation :item="{}"></add-edit-presentation>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import AddEditPresentation from '../../components/AddEditPresentation.vue';

@Component({
  components: {
    AddEditPresentation,
  },
})
export default class AddPresentation extends Vue {
  /* PUBLIC PROPERTIES */
  /* PRIVATE PROPERTIES */
  /* VUEX GETTERS */
  /* VUEX ACTIONS */
  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */
  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  // private mounted() {}
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/
}
</script>

<style scoped></style>
