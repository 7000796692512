<template>
  <div v-if="sponsor" class="full-height">
    <ActivityDrawer
      v-if="sponsor.enableStandChat && isAuthenticated"
      class="fill-height"
      :is-mod="isMod"
      :is-sponsor="true"
      :sponsor="sponsor"
      :page-loaded="pageLoaded"
    />
    <div :class="$vuetify.breakpoint.mobile ? 'ma-2' : 'ma-10'">
      <v-row align="center" dense>
        <!-- Link -->
        <!-- TODO(David):  -->
        <!-- <v-col cols="12" v-if="!$vuetify.breakpoint.mobile">
          <a @click="goToExpo">{{ 'Exhbition' }}</a>
          &nbsp;&nbsp;&gt;&nbsp;&nbsp;{{ sponsor.title }}
        </v-col> -->
        <!-- Title / Subtitle -->
        <v-col sm="5" lg="11">
          <v-row dense>
            <v-col cols="12">
              <span class="text-h4 font-weight-bold">
                {{ sponsor.title }}
              </span>
            </v-col>
            <v-col cols="12">
              <span class="text-h6 font-weight-regular">
                {{ sponsor.shortDescription }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <!-- Logo -->
        <v-col sm="5" lg="1">
          <v-card height="120" flat tile color="transparent">
            <v-row
              align="center"
              class="fill-height ma-0 pa-0"
              justify="center"
            >
              <v-img
                contain
                :aspect-ratio="16 / 9"
                :src="sponsor.thumbnailImageUri"
                @error="handleImageError()"
                max-height="60"
              >
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!-- Stand -->
      <v-row v-if="!$vuetify.breakpoint.mobile">
        <v-col cols="12">
          <v-row justify="center">
            <v-img
              usemap
              class="image"
              contain
              :src="sponsor.standImageUri"
              :aspect-ratio="16 / 9"
              max-width="80%"
              max-height="100%"
            >
              <template v-for="m in sponsor.maps">
                <!-- LINK -->
                <div v-if="m.type === LINK_TYPE" :key="m.id">
                  <v-tooltip left>
                    <template #activator="{ on, attrs }">
                      <a
                        v-bind="attrs"
                        :style="`position:absolute; top: ${m.posTop};
                                                    left: ${m.posLeft};
                                                    height: ${m.mapHeight};
                                                    width: ${m.mapWidth};`"
                        v-on="on"
                        @click="openNewTab(m.itemContent1)"
                      ></a>
                    </template>
                    <span>{{ m.title }}</span>
                  </v-tooltip>
                </div>
                <!-- VIDEO -->
                <div
                  v-else-if="
                    m.type === YOUTUBE_TYPE ||
                    m.type === VIMEO_TYPE ||
                    m.type === IFRAME_TYPE
                  "
                  class="text-center"
                  :key="m.id"
                  @click.capture="videoViewLog(m)"
                >
                  <SponsorVideo :item="m" />
                </div>
                <!-- DOCUMENT -->
                <div
                  v-else-if="m.type === DOCUMENT_TYPE"
                  class="text-center"
                  :key="m.id"
                >
                  <SponsorDocument
                    :sponsor-id="sponsor ? sponsor.id : ''"
                    :sponsor-title="sponsor ? sponsor.title : ''"
                    :item="m"
                  ></SponsorDocument>
                </div>
              </template>
            </v-img>
          </v-row>
        </v-col>
      </v-row>
      <!-- Description -->
      <v-row>
        <v-col cols="12">
          <span class="text-subtitle-1">
            <p v-html-safe:true="sponsor.description"></p>
          </span>
        </v-col>
      </v-row>

      <!-- Mod downloads -->
      <v-row v-if="isMod && !$vuetify.breakpoint.mobile">
        <v-col cols="12">
          <v-btn
            :loading="downloadingViewers"
            :disabled="
              downloadingViewers ||
              downloadingDownloads ||
              downloadingVideoViews
            "
            color="blue-grey"
            class="ma-2 white--text"
            @click="downloadEntityItems"
          >
            Stand visitors
            <v-icon right dark> mdi-cloud-download </v-icon>
          </v-btn>
          <v-btn
            :loading="downloadingDownloads"
            :disabled="
              downloadingViewers ||
              downloadingDownloads ||
              downloadingVideoViews
            "
            color="blue-grey"
            class="ma-2 white--text"
            @click="downloadDocumentUniqueUsers"
          >
            Document downloads
            <v-icon right dark> mdi-cloud-download </v-icon>
          </v-btn>
          <v-btn
            :loading="downloadingVideoViews"
            :disabled="
              downloadingViewers ||
              downloadingDownloads ||
              downloadingVideoViews
            "
            color="blue-grey"
            class="ma-2 white--text"
            @click="downloadVideoViewsUniqueUsers"
          >
            Video views
            <v-icon right dark> mdi-cloud-download </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <!-- Socials -->
      <!-- <v-row v-if="links && links.length > 0">
        <v-col cols="12">
          <v-toolbar
            flat
            light
            color="#EFEFEF"
            class="text-h6 text-weight-bold"
          >
            <span>{{ $t('sponsor.followUs') }} </span>
            <v-spacer></v-spacer>
            <span v-for="l in links" :key="l.uniqueId">
              <v-btn v-if="l.linkUri" icon @click="openNewTab(l.linkUri)">
                <v-icon>{{ l.iconUri }}</v-icon>
              </v-btn>
            </span>
          </v-toolbar>
        </v-col>
      </v-row> -->

      <v-row>
        <!-- TEAM -->
        <v-col
          v-if="sponsor && sponsor.staff && sponsor.staff.length > 0"
          lg="6"
          sm="12"
        >
          <v-toolbar
            flat
            light
            color="#D9E4EC"
            class="text-subtitle-2 text-uppercase text-weight-bold"
          >
            <span :style="{ color: '#367299' }">{{ $t('sponsor.staff') }}</span>
          </v-toolbar>
          <v-list>
            <template v-for="(u, i) in sponsor.staff">
              <v-list-item :key="u.id">
                <v-list-item-avatar
                  :size="$vuetify.breakpoint.mobile ? 40 : 60"
                >
                  <Avatar
                    colour="#9e9e9e"
                    :size="60"
                    :src="u.photoUri"
                    :alt="u.userName + ' avatar'"
                    :fullname="u.userName"
                    default="mdi-account-circle"
                  />
                </v-list-item-avatar>
                <v-list-item-content :style="{ minWidth: '40%' }">
                  <v-list-item-title class="font-weight-bold">
                    {{ u.userName }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="font-italic">
                    {{ u.jobTitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-spacer></v-spacer>
                <div>
                  <v-btn color="#9e9e9e" v-if="u.link1" icon>
                    <v-icon>
                      {{ u.link1Icon }}
                    </v-icon>
                  </v-btn>

                  <v-btn
                    color="#9e9e9e"
                    v-if="u.link2"
                    icon
                    @click="openNewTab(u.link2)"
                  >
                    <v-icon>
                      {{ u.link2Icon }}
                    </v-icon>
                  </v-btn>

                  <v-btn
                    color="#9e9e9e"
                    v-if="u.link3"
                    icon
                    @click="openNewTab(u.link3)"
                  >
                    <v-icon>
                      {{ u.link3Icon }}
                    </v-icon>
                  </v-btn>
                  <v-icon v-if="isOnline(u.userId) === true" color="green">
                    mdi-circle
                  </v-icon>
                  <v-icon v-else color="red">mdi-circle</v-icon>
                </div>
              </v-list-item>
              <v-divider
                :key="i"
                v-if="sponsor && sponsor.staff && i < sponsor.staff.length - 1"
              ></v-divider>
            </template>
          </v-list>
        </v-col>

        <!-- DOCUMENTS -->
        <v-col
          v-if="sponsor && sponsor.maps && sponsor.maps.length > 0"
          lg="6"
          sm="12"
        >
          <v-toolbar
            flat
            light
            color="#D9E4EC"
            class="text-subtitle-2 text-uppercase text-weight-bold"
          >
            <span :style="{ color: '#367299' }">{{
              $t('sponsor.documents')
            }}</span>
          </v-toolbar>
          <v-list>
            <v-list>
              <template v-for="(m, i) in sponsor.maps">
                <!-- DOCUMENTS -->
                <div v-if="m.type === DOCUMENT_TYPE" :key="m.id">
                  <v-list-item
                    v-if="m.itemContent1"
                    :href="$vuetify.breakpoint.mobile ? m.itemContent1 : ''"
                    :target="$vuetify.breakpoint.mobile ? '_blank' : ''"
                    @click="
                      openDocumentNewTab(m.itemContent1, m.id, m.itemTitle1)
                    "
                  >
                    <v-list-item-avatar>
                      <v-icon size="32" color="#F40F02">
                        mdi-file-pdf-box
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content :style="{ minWidth: '60%' }">
                      <v-list-item-title>
                        {{ m.itemTitle1 }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-spacer></v-spacer>
                    <!-- <v-btn
                      v-if="$vuetify.breakpoint.mobile"
                      :href="$vuetify.breakpoint.mobile ? m.itemContent1 : ''"
                      :target="$vuetify.breakpoint.mobile ? '_blank' : ''"
                      icon
                    >
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn> -->
                    <v-btn
                      v-if="isAuthenticated"
                      icon
                      @click.stop="
                        bookmarkDocumentExecute(m.itemTitle1, m.itemContent1)
                      "
                    >
                      <v-icon v-if="isABookmark(sponsorId, m.itemContent1)">
                        mdi-bookmark-check
                      </v-icon>
                      <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
                    </v-btn>
                  </v-list-item>

                  <v-list-item
                    v-if="m.itemContent2"
                    @click="
                      openDocumentNewTab(m.itemContent2, m.id, m.itemTitle2)
                    "
                  >
                    <v-list-item-avatar>
                      <v-icon size="32" color="#F40F02">
                        mdi-file-pdf-box</v-icon
                      >
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ m.itemTitle2 }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        v-if="isAuthenticated"
                        icon
                        @click.stop="
                          bookmarkDocumentExecute(m.itemTitle2, m.itemContent2)
                        "
                      >
                        <v-icon v-if="isABookmark(sponsorId, m.itemContent2)">
                          mdi-bookmark-check
                        </v-icon>
                        <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item
                    v-if="m.itemContent3"
                    @click="
                      openDocumentNewTab(m.itemContent3, m.id, m.itemTitle3)
                    "
                  >
                    <v-list-item-avatar>
                      <v-icon size="32" color="#F40F02">
                        mdi-file-pdf-box</v-icon
                      >
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ m.itemTitle3 }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        v-if="isAuthenticated"
                        icon
                        @click.stop="
                          bookmarkDocumentExecute(m.itemTitle3, m.itemContent3)
                        "
                      >
                        <v-icon v-if="isABookmark(sponsorId, m.itemContent3)">
                          mdi-bookmark-check
                        </v-icon>
                        <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item
                    v-if="m.itemContent4"
                    @click="
                      openDocumentNewTab(m.itemContent4, m.id, m.itemTitle4)
                    "
                  >
                    <v-list-item-avatar>
                      <v-icon size="32" color="#F40F02">
                        mdi-file-pdf-box</v-icon
                      >
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ m.itemTitle4 }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        v-if="isAuthenticated"
                        icon
                        @click.stop="
                          bookmarkDocumentExecute(m.itemTitle4, m.itemContent4)
                        "
                      >
                        <v-icon v-if="isABookmark(sponsorId, m.itemContent4)">
                          mdi-bookmark-check
                        </v-icon>
                        <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item
                    v-if="m.itemContent5"
                    @click="
                      openDocumentNewTab(m.itemContent5, m.id, m.itemTitle5)
                    "
                  >
                    <v-list-item-avatar>
                      <v-icon size="32" color="#F40F02">
                        mdi-file-pdf-box</v-icon
                      >
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ m.itemTitle5 }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        v-if="isAuthenticated"
                        icon
                        @click.stop="
                          bookmarkDocumentExecute(m.itemTitle5, m.itemContent5)
                        "
                      >
                        <v-icon v-if="isABookmark(sponsorId, m.itemContent5)">
                          mdi-bookmark-check
                        </v-icon>
                        <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </div>

                <!-- LINK Video -->
                <div
                  v-else-if="
                    m.type === VIMEO_TYPE ||
                    m.type === YOUTUBE_TYPE ||
                    m.type === IFRAME_TYPE
                  "
                  :key="m.id"
                  @click.capture="videoViewLog(m)"
                >
                  <SponsorVideo :item="m" :text="true" />
                </div>

                <!-- OTTHER ITEMS -->
                <v-list-item
                  v-else
                  @click="openNewTab(m.itemContent1)"
                  :key="m.id"
                >
                  <!-- LINK FACEBOOK -->
                  <v-list-item-avatar
                    v-if="
                      m.type === LINK_TYPE &&
                      m.title &&
                      m.title.toLowerCase().includes('facebook')
                    "
                  >
                    <v-icon size="32" color="#4267B2"> mdi-facebook</v-icon>
                  </v-list-item-avatar>

                  <!-- LINK TWITTER -->
                  <v-list-item-avatar
                    v-else-if="
                      m.type === LINK_TYPE &&
                      m.title &&
                      m.title.toLowerCase().includes('twitter')
                    "
                  >
                    <v-icon size="32" color="#1DA1F2"> mdi-twitter</v-icon>
                  </v-list-item-avatar>

                  <!-- LINK INSTAGFRAM -->
                  <v-list-item-avatar
                    v-else-if="
                      m.type === LINK_TYPE &&
                      m.title &&
                      m.title.toLowerCase().includes('instagram')
                    "
                  >
                    <v-icon size="32" color="black"> mdi-instagram</v-icon>
                  </v-list-item-avatar>

                  <!-- LINK Linkedin -->
                  <v-list-item-avatar
                    v-else-if="
                      m.type === LINK_TYPE &&
                      m.title &&
                      m.title.toLowerCase().includes('linkedin')
                    "
                  >
                    <v-icon size="32" color="#0e76a8"> mdi-linkedin</v-icon>
                  </v-list-item-avatar>

                  <!-- LINK youtube -->
                  <v-list-item-avatar
                    v-else-if="
                      m.type === LINK_TYPE &&
                      m.title &&
                      m.title.toLowerCase().includes('youtube')
                    "
                  >
                    <v-icon size="32" color="#FF0000"> mdi-youtube</v-icon>
                  </v-list-item-avatar>

                  <!-- LINK general -->
                  <v-list-item-avatar v-else-if="m.type === LINK_TYPE">
                    <v-icon size="32" color="black">mdi-open-in-new</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ m.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-italic">
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-spacer></v-spacer>
                </v-list-item>
                <v-divider
                  :key="i"
                  v-if="sponsor && sponsor.maps && i < sponsor.maps.length - 1"
                ></v-divider>
              </template>
            </v-list>
          </v-list>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="downloadDialog" max-width="290">
      <v-card>
        <v-card-text class="pt-5">
          <p class="ma-0 pa-0 text-center subtitle-1">
            {{ $t('sponsor.noDownload') }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="downloadDialog = false">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import ActivityDrawer from '@/components/ActivityDrawer.vue';
import Avatar from '@/components/Avatar.vue';
import SponsorDocument from '@/components/SponsorDocument.vue';
import SponsorVideo from '@/components/SponsorVideo.vue';
import { DownloadLog } from '@/models/DownloadLog';
import { HeartbeatResponse } from '@/models/HeartbeatResponse';
import { DataTable, UniqueUserItem } from '@/models/Reports/DataTable';
import { EntityListItem } from '@/models/Reports/EntityListItem';
import { AppInsightsLogger } from '@/services/appInsightsLogger';
import {
  PlatformUserActions,
  PlatformUserGetters,
} from '@/store/platformUser/types';
import isMobile from 'ismobilejs';
import { Component, Vue } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { Action, Getter } from 'vuex-class';
import { Scan } from '../models/Scan';
import { ScanEntityTypes } from '../models/ScanEntityTypes';
import { ScanTypes } from '../models/ScanTypes';
import { SponsorItem, SponsorMapItem } from '../models/Sponsor';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '../store/bookmark/types';
import {
  NavigationActions,
  NavigationGetters,
} from '../store/navigation/types';
import { PlatformActions } from '../store/platform/types';
import {
  SignalrActions,
  SignalrGetters,
  SignalrGroup,
  SignalrGroupTypes,
} from '../store/signalr/types';
import xlsx from 'xlsx';
import moment from 'moment-timezone';
import { LogActions } from '@/store/log/log';
import { AuthGetters } from '@/store/auth/types';

const authNamespace = 'auth';
const bookmarkNamespace = 'bookmark';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';
const signalrNamespace = 'signalr';
const navigationNamespace = 'navigation';

const logNamespace = 'log';

Component.registerHooks(['beforeRouteUpdate']);

@Component({
  components: {
    ActivityDrawer,
    Avatar,
    SponsorDocument,
    SponsorVideo,
  },
})
export default class Vod extends Vue {
  /* PUBLIC PROPERTIES */

  /* PRIVATE PROPERTIES */
  private sponsorId!: string;
  private LINK_TYPE = 'Link';
  private YOUTUBE_TYPE = 'Youtube';
  private VIMEO_TYPE = 'Vimeo';
  private IFRAME_TYPE = 'IFrame';
  private DOCUMENT_TYPE = 'Documents';
  private isMod = false;
  private pageLoaded = false;
  private sponsor?: SponsorItem | null = null;
  private heartBeat!: Scan;
  private heartBeatInteral = 0;
  private thumbnailError = false;
  private defaultThumbnailError = false;
  private sponsorStats: DataTable<UniqueUserItem> | null = null;

  private downloadingViewers = false;
  private downloadingDownloads = false;
  private downloadingVideoViews = false;
  private downloadDialog = false;

  /* VUEX GETTERS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Bookmark Gettters
  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string, entityUri?: string) => boolean;

  // navigation Getters
  @Getter(NavigationGetters.ACTIVITY_DRAWER, {
    namespace: navigationNamespace,
  })
  private drawer!: boolean;

  // platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  @Getter(PlatformUserGetters.VISIBLE_PLATFORM_USER_EXISTS, {
    namespace: platformUserNamespace,
  })
  private isOnline!: (userId: string) => boolean;

  @Action(NavigationActions.ACTIVITY_INVERT_DRAWER, {
    namespace: navigationNamespace,
  })
  private invertDrawer!: () => void;

  @Getter(SignalrGetters.CURRENT_GROUP, { namespace: signalrNamespace })
  private currentGroup!: SignalrGroup;

  /* VUEX ACTIONS */
  // Bookmark Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  // Log Actions
  @Action(LogActions.SET_TRACE_LOG_STATE, { namespace: logNamespace })
  private setTraceLogState!: ({
    entityId1,
    entityId2,
    page,
    title,
  }: {
    entityId1?: string;
    entityId2?: string;
    page: string;
    title?: string;
  }) => void;

  // Platform actions
  @Action(PlatformActions.DOWNLOAD_LOG, { namespace: platformNamespace })
  private downloadLog!: (downloadLog: DownloadLog) => Promise<void>;

  @Action(PlatformUserActions.LOAD_PLATFORM_USERS_FOR_GROUP, {
    namespace: platformUserNamespace,
  })
  private loadPlatformUsersForGroup!: ({
    groupId,
    groupType,
  }: {
    groupId: string;
    groupType: string;
  }) => Promise<void>;

  // Platform user actions
  @Action(PlatformUserActions.IS_SPONSOR_MODERATOR, {
    namespace: platformUserNamespace,
  })
  private isSponsorModerator!: (sponsorId: string) => Promise<boolean>;

  @Action(PlatformActions.LOG_SCAN, { namespace: platformNamespace })
  private scanLog!: (scan: Scan) => Promise<void | HeartbeatResponse>;

  // signalr actions
  @Action(SignalrActions.UPDATE_CURRENT_ROOM_COUNT, {
    namespace: signalrNamespace,
  })
  private updatedCurrenRoomCount!: (count: number) => void;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get hasDocumentDownloads(): boolean {
    if (
      this.sponsorStats &&
      this.sponsorStats.downloadUniqueUsers &&
      this.sponsorStats.downloadUniqueUsers.items
    ) {
      return true;
    }
    return false;
  }

  get hasEntityDownloads(): boolean {
    if (this.sponsorStats && this.sponsorStats.items) {
      return true;
    }
    return false;
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}

  private async mounted() {
    this.sponsorId = this.$route.params.sponsorId;
    await this.load();
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}

  private async beforeDestroy() {
    if (isMobile(window.navigator).any) {
      window.removeEventListener('pagehide', this.pageUnloaded);
      window.removeEventListener('visibilitychange', this.visibilityChanged);
    } else {
      window.removeEventListener('beforeunload', this.pageUnloaded);
    }
    await this.handleLeave();
  }

  // private destroyed() {}
  // private errorCaptured() {}

  private async beforeRouteUpdate(
    to: Route,
    from: Route,
    next: NavigationGuardNext,
  ) {
    await this.handleLeave();
    this.sponsorId = to.params.sponsorId;
    await this.load();
    next();
  }

  /* PRIVATE METHODS*/
  private bookmarkDocumentExecute(title: string, url: string) {
    const bi = this.getBookmark(this.sponsorId, url);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      const bm: BookmarkItem = {
        entityId: this.sponsorId,
        entityType: BookmarkEntityType.SPONSOR_DOCUMENT,
        type: BookmarkType.DOCUMENT,
        entityUri: url,
        title: `${this.sponsor?.title} - ${title}`,
      };
      this.bookmarkItem(bm);
    }
  }

  private bookmarkExecute(s: SponsorItem | null | undefined) {
    if (!s) {
      return;
    }
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const bi = this.getBookmark(s.id);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      const bm: BookmarkItem = {
        entityId: s.id,
        entityType: BookmarkEntityType.SPONSOR_STAND,
        type: BookmarkType.EXHIBITION,
        entityUri: `/${eventCode}/sponsor/${s.id}`,
        title: s.title,
      };
      this.bookmarkItem(bm);
    }
  }

  private async downloadDocumentUniqueUsers() {
    try {
      if (this.isMod) {
        this.downloadingDownloads = true;
        const platformId = sessionStorage.getItem('platformId') ?? '';
        const res = await Vue.$http.get<DataTable<UniqueUserItem>>(
          `/api/platform/${platformId}/report/${this.sponsorId}/sponsorstats`,
        );
        this.sponsorStats = res.data;
      }

      if (
        this.sponsorStats &&
        this.sponsorStats.downloadUniqueUsers &&
        this.sponsorStats.downloadUniqueUsers.items &&
        this.sponsorStats.downloadUniqueUsers.items.length > 0
      ) {
        const eventCode = sessionStorage.getItem('eventCode') ?? '';
        const title = `${eventCode}_downloaduniqueusers_${this.sponsor?.title}`;
        const fileName = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        const workSheet = xlsx.utils.json_to_sheet(
          this.sponsorStats.downloadUniqueUsers.items,
          { cellDates: true },
        );
        const wb = xlsx.utils.book_new();

        xlsx.utils.book_append_sheet(wb, workSheet, 'export');
        xlsx.writeFile(wb, `${fileName}.xlsx`);
      } else {
        this.downloadDialog = true;
      }
    } catch (e: any) {
      console.log(e);
      AppInsightsLogger.logError(
        'Player - downloadEntityItems failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.downloadingDownloads = false;
  }

  private async downloadEntityItems() {
    try {
      if (this.isMod) {
        this.downloadingViewers = true;
        const platformId = sessionStorage.getItem('platformId') ?? '';
        const res = await Vue.$http.get<DataTable<UniqueUserItem>>(
          `/api/platform/${platformId}/report/${this.sponsorId}/sponsorstats`,
        );
        this.sponsorStats = res.data;

        if (
          this.sponsorStats &&
          this.sponsorStats.items &&
          this.sponsorStats.items.length > 0
        ) {
          const eventCode = sessionStorage.getItem('eventCode') ?? '';
          const title = `${eventCode}_uniqueusers_${this.sponsor?.title}`;
          const fileName = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
          const items = this.sponsorStats.items ?? this.sponsorStats.items;
          const parsed = this.parseDates(items);
          const workSheet = xlsx.utils.json_to_sheet(parsed, {
            cellDates: true,
            dateNF: 'dd/mm/yyyy hh:mm',
          });
          const wb = xlsx.utils.book_new();

          xlsx.utils.book_append_sheet(wb, workSheet, 'export');
          xlsx.writeFile(wb, `${fileName}.xlsx`);
        } else {
          this.downloadDialog = true;
        }
      }
    } catch (e: any) {
      console.log(e);
      AppInsightsLogger.logError(
        'Player - downloadEntityItems failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.downloadingViewers = false;
  }

  private async downloadVideoViewsUniqueUsers() {
    try {
      if (this.isMod) {
        this.downloadingVideoViews = true;
        const platformId = sessionStorage.getItem('platformId') ?? '';
        const res = await Vue.$http.get<DataTable<UniqueUserItem>>(
          `/api/platform/${platformId}/report/${this.sponsorId}/sponsorstats`,
        );
        this.sponsorStats = res.data;
      }

      if (
        this.sponsorStats &&
        this.sponsorStats.videoViewUniqueUsers &&
        this.sponsorStats.videoViewUniqueUsers.items &&
        this.sponsorStats.videoViewUniqueUsers.items.length > 0
      ) {
        const eventCode = sessionStorage.getItem('eventCode') ?? '';
        const title = `${eventCode}_videoviewuniqueusers_${this.sponsor?.title}`;
        const fileName = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        const workSheet = xlsx.utils.json_to_sheet(
          this.sponsorStats.videoViewUniqueUsers.items,
          { cellDates: true },
        );
        const wb = xlsx.utils.book_new();

        xlsx.utils.book_append_sheet(wb, workSheet, 'export');
        xlsx.writeFile(wb, `${fileName}.xlsx`);
      } else {
        this.downloadDialog = true;
      }
    } catch (e: any) {
      console.log(e);
      AppInsightsLogger.logError(
        'Player - downloadEntityItems failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.downloadingVideoViews = false;
  }

  private goToExpo() {
    this.$router.push({ name: 'expo' });
  }

  private async handleHeartBeat() {
    const res = await this.scanLog(this.heartBeat);
    if (res) {
      this.updatedCurrenRoomCount(res.count);
    }
  }

  private handleImageError() {
    if (!this.thumbnailError) {
      this.thumbnailError = true;
    } else if (!this.defaultThumbnailError) {
      this.defaultThumbnailError = true;
    }
  }

  private async handleLeave() {
    if (this.heartBeatInteral !== 0) {
      window.clearInterval(this.heartBeatInteral);
      this.heartBeatInteral = 0;
    }
    this.log(ScanTypes.LEAVE_STAND_LOG);
    this.pageLoaded = false;
  }

  private async load() {
    if (!this.drawer) {
      this.invertDrawer();
    }

    if (!this.sponsorId) {
      // TODO: Handle properly
      return;
    }

    const platformId = sessionStorage.getItem('platformId') ?? '';
    this.isMod = await this.isSponsorModerator(this.sponsorId);
    try {
      const res = await Vue.$http.get(
        `/api/platform/${platformId}/sponsors/${this.sponsorId}`,
      );
      if (isMobile(window.navigator).any) {
        window.addEventListener('pagehide', this.pageUnloaded);
        window.addEventListener('visibilitychange', this.visibilityChanged);
      } else {
        window.addEventListener('beforeunload', this.pageUnloaded);
      }

      this.sponsor = res.data;
      await this.log(ScanTypes.ENTER_STAND_LOG);

      this.heartBeat = {
        entityId: this.sponsorId,
        scanEntityType: ScanEntityTypes.SPONSOR,
        scanType: ScanTypes.HEARTBEAT,
        userId: this.currentUserId,
      };

      this.heartBeatInteral = window.setInterval(
        async () => await this.handleHeartBeat(),
        300000,
      );

      // await this.loadPlatformUsersForGroup({
      //   groupId: this.sponsorId,
      //   groupType: SignalrGroupTypes.SPONSOR,
      // });

      this.pageLoaded = true;

      this.setTraceLogState({
        entityId1: this.sponsorId,
        title: this.sponsor ? this.sponsor.title : 'Stand',
        page: 'Stand',
      });
    } catch (e: any) {
      // TODO Insights
    }
  }

  private async log(scanType: string) {
    if (this.sponsor) {
      await this.scanLog({
        entityId: this.sponsor.id,
        scanEntityType: ScanEntityTypes.SPONSOR,
        scanType,
        userId: this.currentUserId,
      });
    }
  }

  private async openDocumentNewTab(url: string, id: string, title: string) {
    if (id) {
      const log: DownloadLog = {
        entityId: id,
        itemContent: url,
        itemTitle: title,
        sponsorId: this.sponsorId,
        userId: this.currentUserId,
        logType: 'DOWNLOAD_ITEM',
      };
      await this.downloadLog(log);
    }

    if (url.startsWith('https:') || url.startsWith('http:')) {
      if (this.$vuetify.breakpoint.mobile) {
        console.log('here');
      } else {
        const win = window.open(url, '_blank');
        if (win) {
          win.focus();
        } else {
          this.$router.push({
            path: url,
          });
        }
      }
    }
  }

  private async videoViewLog(item: SponsorMapItem) {
    if (item) {
      const log: DownloadLog = {
        entityId: item.id,
        itemContent: item.itemContent1,
        itemTitle: item.title,
        sponsorId: this.sponsorId,
        userId: this.currentUserId,
        logType: 'VIDEO_VIEW_ITEM',
      };
      await this.downloadLog(log);
    }
  }

  private async openNewTab(url: string) {
    if (typeof url !== 'string') {
      return;
    }

    if (
      url.startsWith('https:') ||
      url.startsWith('http:') ||
      url.startsWith('mailto')
    ) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push({
        path: url,
      });
    }
  }

  private async pageUnloaded() {
    await this.handleLeave();
  }

  private parseDates(arr: UniqueUserItem[]): UniqueUserItem[] {
    const newArr = arr.map((obj: UniqueUserItem) => {
      const newObj: UniqueUserItem = {
        ...obj,
        firstConnection:
          typeof obj.firstConnection === 'string'
            ? moment.utc(obj.firstConnection).toDate()
            : '',
        lastConnection:
          typeof obj.lastConnection === 'string'
            ? moment.utc(obj.lastConnection).toDate()
            : '',
        start:
          typeof obj.start === 'string'
            ? obj.start === '0001-01-01T00:00:00'
              ? ''
              : moment.tz(obj.start, 'Europe/Paris').toDate()
            : '',
      };

      return newObj;
    });
    return newArr;
  }

  private async visibilityChanged() {
    if (document.visibilityState === 'visible') {
      await this.log(ScanTypes.ENTER_STAND_LOG);
      if (this.heartBeatInteral === 0) {
        if (!this.heartBeat && this.sponsorId) {
          this.heartBeat = {
            entityId: this.sponsorId,
            scanEntityType: ScanEntityTypes.SPONSOR,
            scanType: ScanTypes.HEARTBEAT,
            userId: this.currentUserId,
          };
          this.heartBeatInteral = window.setInterval(
            async () => await this.handleHeartBeat(),
            300000,
          );
        }
      }
    } else {
      await this.handleLeave();
    }
  }
}
</script>

<style scoped>
.chat-close-button {
  visibility: hidden;
}
.chat-open-button {
  margin-top: 10px;
  position: fixed;
  z-index: 9999;
  right: 0;
}
.chat-open-button {
  color: black !important;
}
</style>
