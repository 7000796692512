<template>
  <div style="height: 100%">
    <component :is="dynamicComponent" v-if="customHtml && loaded"></component>

    <!--
    ******  DEESKTOP LAYOUT ********
 -->
    <div v-if="!customHtml && loaded && !$vuetify.breakpoint.mobile">
      <v-tabs v-model="currentTab" grow background-color="indigo" dark>
        <v-tab v-for="(sg, i) in sessionGroups" :key="i">{{ sg.title }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(sg, i) in sessionGroups" :key="i">
          <v-calendar
            ref="calendar"
            :categories="getCategories(sg.groupSessions)"
            color="primary"
            :event-color="getEventColor"
            :events="getSessions(sg.groupSessions)"
            :first-interval="firstInterval(sg.groupSessions)"
            :interval-count="intervalCount(sg.groupSessions)"
            :interval-height="calendarIntervalHeight"
            interval-minutes="15"
            :short-intervals="false"
            :value="sg.groupDate"
            type="category"
            category-show-all
            @click:event="sessionSelected"
            @mouseenter:event="mouseEnterSession"
            @mouseleave:event="mouseLeaveSession"
          >
            <template #event="{ event }">
              <v-hover v-slot="{ hover }" :disabled="event.hasContent">
                <v-card
                  color="transparent"
                  flat
                  tile
                  class="ma-0 pa-0"
                  height="100%"
                >
                  <v-card-text>
                    <p
                      v-if="longerThanTwenty(event)"
                      class="text-wrap ma-0 px-2 text-center"
                    >
                      <span
                        :style="{ color: event.textColour }"
                        class="overline font-weight-normal"
                        >{{ formatStartEnd(event.start, event.end) }}</span
                      >
                    </p>
                    <p class="text-wrap ma-0 px-2 text-center">
                      <span
                        :style="{ color: event.textColour }"
                        class="subtitle-1 font-weight-bold"
                        >{{ event.title }}</span
                      >
                    </p>
                    <p
                      v-if="event.label && longerThanTwenty(event)"
                      class="text-wrap ma-0 px-2 text-center"
                    >
                      <v-chip small color="white">
                        <span style="color: black">
                          {{ event.label }}
                        </span>
                      </v-chip>
                    </p>
                  </v-card-text>
                  <v-footer
                    absolute
                    padless
                    tile
                    color="transparent"
                    height="24px"
                    class="ma-1"
                  >
                    <v-container class="ma-0 pa-0">
                      <v-row class="ma-0 pa-0">
                        <v-col
                          v-if="event.isLive"
                          class="ma-0 pa-0"
                          cols="auto"
                        >
                          <v-chip small color="black" label>
                            <v-icon left class="blink-me" color="red">
                              mdi-record
                            </v-icon>
                            <span class="red--text">LIVE</span>
                          </v-chip>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col
                          v-if="event.showViews"
                          class="ma-0 pa-0"
                          cols="auto"
                        >
                          <v-chip color="transparent" label>
                            <v-icon left :color="event.textColour"
                              >mdi-eye</v-icon
                            >
                            <span :style="{ color: event.textColour }">{{
                              event.views
                            }}</span>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-footer>
                  <v-expand-transition>
                    <v-overlay v-if="hover" absolute color="#036358">
                      <v-chip x-large color="primary" label>
                        <span
                          style="text-align: center; white-space: pre-line"
                          >{{ $t('session.noContent') }}</span
                        >
                      </v-chip>
                    </v-overlay>
                  </v-expand-transition>
                </v-card>
              </v-hover>
            </template>
          </v-calendar>
          <v-menu
            v-model="sessionDetailOpen"
            offset-x
            :close-on-content-click="false"
            :activator="hoveredElement"
            max-width="450px"
          >
            <v-card
              v-if="hoveredEvent"
              flat
              color="grey lighten-4"
              width="450px"
              height="464px"
              @mouseenter="mouseEnterDetails"
              @mouseleave="overDetails = false"
            >
              <v-toolbar :color="getEventColor(hoveredEvent)">
                <v-icon :color="hoveredEvent.textColour"
                  >mdi-information-outline</v-icon
                >
                <v-toolbar-title class="pl-2">
                  <span :style="{ color: hoveredEvent.textColour }">
                    Session
                  </span>
                  <span
                    v-if="hoveredEvent.customCode"
                    :style="{ color: hoveredEvent.textColour }"
                  >
                    :&nbsp;{{ hoveredEvent.customCode }}
                  </span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="bookmarkExecute(selectedSession)">
                  <v-icon
                    v-if="selectedSession && isABookmark(selectedSession.id)"
                    :color="hoveredEvent.textColour"
                  >
                    mdi-bookmark-check
                  </v-icon>
                  <v-icon v-else :color="hoveredEvent.textColour">
                    mdi-bookmark-plus-outline
                  </v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon
                    :color="hoveredEvent.textColour"
                    @click="closeSessionDetails"
                    >mdi-close</v-icon
                  >
                </v-btn>
              </v-toolbar>
              <v-container class="overflow-y-auto light-chat ma-0">
                <v-row
                  v-if="loadingSession"
                  class="fill-height"
                  align-content="center"
                  justify="center"
                >
                  <v-col cols="6">
                    <v-progress-linear
                      color="deep-purple accent-4"
                      indeterminate
                      rounded
                      height="6"
                    ></v-progress-linear>
                  </v-col>
                </v-row>

                <v-row v-else-if="selectedSession && !loadingSession" dense>
                  <v-col
                    cols="12"
                    class="overline font-weight-bold text--disabled"
                  >
                    {{ $t('session.title') }}
                  </v-col>
                  <v-col cols="12" class="pl-2 subtitle-1 font-weight-bold">
                    {{ selectedSession.title }}
                  </v-col>
                  <v-col
                    v-if="selectedSession.type"
                    cols="12"
                    class="overline font-weight-bold text--disabled"
                  >
                    {{ $t('session.type') }}
                  </v-col>
                  <v-col
                    v-if="selectedSession.type"
                    cols="12"
                    class="pl-2 subtitle-1 font-weight-bold"
                  >
                    {{ selectedSession.type }}
                  </v-col>
                  <v-col
                    v-if="selectedSession.theme"
                    cols="12"
                    class="overline font-weight-bold text--disabled"
                  >
                    {{ $t('session.theme') }}
                  </v-col>
                  <v-col
                    v-if="selectedSession.theme"
                    cols="12"
                    class="pl-2 subtitle-1 font-weight-bold"
                  >
                    {{ selectedSession.theme }}
                  </v-col>
                  <v-col
                    v-if="
                      selectedSession.moderators &&
                      selectedSession.moderators.length > 0
                    "
                    cols="12"
                    class="overline font-weight-bold text--disabled"
                  >
                    {{ $t('session.moderators') }}
                  </v-col>
                  <v-col
                    v-if="
                      selectedSession.moderators &&
                      selectedSession.moderators.length > 0
                    "
                    cols="12"
                    class="pl-2 subtitle-1 font-weight-bold"
                  >
                    <span>{{ selectedSession.moderators.join(',  ') }}</span>
                  </v-col>
                  <v-col
                    v-if="
                      selectedSession.presentationItems &&
                      selectedSession.presentationItems.length > 0
                    "
                    cols="12"
                    class="overline font-weight-bold text--disabled"
                  >
                    {{ $t('session.speakers') }}
                  </v-col>
                  <v-col
                    v-if="
                      selectedSession.presentationItems &&
                      selectedSession.presentationItems.length > 0
                    "
                    cols="12"
                    class="pl-2 subtitle-1 font-weight-bold"
                  >
                    <span>{{
                      uniqueSpeakers(selectedSession.presentationItems).join(
                        ',  ',
                      )
                    }}</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-menu>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <!--
    ******  MOBILE LAYOUT ********
 -->
    <div v-else>
      <v-container>
        <v-row class="ma-0 pa-0" dense>
          <v-col class="ma-0 pa-0" cols="4">
            <v-combobox
              class="text-subtitle-1"
              solo
              flat
              hide-details
              :items="sessionGroups"
              :item-text="formatDay"
              :item-value="getItemId"
              v-model="mobileSelectedDay"
              small
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row v-if="mobileSelectedDay" dense class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <v-btn-toggle
              v-model="mobileSelectedRoom"
              dense
              class="d-flex flex-wrap"
              group
              mandatory
              color="primary"
            >
              <v-btn
                v-for="(g, i) in getCategories(mobileSelectedDay.groupSessions)"
                :key="i"
                :value="g"
              >
                {{ g }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row dense class="ma-0 pa-0">
          <v-col cols="12">
            <v-calendar
              v-if="
                mobileSelectedDay &&
                mobileSelectedRoom &&
                mobileSessions &&
                mobileSessions.length > 0
              "
              ref="calendar"
              :categories="mobileSelectedRoom"
              color="primary"
              :event-color="getEventColor"
              :events="getSessions(mobileSessions)"
              :first-interval="firstInterval(mobileSessions)"
              :interval-count="intervalCount(mobileSessions)"
              :interval-height="calendarIntervalHeight"
              interval-minutes="15"
              :short-intervals="false"
              :value="mobileSelectedDay.groupDate"
              type="day"
              @click:event="mobileSessionSelected"
            >
              <template #event="{ event }">
                <v-card
                  color="transparent"
                  flat
                  tile
                  class="ma-0 pa-0"
                  height="100%"
                  @click="closeMobileDialog"
                >
                  <v-card-text>
                    <p
                      v-if="longerThanTwenty(event)"
                      class="text-wrap ma-0 px-2 text-center"
                    >
                      <span
                        :style="{ color: event.textColour }"
                        class="overline font-weight-normal"
                        >{{ formatStartEnd(event.start, event.end) }}</span
                      >
                    </p>
                    <p class="text-wrap ma-0 px-2 text-center">
                      <span
                        :style="{ color: event.textColour }"
                        class="subtitle-1 font-weight-bold"
                        >{{ event.title }}</span
                      >
                    </p>
                    <p
                      v-if="event.label && longerThanTwenty(event)"
                      class="text-wrap ma-0 px-2 text-center"
                    >
                      <v-chip small color="white">
                        <span style="color: black">
                          {{ event.label }}
                        </span>
                      </v-chip>
                    </p>
                  </v-card-text>
                  <v-footer
                    absolute
                    padless
                    tile
                    color="transparent"
                    height="24px"
                    class="ma-1"
                  >
                    <v-container class="ma-0 pa-0">
                      <v-row class="ma-0 pa-0">
                        <v-col
                          v-if="event.isLive"
                          class="ma-0 pa-0"
                          cols="auto"
                        >
                          <v-chip small color="black" label>
                            <v-icon left class="blink-me" color="red">
                              mdi-record
                            </v-icon>
                            <span class="red--text">LIVE</span>
                          </v-chip>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col
                          v-if="event.showViews"
                          class="ma-0 pa-0"
                          cols="auto"
                        >
                          <v-chip color="transparent" label>
                            <v-icon left :color="event.textColour"
                              >mdi-eye</v-icon
                            >
                            <span :style="{ color: event.textColour }">{{
                              event.views
                            }}</span>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-footer>
                </v-card>
              </template>
            </v-calendar>
            <span v-else>No sessions</span>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog
        v-model="mobileDialogState"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        persistent
        :retain-focus="false"
      >
        <v-card v-if="mobileSelectedEvent">
          <v-toolbar dense flat>
            <span
              class="text-subtitle-1 font-weight-bold"
              :style="{ lineHeight: '48px' }"
            >
              Session
            </span>
            <span
              v-if="mobileSelectedEvent.customCode"
              class="text-subtitle-1 font-weight-bold"
              :style="{ lineHeight: '48px' }"
            >
              : {{ mobileSelectedEvent.customCode }}
            </span>
            <v-spacer></v-spacer>
            <v-btn icon tile @click="bookmarkExecute(selectedSession)">
              <v-icon v-if="selectedSession && isABookmark(selectedSession.id)">
                mdi-bookmark-check
              </v-icon>
              <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
            </v-btn>
            <v-btn
              icon
              tile
              :style="{
                marginRight: '-16px',
                backgroundColor: '#EEEEEE',
              }"
            >
              <v-icon @click="closeMobileDialog">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-container>
            <v-row
              v-if="loadingSession"
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col cols="6">
                <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>

            <v-row v-else-if="selectedSession && !loadingSession" dense>
              <v-col cols="12" class="overline font-weight-bold text--disabled">
                {{ $t('session.title') }}
              </v-col>
              <v-col cols="12" class="pl-2 subtitle-1 font-weight-bold">
                {{ selectedSession.title }}
              </v-col>
              <v-col
                v-if="selectedSession.type"
                cols="12"
                class="overline font-weight-bold text--disabled"
              >
                {{ $t('session.type') }}
              </v-col>
              <v-col
                v-if="selectedSession.type"
                cols="12"
                class="pl-2 subtitle-1 font-weight-bold"
              >
                {{ selectedSession.type }}
              </v-col>
              <v-col
                v-if="selectedSession.theme"
                cols="12"
                class="overline font-weight-bold text--disabled"
              >
                {{ $t('session.theme') }}
              </v-col>
              <v-col
                v-if="selectedSession.theme"
                cols="12"
                class="pl-2 subtitle-1 font-weight-bold"
              >
                {{ selectedSession.theme }}
              </v-col>
              <v-col
                v-if="
                  selectedSession.moderators &&
                  selectedSession.moderators.length > 0
                "
                cols="12"
                class="overline font-weight-bold text--disabled"
              >
                {{ $t('session.moderators') }}
              </v-col>
              <v-col
                v-if="
                  selectedSession.moderators &&
                  selectedSession.moderators.length > 0
                "
                cols="12"
                class="pl-2 subtitle-1 font-weight-bold"
              >
                <span>{{ selectedSession.moderators.join(',  ') }}</span>
              </v-col>
              <v-col
                v-if="
                  selectedSession.presentationItems &&
                  selectedSession.presentationItems.length > 0
                "
                cols="12"
                class="overline font-weight-bold text--disabled"
              >
                {{ $t('session.speakers') }}
              </v-col>
              <v-col
                v-if="
                  selectedSession.presentationItems &&
                  selectedSession.presentationItems.length > 0
                "
                cols="12"
                class="pl-2 subtitle-1 font-weight-bold"
              >
                <span>{{
                  uniqueSpeakers(selectedSession.presentationItems).join(',  ')
                }}</span>
              </v-col>
            </v-row>
            <v-row v-if="mobileSelectedEvent.hasContent">
              <v-col cols="12">
                <v-btn
                  @click="sessionSelected({ event: mobileSelectedEvent })"
                  block
                  color="primary"
                >
                  <v-icon left> mdi-play </v-icon>
                  {{ $t('session.viewSession') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="mobileAlertState"
        :timeout="3000"
        absolute
        top
        color="primary"
        type="info"
        transition="scale-transition"
        dismissible
      >
        {{ $t('session.noContent') }}
      </v-snackbar>
    </div>
  </div>
</template>

<script lang="ts">
import uniqueFilter from '@/filters/unique.filter';
import {
  PresentationItem,
  SessionActions,
  SessionGetters,
  SessionItem,
} from '@/store/session/types';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { GroupedSessionCalendarItem } from '../models/GroupedSessionCalendarItem';
import { GroupedSessionCalendarItems } from '../models/GroupedSessionCalendarItems';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '../store/bookmark/types';
import { PlatformGetters } from '../store/platform/types';
const bookmarkNamespace = 'bookmark';
const platformNamespace = 'platform';
const sessionNamespace = 'session';

Component.registerHooks(['beforeRouteUpdate']);

@Component({
  components: {},
})
export default class Programme extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public userProgramme!: boolean;

  /* PRIVATE PROPERTIES */
  private currentTab = 0;
  private customHtml = '';
  private hoveredElement: EventTarget | null = null;
  private hoveredEvent: GroupedSessionCalendarItem | null = null;
  private loaded = false;
  private loadingSession = false;
  private overDetails = false;
  private sessionDetailOpen = false;
  private sessionGroups: GroupedSessionCalendarItems[] = [];
  private noUserSessions = false;

  private mobileDialogState: boolean = false;
  private mobileAlertState: boolean = false; //TODO(David): Cross site alerts
  private mobileSelectedDay: GroupedSessionCalendarItems | null = null;
  private mobileSelectedRoom: string | null = null;
  private mobileSessions: GroupedSessionCalendarItem[] = [];
  private mobileSelectedEvent: GroupedSessionCalendarItem | null = null;

  /* VUEX GETTERS */
  // Bookmark Getters
  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string) => boolean;

  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  // Platform Getters
  @Getter(PlatformGetters.CALENDAR_INTERVAL_HEIGHT, {
    namespace: platformNamespace,
  })
  private calendarIntervalHeight!: string;

  // Session Getters
  @Getter(SessionGetters.SELECTED_SESSION, { namespace: sessionNamespace })
  private selectedSession!: SessionItem;

  /* VUEX ACTIONS */
  // Session Actions
  @Action(SessionActions.LOAD_SELECTED_SESSION, { namespace: sessionNamespace })
  private loadSelectedSession!: ({
    isMod,
    noq,
    sessionId,
  }: {
    isMod: boolean;
    noq: boolean;
    sessionId: string;
  }) => Promise<boolean>;

  // Bookmarks Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  /* WATCHES */
  @Watch('mobileSelectedDay', { immediate: true })
  mobileSelectedDayChanged() {
    if (this.$vuetify.breakpoint.mobile && this.mobileSelectedRoom) {
      this.setMobileSessions();
    }
  }

  @Watch('mobileSelectedRoom', { immediate: true })
  mobileSelectedRoomChanged() {
    if (this.$vuetify.breakpoint.mobile) {
      this.setMobileSessions();
    }
  }

  /* LOCAL GETTERS/SETTERS */
  get dynamicComponent(): { template: string } {
    return {
      template: `${this.customHtml}`,
    };
  }
  /* LIFECYCLE METHODS */

  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private async mounted() {
    await this.loadSessionGroups();
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private async beforeRouteUpdate() {
    await this.loadSessionGroups();
  }

  private bookmarkExecute(s: SessionItem) {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const bi = this.getBookmark(s.id);
    if (bi) {
      this.unbookmarkItem(bi);
      this.sessionDetailOpen = false;
    } else {
      const bm: BookmarkItem = {
        end: s.end,
        entityId: s.id,
        entityType: BookmarkEntityType.SESSION,
        type: BookmarkType.VIDEO,
        entityUri: `/${eventCode}/session/${s.id}`,
        start: s.start,
        title: s.title,
      };
      this.bookmarkItem(bm);
    }
  }

  private closeMobileDialog() {
    this.mobileDialogState = false;
    this.mobileSelectedEvent = null;
  }

  private closeSessionDetails() {
    this.sessionDetailOpen = false;
  }

  private firstInterval(sessions: GroupedSessionCalendarItem[]): number {
    const starts = sessions.map((s) => moment(s.start));
    const earliest = moment.min(starts);
    const nearestPastQtr = this.nearestPastMinutes(15, moment(earliest));
    const startMidnight = nearestPastQtr.clone().startOf('day');
    const diffStartMinutes = nearestPastQtr.diff(startMidnight, 'minutes');
    const startInterval = Math.floor(diffStartMinutes / 15);
    return startInterval;
  }

  private formatDay(item: GroupedSessionCalendarItems): string {
    const s = moment(item.groupDate)
      .locale(this.$i18n.locale)
      .format('ddd DD MMM');
    return s;
  }

  private formatStartEnd(start: Date, end: Date) {
    const s = moment(start).format('HH:mm');
    const e = moment(end).format('HH:mm');
    return `${s}-${e}`;
  }

  private getCategories(sessions: GroupedSessionCalendarItem[]): string[] {
    const groups = [...new Set(sessions.map((s) => s.category))];
    return groups;
  }

  private getEventColor(event: GroupedSessionCalendarItem) {
    if (event.colour) {
      return event.colour;
    }
    return 'blue';
  }

  private getItemId(item: GroupedSessionCalendarItems): string {
    return item.groupId.toString();
  }

  private getSessions(
    sessions: GroupedSessionCalendarItem[],
  ): GroupedSessionCalendarItem[] {
    if (this.userProgramme) {
      const userSessions = sessions.filter((s) => {
        return this.isABookmark(s.id);
      });
      if (userSessions.length < 1) {
        this.noUserSessions = true;
      }
      return userSessions;
    } else {
      return sessions;
    }
  }

  private intervalCount(sessions: GroupedSessionCalendarItem[]): number {
    const starts = sessions.map((s) => moment(s.start));
    const ends = sessions.map((s) => moment(s.end));
    const earliest = moment.min(starts);
    const latest = moment.max(ends);
    const nearestPastQtr = this.nearestPastMinutes(15, moment(earliest));
    const midnight = nearestPastQtr.clone().startOf('day');
    const diffStartMinutes = nearestPastQtr.diff(midnight, 'minutes');
    const startInterval = Math.floor(diffStartMinutes / 15);
    const nearestFutureQtr = this.nearestFutureMinutes(15, moment(latest));
    const diffEndMinutes = nearestFutureQtr.diff(midnight, 'minutes');
    const endInterval = Math.floor(diffEndMinutes / 15);
    const temp = endInterval - startInterval;
    return temp;
  }

  private async loadSessionGroups() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      let url = `/api/platform/${platformId}/sessions/calendar`;
      const sessionGroups = await Vue.$http.get<GroupedSessionCalendarItems[]>(
        url,
      );
      if (sessionGroups.data) {
        this.sessionGroups = sessionGroups.data;
        this.mobileSelectedDay = sessionGroups.data[0];
        sessionGroups.data.forEach(
          (sg: GroupedSessionCalendarItems, i: number) => {
            if (sg.today) {
              this.currentTab = i;
              this.mobileSelectedDay = sg;
            }
          },
        );
      }

      this.loaded = true;
    } catch (e: any) {
      AppInsightsLogger.logError('Programme - created failed', undefined, true);
      AppInsightsLogger.logException(e, false);
    }
  }

  private longerThanTwenty(ev: GroupedSessionCalendarItem): boolean {
    const s = moment(ev.start);
    const e = moment(ev.end);
    return e.diff(s, 'minutes') >= 20;
  }

  private async mobileSessionSelected({
    event,
  }: {
    event: GroupedSessionCalendarItem;
  }) {
    this.mobileSelectedEvent = event;
    this.mobileDialogState = true;
    this.loadingSession = true;
    await this.loadSelectedSession({
      isMod: false,
      noq: true,
      sessionId: event.id,
    });
    this.loadingSession = false;
  }

  private async mobibleOpenSession(event: GroupedSessionCalendarItem) {
    if (event.hasContent) {
      if (event.externalContentUri) {
        this.openNewTab(event.externalContentUri);
      } else {
        this.mobileSelectedEvent = event;
        this.mobileDialogState = true;
      }
    }
  }

  private mouseEnterDetails(event: MouseEvent) {
    this.overDetails = true;
    event.cancelBubble = true;
  }

  private async mouseEnterSession({
    nativeEvent,
    event,
  }: {
    nativeEvent: MouseEvent;
    event: GroupedSessionCalendarItem;
  }) {
    const open = async () => {
      if (this.overDetails) {
        return;
      }
      this.hoveredElement = nativeEvent.target;
      setTimeout(() => {
        this.hoveredEvent = event;
        this.sessionDetailOpen = true;
      }, 10);
      this.loadingSession = true;
      await this.loadSelectedSession({
        isMod: false,
        noq: true,
        sessionId: event.id,
      });
      this.loadingSession = false;
    };

    if (this.sessionDetailOpen && !this.overDetails) {
      this.sessionDetailOpen = false;
      setTimeout(open, 10);
    } else {
      setTimeout(open, 10);
    }
  }

  private mouseLeaveSession() {
    const close = () => {
      if (!this.overDetails) {
        this.sessionDetailOpen = false;
      }
    };

    setTimeout(close, 10);
  }

  private nearestFutureMinutes(
    interval: number,
    someMoment: moment.Moment,
  ): moment.Moment {
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  }

  private nearestMinutes(
    interval: number,
    someMoment: moment.Moment,
  ): moment.Moment {
    const roundedMinutes =
      Math.round(someMoment.clone().minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  }

  private nearestPastMinutes(
    interval: number,
    someMoment: moment.Moment,
  ): moment.Moment {
    const roundedMinutes =
      Math.floor(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  }

  private async openNewTab(url: string) {
    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push(url);
    }
  }

  private sessionSelected({
    event,
  }: {
    event: GroupedSessionCalendarItem | null;
  }) {
    if (event && event.hasContent) {
      if (event.externalContentUri) {
        this.openNewTab(event.externalContentUri);
      } else {
        this.$router.push({ name: 'session', params: { sessionId: event.id } });
      }
    }
  }

  private setMobileSessions() {
    if (this.mobileSelectedDay && this.mobileSelectedRoom) {
      this.mobileSessions = this.mobileSelectedDay.groupSessions.filter(
        (gs) => gs.category === this.mobileSelectedRoom,
      );
    } else {
      this.mobileSessions = [];
    }
  }

  private uniqueSpeakers(items: PresentationItem[]) {
    const speakers = items.map((i) => {
      return i.speakerNameOverride ? i.speakerNameOverride : i.speakerName;
    });
    return speakers.filter(uniqueFilter);
  }
}
</script>

<style scoped>
.transparent {
  background-color: transparent;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}

.blink-me {
  animation: blinker 1.25s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.25;
  }
}

.black {
  background-color: black;
}

.subtitle-1 {
  font-size: 0.9rem !important;
  line-height: 1.3rem;
}

.light-chat {
  font-size: 12px;
  height: calc(100% - 64px);
}

.light-chat::-webkit-scrollbar {
  width: 5px;
}

.light-chat::-webkit-scrollbar-track {
  background: transparent;
  border-left: 0;
}

.light-chat::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: 0;
  border-radius: 7px;
}

.light-chat::-webkit-scrollbar-thumb:hover {
  background: black;
}
</style>
