<template>
  <div>
    <background :overlay="true" />
    <div>
      <v-tooltip v-if="!$vuetify.breakpoint.mobile" left>
        <template #activator="{ on, attrs }">
          <v-btn
            class="mb-8"
            fab
            small
            absolute
            bottom
            right
            v-bind="attrs"
            v-on="on"
            @click="scrollTop()"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('common.Return') }}</span>
      </v-tooltip>

      <SponsorFilters class="pa-5"></SponsorFilters>

      <div
        :class="
          $vuetify.breakpoint.mobile ? '' : 'overflow-y-auto scroller pa-0 ma-0'
        "
        :style="{ height: scrollerHeight + 'px' }"
      >
        <div id="top"></div>
        <div v-if="sponsorsGroupedByType && sponsorsGroupedByType.length > 0">
          <div v-for="st in sponsorsGroupedByType" :key="st.id">
            <v-toolbar
              v-if="st.sponsors && st.sponsors.length > 0"
              dense
              flat
              :color="st.colour ? st.colour : 'primary'"
              class="mx-5 text-h5 text-uppercase"
              height="50"
            >
              <v-icon
                v-if="st.iconUri"
                :color="st.textColour ? st.textColour : 'black'"
              >
                {{ st.iconUri }}
              </v-icon>
              <span
                :style="
                  st.textColour ? { color: st.textColour } : { color: 'black' }
                "
                >{{ st.title }}
              </span>
            </v-toolbar>

            <div
              v-if="st && st.sponsors && st.sponsors.length > 0"
              class="d-flex flex-wrap mb-5"
            >
              <SponsorCard
                v-for="s in st.sponsors"
                :key="s.id"
                :sponsor="s"
                @click.native="sponsorSelected(s)"
                class="ml-5 mt-5"
                :style="{ cursor: 'pointer' }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// import { AppInsightsLogger } from "../services/appInsightsLogger";
import Background from '@/components/Background.vue';
import SponsorCard from '@/components/SponsorCard.vue';
import SponsorFilters from '@/components/SponsorFilters.vue';
import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router/types/router';
import {
  SponsorsWithTypesAndHeaders,
  SponsorOrHeaderItem,
  SponsorTypeWithSponsorsItem,
  SponsorShortItem,
} from '../models/SponsorsWithTypesAndHeaders';
import { SponsorItem } from '@/models/Sponsor';
import { LogActions } from '@/store/log/log';
import { AppInsightsLogger } from '@/services/appInsightsLogger';

const contentNamespace = 'content';
const logNamespace = 'log';

@Component({
  components: {
    Background,
    SponsorCard,
    SponsorFilters,
  },
})
export default class Library extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public contentKey!: string;

  @Prop()
  public id!: string;

  /* PRIVATE PROPERTIES */
  private loading: boolean = false;
  private scrollerHeight = 0;
  private model = 0;
  private sponsorsGroupedByType: SponsorTypeWithSponsorsItem[] = [];
  private loaded = false;
  private customHtml = '';

  /* VUEX GETTERS */

  /* VUEX ACTIONS */
  // Log Actions
  @Action(LogActions.SET_TRACE_LOG_STATE, { namespace: logNamespace })
  private setTraceLogState!: ({
    entityId1,
    entityId2,
    page,
    title,
  }: {
    entityId1?: string;
    entityId2?: string;
    page: string;
    title: string;
  }) => void;

  /* WATCHES */
  @Watch('$route', { immediate: false, deep: true })
  private async onRouteChange(newRoute: Route, oldRoute: Route) {
    await this.loadContentExecute();
  }

  /* LOCAL GETTERS/SETTERS */
  // TODO(David): Add tag filters

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}

  private created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  // private beforeMount() {}

  private async mounted() {
    await this.loadContentExecute();
    this.setTraceLogState({ page: 'Exhibition', title: 'Exhibiton' });
  }

  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}

  private beforeDestroy() {}

  private destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }

  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private handleResize() {
    let height = 0;
    if (this.$vuetify.breakpoint.mobile) {
      height = window.innerHeight - 50;
    } else {
      height = window.innerHeight - 205;
    }
    this.scrollerHeight = height;
  }

  private async loadContentExecute() {
    this.loading = true;

    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get(`/api/platform/${platformId}/expo`);
      if (res) {
        this.customHtml = res.data;
      }

      if (this.customHtml) {
        this.loaded = true;
      } else {
        const sponsors = await Vue.$http.get<SponsorTypeWithSponsorsItem[]>(
          `/api/platform/${platformId}/sponsors/filter${window.location.search}`,
        );
        this.sponsorsGroupedByType = sponsors.data;
        this.loaded = true;
      }
    } catch (e: any) {
      AppInsightsLogger.logError('Expo - created failed', undefined, true);
      AppInsightsLogger.logException(e, false);
    }

    this.loading = false;
  }

  private scrollTop() {
    const e = document.getElementById('top');
    if (e) {
      e.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  private async sponsorSelected(item: SponsorShortItem) {
    if (item.externalContentUri) {
      this.openNewTab(item.externalContentUri);
    } else {
      this.$router.push({ name: 'stand', params: { sponsorId: item.id } });
    }
  }

  private async openNewTab(url: string) {
    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push(url);
    }
  }
}
</script>

<style scoped>
.scroller {
  width: 100%;
  font-size: 12px;
  overflow-x: hidden;
  z-index: 9999;
}

.scroller::-webkit-scrollbar {
  width: 10px;
}

.scroller::-webkit-scrollbar-track {
  background: #b0b0b050;
  border-left: 0;
}

.scroller::-webkit-scrollbar-thumb {
  background: #b0b0b090;
  border: 0;
  border-radius: 7px;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #00000030;
}

.scroller::-webkit-scrollbar-thumb:active {
  background: #00000090;
}
</style>
