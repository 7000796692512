<template>
  <v-menu
    v-model="conversationsOpen"
    left
    bottom
    offset-y
    transition="slide-y-transition"
    open-on-hover
    close-delay="50"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-badge overlap color="green" :value="unread > 0" :content="unread">
          <v-icon :style="{ color: bannerBarTextColour }"
            >mdi-message-outline</v-icon
          >
        </v-badge>
      </v-btn>
    </template>
    <v-card class="dropbox">
      <v-toolbar dense flat light color="grey lighten-2">
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ $t('bannerBar.privateMessages') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn tile icon>
          <v-icon @click="conversationsOpen = false">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form class="ma-0 pa-0">
        <v-text-field
          id="doc-search"
          ref="search"
          v-model="userSearchText"
          :background-color="!isFocused ? 'grey lighten-3' : undefined"
          :class="!isFocused ? undefined : 'rounded-lg'"
          :flat="!isFocused && !isSearching"
          placeholder="Find a participant"
          autocomplete="off"
          class="ma-1"
          dense
          hide-details
          solo
          @blur="onBlur"
          @clear="resetSearch"
          @focus="onFocus"
          @keydown.esc="onEsc"
        >
          <template #prepend-inner>
            <v-icon :color="!isFocused ? 'grey' : undefined" class="ml-1 mr-2">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-form>
      <v-card flat class="ma-0 pa-0 list-height">
        <v-card
          v-if="isSearching && isFindingUsers"
          flat
          class="ma-0 pa-0 list-height"
          align-content="center"
          justify="center"
        >
          <v-container class="ma-0 pa-0 fill-height">
            <v-row dense justify="center">
              <v-col cols="6">
                <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-container v-else class="overflow-y-auto light-chat ma-0 pa-0">
          <v-list v-if="isSearching && !isFindingUsers">
            <template v-for="(u, i) in foundUsers">
              <v-list-item :key="i" @click="openNewConverstation(u.userId)">
                <v-list-item-avatar>
                  <v-img
                    v-if="u.photoUri"
                    :alt="`${u.userName} avatar`"
                    :src="u.photoUri"
                  />
                  <v-icon v-else>mdi-account-circle-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="u.userName"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon> mdi-message-outline </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-list>
          <v-list v-else two-line>
            <template v-for="item in conversations">
              <v-list-item
                :key="item.otherUserId"
                @click="openConversationExecute(item.id)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.otherUserName"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.latestMessage"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-badge
                    color="red"
                    :value="item.unreadCount > 0"
                    :content="item.unreadCount"
                  ></v-badge>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-container>
      </v-card>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { AuthGetters } from '../store/auth/types';
import {
  ConversationActions,
  ConversationGetters,
  ConversationItem,
} from '../store/conversation/types';
import { PlatformGetters } from '../store/platform/types';
import {
  PlatformUserGetters,
  PlatformUserItem,
} from '../store/platformUser/types';

const authNamespace = 'auth';
const conversationNamespace = 'conversation';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component({})
export default class ConversationsList extends Vue {
  /* PUBLIC PROPERTIES */
  private conversationsOpen = false;
  private foundUsers: PlatformUserItem[] = [];
  private isFindingUsers = false;
  private isFocused = false;
  private isSearching = false;
  private userSearchText = '';

  /* VUEX GETTERS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Conversation Getters
  @Getter(ConversationGetters.CONVERSATIONS, {
    namespace: conversationNamespace,
  })
  private conversations!: ConversationItem[];

  @Getter(ConversationGetters.CONVERSATIONS_UNREAD_COUNT, {
    namespace: conversationNamespace,
  })
  private unread!: number;

  @Getter(ConversationGetters.CONVERSATION_IS_OPEN, {
    namespace: conversationNamespace,
  })
  private conversationIsOpen!: (id: string) => boolean;

  // Platform Getters
  @Getter(PlatformGetters.BANNER_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private bannerBarTextColour!: string;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  /* VUEX ACTIONS */
  @Action(ConversationActions.OPEN_CONVERSATION, {
    namespace: conversationNamespace,
  })
  private openConversation!: (conversation: ConversationItem) => void;

  @Action(ConversationActions.SET_CONVERSATIONS, {
    namespace: conversationNamespace,
  })
  private setConversations!: (conversations: ConversationItem[]) => void;

  @Action(ConversationActions.VERIFY_CONVERSATION_VISIBLE, {
    namespace: conversationNamespace,
  })
  private verifyConversationVisible!: (id: string) => void;

  /* WATCHES */
  @Watch('userSearchText')
  private userSearchTextChanged(): void {
    if (this.userSearchText && this.userSearchText.length > 2) {
      this.isSearching = true;
      this.findUsers(this.userSearchText);
    } else {
      this.isSearching = false;
      this.foundUsers = [];
    }
  }

  @Watch('conversationsOpen')
  private async conversationsOpenChanged(): Promise<void> {
    if (!this.conversationsOpen) {
      await this.loadConversationItems();
    } else {
      this.resetSearch();
    }
  }

  @Watch('isAuthenticated')
  private async isAuthenticatedChanged() {
    if (this.isAuthenticated) {
      await this.loadConversationItems();
    }
  }

  private async openConversationExecute(conversationId: string): Promise<void> {
    if (this.conversationIsOpen(conversationId)) {
      this.verifyConversationVisible(conversationId);
      return;
    }
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<ConversationItem>(
        `/api/platform/${platformId}/conversations/${conversationId}`,
      );
      if (res && res.data) {
        this.openConversation(res.data);
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'BannerBar - openConversationExecute failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.conversationsOpen = false;
    this.resetSearch();
  }

  private async findUsers(query: string): Promise<void> {
    this.isFindingUsers = true;
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<PlatformUserItem[]>(
        `/api/platform/${platformId}/conversations/users?q=${query}`,
      );
      if (res && res.data) {
        this.foundUsers = res.data;
      } else {
        this.foundUsers = [];
      }
    } catch (e: any) {
      this.foundUsers = [];
      AppInsightsLogger.logError(
        'BannerBar - openConversationExecute failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.isFindingUsers = false;
  }

  private async openNewConverstation(user2: string): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const user1 = this.currentUserId;
    try {
      const convo = { eventId: platformId, user1, user2 };
      const res = await Vue.$http.post(
        `/api/platform/${platformId}/conversations/new`,
        convo,
      );
      const conversationId = res.data.conversationId;
      const item = await Vue.$http.get<ConversationItem>(
        `/api/platform/${platformId}/conversations/${conversationId}`,
      );
      this.openConversation(item.data);
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - openNewConverstation failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.conversationsOpen = false;
    this.resetSearch();
  }

  private async loadConversationItems(): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<ConversationItem[]>(
        `/api/platform/${platformId}/conversations`,
      );
      if (res && res.data) {
        this.setConversations(res.data);
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'BannerBar - loadConversationItems',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async mounted() {
    await this.loadConversationItems();
  }

  private onBlur(): void {
    // this.resetSearch();
  }

  private onFocus(): void {
    this.isFocused = true;
  }

  private onEsc(): void {
    (this.$refs.search as HTMLElement).blur();
  }

  private resetSearch(): void {
    this.isFocused = false;
    this.foundUsers = [];
    this.userSearchText = '';
    this.isFindingUsers = false;
    this.isSearching = false;
  }
}
</script>

<style scoped>
.title {
  font-family: Ubuntu !important;
  font-size: 1.8rem !important;
  font-weight: 500;
}

.dropbox {
  width: 400px;
  height: 60vh;
}

.list-height {
  height: calc(60vh - 100px);
}

.light-chat {
  font-size: 12px;
  height: 100%;
}

.light-chat::-webkit-scrollbar {
  width: 5px;
}

.light-chat::-webkit-scrollbar-track {
  background: transparent;
  border-left: 0;
}

.light-chat::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: 0;
  border-radius: 7px;
}

.light-chat::-webkit-scrollbar-thumb:hover {
  background: black;
}
</style>
