<template>
  <div class="conversationsWrapper">
    <v-card
      flat
      class="conversationsContainer d-flex flex-row-reverse flex-wrap-reverse"
    >
      <template v-for="c in conversationsOpen">
        <ConversationBox :key="c.id" :conversation="c" />
      </template>
    </v-card>
  </div>
</template>

<script lang="ts">
import ConversationBox from '@/components/ConversationBox.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {
  ConversationGetters,
  ConversationItem,
} from '../store/conversation/types';
const conversationNamespace = 'conversation';

@Component({
  components: {
    ConversationBox,
  },
})
export default class CoversationBar extends Vue {
  @Getter(ConversationGetters.CONVERSATIONS_OPEN, {
    namespace: conversationNamespace,
  })
  private conversationsOpen!: ConversationItem[];
}
</script>

<style scoped>
.conversationsWrapper {
  width: 100%;
  height: calc(100vh - 128px);
  pointer-events: none;
}
.conversationsContainer {
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 3000;
  background: transparent;
  max-height: 282px;
  overflow: hidden;
}
</style>
