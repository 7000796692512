<template>
  <div>
    <v-card :height="sponsor ? 'auto' : 0" :width="cardWidth" tile outlined>
      <v-card height="120" flat tile color="transparent">
        <v-row align="center" class="fill-height ma-0 pa-0" justify="center">
          <v-img
            contain
            :aspect-ratio="16 / 9"
            :src="sponsor.thumbnailImageUri"
            @error="handleImageError()"
            max-height="60"
          >
            <template #placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-row>
      </v-card>
      <v-divider></v-divider>
      <v-card height="120" flat tile color="transparent">
        <v-card-title>{{ sponsor.title }}</v-card-title>
        <v-card-subtitle>{{ sponsor.shortDescription }}</v-card-subtitle>
      </v-card>
      <v-divider></v-divider>
      <v-card height="60" flat tile color="transparent">
        <v-row align="center" class="fill-height ma-0 pa-0 ml-5">
          <span class="text-caption">
            <a>{{ $t('sponsor.viewMore') }}</a>
          </span>
        </v-row>
      </v-card>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { SponsorShortItem } from '../models/SponsorsWithTypesAndHeaders';

@Component
export default class extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public sponsor!: SponsorShortItem;

  /* PRIVATE PROPERTIES */
  private thumbnailError = false;
  private defaultThumbnailError = false;

  /* VUEX GETTERS */
  /* VUEX ACTIONS */
  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get cardWidth(): number {
    if (this.$vuetify.breakpoint.mobile) {
      return window.innerWidth - 40;
    } else {
      return 340;
    }
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  // private mounted() {}
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/

  private handleImageError() {
    if (!this.thumbnailError) {
      this.thumbnailError = true;
    } else if (!this.defaultThumbnailError) {
      this.defaultThumbnailError = true;
    }
  }
}
</script>

<style scoped></style>
