<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-row justify="center">
      <v-col cols="11" md="6">
        <v-text-field
          class="ma-0 pa-0"
          min-height="36px"
          height="36px"
          hide-details
          prepend-inner-icon="mdi-account-search-outline"
          solo
          dense
          clear-icon="mdi-close-circle"
          clearable
          label="Search"
          :suffix="countString()"
          type="text"
          :loading="loading"
          @input="debounceSearch"
          @click:clear="clearSearch"
        >
        </v-text-field>
      </v-col>
      <v-col cols="1" class="pl-0">
        <v-dialog
          v-model="dialog"
          max-width="600px"
          transition="dialog-bottom-transition"
          v-if="showFilters"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :icon="$vuetify.breakpoint.smAndDown"
              :outlined="$vuetify.breakpoint.mdAndUp"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-filter</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp">{{
                $t('common.moreFilters')
              }}</span>
              <span v-if="$vuetify.breakpoint.mdAndUp && filterCount">
                •{{ filterCount }}
              </span>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t('common.moreFilters') }}</span>
            </v-card-title>
            <v-card-text class="mb-0 pb-0">
              <v-form ref="form">
                <v-combobox
                  v-if="groups && groups.length > 0"
                  outlined
                  hide-details
                  clearable
                  class="py-2"
                  :label="$t('sponsor.Group')"
                  prepend-inner-icon=""
                  :items="groups"
                  v-model="group"
                  @change="filterSelectionMade"
                >
                </v-combobox>

                <v-combobox
                  v-if="themes && themes.length > 0"
                  outlined
                  hide-details
                  clearable
                  class="py-2"
                  :label="$t('sponsor.Theme')"
                  prepend-inner-icon=""
                  :items="themes"
                  v-model="theme"
                  @change="filterSelectionMade"
                >
                </v-combobox>

                <v-combobox
                  v-if="industries && industries.length > 0"
                  outlined
                  hide-details
                  clearable
                  class="py-2"
                  :label="$t('sponsor.Industry')"
                  prepend-inner-icon=""
                  :items="industries"
                  v-model="industry"
                  @change="filterSelectionMade"
                >
                </v-combobox>

                <v-combobox
                  v-if="expertises && expertises.length > 0"
                  outlined
                  hide-details
                  clearable
                  class="py-2"
                  :label="$t('sponsor.Expertise')"
                  prepend-inner-icon=""
                  :items="expertises"
                  v-model="expertise"
                  @change="filterSelectionMade"
                >
                </v-combobox>

                <!-- <v-combobox
                  v-if="keywords && keywords.length > 0"
                  multiple
                  outlined
                  hide-details
                  clearable
                  class="py-2"
                  :label="$t('sponsor.Keywords')"
                  prepend-inner-icon=""
                  :items="keywords"
                  v-model="keyword"
                  @change="filterSelectionMade"
                >
                </v-combobox> -->
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="blue darken-1"
                text
                @click.stop="clearFiltersExecute"
              >
                <span>{{ $t('common.clear') }}</span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                {{ $t('common.close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Getter } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// import { AppInsightsLogger } from "../services/appInsightsLogger";

@Component
export default class SponsorFilters extends Vue {
  /* PUBLIC PROPERTIES */

  /* PRIVATE PROPERTIES */
  private debounce: number | undefined;
  private searchText: string | null = '';

  private groups: string[] = [];
  private themes: string[] = [];
  private industries: string[] = [];
  private expertises: string[] = [];
  private keywords: string[] = [];
  private showFilters = false;

  private group: string | null = '';
  private theme: string | null = '';
  private industry: string | null = '';
  private expertise: string | null = '';
  private keyword: string[] = [];
  private filterCount = 0;

  private mounting = false;
  private dialog = false;
  private loading = false;

  /* VUEX GETTERS */

  /* VUEX ACTIONS */

  /* WATCHES */
  @Watch('searchText')
  private async searchTextChanged() {
    if (this.mounting) {
      return;
    }
    // Do search if more than 3 or more characters
    if (this.searchText && this.searchText.length >= 3) {
      await this.applySearchAndFilters();
    } else if (!this.searchText) {
      await this.applySearchAndFilters();
    }
  }

  /* LOCAL GETTERS/SETTERS */
  private countString() {
    // if (this.totalItems === 0) {
    //   return this.$t('common.noResults');
    // } else if (this.totalItems === 1) {
    //   return `${this.totalItems} ${this.$t('common.result')}`;
    // }
    // return `${this.totalItems} ${this.$t('common.results')}`;
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private async mounted() {
    this.loading = true;
    const platformId = sessionStorage.getItem('platformId') ?? '';
    let uri = `/api/platform/${platformId}/sponsors/filters`;
    const res = await Vue.$http.get(uri);
    if (res) {
      this.groups = res.data.groups;
      this.themes = res.data.themes;
      this.industries = res.data.industries;
      this.expertises = res.data.expertises;
      this.keywords = res.data.keywords;

      if (
        this.groups.length > 0 ||
        this.themes.length > 0 ||
        this.industries.length > 0 ||
        this.expertises.length > 0 ||
        this.keywords.length > 0
      ) {
        this.showFilters = true;
      }
    }

    if (this.$route.query['search']) {
      this.searchText = this.$route.query['search'] as string;
    }

    this.filterCount = 0;
    if (this.$route.query['group']) {
      this.group = this.$route.query['group'] as string;
      this.filterCount++;
    }

    if (this.$route.query['theme']) {
      this.theme = this.$route.query['theme'] as string;
      this.filterCount++;
    }

    if (this.$route.query['industry']) {
      this.industry = this.$route.query['industry'] as string;
      this.filterCount++;
    }

    if (this.$route.query['expertise']) {
      this.expertise = this.$route.query['expertise'] as string;
      this.filterCount++;
    }

    if (
      this.$route.query['keywords'] &&
      this.$route.query['keywords'].length > 0
    ) {
      (this.$route.query['keywords'] as string[]).forEach((k: string) => {
        this.keyword.push(k);
        this.filterCount++;
      });
    }
    this.loading = false;
    await this.filterSelectionMade();
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private clearSearch() {
    this.searchText = '';
  }

  private async clearFiltersExecute() {
    (this.$refs.form as any).reset();
    await this.applySearchAndFilters();
  }

  private debounceSearch(event: string) {
    if (event === null || event === undefined) {
      return;
    }

    if (this.debounce) {
      clearTimeout(this.debounce);
    }
    this.debounce = window.setTimeout(() => {
      this.searchText = event;
    }, 600);
  }

  private async filterSelectionMade() {
    if (this.loading) return;
    await this.applySearchAndFilters();
  }

  private async applySearchAndFilters() {
    const dict: Record<string, string | string[]> = {};
    if (this.searchText) {
      dict['search'] = this.searchText;
    }

    this.filterCount = 0;
    if (this.group) {
      dict['group'] = this.group;
      this.filterCount++;
    }

    if (this.theme) {
      dict['theme'] = this.theme;
      this.filterCount++;
    }

    if (this.industry) {
      dict['industry'] = this.industry;
      this.filterCount++;
    }

    if (this.expertise) {
      dict['expertise'] = this.expertise;
      this.filterCount++;
    }

    if (this.keyword && this.keyword.length > 0) {
      this.keyword.forEach((k: string) => {
        if (!dict['keywords']) {
          dict['keywords'] = [];
        }
        (dict['keywords'] as string[]).push(k);
        this.filterCount++;
      });
    }

    // TODO(David): Is this the best way to do this?
    if (JSON.stringify(this.$route.query) === JSON.stringify(dict)) {
      return;
    }

    await this.$router.push({
      query: dict,
    });
  }
}
</script>
