export const isLight = (color: string): boolean => {
    let r: number, g: number, b: number;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If HEX --> store the red, green, blue values in separate variables
        const rgbMatch = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        if (!rgbMatch) throw new Error('Invalid RGB color format');

        r = parseInt(rgbMatch[1], 10);
        g = parseInt(rgbMatch[2], 10);
        b = parseInt(rgbMatch[3], 10);
    } else {

        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        const hexColor = color.length < 5 ? +("0x" + color.slice(1).replace(/./g, '$&$&')) : +("0x" + color.slice(1));

        r = hexColor >> 16;
        g = hexColor >> 8 & 255;
        b = hexColor & 255;
    }

    // HSP equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    return (hsp > 127.5);
}

export const isDark = (color: string): boolean => {
    return !isLight(color);
}

export const lightOrDark = (color: string): 'light' | 'dark' => {
    if (isLight(color)) {
        return 'light';
    } else {
        return 'dark';
    }
}