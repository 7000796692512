<template>
  <div
    :class="[
      name,
      {
        'has-exceeded-limit': limitStatus > 100,
      },
    ]"
  >
    <textarea
      ref="textarea"
      :class="`${name}__textarea`"
      :value="value"
      rows="1"
      :placeholder="placeholder"
      @input="updateValue"
    />
    <div :class="`${name}__limit`">
      <span :class="`${name}__remainingCharacters`">{{
        remainingCharacters
      }}</span>
      <svg
        :class="`${name}__counter`"
        viewBox="0 0 33.83098862 33.83098862"
        height="16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          :class="`${name}__counterUnderlay`"
          cx="16.91549431"
          cy="16.91549431"
          r="15.91549431"
          fill="none"
          stroke-width="2"
        />
        <circle
          :class="`${name}__counterProgress`"
          :stroke-dasharray="`${limitStatus},100`"
          cx="16.91549431"
          cy="16.91549431"
          r="15.91549431"
          fill="none"
          stroke-width="4"
        />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

@Component({})
export default class TweetBox extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public limit!: number;

  @Prop()
  public placeholder!: string;

  @Prop()
  public value!: string;

  @Ref('textarea')
  readonly textarea!: HTMLTextAreaElement;

  get name(): string {
    return 'TweetBox';
  }

  get valueAllowed(): number | string {
    return this.limit ? this.value.slice(0, this.limit) : this.value;
  }

  get valueExcess(): number | string {
    return this.limit ? this.value.slice(this.limit) : '';
  }

  get limitStatus(): number {
    return (this.value.length / this.limit) * 100;
  }
  get remainingCharacters(): number {
    return this.limit - this.value.length;
  }

  get textareaStyle(): CSSStyleDeclaration {
    return getComputedStyle(this.$refs.textarea as Element);
  }

  public textFocus() {
    this.textarea.focus();
  }

  private updateValue(e: Event) {
    this.textareaGrow();
    if (e && e.target) {
      this.$emit(`input`, (e.target as HTMLInputElement).value);
    }
  }
  // Update the size of the textarea to fit the number
  // of lines of text.
  private textareaGrow() {
    const paddingTop = parseInt(
      this.textareaStyle.getPropertyValue('padding-top'),
      10,
    );
    const paddingBottom = parseInt(
      this.textareaStyle.getPropertyValue('padding-bottom'),
      10,
    );
    const lineHeight = parseInt(
      this.textareaStyle.getPropertyValue('line-height'),
      10,
    );

    // Resetting the row count to `1` is necessary for
    // recalculating the `scrollHeight` of the textarea.
    (this.$refs.textarea as HTMLTextAreaElement).rows = 1;

    // We're calculating the inner height of the textare
    // and take this value to also calculate the number
    // of rows needed to fit the currently entered text.
    const innerHeight =
      (this.$refs.textarea as HTMLTextAreaElement).scrollHeight -
      paddingTop -
      paddingBottom;
    (this.$refs.textarea as HTMLTextAreaElement).rows =
      innerHeight / lineHeight;
  }

  private mounted() {
    this.textareaGrow();
  }
}
</script>

<style scoped>
.TweetBox {
  position: relative;
}
.TweetBox__htmlarea,
.TweetBox__textarea {
  padding: 1em;
  padding-right: 3.75em;
  width: 100%;
  line-height: 1.25;
  border: 2px solid transparent;
  border-radius: 0.5em;
  max-height: 50px;
}
.TweetBox__htmlarea {
  position: absolute;
  height: 100%;
  background-color: #fff;
  color: transparent;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-height: 50px;
}
.TweetBox__textarea {
  display: block;
  position: relative;
  border-color: #99dde6;
  outline: 0;
  background-color: transparent;
  resize: none;
  max-height: 50px;
}
.TweetBox__textarea:focus {
  border-color: #47c2d2;
}
.TweetBox em {
  background: #ffb8c2;
}
.TweetBox__limit {
  display: flex;
  position: absolute;
  right: 0.75em;
  bottom: 0.75em;
  align-items: center;
}
.TweetBox__remainingCharacters {
  margin-right: 0.5em;
  color: #657786;
  font-size: 0.75em;
}
.has-exceeded-limit .TweetBox__remainingCharacters {
  color: #e0245e;
}
.TweetBox__counter {
  overflow: visible;
  transform: rotate(-90deg);
  transform-origin: center;
}
.TweetBox__counterUnderlay {
  stroke: #ccd6dd;
}
.TweetBox__counterProgress {
  stroke: #1da1f2;
}
.has-exceeded-limit .TweetBox__counterProgress {
  stroke: #e0245e;
  animation: counterPulse 0.3s ease-in-out;
  animation-iteration-count: 1;
}
@keyframes counterPulse {
  0% {
    stroke-width: 4;
  }
  50% {
    stroke-width: 6;
  }
  100% {
    stroke-width: 4;
  }
}
.TweetBox .TweetBox__textarea::-webkit-scrollbar {
  width: 3px;
}
.TweetBox .TweetBox__textarea::-webkit-scrollbar-track {
  background: transparent;
  border-left: 0;
}
.TweetBox .TweetBox__textarea::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: 0;
  border-radius: 7px;
}
.TweetBox .TweetBox__textarea::-webkit-scrollbar-thumb:hover {
  background: black;
}
</style>
