<template>
  <v-container
    v-if="!$vuetify.breakpoint.mobile"
    class="width: 100%"
    style="max-width: 98% !important"
  >
    <v-row v-if="bannerAds && bannerAds.length > 0" justify="center">
      <v-col class="pt-0 pb-5">
        <v-card elevation="24" class="mx-auto" color="blue">
          <v-carousel cycle :show-arrows="false" hide-delimiters height="100%">
            <v-carousel-item
              v-for="(ad, i) in bannerAds"
              :key="i"
              :src="ad.imageUri"
              active-class="adwide"
              link
              style="cursor: pointer"
              @click.stop="adClick(ad)"
            ></v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { AdClickLog } from '@/models/AdClickLog';
import { AdItem } from '@/models/AdItem';
import { PlatformActions } from '@/store/platform/types';
import { PlatformUserGetters } from '@/store/platformUser/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component
export default class BannerAd extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  private ads: AdItem[] | undefined;

  @Prop()
  private poster!: boolean;

  /* PRIVATE PROPERTIES */

  /* VUEX GETTERS */
  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;
  /* VUEX ACTIONS */
  // Platform Actions
  @Action(PlatformActions.SPONSOR_AD_LOG, { namespace: platformNamespace })
  private adClickLog!: (adClickLog: AdClickLog) => Promise<void>;
  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */
  get bannerAds(): AdItem[] | undefined {
    if (this.poster) {
      return this.ads?.filter((a: AdItem) => {
        return a.type === 'BannerPoster';
      });
    } else {
      return this.ads?.filter((a: AdItem) => {
        return a.type === 'BannerVod';
      });
    }
  }

  /* PRIVATE METHODS*/
  private async adClick(ad: AdItem) {
    if (ad.link) {
      const log: AdClickLog = {
        entityId: ad.id,
        link: ad.link,
        title: ad.title,
        type: ad.type,
        userId: this.currentUserId,
      };
      await this.adClickLog(log);
      await this.openNewTab(ad.link);
    }
  }

  private async openNewTab(url: string) {
    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push(url);
    }
  }
}
</script>

<style>
div.v-window-item.adwide > div > div.v-image__image.v-image__image--cover {
  background-position-x: left !important;
  background-position-y: center !important;
}
</style>
