<template>
  <div v-if="sessionAds && sessionAds.length > 0">
    <v-card max-width="414" tile outlined>
      <v-carousel cycle :show-arrows="false" hide-delimiters height="100%">
        <v-carousel-item
          v-for="(ad, i) in sessionAds"
          :key="i"
          :src="ad.imageUri"
          link
          style="cursor: pointer"
          @click.stop="adClick(ad)"
        ></v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script lang="ts">
import { AdClickLog } from '@/models/AdClickLog';
import { AdItem } from '@/models/AdItem';
import { PlatformActions } from '@/store/platform/types';
import { PlatformUserGetters } from '@/store/platformUser/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component
export default class SessionAd extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  private ads: AdItem[] | undefined;

  @Prop()
  private poster!: boolean;

  @Prop()
  private position!: string;

  /* PRIVATE PROPERTIES */

  /* VUEX GETTERS */
  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;
  /* VUEX ACTIONS */
  // Platform Actions
  @Action(PlatformActions.SPONSOR_AD_LOG, { namespace: platformNamespace })
  private adClickLog!: (adClickLog: AdClickLog) => Promise<void>;
  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */
  get cardWidth(): number {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 320;
    } else {
      return 400;
    }
  }
  get cardHeight(): number {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 179;
    } else {
      return 224;
    }
  }

  get sessionAds(): AdItem[] | undefined {
    if (this.poster) {
      if (this.position === 'slot1') {
        const temp = this.ads?.filter((a: AdItem) => {
          return a.type === 'InlinePosterSlot1';
        });
        temp?.sort(function (a, b) {
          var nameA = a.title.toUpperCase(); // ignore upper and lowercase
          var nameB = b.title.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        return temp;
      } else if (this.position === 'slot2') {
        const temp = this.ads?.filter((a: AdItem) => {
          a.type === 'InlinePosterSlot2';
        });
        temp?.sort(function (a, b) {
          var nameA = a.title.toUpperCase(); // ignore upper and lowercase
          var nameB = b.title.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        return temp;
      }
    } else {
      if (this.position === 'slot1') {
        const temp = this.ads?.filter((a: AdItem) => {
          return a.type === 'InlineVodSlot1';
        });
        temp?.sort(function (a, b) {
          var nameA = a.title.toUpperCase(); // ignore upper and lowercase
          var nameB = b.title.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        return temp;
      } else if (this.position === 'slot2') {
        const temp = this.ads?.filter((a: AdItem) => {
          return a.type === 'InlineVodSlot2';
        });
        temp?.sort(function (a, b) {
          var nameA = a.title.toUpperCase(); // ignore upper and lowercase
          var nameB = b.title.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        return temp;
      }
    }
    return undefined;
  }

  /* PRIVATE METHODS*/
  private async adClick(ad: AdItem) {
    if (ad.link) {
      const log: AdClickLog = {
        entityId: ad.id,
        link: ad.link,
        title: ad.title,
        type: ad.type,
        userId: this.currentUserId,
      };
      await this.adClickLog(log);
      await this.openNewTab(ad.link);
    }
  }

  private async openNewTab(url: string) {
    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push(url);
    }
  }
}
</script>

<style>
div.v-window-item.adwide > div > div.v-image__image.v-image__image--cover {
  background-position-x: left !important;
  background-position-y: center !important;
}
</style>
