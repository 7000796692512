<template>
  <div v-if="!pageLoaded" class="pa-0 ma-0 fill-height">
    <v-container class="fill-height">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col cols="6">
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div v-else class="pa-0 ma-0">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="overline">
          {{ $t('session.details') }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div class="overflow-y-auto scroller px-2 my-0 py-0">
      <v-row dense>
        <v-col
          v-if="session.audioFileUri"
          cols="12"
          class="font-weight-bold text--disabled"
        >
          {{ $t('session.Audio') }}
        </v-col>
        <v-col v-if="session.audioFileUri" cols="12">
          <audio-player
            :file="session.audioFileUri"
            :flat="true"
            :auto-play="true"
          ></audio-player>
        </v-col>
        <v-col cols="12" class="text-h6 font-weight-bold text--disabled">
          {{ $t('session.title') }}
        </v-col>
        <v-col cols="12" class="pl-2 text-subtitle-1 font-weight-bold">
          {{ session.title }}
        </v-col>
        <v-col
          v-if="session.type"
          cols="12"
          class="text-h6 font-weight-bold text--disabled"
        >
          {{ $t('session.type') }}
        </v-col>
        <v-col
          v-if="session.type"
          cols="12"
          class="pl-2 text-subtitle-1 font-weight-bold"
        >
          {{ session.type }}
        </v-col>
        <v-col
          v-if="session.theme"
          cols="12"
          class="text-h6 font-weight-bold text--disabled"
        >
          {{ $t('session.theme') }}
        </v-col>
        <v-col
          v-if="session.theme"
          cols="12"
          class="pl-2 text-subtitle-1 font-weight-bold"
        >
          {{ session.theme }}
        </v-col>
        <v-col
          v-if="session.moderators && session.moderators.length > 0"
          cols="12"
          class="text-h6 font-weight-bold text--disabled"
        >
          {{ $t('session.moderators') }}
        </v-col>
        <v-col
          v-if="session.moderators && session.moderators.length > 0"
          cols="12"
          class="pa-0 pl-2 text-subtitle-1 font-weight-bold"
        >
          <v-list dense flat class="ma-0 pa-0">
            <template v-for="(mod, m) in session.moderators">
              <div :key="m">
                <v-list-item class="ma-0 pa-0">
                  <v-list-item-content class="ma-0 pa-0">
                    <v-list-item-title class="ma-0 pa-0">
                      {{ mod }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </template>
          </v-list>
        </v-col>
        <v-col
          v-if="
            session.presentationItems && session.presentationItems.length > 0
          "
          cols="12"
          class="text-h6 font-weight-bold text--disabled"
        >
          {{ $t('session.speakers') }}
        </v-col>
        <v-col
          v-if="
            session.presentationItems && session.presentationItems.length > 0
          "
          cols="12"
          class="pa-0 pl-2 text-subtitle-1 font-weight-bold"
        >
          <v-list dense flat class="ma-0 pa-0">
            <template
              v-for="(item, i) in uniqueSpeakers(session.presentationItems)"
            >
              <div :key="i">
                <v-list-item class="ma-0 pa-0">
                  <v-list-item-content class="ma-0 pa-0">
                    <v-list-item-title class="ma-0 pa-0">
                      <span
                      :style="speakersModuleEnabled && !item.speakerNameOverride ? { cursor: 'pointer' } : {}"
                      @click="navigateToSpeaker(item)">
                        {{ item.speakerNameOverride ? item.speakerNameOverride : item.speakerName }}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </template>
          </v-list>
        </v-col>
        <v-col
          v-if="session.isPresentation && session.activeSpeakerName"
          cols="12"
          class="text-h6 font-weight-bold text--disabled"
        >
          {{ session.isAPoster ? $t('session.authors') : $t('session.speakers') }}
        </v-col>
        <v-col
          v-if="session.isPresentation && session.activeSpeakerName"
          cols="12"
          class="pa-0 pl-2 text-subtitle-1 font-weight-bold"
        >
          {{ session.activeSpeakerName }}
        </v-col>
        <v-col
          v-if="session.isPresentation && session.coAuthors"
          cols="12"
          class="text-h6 font-weight-bold text--disabled"
        >
          {{ $t('session.coauthors') }}
        </v-col>
        <v-col
          v-if="session.isPresentation && session.coAuthors"
          cols="12"
          class="pa-0 pl-2 text-subtitle-1 font-weight-bold"
        >
          {{ session.coAuthors }}
        </v-col>
        <v-col
          v-if="
            (session.posterUploadUri && allowPresentationDownload) ||
            (session.presentationFileUri && allowPresentationDownload) ||
            (session.abstractFileUri && allowAbstractDownload)
          "
          cols="12"
          class="text-h6 font-weight-bold text--disabled"
        >
          {{ $t('session.downloads') }}
        </v-col>
        <v-col
          v-if="
            (session.posterUploadUri && allowPresentationDownload) ||
            (session.presentationFileUri && allowPresentationDownload) ||
            (session.abstractFileUri && allowAbstractDownload)
          "
          cols="11"
          class="ml-2 font-weight-bold"
        >
          <v-col
            v-if="session.presentationFileUri && allowPresentationDownload"
            cols="12"
            class="ma-0 pa-0"
          >
            <v-btn
              color="blue-grey"
              class="white--text"
              @click="downloadFile(session.presentationFileUri, 'presentation')"
            >
              {{ $t('session.presentation') }}
              <v-icon right dark> mdi-cloud-download </v-icon>
            </v-btn>
            <v-btn
              v-if="isAuthenticated"
              icon
              @click.stop="
                bookmarkExecute(
                  $t('session.presentation'),
                  session.presentationFileUri,
                )
              "
            >
              <v-icon
                v-if="isABookmark(session.id, session.presentationFileUri)"
                >mdi-bookmark-check</v-icon
              >
              <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
            </v-btn>
          </v-col>

          <v-col
            v-if="session.posterUploadUri && allowPresentationDownload"
            cols="12"
            class="ma-0 pa-0"
          >
            <v-btn
              color="blue-grey"
              class="white--text"
              @click="downloadFile(session.posterUploadUri, 'poster')"
            >
              {{ $t('session.poster') }}
              <v-icon right dark> mdi-cloud-download </v-icon>
            </v-btn>
            <v-btn
              v-if="isAuthenticated"
              icon
              @click.stop="
                bookmarkExecute($t('session.poster'), session.posterUploadUri)
              "
            >
              <v-icon v-if="isABookmark(session.id, session.posterUploadUri)"
                >mdi-bookmark-check</v-icon
              >
              <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
            </v-btn>
          </v-col>



          <v-col
            v-if="session.abstractFileUri && allowAbstractDownload"
            cols="12"
            class="ma-0 pa-0"
          >
            <v-btn
              color="blue-grey"
              class="white--text"
              @click="downloadFile(session.abstractFileUri, 'abstract')"
            >
              {{ $t('session.abstract') }}
              <v-icon right dark> mdi-cloud-download </v-icon>
            </v-btn>
            <v-btn
              icon
              @click.stop="
                bookmarkExecute($t('session.abstract'), session.abstractFileUri)
              "
            >
              <v-icon v-if="isABookmark(session.id, session.abstractFileUri)"
                >mdi-bookmark-check</v-icon
              >
              <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
            </v-btn>
          </v-col>
        </v-col>

         <v-col
            v-if="session.posterUploadUri"
            cols="12"
            class="ma-0 pa-0 mt-2"
          >
            <v-btn
              color="green"
              class="white--text"
              @click="startSlideShowPresentation(session.posterUploadUri)"
            >
              Display
              <v-icon right dark> mdi-presentation-play </v-icon>
            </v-btn>
          </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import AudioPlayer from '@/components/AudioPlayer.vue';
import { AuthGetters } from '@/store/auth/types';
import { PlatformGetters } from '@/store/platform/types';
import Presentation from '@/views/Presentation.vue';
import { LocaleMessages } from 'vue-i18n';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import uniqueFilter from '../filters/unique.filter';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '../store/bookmark/types';
import {
  PresentationItem,
  SessionGetters,
  SessionItem,
} from '../store/session/types';

const authNamespace = 'auth';
const platformNamespace = 'platform';
const sessionNamespace = 'session';
const bookmarkNamespace = 'bookmark';

@Component({
  components: {
    AudioPlayer,
  },
})
export default class SessionDetailsSide extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public isActive!: boolean;

  @Prop()
  public pageLoaded!: boolean;

  /* PRIVATE PROPERTIES */

  /* VUEX GETTERS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Bookmark Getters
  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string, entityUri?: string) => boolean;

  // Platform Getters
  @Getter(PlatformGetters.ALLOW_ABSTRACT_DOWNLOAD, {
    namespace: platformNamespace,
  })
  private allowAbstractDownload!: boolean;

  @Getter(PlatformGetters.ALLOW_PRESENTATION_DOWNLOAD, {
    namespace: platformNamespace,
  })
  private allowPresentationDownload!: boolean;

  @Getter(PlatformGetters.SPEAKERS_MODULE_ENABLED, {
    namespace: platformNamespace,
  })
  private speakersModuleEnabled;

  // Session Getters
  @Getter(SessionGetters.SELECTED_SESSION, { namespace: sessionNamespace })
  private session!: SessionItem;

  /* VUEX ACTIONS */
  // Bookmark Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */

  /* PRIVATE METHODS*/

  private getExtension(uri: string) {
    const a = uri.split('?')[0].split('.');
    if (a.length > 0) {
      return a[a.length - 1];
    } else {
      return 'pdf';
    }
  }

  private async downloadFile(uri: string | undefined, type: string) {
    if (!uri) {
      return;
    }
    try {
      const res = await fetch(uri, {
        method: 'GET',
      });
      const url = window.URL.createObjectURL(new Blob([await res.blob()]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${type}.${this.getExtension(uri)}`);
      document.body.appendChild(link);
      link.click();
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Session Details Side - download failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private uniqueSpeakers(items: PresentationItem[]): PresentationItem[] {

    var unique = [] ;
    var distinct: PresentationItem[] = [];
    for( let i = 0; i < items.length; i++ ) {
      if(!unique[items[i].speakerId]){
        distinct.push(items[i]);
        unique[items[i].speakerId] = 1;
      }
    }
    return distinct;


    // const speakers = items.map((i) => {
    //   return i.speakerNameOverride ? i.speakerNameOverride : i.speakerName;
    // });
    // return speakers.filter(uniqueFilter);
  }

  private bookmarkExecute(
    title: string | LocaleMessages,
    url: string | undefined,
  ) {
    if (url) {
      const bi = this.getBookmark(this.session.id, url);
      if (bi) {
        this.unbookmarkItem(bi);
      } else {
        let et = '';
        if (this.session.isAPoster) {
          et = BookmarkEntityType.POSTER_DOCUMENT;
        } else if (this.session.isPresentation) {
          et = BookmarkEntityType.PRESENTATION_DOCUMENT;
        } else {
          et = BookmarkEntityType.SESSION_DOCUMENT;
        }

        const bm: BookmarkItem = {
          entityId: this.session.id,
          entityType: et,
          type: BookmarkType.DOCUMENT,
          entityUri: url,
          title: `${title} - ${this.session.title}`,
        };
        this.bookmarkItem(bm);
      }
    }
  }

  private async openNewTab(url: string | undefined) {
    if (!url) {
      return;
    }
    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank', 'fullscreen=yes');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push(url);
    }
  }

  private startSlideShowPresentation(url:string | undefined)
  {
    if (!url) return;

    this.$root.$emit("start-slide-show", url);

  }

  private navigateToSpeaker(pres: PresentationItem) {
    if (pres.speakerNameOverride) return;

    if (!this.speakersModuleEnabled) return;

    if (!pres.speakerId) return;

    this.$router.push({
      name: 'speakers',
      query: {
        search: pres.speakerName,
        id: pres.speakerId,
        speaker: 'true',
      },
    });
  }
}
</script>

<style scoped>
.scroller {
  height: calc(100vh - 200px);
  width: calc(100% - 20px);
  font-size: 12px;
  overflow-x: hidden;
  z-index: 9999;
}

.scroller::-webkit-scrollbar {
  width: 10px;
}

.scroller::-webkit-scrollbar-track {
  background: #b0b0b050;
  border-left: 0;
}

.scroller::-webkit-scrollbar-thumb {
  background: #b0b0b090;
  border: 0;
  border-radius: 7px;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #00000030;
}

.scroller::-webkit-scrollbar-thumb:active {
  background: #00000090;
}
</style>
