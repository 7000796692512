<template>
  <div :style="cssVars">
    <Background
      v-if="items"
      :colour="items.backgroundColour"
      :override="items.backgroundImageUri"
      :overlay="false"
    />
    <div v-if="items" class="full-height zero">
      <span v-if="items.pinnedSession" class="pinned-container">
        <div class="pinned-row">
          <div class="pinned">
            <div class="hero-image-wrapper">
              <img
                :src="items.pinnedSessionImageUri"
                class="hero-image-wrapper static-image image-layer"
              />
              <div class="hero-vignette vignette-layer"></div>
            </div>
            <div class="session-info">
              <div class="session-title">
                <div
                  :style="{ color: textColour }"
                  class="text-h4 session-title-text"
                >
                  {{ items.pinnedSession.title }}
                </div>
                <div>
                  <v-btn
                    v-if="sessionActive(items.pinnedSession)"
                    v-bind="pinnedSessionButtonSize"
                    :dark="defaultButtonColour ? dark(defaultButtonColour) : true"
                    :light="defaultButtonColour ? light(defaultButtonColour): false"
                    :color="defaultButtonColour ? defaultButtonColour : '#1976d2'"
                    class="ma-1"
                    @click.stop="sessionSelected(pinnedSession)"
                  >
                    <v-icon
                      :dark="defaultButtonColour ? dark(defaultButtonColour) : true"
                      :light="defaultButtonColour ? light(defaultButtonColour): false"
                      left>
                        mdi-play
                    </v-icon>
                    {{ $t('common.Launch') }}
                  </v-btn>
                  <v-btn
                    dark
                    v-bind="pinnedSessionButtonSize"
                    color="black"
                    class="ma-1"
                    @click="showMoreInfo(pinnedSession)"
                  >
                    <v-icon dark left> mdi-information-outline </v-icon>
                    {{ $t('common.MoreInfo') }}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>

      <div v-if="items.live && items.live.length" class="one">
        <HomeVodSessionGroup
          group-id="live"
          group-type="live"
          group-name="Live Now"
          :items="items.live"
          :text-colour="textColour"
          @more="showMoreInfo"
        />
      </div>
      <div v-if="items.rooms && items.rooms.length" class="one">
        <HomeVodSessionGroup
          v-for="r in items.rooms"
          :key="r.roomId"
          :group-id="r.roomId"
          group-type="room"
          :group-name="r.roomName.substring(3)"
          :items="r.roomSessions"
          :text-colour="textColour"
          @more="showMoreInfo"
        />
      </div>
      <div v-if="items.themes && items.themes.length" class="one">
        <HomeVodSessionGroup
          v-for="th in items.themes"
          :key="th.themeName"
          :group-id="th.themeName"
          :group-name="th.themeName"
          :items="th.themeSessions"
          group-type="theme"
          :text-colour="textColour"
          @more="showMoreInfo"
        />
      </div>
      <div v-if="items.types && items.types.length" class="one">
        <HomeVodSessionGroup
          v-for="th in items.types"
          :key="th.typeName"
          :group-id="th.typeName"
          :group-name="th.typeName"
          group-type="type"
          :items="th.typeSessions"
          :text-colour="textColour"
          @more="showMoreInfo"
        />
      </div>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="1024px" width="90%">
        <v-card v-if="selectedSession" :color="backgroundColour">
          <v-img
            :aspect-ratio="16 / 9"
            width="100%"
            :src="selectedSession.thumbnailUri"
          />
          <div class="dialog-overlay">
            <div class="dialog-close">
              <v-btn
                class="ma-1"
                color="white"
                small
                outlined
                fab
                @click.stop="dialog = false"
              >
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </div>
            <div class="dialog-title">
              <v-btn
                v-if="sessionActive(selectedSession)"
                :dark="defaultButtonColour ? dark(defaultButtonColour) : true"
                :light="defaultButtonColour ? light(defaultButtonColour): false"
                :color="defaultButtonColour ? defaultButtonColour : '#1976d2'"
                class="ma-1"
                @click.stop="sessionSelected(selectedSession)"
              >
                <v-icon
                  :dark="defaultButtonColour ? dark(defaultButtonColour) : true"
                  :light="defaultButtonColour ? light(defaultButtonColour): false"
                  left> mdi-play
                </v-icon>
                {{ $t('common.Launch') }}
              </v-btn>
              <v-btn
                class="ml-2"
                color="white"
                small
                outlined
                fab
                @click.stop="bookmarkExecute(selectedSession)"
              >
                <v-icon v-if="isABookmark(selectedSession.id)">
                  mdi-bookmark-check
                </v-icon>
                <v-icon v-else> mdi-bookmark-plus-outline </v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
        <SessionDetails v-if="selectedSession" :session="selectedSession" />
      </v-dialog>
    </v-row>
  </div>
</template>

<script lang="ts">
import Background from '@/components/Background.vue';
import HomeVodSessionGroup from '@/components/HomeVodSessionGroup.vue';
import SessionDetails from '@/components/SessionDetails.vue';
import { AppInsightsLogger } from '@/services/appInsightsLogger';
import {
  SessionActions,
  SessionGetters,
  HomeVodItems,
  SessionItem,
} from '@/store/session/types';
import { Getter, Action } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '@/store/bookmark/types';
import { PlatformActions, PlatformGetters } from '../store/platform/types';
import { isLight, isDark, lightOrDark } from '../utils/lightordark';

const bookmarkNamespace = 'bookmark';
const sessionNamespace = 'session';
const platformNamespace = 'platform';

@Component({
  components: {
    Background,
    SessionDetails,
    HomeVodSessionGroup,
  },
})
export default class HomeVod extends Vue {
  /* PUBLIC PROPERTIES */

  /* PRIVATE PROPERTIES */
  private items: HomeVodItems | null | undefined = null;
  private dialog = false;

  /* VUEX GETTERS */
  // Bookmark Getters
  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string) => boolean;
  @Getter(SessionGetters.LOADING_SESSIONS, { namespace: sessionNamespace })
  private loading!: boolean;

  // Platform Getters
  @Getter(PlatformGetters.DEFAULT_BUTTON_COLOUR, {
    namespace: platformNamespace,
  })
  private defaultButtonColour!: string;

  // Session Getters
  @Getter(SessionGetters.SELECTED_SESSION, { namespace: sessionNamespace })
  private selectedSession!: SessionItem;

  /* VUEX ACTIONS */
  // Bookmark Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  // Session Actions
  @Action(SessionActions.SET_LOADING_SESSIONS, { namespace: sessionNamespace })
  private setLoadingSessions!: (val: boolean) => void;

  @Action(SessionActions.LOAD_SELECTED_SESSION, { namespace: sessionNamespace })
  private loadSelectedSession!: ({
    isMod,
    noq,
    sessionId,
  }: {
    isMod: boolean;
    noq: boolean;
    sessionId: string;
  }) => Promise<boolean>;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get cssVars(): any {
    let col = this.items?.backgroundColour
      ? this.items.backgroundColour
      : '#141414';
    let res = {
      '--image-bottom-gradient': `linear-gradient(
          to bottom,
          ${this.hexToRGB(col, 0.01)} 0,
          ${this.hexToRGB(col, 0.15)} 15%,
          ${this.hexToRGB(col, 0.35)} 29%,
          ${this.hexToRGB(col, 0.58)} 44%,
          ${this.hexToRGB(col)} 68%,
          ${this.hexToRGB(col)} 100%
        )`,
      '--background-colour': `${col}`,
    };
    return res;
  }

  get textColour(): string {
    if (this.items && this.items.textColour) {
      return this.items.textColour;
    }
    return '#030303';
  }

  get backgroundColour(): string {
    if (this.items && this.items.backgroundColour) {
      return this.items.backgroundColour;
    }
    return '#141414';
  }

  get pinnedSessionButtonSize(): Record<string, unknown> {
    const size = {
      xs: 'small',
      sm: 'small',
      md: 'large',
      lg: 'large',
      xl: 'x-large',
    }[this.$vuetify.breakpoint.name];
    return size ? { [size]: true } : {};
  }

  get pinnedSession(): SessionItem | undefined {
    return this.items?.pinnedSession;
  }

  /* PRIVATE METHODS*/
  private dark(color: string): boolean {
    return isDark(color);
  }

  private light(color: string): boolean {
    return isLight(color);
  }

  private async beforeMount() {
    await this.loadData();
  }

  private bookmarkExecute(s: SessionItem | null) {
    if (!s) return;
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const bi = this.getBookmark(s.id);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      let t = '';
      let et = '';
      let eu = '';
      t = BookmarkType.VIDEO;
      et = BookmarkEntityType.SESSION;
      eu = `/${eventCode}/session/${s.id}`;

      const bm: BookmarkItem = {
        end: s.end,
        entityId: s.id,
        entityType: et,
        type: t,
        entityUri: eu,
        start: s.start,
        title: s.title,
      };
      this.bookmarkItem(bm);
    }
  }

  private async loadData(): Promise<void> {
    this.setLoadingSessions(true);
    const platformId = sessionStorage.getItem('platformId') ?? '';

    try {
      const res = await Vue.$http.get<HomeVodItems>(
        `/api/platform/${platformId}/sessions/homevod`,
      );
      this.items = res.data;
    } catch (e: any) {
      AppInsightsLogger.logError('HomeVod - loadData failed', undefined, true);
      AppInsightsLogger.logException(e, false);
    }
    this.setLoadingSessions(false);
  }

  private hexToRGB(hex: string, alpha = 0): string {
    if (hex.indexOf('#') > -1) {
      hex = hex.substring(1);
    }
    let r: number, g: number, b: number;

    if (hex.length == 3) {
      r = parseInt(hex.slice(0, 1) + hex.slice(0, 1), 16);
      g = parseInt(hex.slice(1, 2) + hex.slice(1, 2), 16);
      b = parseInt(hex.slice(2, 3) + hex.slice(2, 3), 16);
    } else {
      r = parseInt(hex.slice(0, 2), 16);
      g = parseInt(hex.slice(2, 4), 16);
      b = parseInt(hex.slice(4, 6), 16);
    }

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }

  private async showMoreInfo(session: SessionItem | undefined) {
    if (!session) return;
    await this.loadSelectedSession({
      isMod: false,
      sessionId: session.id,
      noq: true,
    });
    this.dialog = true;
  }

  private async openNewTab(url: string | undefined) {
    if (url && (url.startsWith('https:') || url.startsWith('http:'))) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else if (url) {
      await this.$router.push(url);
    }
  }

  private sessionActive(session: SessionItem | null): boolean {
    if (!session) return false;
    if (
      this.validVideoIFrameUri(session.videoIFrameUri) ||
      (session.isExternalContentSession && session.externalContentUri)
    ) {
      return true;
    }
    return false;
  }

  private async sessionSelected(session: SessionItem | undefined | null) {
    if (!session) return;
    if (session.isExternalContentSession) {
      this.openNewTab(session.externalContentUri);
    } else if (this.sessionActive(session)) {
      if (session.isSession) {
        const sessionId = session.id;
        await this.$router.push({
          name: 'session',
          params: { sessionId },
        });
      }
    }
  }

  private validVideoIFrameUri(uri?: string): boolean {
    if (uri && uri.toLowerCase().startsWith('https:')) {
      return true;
    }
    return false;
  }
}
</script>

<style scoped>
.zero {
  z-index: 0;
}
.one {
  z-index: 1;
  position: relative;
  outline: 0;
}
.pinned-container {
  display: block;
  z-index: 1;
  position: relative;
}

.session-info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.session-info .session-title {
  position: absolute;
  top: 8vw;
  left: 4%;
  width: 90%;
  max-width: 1000px;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.session-title-text {
  font-size: calc(12px + 0.9vw) !important;
  line-height: calc(16px + 0.9vw) !important;
}

.dialog-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  background: linear-gradient(to top, #181818, transparent 50%);
}

.dialog-overlay .dialog-title {
  position: absolute;
  width: 40%;
  left: 3em;
  bottom: 5%;
}

.dialog-overlay .dialog-close {
  position: absolute;
  top: 0;
  right: 0;
}

.pinned-container .pinned-row {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.pinned-row {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  margin-bottom: 20px;
  background-color: var(--background-colour);
  position: relative;
  padding-bottom: 30%;
}

.pinned-row .pinned {
  position: absolute;
  background-color: var(--background-colour);
  width: 100%;
  height: 56.25vw;
  z-index: 0;
}

.pinned-row .pinned .hero-image-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.pinned-row .pinned .hero-image-wrapper .static-image {
  position: absolute;
  background-position: center center;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
  -o-transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
  -moz-transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
  transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
}

.pinned-row .pinned .hero-image-wrapper .image-layer {
  z-index: 5;
}

.pinned-row .pinned .hero-image-wrapper .image-layer img {
  border: 0;
}

.pinned-row .pinned .hero-image-wrapper .hero-vignette {
  background-image: var(--image-bottom-gradient);
  background-size: 100% 100%;
  background-position: 0 top;
  background-repeat: repeat-x;
  background-color: transparent;
  width: 100%;
  height: 56.25vw;
  top: auto;
  bottom: -1px;
  opacity: 1;
}

.pinned-row .pinned .hero-image-wrapper .vignette-layer {
  z-index: 8;
  position: absolute;
  left: 0;
  right: 0;
}
</style>
