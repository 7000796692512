<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title>
        Sessions
        <v-btn
          class="ml-2"
          @click="$router.push({ name: 'addsession' })"
          outlined
          small
        >
          <v-icon>mdi-plus</v-icon>
          Add
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="sessionHeaders"
        :items="sessionItems"
        single-expand
        item-key="id"
        show-expand
        :search="search"
        :loading="loading"
        loading-text="Loading... Please wait"
        class="elevation-1"
        sort-by="[start, roomOrder]"
        :items-per-page="100"
        :footer-props="footerProps"
      >
        <template v-slot:top>
          <v-container fluid>
            <v-row class="ma-0 pa-0" dense>
              <v-spacer></v-spacer>
              <v-col cols="3">
                <v-select
                  :items="sessionDates"
                  v-model="dateFilterValue"
                  label="Date"
                  single-line
                  hide-details
                  clearable
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="sessionRooms"
                  v-model="roomFilterValue"
                  label="Room"
                  single-line
                  hide-details
                  clearable
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:[`item.start`]="{ item }">
          {{ formatLongDate(item.start, item.end) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-4" @click="editSessionItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="handleSessionDelete(item)"> mdi-delete </v-icon>
        </template>

        <template v-slot:expanded-item="{ item }">
          <v-card class="ma-3" flat color="transparent">
            <v-row dense>
              <v-col cols="12">
                <v-card outlined>
                  <v-data-table
                    dense
                    :items="item.presentations"
                    sort-by="order"
                    :headers="presentationHeaders"
                    hide-default-footer
                    :items-per-page="-1"
                  >
                    <template v-slot:[`item.start`]="{ item }">
                      {{ formatLongDate(item.start, item.end) }}
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon
                        small
                        class="mr-4"
                        @click="editPresentationItem(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="handlePresenationDelete(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn @click="addPresentationItem(item)" outlined small>
                  <v-icon>mdi-plus</v-icon>
                  Add
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-data-table>
    </v-card>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        persistent
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{
              sessionEditing ? 'Edit Session' : 'Edit Presentation'
            }}</v-toolbar-title>
          </v-toolbar>
          <add-edit-session
            v-if="sessionEditing"
            :item="sessionEditing"
            @close="handleSessionClose"
            @cancel="handleCancel"
          ></add-edit-session>
          <add-edit-presentation
            v-if="presentationEditing"
            :item="presentationEditing"
            @close="handlePresentationClose"
            @cancel="handleCancel"
          ></add-edit-presentation>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import AddEditPresentation from '../../components/AddEditPresentation.vue';
import AddEditSession from '../../components/AddEditSession.vue';
import moment from 'moment';
import {
  ProgrammePresentationItem,
  ProgrammeSessionItem,
} from '../../models/Programme';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../../services/appInsightsLogger';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    AddEditPresentation,
    AddEditSession,
  },
})
export default class SessionList extends Vue {
  /* PUBLIC PROPERTIES */
  /* PRIVATE PROPERTIES */
  private loading: boolean = true;
  private loaded: boolean = false;
  private sessionEditing: ProgrammeSessionItem | null = null;
  private presentationEditing: ProgrammePresentationItem | null = null;
  private dialog: boolean = false;
  private dateFilterValue = null;
  private roomFilterValue = null;
  private search: string = '';
  private sessionItems: ProgrammeSessionItem[] = [];
  private sessionHeaders = [
    { text: 'Session Title', value: 'title' },
    { text: 'Room', value: 'roomName', filter: this.RoomFilter },
    { text: 'Start/End', value: 'start', filter: this.DateFilter },
    { text: '', value: 'actions', sortable: false },
    { text: '', value: 'data-table-expand' },
  ];
  private presentationHeaders = [
    { text: 'Order', value: 'order' },
    { text: 'Presentation Title', value: 'title' },
    { text: 'Speaker', value: 'speakerName' },
    { text: 'Start/End', value: 'start' },
    { text: '', value: 'actions', sortable: false },
  ];
  private footerProps = {
    itemsPerPageOptions: [100, 200, 500, -1],
  };

  /* VUEX GETTERS */
  /* VUEX ACTIONS */
  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */
  get sessionDates(): string[] {
    if (!this.sessionItems || this.sessionItems.length < 1) {
      return [];
    } else {
      return [
        ...new Set(
          this.sessionItems.map((d) => {
            return moment(d.start)
              .locale(this.$i18n.locale)
              .format('DD/MM/YYYY');
          }),
        ),
      ];
    }
  }

  get sessionRooms(): string[] {
    return [
      ...new Set(
        this.sessionItems.map((d) => {
          if (d.roomName) {
            return d.roomName;
          } else {
            return 'No room'; // TODO(david): translate
          }
        }),
      ),
    ];
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private async mounted() {
    await this.loadSessions();
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/
  private addPresentationItem(item: ProgrammeSessionItem) {
    this.sessionEditing = null;
    this.presentationEditing = {
      id: '',
      start: item.start,
      end: item.end,
      sessionId: item.id,
      order: 0,
    };
    this.dialog = true;
  }

  private editSessionItem(item: ProgrammeSessionItem) {
    this.presentationEditing = null;
    this.sessionEditing = item;
    this.dialog = true;
  }

  private editPresentationItem(item: ProgrammePresentationItem) {
    this.sessionEditing = null;
    this.presentationEditing = item;
    this.dialog = true;
  }

  private formatLongDate(start: Date, end: Date) {
    let s = '';
    let e = '';
    if (start) {
      s = moment(start).locale(this.$i18n.locale).format('ddd DD MMM HH:mm');
    }

    if (end) {
      e = moment(end).locale(this.$i18n.locale).format('HH:mm');
    }
    return `${s} - ${e}`;
  }

  // private formatStartEnd(start: Date, end: Date) {
  //   const s = moment(start).locale(this.$i18n.locale).format('HH:mm');
  //   const e = moment(end).locale(this.$i18n.locale).format('HH:mm');
  //   return `${s}-${e}`;
  // }

  private handleCancel() {
    this.sessionEditing = null;
    this.presentationEditing = null;
    this.dialog = false;
  }

  private handlePresentationClose({
    item,
    refresh,
  }: {
    item: ProgrammePresentationItem;
    refresh: boolean;
  }) {
    const session = this.sessionItems.find((i) => i.id === item.sessionId);

    if (refresh) {
      this.loadSessions();
    } else if (session && session) {
      if (!session.presentations) {
        session.presentations = [];
      }

      const index = session.presentations.findIndex((i) => i.id === item.id);

      if (index > -1) {
        session.presentations.splice(index, 1, item);
      } else {
        session.presentations.push(item);
      }
    }

    this.presentationEditing = null;
    this.dialog = false;
  }

  private handleSessionClose(item: ProgrammeSessionItem) {
    const index = this.sessionItems.findIndex((i) => i.id === item.id);

    if (index > -1) {
      this.sessionItems.splice(index, 1, item);
    } else {
      this.sessionItems.push(item);
    }

    this.sessionEditing = null;
    this.dialog = false;
  }

  private async handlePresenationDelete(item: ProgrammePresentationItem) {
    const res = await this.$dialog.confirm({
      title: 'Delete presentation?',
      text: 'Are you sure you want to delete this presentation?',
      actions: {
        false: 'No',
        true: 'Yes',
      },
    });

    if (res) {
      const platformId = sessionStorage.getItem('platformId') ?? '';
      try {
        let url = `/api/v2/platform/${platformId}/programme/presentations/${item.id}`;
        const res = await Vue.$http.delete(url);
        var session = this.sessionItems.find((i) => i.id === item.sessionId);
        if (session && session.presentations) {
          const index = session.presentations.findIndex(
            (i) => i.id === item.id,
          );
          if (index > -1) {
            session.presentations.splice(index, 1);
          }
        }
      } catch (e: any) {
        AppInsightsLogger.logError(
          'Programme Session List - created failed',
          undefined,
          true,
        );
        AppInsightsLogger.logException(e, false);
      }
    }
  }

  private async handleSessionDelete(item: ProgrammeSessionItem) {
    const res = await this.$dialog.confirm({
      title: 'Delete session?',
      text: 'Are you sure you want to delete this session?',
      actions: {
        false: 'No',
        true: 'Yes',
      },
    });

    if (res) {
      const platformId = sessionStorage.getItem('platformId') ?? '';
      try {
        let url = `/api/v2/platform/${platformId}/programme/sessions/${item.id}`;
        const res = await Vue.$http.delete(url);
        const index = this.sessionItems.findIndex((i) => i.id === item.id);
        if (index > -1) {
          this.sessionItems.splice(index, 1);
        }
      } catch (e: any) {
        AppInsightsLogger.logError(
          'Programme Session List - created failed',
          undefined,
          true,
        );
        AppInsightsLogger.logException(e, false);
      }
    }
  }

  private async loadSessions() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      let url = `/api/v2/platform/${platformId}/programme/sessions`;
      const pData = await Vue.$http.get<ProgrammeSessionItem[]>(url);
      if (pData.data) {
        this.sessionItems = pData.data;
      }
      this.loaded = true;
      this.loading = false;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Programme Session List - created failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private DateFilter(value, search, item) {
    if (!this.dateFilterValue) {
      return true;
    }
    return (
      moment(item.start).locale(this.$i18n.locale).format('DD/MM/YYYY') ===
      this.dateFilterValue
    );
  }

  private RoomFilter(value, search, item) {
    if (!this.roomFilterValue) {
      return true;
    }
    return value === this.roomFilterValue;
  }
}
</script>

<style scoped></style>
