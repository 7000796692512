<template>
  <div style="height: 100%">
    <v-card class="pa-4">
      <v-card-title>
        <h1 class="text-h5">{{ $t('menuItems.reportItems.messages') }}</h1>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-row v-if="messageSummary">
          <v-col cols="12" md="3" sm="6">
            <v-card>
              <v-card-title class="justify-center text-subtitle-1 mb-0 pb-0">
                All Chat:
              </v-card-title>
              <v-card-text class="text-center mt-0 pt-0 text-h6 primary--text">
                {{ messageSummary.chatMessages }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-card>
              <v-card-title class="justify-center text-subtitle-1 mb-0 pb-0">
                All Questions:
              </v-card-title>
              <v-card-text class="text-center mt-0 pt-0 text-h6 primary--text">
                {{ messageSummary.sessionQuestions }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-card>
              <v-card-title class="justify-center text-subtitle-1 mb-0 pb-0">
                Private messages :
              </v-card-title>
              <v-card-text class="text-center mt-0 pt-0 text-h6 primary--text">
                {{ messageSummary.privateMessages }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="sessions">
          <v-col cols="12" md="8" sm="12">
            <v-form>
              <v-autocomplete
                v-model="entity"
                :items="sessions"
                :loading="messageSummaryLoading"
                item-text="title"
                item-value="id"
                dense
              >
              </v-autocomplete>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="entityMessageSummary">
          <v-col cols="12" md="3" sm="6">
            <v-card>
              <v-card-title class="justify-center text-subtitle-1 mb-0 pb-0">
                Chat:
              </v-card-title>
              <v-card-text class="text-center mt-0 pt-0 text-h6 primary--text">
                {{ entityMessageSummary.chatMessages }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-card>
              <v-card-title class="justify-center text-subtitle-1 mb-0 pb-0">
                Questions:
              </v-card-title>
              <v-card-text class="text-center mt-0 pt-0 text-h6 primary--text">
                {{ entityMessageSummary.sessionQuestions }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card v-if="entityMessageData">
        <v-card-title>
          <v-text-field
            v-model="entityMessageSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn tile icon @click="downloadItems"
            ><v-icon>mdi-cloud-download-outline</v-icon></v-btn
          >
        </v-card-title>
        <v-data-table
          :headers="entityMessageData.headers"
          :items="entityMessageData.items"
          :search="entityMessageSearch"
          :loading="entityMessagesLoading"
        ></v-data-table>
      </v-card>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import xlsx from 'xlsx';
import { AppInsightsLogger } from '../../services/appInsightsLogger';
import {
  DataTable,
  MessageItem,
  MessagesSummary,
} from '@/models/Reports/DataTable';
import { EntityListItem } from '@/models/Reports/EntityListItem';

@Component({})
export default class UniqueUsers extends Vue {
  private messageSummary: MessagesSummary | null = null;
  private sessions: EntityListItem[] | null = null;
  private entityMessageSummary: MessagesSummary | null = null;
  private entityMessageData: DataTable<MessageItem> | null = null;
  private messageSummaryLoading = false;
  private entityMessagesLoading = false;
  private entityMessageSearch = '';
  private selectedEntity = '';

  get entity(): string {
    return this.selectedEntity;
  }
  set entity(value: string) {
    this.selectedEntity = value;
    this.selectedEntityChanged();
  }

  private async created() {
    this.messageSummary = null;
    this.sessions = null;
    this.entityMessageData = null;
    this.entityMessageSummary = null;
    this.entityMessageSearch = '';
  }

  private downloadItems() {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const temp = this.sessions!.find(
      (ti: EntityListItem) => ti.id === this.selectedEntity,
    );
    if (!temp) {
      return;
    }
    const title = `${eventCode}_messages_${temp.title}`;

    if (title && this.entityMessageData && this.entityMessageData.items) {
      const fileName = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      const workSheet = xlsx.utils.json_to_sheet(this.entityMessageData.items);
      const wb = xlsx.utils.book_new();

      xlsx.utils.book_append_sheet(wb, workSheet, 'export');
      xlsx.writeFile(wb, `${fileName}.xlsx`);
    }
  }

  private async mounted() {
    this.messageSummaryLoading = true;
    this.messageSummary = null;
    this.sessions = null;
    this.entityMessageData = null;
    this.entityMessageSummary = null;
    this.entityMessageSearch = '';
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<DataTable<EntityListItem>>(
        `/api/platform/${platformId}/report/messages`,
      );
      this.messageSummary = res.data.messagesSummary;
      this.sessions = res.data.items;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Reports - get messages summary data failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.messageSummaryLoading = false;
  }

  private async selectedEntityChanged() {
    this.entityMessagesLoading = true;
    this.entityMessageData = null;
    this.entityMessageSummary = null;
    this.entityMessageSearch = '';

    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<DataTable<MessageItem>>(
        `/api/platform/${platformId}/report/${this.selectedEntity}/messages`,
      );
      this.entityMessageData = res.data;
      this.entityMessageSummary = res.data.messagesSummary;
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - selectedEntityChanged failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
    this.entityMessagesLoading = false;
  }
}
</script>

<style scoped>
.smaller-title {
  font-size: 1rem;
}
</style>
