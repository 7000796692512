<template>
  <div>
    <v-navigation-drawer
      v-if="!$vuetify.breakpoint.mobile"
      v-model="drawerState"
      :color="navBarBackgroundColour"
      :mini-variant.sync="mini"
      hide-overlay
      persistent
      enable-resize-watcher
      :expand-on-hover="!pinned && !$vuetify.breakpoint.mobile"
      mini-variant-width="50"
      clipped
      app
    >
      <v-list v-if="loaded">
        <v-btn
          v-if="!$vuetify.breakpoint.mobile && !mini"
          align="end"
          class="float-right"
          icon
          absolute
          right
          top
          style="z-index: 9999"
          @click.stop="invertPinned()"
        >
          <v-icon :color="navBarTextColour">
            {{
              pinned ? 'mdi-pin mdi-rotate-45' : 'mdi-pin-outline mdi-rotate-45'
            }}
          </v-icon>
        </v-btn>
        <v-list-item-group v-model="model">
          <template v-for="(item, i) in items">
            <v-list-item
              v-if="!item || !item.subItems || item.subItems.length < 1"
              :key="i"
              exact
              :value="item.link"
              active-class="border"
              @click.stop="handleItem(item)"
            >
              <v-list-item-action>
                <v-icon :color="navBarTextColour">{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title :style="{ color: navBarTextColour }">
                  <span v-if="item.title_en && locale === 'en'">
                    {{ item.title_en }}
                  </span>
                  <span v-else-if="item.title_fr && locale === 'fr'">
                    {{ item.title_fr }}
                  </span>
                  <span v-else>{{ $t(item.title) }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="item.loginRequired && !isAuthenticated">
                <v-icon small :color="navBarTextColour">lock</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-group
              v-else-if="item.subItems && item.subItems.length > 0"
              :key="i"
              :color="navBarTextColour"
            >
              <template #appendIcon>
                <v-icon :color="navBarTextColour">$expand</v-icon>
              </template>
              <template #prependIcon>
                <v-icon :color="navBarTextColour">{{ item.icon }}</v-icon>
              </template>
              <template #activator>
                <v-list-item-title :style="{ color: navBarTextColour }">
                  <span v-if="item.title_en && locale === 'en'">
                    {{ item.title_en }}
                  </span>
                  <span v-else-if="item.title_fr && locale === 'fr'">
                    {{ item.title_fr }}
                  </span>
                  <span v-else>{{ $t(item.title) }}</span>
                </v-list-item-title>
              </template>
              <template v-for="(subItem, s) in item.subItems">
                <v-list-item
                  :key="s"
                  exact
                  :value="subItem.link"
                  active-class="border"
                  @click.stop="handleItem(subItem)"
                >
                  <v-list-item-icon>
                    <v-icon :color="navBarTextColour">{{
                      subItem.icon
                    }}</v-icon>
                  </v-list-item-icon>
                  <span
                    v-if="subItem.title_en && locale === 'en'"
                    :style="{ color: navBarTextColour }"
                  >
                    {{ subItem.title_en }}
                  </span>
                  <span
                    v-else-if="subItem.title_fr && locale === 'fr'"
                    :style="{ color: navBarTextColour }"
                  >
                    {{ subItem.title_fr }}
                  </span>
                  <span
                    v-else-if="subItem.title"
                    :style="{ color: navBarTextColour }"
                  >
                    {{ $t(subItem.title) }}
                  </span>
                  <span v-else :style="{ color: navBarTextColour }">
                    {{ $t(item.title) }}
                  </span>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
        </v-list-item-group>
      </v-list>
      <template #append>
        <v-card
          :class="loaded && showAds ? 'mx-auto mb-2' : ' mx-auto mb-2 d-none'"
          elevation="24"
          max-width="200"
        >
          <v-carousel
            cycle
            :show-arrows="false"
            hide-delimiters
            hide-delimiter-background
            height="200"
          >
            <v-carousel-item
              v-for="(ad, i) in ads"
              :key="i"
              :src="ad.imageUri"
              link
              style="cursor: pointer"
              @click.stop="adClick(ad)"
            ></v-carousel-item>
          </v-carousel>
        </v-card>
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-else
      v-model="drawerState"
      :color="navBarBackgroundColour"
      app
      clipped
    >
      <v-list v-if="loaded">
        <v-list-item-group v-model="model">
          <template v-for="(item, i) in items">
            <v-list-item
              v-if="!item || !item.subItems || item.subItems.length < 1"
              :key="i"
              exact
              :value="item.link"
              active-class="border"
              @click="handleItem(item)"
            >
              <v-list-item-action>
                <v-icon :color="navBarTextColour">{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title :style="{ color: navBarTextColour }">
                  <span v-if="item.title_en && locale === 'en'">
                    {{ item.title_en }}
                  </span>
                  <span v-else-if="item.title_fr && locale === 'fr'">
                    {{ item.title_fr }}
                  </span>
                  <span v-else>{{ $t(item.title) }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="item.loginRequired && !isAuthenticated">
                <v-icon small :color="navBarTextColour">lock</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-group
              v-else-if="item.subItems && item.subItems.length > 0"
              :key="i"
              :color="navBarTextColour"
            >
              <template #appendIcon>
                <v-icon :color="navBarTextColour">$expand</v-icon>
              </template>
              <template #prependIcon>
                <v-icon :color="navBarTextColour">{{ item.icon }}</v-icon>
              </template>
              <template #activator>
                <v-list-item-title :style="{ color: navBarTextColour }">
                  <span v-if="item.title_en && locale === 'en'">
                    {{ item.title_en }}
                  </span>
                  <span v-else-if="item.title_fr && locale === 'fr'">
                    {{ item.title_fr }}
                  </span>
                  <span v-else>{{ $t(item.title) }}</span>
                </v-list-item-title>
              </template>
              <template v-for="(subItem, s) in item.subItems">
                <v-list-item
                  :key="s"
                  exact
                  :value="subItem.link"
                  active-class="border"
                  @click="handleItem(subItem)"
                >
                  <v-list-item-icon>
                    <v-icon :color="navBarTextColour">{{
                      subItem.icon
                    }}</v-icon>
                  </v-list-item-icon>
                  <span
                    v-if="subItem.title_en && locale === 'en'"
                    :style="{ color: navBarTextColour }"
                  >
                    {{ subItem.title_en }}
                  </span>
                  <span
                    v-else-if="subItem.title_fr && locale === 'fr'"
                    :style="{ color: navBarTextColour }"
                  >
                    {{ subItem.title_fr }}
                  </span>
                  <span
                    v-else-if="subItem.title"
                    :style="{ color: navBarTextColour }"
                  >
                    {{ $t(subItem.title) }}
                  </span>
                  <span v-else :style="{ color: navBarTextColour }">
                    {{ $t(item.title) }}
                  </span>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AdClickLog } from '../models/AdClickLog';
import { AdItem } from '../models/AdItem';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { ModuleItem } from '../models/ModuleItem';
import { PlatformActions, PlatformGetters } from '../store/platform/types';
import { AuthGetters } from '@/store/auth/types';
import { PlatformUserGetters } from '@/store/platformUser/types';
import { NavigationActions, NavigationGetters } from '@/store/navigation/types';

const authNamespace = 'auth';
const navigationNamespace = 'navigation';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component
export default class NavDrawer extends Vue {
  /* PUBLIC PROPERTIES */

  /* PRIVATE PROPERTIES */
  private eventCode = sessionStorage.getItem('eventCode');
  private ads: AdItem[] = [];
  private items: ModuleItem[] = [];
  private loaded = false;
  private mini = true;
  private model = 1;
  private pinned = false;

  /* VUEX GETTERS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Navigation Getters
  @Getter(NavigationGetters.NAVIGATION_DRAWER, {
    namespace: navigationNamespace,
  })
  private drawer!: boolean;

  @Action(NavigationActions.NAVIGATION_INVERT_DRAWER, {
    namespace: navigationNamespace,
  })
  private invertDrawer!: () => void;

  // Platform Getters
  @Getter(PlatformGetters.NAV_BAR_MINI, {
    namespace: platformNamespace,
  })
  private navBarMini!: boolean;

  @Getter(PlatformGetters.NAV_BAR_TEXT_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarTextColour!: string;

  @Getter(PlatformGetters.NAV_BAR_BACKGROUND_COLOUR, {
    namespace: platformNamespace,
  })
  private navBarBackgroundColour!: string;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  /* VUEX ACTIONS */
  // Navigation Actions
  @Action(NavigationActions.SET_MOBILE_PAGE_TITLE, {
    namespace: navigationNamespace,
  })
  private setMobilePageTitle!: (title: string) => void;

  // Platform Actions
  @Action(NavigationActions.NAVIGATION_SET_DRAWER, {
    namespace: navigationNamespace,
  })
  private setDrawer!: (value: boolean) => void;

  @Action(PlatformActions.SPONSOR_AD_LOG, { namespace: platformNamespace })
  private adClickLog!: (adClickLog: AdClickLog) => void;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */
  get drawerState(): boolean {
    return this.drawer;
  }
  set drawerState(state: boolean) {
    if (state !== this.drawer) {
      this.invertDrawer();
    }
  }

  get locale(): string {
    return this.$i18n.locale;
  }

  get showAds(): boolean {
    if (this.pinned) {
      return true;
    } else {
      return !this.mini;
    }
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}

  private async created() {
    this.loaded = false;
    const platformId = sessionStorage.getItem('platformId') ?? '';

    if (platformId) {
      try {
        const mods = await Vue.$http.get<ModuleItem[]>(
          `/api/platform/${platformId}/modules`,
        );

        // if (this.$vuetify.breakpoint.smAndDown) {
        //   mods.data.filter((d: ModuleItem) => {
        //     if (
        //       d.title.includes('home') ||
        //       d.title.includes('vod') ||
        //       d.title.includes('livenow') ||
        //       d.title.includes('posters') ||
        //       d.title.includes('participantItems') ||
        //       d.title.includes('exhibition')
        //     ) {
        //       this.items.push(d);
        //     }
        //   });
        // } else {
        //   }
        this.items = mods.data;

        const ads = await Vue.$http.get(
          `/api/platform/${platformId}/sponsors/sda?datype=sidebar`,
        );
        this.ads = ads.data;
      } catch (e: any) {
        AppInsightsLogger.logError('Player - created failed', undefined, true);
        AppInsightsLogger.logException(e, false);
      }
      if (!this.$vuetify.breakpoint.mobile) {
        this.setDrawer(true);
      }
      this.loaded = true;
    }
  }

  private beforeMount() {
    this.mini = this.navBarMini;
    this.pinned = !this.navBarMini;
  }
  // private mounted() {}
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private async adClick(ad: AdItem) {
    if (ad.link) {
      const log: AdClickLog = {
        entityId: ad.id,
        link: ad.link,
        title: ad.title,
        type: ad.type,
        userId: this.currentUserId,
      };
      await this.adClickLog(log);
      await this.openNewTab(ad.link);
    }
  }

  private async handleItem(item: ModuleItem) {
    if (item[`Title_${this.$i18n.locale}`]) {
      this.setMobilePageTitle(item[`Title_${this.$i18n.locale}`]);
    } else {
      this.setMobilePageTitle(this.$t(item.title) as string);
    }
    if (item) {
      const eventCode = sessionStorage.getItem('eventCode') ?? '';
      let url = '';
      if (item.custom) {
        const eventCode = sessionStorage.getItem('eventCode') ?? '';
        url = `/${eventCode}/${item.parent}/custom/${item.id}`;
        await this.$router.push(url);
      } else if (item.link) {
        await this.openNewTab(item.link);
      } else {
        url = `/${eventCode}/${item.parent}`;
        await this.openNewTab(url);
      }
    }
  }

  private invertPinned() {
    this.pinned = !this.pinned;
    setTimeout(() => {
      if (this.mini) {
        this.mini = false;
      }
    }, 1);
  }

  private async openNewTab(url: string) {
    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      if (this.$route.fullPath === url) return;
      if (this.$route.query && !this.$route.fullPath.includes('?')) {
        this.$router.replace({ path: url, query: {} });
      } else {
        await this.$router.push(url);
      }
    }
  }
}
</script>

<style scoped>
.border::before {
  background-color: white;
  opacity: 0.12;
}
.border::after {
  background-color: white;
  opacity: 0.12;
}
</style>
