<script lang="ts">
import { TimeSliceCount } from '@/models/Reports/Summary';
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { Line, mixins } from 'vue-chartjs';
import Chart from 'chart.js';
import moment from 'moment';

@Component({
  extends: Line,
  mixins: [mixins.reactiveProp],
})
export default class TimeSliceCountChart extends Mixins(
  mixins.reactiveProp,
  Line,
) {
  /* PUBLIC PROPERTIES */
  @Prop()
  public title!: string;

  /* PRIVATE PROPERTIES */
  private options: Chart.ChartOptions = {
    //Chart.js options
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: true,
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          time: {
            unit: 'minute',
            displayFormats: {
              minute: 'DD-MM HH:mm',
            },
            parser: function (time) {
              return moment.utc(time).local();
            },
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 1,
            maxRotation: 0,
            minRotation: 0,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    legend: {
      display: true,
    },
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: this.title,
    },
  };

  /* VUEX GETTERS */
  /* VUEX ACTIONS */
  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */
  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private mounted() {
    if (this.chartData) {
      this.renderChart(this.chartData, this.options);
    }
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/
}
</script>

<style scoped></style>
