<template>
  <!-- <v-container fluid> -->
  <v-card flat tile>
    <v-row no-gutters>
      <v-col align-self="start" cols="auto">
        <v-avatar
          v-if="session.sponsorThumbnailUri"
          class="profile pointer"
          color="transparent"
          size="103"
          tile
          @click.stop="adClick"
        >
          <v-img
            v-if="session.sponsorThumbnailUri"
            :src="session.sponsorThumbnailUri"
          ></v-img>
        </v-avatar>
      </v-col>
      <v-col align-self="start">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="10">
              <span class="title">{{ session.title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col align-self="start" justify-self="end" cols="2">
              <v-layout v-if="session.isLive" justify-end>
                <v-icon class="blink-me" color="red">mdi-record</v-icon>
                <span v-if="!$vuetify.breakpoint.smAndDown" class="red--text"
                  >LIVE</span
                >
              </v-layout>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!$vuetify.breakpoint.smAndDown">
          <span v-if="session.isSession">
            <span class>{{ session.type }}</span>
            <v-icon class="ml-6">mdi-calendar-clock</v-icon>
            <span class="ml-2">{{
              formatStartEnd(session.start, session.end)
            }}</span>
            <v-icon class="ml-6">mdi-google-classroom</v-icon>
            <span class="ml-2">{{ session.roomName }}</span>
          </span>
          <span v-if="session.isPresentation">
            <v-icon>mdi-account-voice</v-icon>
            <span class="subheading ml-2">{{ session.activeSpeakerName }}</span>
          </span>
          <v-icon v-if="session.keywords" class="ml-6">mdi-key-variant</v-icon>
          <span v-if="session.keywords" class="subheading ml-2">{{
            session.keywords
          }}</span>
          <span  v-if="session.externalMeetingUri">
            <v-btn
              v-if="session.externalMeetingUri"
              small
              color="blue-grey"
              class="white--text mx-3"
              @click="openNewTab(session.externalMeetingUri)"
            >
              {{ $t('session.joinMeeting') }}
              <v-icon right dark> mdi-monitor-account </v-icon>
            </v-btn>
          </span>
        </v-card-text>

      </v-col>
    </v-row>
    <v-divider
      v-if="showViews || showRatings || showLikes || session.isSession"
      class="mx-4"
    ></v-divider>
    <v-card-actions class="align-center">
      <v-btn v-if="isAuthenticated" icon @click="bookmarkExecute(session)">
        <v-icon v-if="isABookmark(session.id)">mdi-bookmark-check</v-icon>
        <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
      </v-btn>
      <span v-if="showViews">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <span class="default" v-bind="attrs" v-on="on">
              <v-icon>mdi-eye</v-icon>
              <span class="ml-2">{{ session.views }}</span>
            </span>
          </template>
          <span>{{ $t('session.Views') }}</span>
        </v-tooltip>
      </span>
      <span v-if="showRatings && isAuthenticated">
        <span class="mx-2"><v-icon>mdi-dots-vertical</v-icon></span>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <span
              :class="ratingReadonly ? 'default' : 'pointer'"
              v-bind="attrs"
              v-on="on"
            >
              <v-rating
                :value="session.rating"
                :readonly="ratingReadonly"
                color="amber"
                dense
                half-increments
                small
                class="inline"
                @input="registerSessionViewRatingExecute($event)"
              ></v-rating>
              <span
                v-if="!$vuetify.breakpoint.smAndDown"
                class="grey--text ml-2"
                >{{ session.rating ? session.rating.toFixed(1) : 0 }} ({{
                  session.numOfRatings
                }})
              </span>
            </span>
          </template>
          <span v-if="ratingReadonly">{{ $t('session.rating') }}</span>
          <span v-else>{{ $t('session.rate') }}</span>
        </v-tooltip>
      </span>
      <span v-if="showLikes && isAuthenticated">
        <span class="mx-2"><v-icon>mdi-dots-vertical</v-icon></span>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              @click="likeButtonExecute"
              v-on="on"
            >
              <v-icon v-if="liked">mdi-thumb-up</v-icon>
              <v-icon v-else>mdi-thumb-up-outline</v-icon>
            </v-btn>
          </template>
          <span v-if="liked">{{ $t('session.Unlike') }}</span>
          <span v-else>{{ $t('session.Like') }}</span>
        </v-tooltip>
      </span>
      <v-spacer></v-spacer>

      <!--
      <v-btn icon>
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>-->
      <v-btn v-if="session.isSession" text @click="show = !show">
        Presentations
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition v-if="session.isSession">
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text>
          <v-list three-line>
            <template v-for="(item, index) in session.presentationItems">
              <div :key="index">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img
                      v-if="item.speakerPhotoUri"
                      :src="item.speakerPhotoUri"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                    <span
                      :style="canNavigateToPresentation(item) ? { cursor: 'pointer' } : {}"
                      @click="navigateToPresentation(item)">
                        {{ item.title }}
                    </span>
                    </v-list-item-title>
                    <v-row dense class="pa-0 ma-0">
                      <v-col cols="6">
                        <v-list-item-subtitle>
                          <span class="font-weight-black"
                            :style="speakersModuleEnabled && !item.speakerNameOverride ? { cursor: 'pointer' } : {}"
                            @click="navigateToSpeaker(item)">
                              {{ item.speakerNameOverride ? item.speakerNameOverride : item.speakerName }}
                          </span>
                          <span v-if="item.coAuthors">, {{ item.coAuthors }}</span>
                        </v-list-item-subtitle>

                      </v-col>
                    </v-row>
                    <v-row dense class="pa-0 ma-0">
                      <v-spacer></v-spacer>
                      <v-col cols="auto" class="ma-0 pa-0" align-self="end">
                        <v-btn
                          v-if="item.presentationFileUri && allowPresentationDownload"
                          x-small
                          color="blue-grey"
                          class="white--text mr-1"
                          @click="
                            downloadFile(
                              item.presentationFileUri,
                              'presentation',
                            )
                          "
                        >
                          {{ $t('session.presentation') }}
                          <v-icon right dark> mdi-cloud-download </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="item.presentationFileUri && allowPresentationDownload && isAuthenticated"
                          x-small
                          icon
                          class="mr-1"
                          @click.stop="
                            bookmarkFileExecute(
                              $t('session.presentation'),
                              item.presentationFileUri,
                            )
                          "
                        >
                          <v-icon
                            v-if="
                              isABookmark(session.id, item.presentationFileUri)
                            "
                            >mdi-bookmark-check</v-icon
                          >
                          <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="item.posterUploadUri && allowPresentationDownload"
                          x-small
                          color="blue-grey"
                          class="white--text mr-1"
                          @click="downloadFile(item.posterUploadUri, 'poster')"
                        >
                          {{ $t('session.poster') }}
                          <v-icon right dark> mdi-cloud-download </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="item.posterUploadUri && allowPresentationDownload && isAuthenticated"
                          x-small
                          icon
                          class="mr-1"
                          @click.stop="
                            bookmarkFileExecute(
                              $t('session.poster'),
                              item.posterUploadUri,
                            )
                          "
                        >
                          <v-icon
                            v-if="isABookmark(session.id, item.posterUploadUri)"
                            >mdi-bookmark-check</v-icon
                          >
                          <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
                        </v-btn>

                        <v-btn
                          v-if="item.abstractFileUri && allowAbstractDownload"
                          x-small
                          color="blue-grey"
                          class="white--text mr-1"
                          @click="
                            downloadFile(item.abstractFileUri, 'abstract')
                          "
                        >
                          {{ $t('session.abstract') }}
                          <v-icon right dark> mdi-cloud-download </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="item.abstractFileUri && allowAbstractDownload && isAuthenticated"
                          x-small
                          icon
                          class="mr-1"
                          @click.stop="
                            bookmarkFileExecute(
                              $t('session.abstract'),
                              item.abstractFileUri,
                            )
                          "
                        >
                          <v-icon
                            v-if="isABookmark(session.id, item.abstractFileUri)"
                            >mdi-bookmark-check</v-icon
                          >
                          <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </template>
          </v-list>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
  <!-- </v-container> -->
</template>

<script lang="ts">
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AdClickLog } from '../models/AdClickLog';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import { PlatformActions, PlatformGetters } from '../store/platform/types';
import { PresentationItem, SessionActions, SessionItem } from '../store/session/types';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '../store/bookmark/types';
import { LocaleMessages } from 'vue-i18n';
import { PlatformUserGetters } from '@/store/platformUser/types';
import { AuthGetters } from '@/store/auth/types';

const authNamespace = 'auth';
const bookmarkNamespace = 'bookmark';
const sessionNamespace = 'session';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component({})
export default class SessionDetails extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public session!: SessionItem;

  /* PRIVATE PROPERTIES */
  private show = true;
  private ratingReadonly = false;
  private liked = false;
  private currentRatingValue = 0;

  /* VUEX GETTERS */
  // Bookmark Getters
  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string, entityUri?: string) => boolean;

  // Platform Getters
  @Getter(PlatformGetters.ALLOW_ABSTRACT_DOWNLOAD, {
    namespace: platformNamespace,
  })
  private allowAbstractDownload!: boolean;

  @Getter(PlatformGetters.ALLOW_PRESENTATION_DOWNLOAD, {
    namespace: platformNamespace,
  })
  private allowPresentationDownload!: boolean;

  @Getter(PlatformGetters.SHOW_VIEWS, {
    namespace: platformNamespace,
  })
  private showViews!: boolean;

  @Getter(PlatformGetters.SHOW_RATINGS, {
    namespace: platformNamespace,
  })
  private showRatings!: boolean;

  @Getter(PlatformGetters.SHOW_LIKES, {
    namespace: platformNamespace,
  })
  private showLikes!: boolean;

  @Getter(PlatformGetters.SHOW_KEYWORDS, {
    namespace: platformNamespace,
  })
  private showKeywords!: boolean;

  @Getter(PlatformGetters.SPEAKERS_MODULE_ENABLED, {
    namespace: platformNamespace,
  })
  private speakersModuleEnabled;

  // Platform user getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  /* VUEX ACTIONS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Bookmark Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  // Platform Actions
  @Action(PlatformActions.SPONSOR_AD_LOG, { namespace: platformNamespace })
  private adClickLog!: (adClickLog: AdClickLog) => Promise<void>;

  // Session Actions
  @Action(SessionActions.UPDATE_SELECTED_SESSION_RATING, {
    namespace: sessionNamespace,
  })
  private updateSelectedSessionRating!: ({
    rating,
    rates,
  }: {
    rating: number;
    rates: number;
  }) => void;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */

  /* PRIVATE METHODS*/
  private formatStartEnd(start: Date, end: Date) {
    const s = moment(start).format('DD/MM/YYYY HH:mm');
    const e = moment(end).format('HH:mm');
    return `${s}-${e}`;
  }

  private registerSessionViewRatingExecute(rating: number) {
    if (this.session.isSession) {
      this.registerSessionViewRating(this.session.id, rating);
      this.ratingReadonly = true;
    } else if (this.session.isPresentation) {
      this.registerPresentationViewRating(
        this.session.sessionId,
        this.session.id,
        rating,
      );
      this.ratingReadonly = true;
    }
  }

  private likeButtonExecute() {
    this.liked = !this.liked;
    if (this.session.isSession) {
      this.registerSessionLike(this.session.id, this.liked);
    } else if (this.session.isPresentation) {
      this.registerPresentationLike(
        this.session.sessionId,
        this.session.id,
        this.liked,
      );
    }
  }

  private created() {
    if (localStorage.getItem(this.session.id)) {
      this.ratingReadonly = true;
    }
    if (localStorage.getItem(`${this.session.id}_liked`) === 'true') {
      this.liked = true;
    }
  }

  private async registerSessionLike(
    sessionId: string,
    liked: boolean,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const data = {
        liked,
        sessionId,
        userId: this.currentUserId,
      };
      await Vue.$http.post(
        `/api/platform/${platformId}/sessions/${sessionId}/like`,
        data,
      );
      localStorage.setItem(`${sessionId}_liked`, liked.toString());
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - registerSessionLike failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async registerPresentationLike(
    sessionId: string,
    presentationId: string,
    liked: boolean,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const data = {
        liked,
        presentationId,
        sessionId,
        userId: this.currentUserId,
      };
      await Vue.$http.post(
        `/api/platform/${platformId}/sessions/${sessionId}/presentation/${presentationId}/like`,
        data,
      );

      localStorage.setItem(`${presentationId}_liked`, liked.toString());
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - registerPresentationLike failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async registerSessionViewRating(
    sessionId: string,
    rating: number,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.post(
        `/api/platform/${platformId}/sessions/${sessionId}/rate/${rating}`,
      );
      this.updateSelectedSessionRating({
        rates: res.data.rates,
        rating: res.data.rating,
      });
      localStorage.setItem(sessionId, 'true');
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - registerSessionViewRating failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async registerPresentationViewRating(
    sessionId: string,
    presentationId: string,
    rating: number,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.post(
        `/api/platform/${platformId}/sessions/${sessionId}/presentation/${presentationId}/rate/${rating}`,
      );
      this.updateSelectedSessionRating({
        rates: res.data.rates,
        rating: res.data.rating,
      });
      localStorage.setItem(presentationId, 'true');
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - registerPresentationViewRating failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async adClick() {
    if (this.session.sponsorThumbnailLinkUri) {
      const log: AdClickLog = {
        entityId: this.session.id,
        link: this.session.sponsorThumbnailLinkUri,
        title: this.session.title,
        type: 'SESSION',
        userId: this.currentUserId,
      };
      await this.adClickLog(log);
      await this.openNewTab(this.session.sponsorThumbnailLinkUri);
    }
  }

  private async openNewTab(url: string | undefined) {
    if (url && (url.startsWith('https:') || url.startsWith('http:'))) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else if (url) {
      await this.$router.push(url);
    }
  }

  private getExtension(uri: string) {
    const a = uri.split('?')[0].split('.');
    if (a.length > 0) {
      return a[a.length - 1];
    } else {
      return 'pdf';
    }
  }

  private async downloadFile(uri: string, type: string) {
    try {
      const res = await fetch(uri, {
        method: 'GET',
      });
      const url = window.URL.createObjectURL(new Blob([await res.blob()]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${type}.${this.getExtension(uri)}`);
      document.body.appendChild(link);
      link.click();
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Session Details Side - download failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private bookmarkExecute(s: SessionItem) {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const bi = this.getBookmark(s.id);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      let t = '';
      let et = '';
      let eu = '';
      if (s.isAPoster) {
        t = BookmarkType.POSTER;
        et = BookmarkEntityType.POSTER;
        eu = `/${eventCode}/session/${s.sessionId}/presentation/${s.id}`;
      } else if (s.isPresentation) {
        t = BookmarkType.VIDEO;
        et = BookmarkEntityType.PRESENTATION;
        eu = `/${eventCode}/session/${s.sessionId}/presentation/${s.id}`;
      } else {
        t = BookmarkType.VIDEO;
        et = BookmarkEntityType.SESSION;
        eu = `/${eventCode}/session/${s.id}`;
      }

      const bm: BookmarkItem = {
        end: s.end,
        entityId: s.id,
        entityType: et,
        type: t,
        entityUri: eu,
        start: s.start,
        title: s.title,
      };
      this.bookmarkItem(bm);
    }
  }

  private bookmarkFileExecute(
    title: string | LocaleMessages,
    url: string | undefined,
  ) {
    if (url) {
      const bi = this.getBookmark(this.session.id, url);
      if (bi) {
        this.unbookmarkItem(bi);
      } else {
        let et = '';
        if (this.session.isAPoster) {
          et = BookmarkEntityType.POSTER_DOCUMENT;
        } else if (this.session.isPresentation) {
          et = BookmarkEntityType.PRESENTATION_DOCUMENT;
        } else {
          et = BookmarkEntityType.SESSION_DOCUMENT;
        }

        const bm: BookmarkItem = {
          entityId: this.session.id,
          entityType: et,
          type: BookmarkType.DOCUMENT,
          entityUri: url,
          title: `${title} - ${this.session.title}`,
        };
        this.bookmarkItem(bm);
      }
    }
  }

  private navigateToSpeaker(pres: PresentationItem) {
    if (pres.speakerNameOverride) return;

    if (!this.speakersModuleEnabled) return;

    if (!pres.speakerId) return;

    this.$router.push({
      name: 'speakers',
      query: {
        search: pres.speakerName,
        id: pres.speakerId,
        speaker: 'true',
      },
    });
  }

  private async navigateToPresentation(pres: PresentationItem) {
    if (!pres.id || !pres.sessionId) return;

    if (!pres.isEventPortalPresentation && !(pres.isPresentation || pres.isAPoster)) return;

    await this.$router.push({
          name: 'presentation',
          params: { sessionId: pres.sessionId, presentationId: pres.id },
        });
  }

  private canNavigateToPresentation(pres: PresentationItem): boolean {
    if (!pres.id || !pres.sessionId) return false;

    if (!(pres.isPresentation || pres.isAPoster)) return false;

    if (!pres.isEventPortalPresentation) return false;

    return true
  }

}
</script>

<style scoped>
.title {
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

.blink-me {
  animation: blinker 1.25s linear infinite;
}

.pointer {
  cursor: pointer;
}

.default {
  cursor: default;
}

.inline {
  display: inline;
}

@keyframes blinker {
  50% {
    opacity: 0.25;
  }
}
</style>
