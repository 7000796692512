import { render, staticRenderFns } from "./HomeVod.vue?vue&type=template&id=2cebd95a&scoped=true"
import script from "./HomeVod.vue?vue&type=script&lang=ts"
export * from "./HomeVod.vue?vue&type=script&lang=ts"
import style0 from "./HomeVod.vue?vue&type=style&index=0&id=2cebd95a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cebd95a",
  null
  
)

export default component.exports