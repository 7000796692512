<template>
  <ContentVideoPlayer
    :is-presentation="true"
    :session-id="sessionId"
    :presentation-id="presentationId"
  />
</template>

<script lang="ts">
import ContentVideoPlayer from '@/components/ContentVideoPlayer.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    ContentVideoPlayer,
  },
})
export default class Presentation extends Vue {
  @Prop()
  public sessionId!: string;

  @Prop()
  public presentationId!: string;
}
</script>

<style scoped></style>
