<template>
  <div class="text-center">
    <v-dialog v-model="brochure" width="500">
      <template #activator="{ on: dialog }">
        <v-tooltip right>
          <template #activator="{ on: tooltip, attrs }">
            <a
              v-bind="attrs"
              :style="`position:absolute; top: ${item.posTop};
                                  left: ${item.posLeft};
                                  height: ${item.mapHeight};
                                  width: ${item.mapWidth};`"
              v-on="{ ...tooltip, ...dialog }"
            ></a>
          </template>
          <span>{{ item.title }}</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>{{
          item.title
        }}</v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item
              v-if="item.itemContent1"
              @click="openNewTab(item.itemContent1, item.id, item.itemTitle1)"
            >
              <v-list-item-avatar>
                <v-icon size="32" color="grey"
                  >mdi-file-download-outline</v-icon
                >
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.itemTitle1"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  icon
                  @click.stop="
                    openNewTab(item.itemContent1, item.id, item.itemTitle1)
                  "
                >
                  <v-icon color="grey lighten-1">mdi-open-in-new</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn
                  icon
                  @click.stop="
                    bookmarkExecute(item.itemTitle1, item.itemContent1)
                  "
                >
                  <v-icon v-if="isABookmark(sponsorId, item.itemContent1)"
                    >mdi-bookmark-check</v-icon
                  >
                  <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item
              v-if="item.itemContent2"
              @click="openNewTab(item.itemContent2, item.id, item.itemTitle2)"
            >
              <v-list-item-avatar>
                <v-icon size="32" color="grey"
                  >mdi-file-download-outline</v-icon
                >
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.itemTitle2"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  icon
                  @click.stop="
                    openNewTab(item.itemContent2, item.id, item.itemTitle2)
                  "
                >
                  <v-icon color="grey lighten-1">mdi-open-in-new</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn
                  icon
                  @click.stop="
                    bookmarkExecute(item.itemTitle2, item.itemContent2)
                  "
                >
                  <v-icon v-if="isABookmark(sponsorId, item.itemContent2)"
                    >mdi-bookmark-check</v-icon
                  >
                  <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item
              v-if="item.itemContent3"
              @click="openNewTab(item.itemContent3, item.id, item.itemTitle3)"
            >
              <v-list-item-avatar>
                <v-icon size="32" color="grey"
                  >mdi-file-download-outline</v-icon
                >
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.itemTitle3"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  icon
                  @click.stop="
                    openNewTab(item.itemContent3, item.id, item.itemTitle3)
                  "
                >
                  <v-icon color="grey lighten-1">mdi-open-in-new</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn
                  icon
                  @click.stop="
                    bookmarkExecute(item.itemTitle3, item.itemContent3)
                  "
                >
                  <v-icon v-if="isABookmark(sponsorId, item.itemContent3)"
                    >mdi-bookmark-check</v-icon
                  >
                  <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item
              v-if="item.itemContent4"
              @click="openNewTab(item.itemContent4, item.id, item.itemTitle4)"
            >
              <v-list-item-avatar>
                <v-icon size="32" color="grey"
                  >mdi-file-download-outline</v-icon
                >
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.itemTitle4"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  icon
                  @click.stop="
                    openNewTab(item.itemContent4, item.id, item.itemTitle4)
                  "
                >
                  <v-icon color="grey lighten-1">mdi-open-in-new</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn
                  icon
                  @click.stop="
                    bookmarkExecute(item.itemTitle4, item.itemContent4)
                  "
                >
                  <v-icon v-if="isABookmark(sponsorId, item.itemContent4)"
                    >mdi-bookmark-check</v-icon
                  >
                  <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item
              v-if="item.itemContent5"
              @click="openNewTab(item.itemContent5, item.id, item.itemTitle5)"
            >
              <v-list-item-avatar>
                <v-icon size="32" color="grey"
                  >mdi-file-download-outline</v-icon
                >
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.itemTitle5"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  icon
                  @click.stop="
                    openNewTab(item.itemContent5, item.id, item.itemTitle5)
                  "
                >
                  <v-icon color="grey lighten-1">mdi-open-in-new</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn
                  icon
                  @click.stop="
                    bookmarkExecute(item.itemTitle5, item.itemContent5)
                  "
                >
                  <v-icon v-if="isABookmark(sponsorId, item.itemContent5)"
                    >mdi-bookmark-check</v-icon
                  >
                  <v-icon v-else>mdi-bookmark-plus-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="brochure = 0">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { SponsorMapItem } from '@/models/Sponsor';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DownloadLog } from '../models/DownloadLog';
import { PlatformActions } from '../store/platform/types';
import {
  BookmarkActions,
  BookmarkEntityType,
  BookmarkGetters,
  BookmarkItem,
  BookmarkType,
} from '../store/bookmark/types';
import { PlatformUserGetters } from '@/store/platformUser/types';

const bookmarkNamespace = 'bookmark';
const platformNamespace = 'platform';
const platformUserNamespace = 'platformUser';

@Component({
  components: {},
})
export default class SponsorDocument extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public item!: SponsorMapItem;

  @Prop()
  public sponsorId!: string;

  @Prop() sponsorTitle!: string;

  /* PRIVATE PROPERTIES */
  private brochure = 0;

  /* VUEX GETTERS */
  // Bookmark Getters
  @Getter(BookmarkGetters.GET_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private getBookmark!: (
    entityId: string,
    entityUri?: string,
  ) => BookmarkItem | undefined;

  @Getter(BookmarkGetters.IS_A_BOOKMARK, {
    namespace: bookmarkNamespace,
  })
  private isABookmark!: (entityId: string, entityUri?: string) => boolean;

  // Platofrm getters
  @Action(PlatformActions.DOWNLOAD_LOG, { namespace: platformNamespace })
  private downloadLog!: (downloadLog: DownloadLog) => Promise<void>;

  // Platform user Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  /* VUEX ACTIONS */
  // Bookmark Actions
  @Action(BookmarkActions.ADD_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private bookmarkItem!: (bookmark: BookmarkItem) => Promise<void>;

  @Action(BookmarkActions.REMOVE_BOOKMARK_ITEM, {
    namespace: bookmarkNamespace,
  })
  private unbookmarkItem!: (bi: BookmarkItem) => Promise<void>;

  /* WATCHES */

  /* LOCAL GETTERS/SETTERS */

  /* PRIVATE METHODS*/
  private bookmarkExecute(title: string, url: string) {
    const bi = this.getBookmark(this.sponsorId, url);
    if (bi) {
      this.unbookmarkItem(bi);
    } else {
      const bm: BookmarkItem = {
        entityId: this.sponsorId,
        entityType: BookmarkEntityType.SPONSOR_DOCUMENT,
        type: BookmarkType.DOCUMENT,
        entityUri: url,
        title: `${this.sponsorTitle} - ${title}`,
      };
      this.bookmarkItem(bm);
    }
  }

  private async openNewTab(url: string, id: string, title: string) {
    if (id) {
      const log: DownloadLog = {
        entityId: id,
        itemContent: url,
        itemTitle: title,
        sponsorId: this.sponsorId,
        userId: this.currentUserId,
        logType: 'DOWNLOAD_ITEM',
      };
      await this.downloadLog(log);
    }

    if (url.startsWith('https:') || url.startsWith('http:')) {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      this.$router.push({
        path: url,
      });
    }
  }
}
</script>

<style scoped></style>
