<template>
    <click-outside :do="close">
        <div class="emoji-picker">
            <div class="emoji-invoker" ref="emojiInvoker">
                <span class="invoker-icon pointer"
                      ref="emojiInvokerIcon"
                      @click="toggleDropdown">
                    <span v-if="isInvokerHovered || show"
                          @mouseleave="isInvokerHovered = false"
                          v-html="emojiInvokerOpen">
                    </span>

                    <span v-else
                          class="show"
                          @mouseenter="isInvokerHovered = true"
                          v-html="emojiInvokerClose">;
                    </span>
                </span>
            </div>
            <div v-show="show"
                 class="emoji-dropdown"
                 ref="emojiDropdown">
                <div class="header">
                    <span v-for="(val,key) in categories"
                          :key="key"
                          :title="getCategoryName(key)"
                          :class="{active: key === scrolledTo}"
                          class="pointer"
                          @click="scrollToCategory(key)"
                          v-html="val">
                    </span>
                </div>

                <div class="search">
                    <span class="search-icon"
                          v-html="searchSVG">
                    </span>
                    <input type="text"
                           v-model="search"
                           placeholder="Search" />
                </div>

                <div v-if="Object.keys(emojis).length===0"
                     class="emojis"
                     ref="emojis">
                    <span class="result-info">No emojis found.</span>
                </div>
                <div v-else
                     class="emojis"
                     ref="emojis">
                    <div v-for="(items, category) in emojis" :key="category">
                        <div class="category"
                             :ref="getCategoryRef(category)">
                             <span class="overline">{{category}}</span>
                        </div>

                        <div class="emoji-list">
                            <span v-for="(item, index) in items"
                                  class="emoji pointer"
                                  :key="index"
                                  :title="item.aliases"
                                  @click="selectEmoji(item)"
                                  @mouseleave="handleMouseLeaveEmoji"
                                  @mouseenter="handleMouseEnterEmoji(item)">
                                {{ item.emoji }}
                            </span>
                        </div>
                    </div>
                </div>
                <!-- <div class="footer" @mouseleave="handleMouseLeaveFooter">
                     <div class="ton-picker">
                        <div class="hands-container">
                            <div class="hands">
                                <span v-for="(item,index) in getToneHands()"
                                      :key="index">
                                      <span v-if="item.name === getDefaultSkinTone().name || showSkinTonePickers"
                                            @click="setDefaultSkinTone(item)"
                                            class="hand"
                                      >
                                        {{ item.emoji}}
                                      </span>
                                </span>
                            </div>
                            <div class="tip"
                                 v-if="showSkinTonePickers">
                                Choose you defualt skin tone
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </click-outside>
</template>

<script lang="ts">
import { CategorisedEmojis, Emoji, SkinTone } from '@/models/EmojiTypes';
import Popper from 'popper.js';
import skinTone, { SkinToneType } from 'skin-tone';
import ClickOutside from './ClickOutside.vue';
import frequentlyUsed from '../data/frequently-used.json';
import {categories, searchSVG, emojiInvokerOpen, emojiInvokerClose} from '../data/svg.js';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { throttle} from '../utils/throttle';

const DEFUALT_SKIN_TONE = 'default_skin_tone';
const FREQUENTLY_USED = 'frequently_used';
const FREQUENTLY_USED_CATEGORY_NAME = 'Frequently Used';
const FREQUENTLY_USED_EMOJIS = 'frequently_used_emojis';
const skinToneNames = [
  { name: 'NONE', type: 'none' as SkinToneType },
  { name: 'WHITE', type: 'white' as SkinToneType },
  { name: 'CREAM_WHITE', type: 'creamWhite' as SkinToneType },
  { name: 'LIGHT_BROWN', type: 'lightBrown' as SkinToneType },
  { name: 'BROWN', type: 'brown'as SkinToneType },
  { name: 'DARK_BROWN', type: 'darkBrown'as SkinToneType}
];


@Component({ components: { ClickOutside }})
export default class EmojiPicker extends Vue {

 /* PUBLIC PROPERTIES */
  @Prop()
  public data!: Emoji[];

  /* PRIVATE PROPERTIES */
  private show = false;
  private search = '';
  private popper: any = null;
  private skinTonePickers: any = [];
  private hoveredEmoji: any = null;
  private emojiInvoker: any = null;
  private emojiDropdown: any = null;
  private selectedEmoji: any = null;
  private searchSVG = searchSVG;
  private categorizedEmojis: any = {};
  private categories = categories;
  private emojiInvokerIcon: any = null;
  private isInvokerHovered = false;
  private showSkinTonePickers = false;
  private emojiInvokerClose = emojiInvokerClose;
  private emojiInvokerOpen = emojiInvokerOpen;
  private scrolledTo = FREQUENTLY_USED;

  /* VUEX GETTERS */
  /* VUEX ACTIONS */

  /* WATCHES */
  @Watch('search')
  private searchWatcher() {
    if (this.search !== '') {
      this.scrolledTo = FREQUENTLY_USED;
    }
  }

  @Watch('show')
  private showWatcher() {
    if (!this.show) {
      this.search = '';
    }

    this.$nextTick(() => {
      this.setupPopper();
    });
  }

  /* LOCAL GETTERS/SETTERS */
  get emojis(): Array<Array<Emoji>> {
    return this.search ? this.filteredEmojis() : this.categorizedEmojis;
  }

  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private mounted() {
    this.skinTonePickers = this.getToneHands();
    this.categorizedEmojis = this.categorizeEmojis();
    this.emojiDropdown = this.$refs.emojiDropdown;
    this.emojiInvoker = this.$refs.emojiInvoker;
    this.emojiInvokerIcon = this.$refs.emojiInvokerIcon;
    this.setupScroll();
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private open() {
    this.show = true;
  }

  private close() {
    this.show = false;
  }

  private toggleDropdown() {
    this.show ? this.close() : this.open();
  }

  private setFrequentUsedEmoji(emojiObj) {
    const maxLength = 9;
    let temp = this.getFrequentUsedEmojis()
    let frequentUsedEmojis: Emoji[] = [];
    if (temp)
    {
      frequentUsedEmojis = temp.filter(item => item.description !== emojiObj.description);
    }
    emojiObj.category = FREQUENTLY_USED_CATEGORY_NAME;
    frequentUsedEmojis = [...frequentUsedEmojis, emojiObj];

    if (frequentUsedEmojis.length > maxLength) {
      frequentUsedEmojis.shift();
    }

    localStorage.setItem(FREQUENTLY_USED_EMOJIS, JSON.stringify(frequentUsedEmojis));
    this.categorizedEmojis[FREQUENTLY_USED_CATEGORY_NAME] = frequentUsedEmojis;
  }

  private getFrequentUsedEmojis(): Emoji[] {
    let frequentUsedEmojis : Emoji[] = []
    let temp = localStorage.getItem(FREQUENTLY_USED_EMOJIS);

    if (temp === null) {
      frequentUsedEmojis = frequentlyUsed as Emoji[];
    } else if (!Array.isArray(temp)) {
      frequentUsedEmojis = JSON.parse(temp);
    }
    return frequentUsedEmojis;
  }

  private selectEmoji(emojiObj) {
    this.$emit('emoji:picked', emojiObj.emoji);
    this.setFrequentUsedEmoji(emojiObj);
    this.close();
  }

  private scrollToCategory(category) {
    let [element] = this.$refs[category] as Element[];

    if (element && category !== 'frequently_used') {
      element.scrollIntoView({
        block: 'start',
        inline: "center",
        behavior: 'smooth',
      })
    } else {
      element.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }

  private setupScroll () {
    let emojis = this.$refs.emojis as Element;
    emojis.addEventListener('scroll', this.handleScroll);
  }

  private handleScroll() {
    let emojis = this.$refs.emojis as Element;
    if (this.search) {
      return;
    }

    let temp = '';
    Object.keys(this.categories).forEach(category => {
      let [element] = this.$refs[this.getCategoryRef(category)] as any;
      if (element && Math.floor(emojis.scrollTop) >= element.offsetTop) {
        // console.log(`Category: ${category} - ScrollTop: ${emojis.scrollTop}  - OffsetTop: ${element.offsetTop}`)
        temp = category;
      }
    });
    if (temp)
    {
      this.scrolledTo = temp;
    }
  }

  private filteredEmojis(): CategorisedEmojis {
    let filteredEmojis: CategorisedEmojis = {};
    for (let category in this.categorizedEmojis) {
      this.categorizedEmojis[category].forEach(emoji => {
        if (!emoji.description.toLowerCase().indexOf(this.search.toLowerCase())) {
          return;
        }
        if (!Object.prototype.hasOwnProperty.call(filteredEmojis, category)) {
          filteredEmojis[category] = [];
        }
        filteredEmojis[category] = [ ...filteredEmojis[category], emoji ];
      });
    }

    return filteredEmojis;
  }

  private getCategoryRef(category) {
    return category.toLowerCase().split(' ').join('_');
  }

  private getCategoryName(categorRef) {
    const words = categorRef.split("_");

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(' ');
  }

  private categorizeEmojis (): CategorisedEmojis {
    let categorizedEmojis: CategorisedEmojis = {};
    let allEmojis = [...this.getFrequentUsedEmojis(), ...this.data];
    let sk = this.getDefaultSkinTone();
    allEmojis.forEach(emojiObj => {
      if (!Object.prototype.hasOwnProperty.call(categorizedEmojis, emojiObj.category)) {
        categorizedEmojis[emojiObj.category] = [];
      }
      emojiObj.emoji = skinTone(emojiObj.emoji, sk.type);
      categorizedEmojis[emojiObj.category] = [...categorizedEmojis[emojiObj.category], emojiObj];
    });

    return categorizedEmojis;
  }

  private getDefaultSkinTone(): SkinTone {
    let defaultSkinTone = localStorage.getItem(DEFUALT_SKIN_TONE);
    return defaultSkinTone === null
      ? { name: 'NONE', emoji: '✋', type: 'none' as SkinToneType }
      : JSON.parse(defaultSkinTone) as SkinTone;
  }

  private setDefaultSkinTone(skinTone) {
    this.showSkinTonePickers = !this.showSkinTonePickers;
    localStorage.setItem(DEFUALT_SKIN_TONE, JSON.stringify(skinTone));
    this.resetInitEmojis(skinTone.name);
  }

  private resetInitEmojis(type: SkinToneType) {
    for (let category in this.categorizedEmojis) {
      this.categorizedEmojis[category].forEach(emojiObj => {
        emojiObj.emoji = skinTone(emojiObj.emoji, type);
      });
    }
  }

  private getToneHands(): SkinTone[] {
    return skinToneNames.map(item => {
      return { ...item, ...{ emoji: skinTone('✋', item.type) } };
    }).sort(this.sortToneHands);
  }

  private sortToneHands(a: SkinTone, b: SkinTone) {
    return a.name === this.getDefaultSkinTone().name ? 1 : 0;
  }

  private handleMouseLeaveFooter() {
    if (this.showSkinTonePickers) {
      this.showSkinTonePickers = false;
    }

    if (this.hoveredEmoji !== null) {
      this.hoveredEmoji = null;
    }
  }

  private handleMouseEnterEmoji(emoji) {
    if (this.showSkinTonePickers) {
      this.showSkinTonePickers = false;
    }
    this.hoveredEmoji = emoji;
  }

  private handleMouseLeaveEmoji(emoji) {
    this.hoveredEmoji = null;
  }

  private setupPopper() {
    if (this.popper === null) {
      this.popper = new Popper(this.emojiInvoker, this.emojiDropdown, { placement: 'top', modifiers: { offset: { offset: '0, 34' }  } });
      return;
    }
    this.popper.scheduleUpdate();
  }
}
</script>

<style lang="scss" style="scope">
.emoji-picker {
  position: relative;
  .emoji-invoker {
    position: relative;
    text-align: right;
    .invoker-icon {
      font-size: 28px;
      .show {
        font-size: 20px;
      }
    }
  }
  .emoji-dropdown {
    width: 330px;
    margin: 10px 0px;
    border: 1px solid #dae1e7;
    border-radius: 5px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header {
      border-bottom: 1px solid #dddd;
      width: 100%;
      margin-bottom: 5px;
      padding: 10px 4px 5px 4px;
      display: flex;
      background: #f8fafc;
      justify-content: space-between;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      span {
        position: relative;
      }
    }
    .search {
      position: relative;
      width: 100%;
      input {
        width: 98%;
        box-sizing: border-box;
        padding: 5px 8px 5px 30px;
        margin-bottom: 5px;
        margin-left: 4px;
        border: 1px solid #dae1e7;
        font-size: 14px;
        border-radius: 9999px;

        &:focus {
          outline: none;
        }
      }
      .search-icon {
        position: absolute;
        left: 14px;
        top: 8px;
      }
    }
    .emojis {
      display: flex;
      flex-direction: column;
      height: 420px;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      position: relative;
      .category {
        text-transform: capitalize;
        padding-left: 6px;
        color: #3d4852;
        z-index: 2;
        top: 0;
        background: white;
      }
      &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(201, 199, 199, 0.867);
        border-radius: 5px;
      }
      .emoji-list {
        display: flex;
        flex-wrap: wrap;
      }
      .emoji {
        padding: 3px;
        font-size: 1.5em;
      }
    }

    .result-info {
      font-size: 1rem;
      margin: auto;
      text-align: center;
      color: grey;
    }
  }
  .active {
    &:before {
      border-right: 2px solid green;
      content: "";
      display: block;
      height: 32px;
      font-size: 36px;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      position: absolute;
      right: 11px;
      top: 14px;
    }
  }
  .pointer {
    cursor: pointer;
  }

  svg {
    fill: currentColor;
    max-height: 18px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 4px;
    width: 100%;
    height: 2.5rem;
    border-top: 1px solid #ddd;
    background: #f9f9f9;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    .emoji-preview {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .emoji {
        font-size: 2rem;
        margin-right: 0.5rem;
      }

      .alias-container {
        font-size: 0.9rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
          color: #3d4852;
        }

        .alias {
          color: grey;
        }
      }
    }

    .title {
      padding-left: 6px;
      color: grey;
    }

    .ton-picker {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 6px;
      .hands-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .hands {
          font-size: 1.3rem;
          display: flex;
          .hand {
            cursor: pointer;
          }
        }
        .tip {
          font-size: 0.8rem;
          color: grey;
        }
      }
    }
  }
}
</style>
