<template>
  <v-card
    :height="minimised ? 40 : 282"
    :width="minimised ? 250 : 320"
    class="acceptMouse mr-2 mt-1"
  >
    <v-toolbar dense flat light height="40px" color="grey lighten-3">
      <v-toolbar-title>
        <span class="subtitle-2">
          <v-badge
            inline
            color="red"
            :value="minimised && conversation.unreadCount > 0"
            :content="conversation.unreadCount"
            >{{ conversation.otherUserName }}</v-badge
          >
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!minimised"
        icon
        tile
        small
        @click.stop="minimiseConversation(conversation.id)"
      >
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <v-btn
        v-if="minimised"
        icon
        tile
        small
        @click.stop="maximise(conversation.id)"
      >
        <v-icon>mdi-arrow-top-right</v-icon>
      </v-btn>
      <v-btn icon tile small @click.stop="closeConversation(conversation)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card v-if="!minimised" height="242">
      <v-container class="overflow-y-auto light-chat ma-0 pa-0">
        <v-list v-mutate.child="scrollToBottom" dense>
          <template v-for="m in conversation.messageItems">
            <v-list-item :key="m.messageId" class="double-dense">
              <v-list-item-content class="mb-0 pb-0">
                <v-list-item-subtitle class="message-text">
                  <span class="font-weight-black">{{ m.senderName }}</span>
                  <span class="font-regular message-text"
                    >: {{ m.message }}</span
                  >
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <div :id="`bottom-${conversation.id}`" />
        </v-list>
      </v-container>
      <v-text-field
        v-model="newMessage"
        class="mx-1"
        outlined
        dense
        hide-details
        @keyup.enter="postConversationExecute"
      >
        <template slot="append">
          <v-btn
            icon
            tile
            class="sendButton"
            @click.stop="postConversationExecute"
          >
            <v-icon>mdi-send-outline</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-card>
  </v-card>
</template>

<script lang="ts">
import { PlatformUserGetters } from '@/store/platformUser/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import {
  ConversationActions,
  ConversationItem,
  NewConversationMessage,
} from '../store/conversation/types';
const conversationNamespace = 'conversation';
const platformUserNamespace = 'platformUser';

@Component({})
export default class ConversationBox extends Vue {
  @Prop()
  public conversation!: ConversationItem;

  private newMessage = '';

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  @Action(ConversationActions.CLOSE_CONVERSATION, {
    namespace: conversationNamespace,
  })
  private closeConversation!: (conversation: ConversationItem) => void;

  @Action(ConversationActions.MINIMISE_CONVERSATION, {
    namespace: conversationNamespace,
  })
  private minimiseConversation!: (id: string) => void;

  @Action(ConversationActions.MAXIMISE_CONVERSATION, {
    namespace: conversationNamespace,
  })
  private maximiseConverstaion!: (id: string) => void;

  private scrollToBottom() {
    const e = document.getElementById(`bottom-${this.conversation.id}`);
    if (e) {
      e.scrollIntoView();
    }
  }

  get minimised(): boolean {
    return this.conversation.minimised;
  }

  private maximise(id: string) {
    this.maximiseConverstaion(id);
    this.$nextTick(() => {
      this.scrollToBottom();
    });
  }

  private postConversationExecute() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    if (this.newMessage.length > 1) {
      const msg: NewConversationMessage = {
        eventId: platformId,
        message: this.newMessage,
        recipient: this.conversation.otherUserId,
        sender: this.currentUserId,
      };

      this.postConversationMessage(msg);
      this.newMessage = '';
    }
  }

  private async postConversationMessage(
    msg: NewConversationMessage,
  ): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      await Vue.$http.post(`/api/platform/${platformId}/conversations`, msg);
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - postConversationMessage failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private mounted() {
    this.scrollToBottom();
  }
}
</script>

<style scoped>
.acceptMouse {
  pointer-events: all;
}

.sendButton {
  margin-top: -6px;
  margin-right: -8px;
}

.light-chat {
  font-size: 12px;
  height: calc(100% - 44px);
}

.light-chat::-webkit-scrollbar {
  width: 5px;
}

.light-chat::-webkit-scrollbar-track {
  background: transparent;
  border-left: 0;
}

.light-chat::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: 0;
  border-radius: 7px;
}

.light-chat::-webkit-scrollbar-thumb:hover {
  background: black;
}

.double-dense {
  min-height: 25px;
}

.message-text {
  white-space: normal !important;
}
</style>
