<template>
    <div style="height: 100%">
        <v-container v-if="!submitSettings.open && loaded" fill-height fluid>
            <v-row align="center" justify="center">
                <v-card max-width="500" class="mx-auto">
                    <v-card-text class="text-h6 text-center"><span v-html="submitSettings.closedText"></span></v-card-text>
                </v-card>
            </v-row>
        </v-container>
        <v-container fluid>
            <v-card flat>
                <v-card-title>
                    {{ $t('submit.Posters') }}
                </v-card-title>
                <v-data-table :headers="presentationHeaders"
                              :items="presentationItems"
                              single-expand
                              item-key="id"
                              :search="search"
                              :loading="loading"
                              :loading-text="$t('submit.LoadingText')"
                              class="elevation-1"
                              sort-by="[internalCode]"
                              :items-per-page="100"
                              :footer-props="footerProps">
                    <template v-slot:top>
                        <v-container fluid>
                            <v-row class="ma-0 pa-0" dense>
                                <v-switch v-model="uploadedOnly"
                                          :label="$t('submit.UploadedOnly')"
                                          class="pa-3">
                                </v-switch>
                                <v-spacer></v-spacer>
                                <v-col cols="3">
                                    <v-text-field v-model="search"
                                                  append-icon="mdi-magnify"
                                                  :label="$t('submit.Search')"
                                                  single-line
                                                  hide-details
                                                  clearable>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>

                    <template v-slot:[`item.start`]="{ item }">
                        {{ formatLongDate(item.start, item.end) }}
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn :color="item.posterUploadUri ? 'green' : 'blue-grey'"
                               class="ma-2 white--text"
                               @click="handleFileUploads(item)">
                            {{ item.posterUploadUri ? $t('submit.ManageFiles') : $t('submit.UploadFiles') }}
                            <v-icon right
                                    dark>
                                {{ item.posterUploadUri ?  "mdi-cloud-check" : "mdi-cloud-upload" }}
                            </v-icon>
                        </v-btn>
                    </template>

                </v-data-table>
            </v-card>
            <v-row justify="center">
                <v-dialog v-model="dialog"
                          fullscreen
                          hide-overlay
                          transition="dialog-bottom-transition"
                          persistent
                          class="scrolloverride"
                          >
                    <SubmitUploadFileDialog v-if="selectedPresentation" :item="selectedPresentation" :settings="submitSettings" @close="handleClose" />
                </v-dialog>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import moment from 'moment';
import {
    SubmitPresentationItem,
    SubmitSessionItem,
    SubmitSettings,
} from '../../models/Submit';
import { Action, Getter } from 'vuex-class';
import { AppInsightsLogger } from '../../services/appInsightsLogger';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SubmitUploadFileDialog from '../../components/SubmitUploadFileDialog.vue'

@Component({
    components: { SubmitUploadFileDialog },
})
export default class SessionList extends Vue {
    /* PUBLIC PROPERTIES */
    /* PRIVATE PROPERTIES */
    private loading: boolean = true;
    private loaded: boolean = false;
    private submitSettings: SubmitSettings = {};
    private dialog: boolean = false;
    private dateFilterValue = null;
    private roomFilterValue = null;
    private uploadedOnly = false;
    private search: string = '';
    private presentationItems: SubmitPresentationItem[] = [];
    private selectedPresentation: SubmitPresentationItem | null = null;

    private presentationHeaders = [
        { text: 'Id', value: 'internalCode', width: 80 },
        { text: this.$t('submit.PosterTitle'), value: 'title' },
        { text: this.$t('submit.Author'), value: 'speakerName' },
        { text: this.$t('submit.Uploaded'), value: 'posterUploadUri', align: ' d-none', filter: this.PosterUploadUriFilter },
        { text: '', value: 'actions', sortable: false, width: 250 },
    ];
    private footerProps = {
        itemsPerPageOptions: [100, 200, 500, -1],
        itemsPerPageText: this.$t('submit.RowsPerPage'),
        itemsPerPageAllText: this.$t('submit.All'),
    };

    /* VUEX GETTERS */
    /* VUEX ACTIONS */
    /* WATCHES */
    /* LOCAL GETTERS/SETTERS */
    /* LIFECYCLE METHODS */
    // private beforeCreate() {}
    // private created() {}
    // private beforeMount() {}
    private async mounted() {
        const platformId = sessionStorage.getItem('platformId') ?? '';
        const res = await Vue.$http.get<SubmitSettings>(`/api/platform/${platformId}/submit`);
        this.submitSettings = res.data
        if (this.submitSettings.closedText) {
            this.loaded = true;
        } else {
            await this.loadPresentations();
        }
    }
    // private beforeUpdate() {}
    // private updated() {}
    // private activated() {}
    // private deactivated() {}
    // private beforeDestroy() {}
    // private destroyed() {}
    // private errorCaptured() {}
    /* PRIVATE METHODS*/

    private DateFilter(value, search, item) {
        if (!this.dateFilterValue) {
            return true;
        }
        return (
            moment(item.start).locale(this.$i18n.locale).format('DD/MM/YYYY') ===
            this.dateFilterValue
        );
    }


    private formatLongDate(start: Date, end: Date) {
        let s = '';
        let e = '';
        if (start) {
            s = moment(start).locale(this.$i18n.locale).format('ddd DD MMM HH:mm');
        }

        if (end) {
            e = moment(end).locale(this.$i18n.locale).format('HH:mm');
        }
        return `${s} - ${e}`;
    }

    private async handleFileUploads(item: SubmitPresentationItem) {
        this.selectedPresentation = item;
        this.dialog = true;
    }

    private handleClose() {
        this.selectedPresentation = null;
        this.dialog = false;
    }

    private async loadPresentations() {
        const platformId = sessionStorage.getItem('platformId') ?? '';
        try {
            let url = `/api/v2/platform/${platformId}/submit/presentations?posters=true`;
            const pData = await Vue.$http.get<SubmitPresentationItem[]>(url);
            if (pData.data) {
                this.presentationItems = pData.data;
            }
            this.loaded = true;
            this.loading = false;
        } catch (e: any) {
            AppInsightsLogger.logError(
                'Submit Presentation List - created failed',
                undefined,
                true,
            );
            AppInsightsLogger.logException(e, false);
        }
    }

    private PosterUploadUriFilter(value, search, item) {
        if (!this.uploadedOnly) {
            return true;
        }
        return value ? true : false;
    }

    private RoomFilter(value, search, item) {
        if (!this.roomFilterValue) {
            return true;
        }
        return value === this.roomFilterValue;
    }
}
</script>

<style scoped></style>