<template>
  <div class="pa-0 ma-0">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="overline text-center"
          >Questions</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>

    <div
      v-mutate.child="onNewQuestion"
      class="overflow-y-auto light-chat mx-0 px-0"
    >
      <div id="top" />
      <v-subheader v-if="authorisationForComments && isMod" class="mx-0 px-0">
        <v-row dense class="ma-0 pa-0">
          <v-col cols="6">
            <v-btn
              text
              x-small
              class="pr-0"
              @click.stop="approveAllQuestionsExecute()"
            >
              <v-icon size="12">mdi-check</v-icon>
              {{ $t('common.approveAll') }}
            </v-btn>
            <v-btn
              text
              x-small
              class="pr-0"
              @click.stop="hideAllQuestionsExecute()"
            >
              <v-icon size="12">mdi-window-close</v-icon>
              {{ $t('common.hideAll') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="autoApproveQuestions"
              class="mt-0 pt-0 auto-approve"
              color="blue"
              hide-details
              :label="$t('common.autoApprove')"
            ></v-switch>
          </v-col>
        </v-row>
      </v-subheader>

      <v-alert
        v-if="questionCount <= 0"
        :value="true"
        color="info"
        icon="info"
        class="mx-3"
        >{{ $t('session.firstQuestion') }}</v-alert
      >
      <div v-for="q in questions" :id="q.id" :key="q.id">
        <v-card
          v-if="!authorisationForComments || isMod || q.isApproved"
          class="pa-2 mx-3 my-2"
        >
          <v-row dense>
            <v-col cols="8">
              <span class="font-weight-bold">{{ q.userName }}</span>
            </v-col>
            <v-col cols="4" class="text-right">
              {{ formatTimestamp(q.timeStamp) }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <span>{{ q.text }}</span>
            </v-col>
          </v-row>
          <v-card-actions v-if="isMod">
            <v-btn
              v-if="authorisationForComments && !q.isApproved"
              text
              x-small
              @click.stop="approveQuestionExecute(q)"
            >
              <v-icon size="12">mdi-check</v-icon>
              {{ $t('common.approve') }}
            </v-btn>
            <v-btn
              v-else-if="authorisationForComments && q.isApproved"
              text
              x-small
              @click.stop="hideQuestionExecute(q)"
            >
              <v-icon size="12">mdi-window-close</v-icon>
              {{ $t('common.hide') }}
            </v-btn>
            <v-btn
              v-if="showId !== q.id"
              text
              x-small
              @click.stop="showReply(q.id)"
            >
              <v-icon size="12">mdi-reply</v-icon>
              {{ $t('common.reply') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <div class="overflow-y-auto light-chat mx-0 px-0 ml-5">
          <div v-for="r in q.replies" :key="r.id">
            <v-card class="pa-2 mx-3 my-2">
              <v-row dense>
                <v-col cols="8">
                  <span class="font-weight-bold">
                    <v-icon size="12">mdi-subdirectory-arrow-right</v-icon>
                    {{ r.userName }}
                  </span>
                </v-col>
                <v-col cols="4" class="text-right">
                  {{ formatTimestamp(r.timeStamp) }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <span>{{ r.text }}</span>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </div>

        <v-expand-transition>
          <div v-if="q.id === showId" class="mx-1">
            <v-row no-gutters>
              <v-col>
                <tweet-box
                  v-model="replyQuestiontText"
                  :limit="500"
                  class="chat-message-compose"
                />
              </v-col>
            </v-row>
            <v-row justify="end" no-gutters>
              <v-btn
                x-small
                color="primary"
                class="mt-1"
                @click.stop="closeReplyTextArea"
                >{{ $t('common.cancel') }}</v-btn
              >
              <v-btn
                x-small
                color="primary"
                class="ml-1 mt-1"
                @click.stop="postQuestionReplyExecute(q.id)"
                >{{ $t('common.reply') }}</v-btn
              >
            </v-row>
          </div>
        </v-expand-transition>
      </div>
      <div id="bottom" />
    </div>

    <v-container class="mt-0 pt-0">
      <v-row no-gutters>
        <v-col>
          <tweet-box
            v-model="newQuestiontText"
            :limit="500"
            class="chat-message-compose"
            :placeholder="$t('session.askQuestion')"
          />
        </v-col>
      </v-row>
      <v-row justify="end" no-gutters>
        <v-btn
          x-small
          color="primary"
          class="mt-1"
          @click.stop="postQuestionExecute"
          >{{ $t('session.postQuestion') }}</v-btn
        >
      </v-row>
    </v-container>
    <v-dialog v-model="sentDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">{{
          $t('session.questionSentTitle')
        }}</v-card-title>
        <v-card-text
          ><span style="white-space: pre-line">{{
            $t('session.questionSentText')
          }}</span></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="sentDialog = false">{{
            $t('common.close')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import TweetBox from '@/components/TweetBox.vue';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  SessionActions,
  SessionGetters,
  SessionQuestionItem,
} from '../store/session/types';
import {
  SignalrGetters,
  SignalrGroup,
  SignalrMessage,
} from '../store/signalr/types';

const signalrNamespace = 'signalr';
const sessionNamespace = 'session';

@Component({ components: { TweetBox } })
export default class SessionQuestions extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public isMod!: boolean;

  @Prop()
  public pageLoaded!: string;

  @Prop()
  public isActive!: boolean;

  @Prop()
  public authorisationForComments!: boolean;

  /* PRIVATE PROPERTIES */
  private newQuestiontText = '';
  private replyQuestiontText = '';
  private showId = '';
  private sentDialog = false;

  /* VUEX GETTERS */
  // Session Getters
  @Getter(SessionGetters.CURRENT_SESSION_QUESTIONS, {
    namespace: sessionNamespace,
  })
  private questions!: SessionQuestionItem[];

  @Getter(SessionGetters.SELECTED_SESSION_AUTO_APPROVE_QUESTIONS, {
    namespace: sessionNamespace,
  })
  private getAutoApproveQuestions!: boolean;

  // Signalr Getters
  @Getter(SignalrGetters.CURRENT_GROUP, { namespace: signalrNamespace })
  private currentGroup!: SignalrGroup;

  @Getter(`${SignalrGetters.USER_CONNECTED}`, { namespace: signalrNamespace })
  private userConnected!: boolean;

  @Getter(SignalrGetters.CURRENT_GROUP_MESSAGES, {
    namespace: signalrNamespace,
  })
  private currentGroupMessages!: SignalrMessage[];

  /* VUEX ACTIONS */
  // Session Actions
  @Action(SessionActions.SET_SELECTED_SESSION_AUTO_APPROVE_QUESTIONS, {
    namespace: sessionNamespace,
  })
  private setAutoApproveQuestions!: (val: boolean) => void;

  /* LOCAL GETTERS / SETTERS */

  get autoApproveQuestions(): boolean {
    return this.getAutoApproveQuestions;
  }

  set autoApproveQuestions(val: boolean) {
    this.setAutoApproveQuestions(val);
  }

  get questionCount(): number {
    if (this.isMod) {
      return this.questions.length;
    } else {
      return this.questions.filter((x) => x.isApproved).length;
    }
  }

  @Watch('isActive')
  private isActiveChanged() {
    this.scrollTop();
  }

  /* PRIVATE METHODS*/
  private getIcon(rolename: string | undefined): string {
    if (!rolename) return '';
    let icon = '';
    switch (rolename.toLowerCase()) {
      case 'bot':
        icon = 'robot';
        break;
      case 'staff':
        icon = 'mdi-shield-star';
        break;
      case 'modAndSpeaker':
        icon = 'mdi-shield-star';
        break;
      case 'moderator':
        icon = 'mdi-shield-star';
        break;
      case 'speaker':
        icon = 'mdi-account-voice';
        break;
      default:
        icon = '';
    }
    return icon;
  }

  private getSecondIcon(rolename: string | undefined): string {
    if (!rolename) return '';
    let icon = '';
    switch (rolename.toLowerCase()) {
      case 'bot':
        icon = '';
        break;
      case 'staff':
        icon = 'mdi-account-voice';
        break;
      case 'modAndSpeaker':
        icon = 'mdi-account-voice';
        break;
      case 'moderator':
        icon = '';
        break;
      case 'speaker':
        icon = '';
        break;
      default:
        icon = '';
    }
    return icon;
  }

  private formatTimestamp(time: string | undefined): string {
    if (!time) {
      return '';
    }
    const t = moment.utc(time);
    if (moment().isSame(t.local(), 'day')) {
      return t.local().format('HH:mm:ss');
    } else {
      return t.local().format('DD/MM/YY');
    }
  }

  private mounted() {
    this.setAutoApproveQuestions(false);
    this.scrollTop();
  }

  private scrollTop() {
    const e = document.getElementById('top');
    if (e) {
      e.scrollIntoView();
    }
  }

  private onNewQuestion() {
    this.scrollTop();
  }

  private showReply(id: string | undefined): void {
    if (!id) {
      this.showId = '';
    } else {
      this.showId = id;
    }
  }

  private clearQuestionReply() {
    this.replyQuestiontText = '';
  }

  private closeReplyTextArea() {
    this.replyQuestiontText = '';
    this.showId = '';
  }

  /* Question Handling */
  private async approveAllQuestionsExecute() {
    const questions = this.questions.filter((q) => {
      if (!q.isApproved) {
        return q;
      }
    });

    questions.forEach(async (q) => {
      await this.approveQuestionExecute(q);
    });
  }

  private async approveQuestionExecute(question: SessionQuestionItem) {
    this.$emit('newMessage', {
      msg: question,
      type: 'approveQuestion',
    });
    this.newQuestiontText = '';
  }

  private async hideAllQuestionsExecute() {
    const questions = this.questions.filter((q) => {
      if (q.isApproved) {
        return q;
      }
    });

    questions.forEach(async (q) => {
      await this.hideQuestionExecute(q);
    });
  }

  private async hideQuestionExecute(question: SessionQuestionItem) {
    this.$emit('newMessage', {
      msg: question,
      type: 'hideQuestion',
    });
    this.newQuestiontText = '';
  }

  private postQuestionExecute() {
    if (this.newQuestiontText.length > 1) {
      this.$emit('newMessage', {
        msg: this.newQuestiontText,
        type: 'postQuestion',
      });
      this.newQuestiontText = '';
      // TODO(David): Check is questions being moderated
      this.sentDialog = true;
    }
  }

  private postQuestionReplyExecute(questionId: string | undefined) {
    if (!questionId) {
      return;
    }
    if (this.replyQuestiontText.length > 1) {
      this.$emit('newMessage', {
        msg: this.replyQuestiontText,
        questionId,
        type: 'postReplyQuestion',
      });
      this.closeReplyTextArea();

      // TODO(David): Check is questions being moderated
    }
  }

  private resetSessionQuestionItemUserPhotoUri(question: SessionQuestionItem) {
    this.$emit('newMessage', {
      msg: question,
      type: 'resetUserPhoto',
    });
  }
}
</script>

<style scoped>
.title {
  font-family: Ubuntu !important;
  font-size: 1.8rem !important;
  font-weight: 500;
}

.light-chat {
  font-size: 13px;
  height: calc(100% - 140px) !important;
}

.light-chat::-webkit-scrollbar {
  width: 5px;
}

.light-chat::-webkit-scrollbar-track {
  background: transparent;
  border-left: 0;
}

.light-chat::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: 0;
  border-radius: 7px;
}

.light-chat::-webkit-scrollbar-thumb:hover {
  background: black;
}

.message-text {
  overflow-wrap: break-word;
  white-space: normal;
}

.auto-approve {
  transform: scale(0.8);
}

.auto-approve >>> label {
  font-size: 12.5px;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
}
</style>
