<template>
  <v-app class="transparent">
    <BannerBar v-if="showBars" />
    <v-main>
      <NavDrawer v-if="showBars" />
      <router-view>
        <slot />
      </router-view>
    </v-main>
    <div
      v-if="conversationBarVisible"
      style="padding: 125px 0px 0px 56px"
      class="conversations"
    >
      <ConversationBar />
    </div>
  </v-app>
</template>

<script lang="ts">
import BannerBar from '@/components/BannerBar.vue';
import ConversationBar from '@/components/ConversationBar.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { AuthGetters } from './store/auth/types';
import { ConversationGetters } from './store/conversation/types';
import { PlatformGetters } from './store/platform/types';

const authNamespace = 'auth';
const conversationNamespace = 'conversation';
const platformNamespace = 'platform';

@Component({
  components: {
    BannerBar,
    ConversationBar,
    NavDrawer,
  },
})
export default class App extends Vue {
  private right = true;

  get showBars(): boolean {
    if (this.$route.meta && this.$route.meta.fullPage !== undefined) {
      return !this.$route.meta?.fullPage; // && this.isAuthenticated;
    } else {
      return false;
    }
  }

  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  @Getter(ConversationGetters.CONVERSATION_BAR_VISIBLE, {
    namespace: conversationNamespace,
  })
  private conversationBarVisible!: boolean;

  @Getter(PlatformGetters.PLATFORM_ID, { namespace: platformNamespace })
  private platformId!: string;

  private beforeCreate() {
    this.$vuetify.breakpoint.mobileBreakpoint = 960;
  }
}
</script>

<style scoped>
.conversations {
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 9999;
}
.transparent {
  background: transparent !important;
}
</style>
