<template>
  <div v-if="!userConnected || !pageLoaded" class="fill-height">
    <v-container class="fill-height">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="subtitle-1 text-center" cols="12">
          {{ $t('chat.loadingChat') }}
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
        <v-col
          v-if="stillNotConnected"
          class="subtitle-1 text-center"
          cols="12"
        >
          <span>{{ $t('errors.cannotConnect') }}</span>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div v-else>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="overline"> Live Chat </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div
      v-mutate.child="onNewMessage"
      class="overflow-y-auto light-chat mx-0 px-0"
    >
      <div v-for="cm in currentGroupMessages" :id="cm.id" :key="cm.id">
        <v-row dense class="ma-0 pa-0">
          <v-col cols="1" class="ma-0 pa-0">
            <Avatar
              class="ml-1 mt-2 mr-0 mb-0"
              size="28"
              :src="cm.userPhotoUri"
              :alt="cm.userName + ' photo'"
              default="mdi-account-circle-outline"
            />
          </v-col>
          <v-col cols="11" class="ma-0 pa-0">
            <v-card class="pa-0 px-2 mx-3 my-2" color="#F5F5F5">
              <v-container class="pa-0 ma-0">
                <v-row dense>
                  <v-col cols="12">
                    <span class="font-weight-bold text--disabled">
                      <v-icon
                        small
                        style="
                          margin-top: -3px;
                          color: rgba(0, 0, 0, 0.38) !important;
                        "
                      >
                        {{ getIcon(cm.roleName) }}
                      </v-icon>
                      <v-icon
                        v-if="getSecondIcon(cm.roleName)"
                        small
                        style="
                          margin-top: -3px;
                          color: rgba(0, 0, 0, 0.38) !important;
                        "
                      >
                        {{ getSecondIcon(cm.roleName) }}
                      </v-icon>
                      {{ cm.userName }}</span
                    >
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <span>{{ cm.message }}</span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="9">
                    <v-btn
                      v-if="isMod"
                      text
                      x-small
                      @click.stop="removeChatMessageExecute(cm)"
                    >
                      <v-icon size="12">mdi-delete-circle</v-icon>
                      {{ $t('common.delete') }}
                    </v-btn>
                    <v-btn
                      v-if="isMod && isSession"
                      x-small
                      text
                      @click.stop="postChatAsQuestionExecute(cm)"
                    >
                      <v-icon size="12">mdi-content-save</v-icon>
                      {{ $t('common.save') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="3" class="text-right text--disabled">
                    {{ getLocalTime(cm.timeStamp) }}
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div id="bottom" />
    </div>
    <v-container class="mt-0 pt-0">
      <v-row no-gutters>
        <v-col>
          <tweet-box
            ref="tweetbox"
            v-model="newChatMessageText"
            class="chat-message-compose"
            :placeholder="$t('chat.sendMessage')"
            limit="140"

          />
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <emoji-picker style="marginTop: -10px" @emoji:picked="handleEmojiPicked" :data="data" />
        <v-spacer></v-spacer>
        <v-btn
          x-small
          color="primary"
          class="mt-1"
          @click.stop="postChatMessageExecute"
        >
          Chat
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import Avatar from '@/components/Avatar.vue';
import TweetBox from '@/components/TweetBox.vue';
import EmojiPicker from '@/components/EmojiPicker.vue';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {
  SignalrGetters,
  SignalrGroup,
  SignalrMessage,
  SignalrMessageTypes,
} from '../store/signalr/types';
import data from '../data/emojis.json';

const signalrNamespace = 'signalr';

@Component({ components: { TweetBox, Avatar, EmojiPicker } })
export default class LiveChat extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public isMod!: boolean;

  @Prop()
  public pageLoaded!: string;

  @Prop()
  public isActive!: boolean;

  @Prop()
  public isSession!: boolean;

  @Prop()
  public stillNotConnected!: boolean;

  @Ref()
  readonly tweetbox!: TweetBox;

  /* PRIVATE PROPERTIES */
  private newChatMessageText = '';

  /* VUEX GETTERS */

  // Signalr Getters
  @Getter(SignalrGetters.CURRENT_GROUP, { namespace: signalrNamespace })
  private currentGroup!: SignalrGroup;

  @Getter(`${SignalrGetters.USER_CONNECTED}`, { namespace: signalrNamespace })
  private userConnected!: boolean;

  @Getter(SignalrGetters.CURRENT_GROUP_MESSAGES, {
    namespace: signalrNamespace,
  })
  private currentGroupMessages!: SignalrMessage[];

  /* WATCHES */
  @Watch('isActive')
  private isActiveChanged() {
    this.scrollBottom();
  }

  /* LOCAL GETTERS */

  /* PRIVATE METHODS*/
  private data() {
    return {
      data: data,
      body: ""
    };
  }

  private mounted() {
    this.scrollBottom();
  }

  private scrollBottom() {
    const e = document.getElementById('bottom');
    if (e) {
      e.scrollIntoView();
    }
  }

  private getIcon(rolename: string | undefined): string {
    if (!rolename) return '';
    let icon = '';
    switch (rolename.toLowerCase()) {
      case 'bot':
        icon = 'robot';
        break;
      case 'staff':
        icon = 'mdi-shield-star';
        break;
      case 'modAndSpeaker':
        icon = 'mdi-shield-star';
        break;
      case 'moderator':
        icon = 'mdi-shield-star';
        break;
      case 'speaker':
        icon = 'mdi-account-voice';
        break;
      default:
        icon = '';
    }
    return icon;
  }

  private getSecondIcon(rolename: string | undefined): string {
    if (!rolename) return '';
    let icon = '';
    switch (rolename.toLowerCase()) {
      case 'bot':
        icon = '';
        break;
      case 'staff':
        icon = 'mdi-account-voice';
        break;
      case 'modAndSpeaker':
        icon = 'mdi-account-voice';
        break;
      case 'moderator':
        icon = '';
        break;
      case 'speaker':
        icon = '';
        break;
      default:
        icon = '';
    }
    return icon;
  }

  getLocalTime(time: string | undefined) {
    if (time) {
      return moment.utc(time, 'HH:mm').local().format('HH:mm');
    }
  }

  private onNewMessage() {
    this.scrollBottom();
  }

  private postChatMessageExecute() {
    if (this.newChatMessageText.length > 1) {
      this.$emit('newMessage', {
        msg: this.newChatMessageText,
        type: 'postChat',
      });
      this.newChatMessageText = '';
    }
  }

  private removeChatMessageExecute(msg: SignalrMessage) {
    msg.messageType = SignalrMessageTypes.REMOVE_CHAT;
    this.$emit('newMessage', {
      msg,
      type: 'deleteChat',
    });
  }

  private postChatAsQuestionExecute(msg: SignalrMessage) {
    this.$emit('newMessage', {
      msg,
      type: 'makeChatAQuestion',
    });
  }

  private handleEmojiPicked(emoji: string) {
      this.newChatMessageText += emoji;
      this.tweetbox.textFocus();
    }

}
</script>

<style scoped>
.title {
  font-family: Ubuntu !important;
  font-size: 1.8rem !important;
  font-weight: 500;
}

.light-chat {
  font-size: 12px;
  height: calc(100% - 140px);
}

.light-chat::-webkit-scrollbar {
  width: 5px;
}

.light-chat::-webkit-scrollbar-track {
  background: transparent;
  border-left: 0;
}

.light-chat::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: 0;
  border-radius: 7px;
}

.light-chat::-webkit-scrollbar-thumb:hover {
  background: black;
}

.message-text {
  overflow-wrap: break-word;
  white-space: normal;
}
</style>
