<template>
  <v-container>
    <div v-if="summary">Summary - {{ summary.title }}</div>
    <div>
      <TimeSliceCountChart
        v-if="summary"
        :chart-data="getChartData(summary.timeSliceCount)"
        :title="`Event: ${summary.title}`"
      ></TimeSliceCountChart>
    </div>
  </v-container>
</template>

<script lang="ts">
import Chart from 'chart.js';
import TimeSliceCountChart from '@/components/TimeSliceCountChart.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Summary, TimeSliceCount } from '@/models/Reports/Summary';
import { AppInsightsLogger } from '@/services/appInsightsLogger';

@Component({
  components: {
    TimeSliceCountChart,
  },
})
export default class extends Vue {
  /* PUBLIC PROPERTIES */
  /* PRIVATE PROPERTIES */
  private summary?: Summary | null = null;
  private eventCode?: string;
  private period = 1440;
  /* VUEX GETTERS */
  /* VUEX ACTIONS */
  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */
  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private async mounted() {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    this.eventCode = sessionStorage.getItem('eventCode') ?? '';
    let uri = `/api/platform/${platformId}/report/summary?eventCode=${this.eventCode}&period=${this.period}`;
    try {
      const res = await this.$http.get<Summary>(uri);
      this.summary = res.data;
    } catch (e: any) {
      AppInsightsLogger.logError('Summary - mounted failed', undefined, true);
      AppInsightsLogger.logException(e, false);
    }
  }
  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}
  /* PRIVATE METHODS*/
  // private temp {
  //   let uri = '';
  //   if (this.eventCode === 'All Platforms') {
  //     uri = uri + `?period=${this.period}`;
  //   } else {
  //     uri = uri + `eventCode=${this.eventCode}&period=${this.period}`;
  //   }
  // }

  private getChartData(data: TimeSliceCount[]): Chart.ChartData {
    const times = data.map((a) => a.time);
    const counts = data.map((a) => a.count);
    const dataCollection: Chart.ChartData = {
      labels: times,
      datasets: [
        {
          label: 'Views',
          backgroundColor: 'blue',
          pointBorderColor: '#f87979',
          data: counts,
        },
      ],
    };
    return dataCollection;
  }
}
</script>

<style scoped></style>
