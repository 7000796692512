<template>
  <iframe
    id="vimeoplayer"
    ref="vimeoplayer"
    width="100%"
    height="100%"
    :src="uri"
    frameborder="0"
    allow="autoplay; fullscreen"
    allowfullscreen
  ></iframe>
</template>

<script lang="ts">
import Player from '@vimeo/player';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public uri!: string;

  /* PRIVATE PROPERTIES */
  private vimeoplayer!: Player;

  /* VUEX GETTERS */
  /* VUEX ACTIONS */
  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */
  /* LIFECYCLE METHODS */
  // private beforeCreate() {}
  // private created() {}
  // private beforeMount() {}
  private mounted() {
    this.vimeoplayer = new Player(this.$refs.vimeoplayer as HTMLIFrameElement);
    // this.vimeoplayer.on('play', this.played);
    // this.vimeoplayer.on('pause', this.paused);
    this.vimeoplayer.on('ended', this.ended);
    // this.vimeoplayer.on('timeupdate', this.timeupdated);
  }

  // private beforeUpdate() {}
  // private updated() {}
  // private activated() {}
  // private deactivated() {}
  // private beforeDestroy() {}
  // private destroyed() {}
  // private errorCaptured() {}

  /* PRIVATE METHODS*/
  private ended(event: any) {
    this.$emit('ended');
  }
}
</script>

<style scoped></style>
