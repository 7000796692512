<template>
    <div style="height: 100%">
        <v-container v-if="!submitSettings.open && loaded" fill-height fluid>
            <v-row align="center" justify="center">
                <v-card max-width="500" class="mx-auto">
                    <v-card-text class="text-h6 text-center"><span v-html="submitSettings.closedText"></span></v-card-text>
                </v-card>
            </v-row>
        </v-container>
        <v-container v-if="submitSettings.open && loaded" fluid>
            <v-card flat>
                <v-card-title>
                    {{ $t('submit.Sessions') }}
                </v-card-title>
                <v-data-table :headers="sessionHeaders"
                              :items="sessionItems"
                              single-expand
                              item-key="id"
                              show-expand
                              :search="search"
                              :loading="loading"
                              :loading-text="$t('submit.LoadingText')"
                              class="elevation-1"
                              sort-by="[start, roomOrder]"
                              :items-per-page="100"
                              :locale="$i18n.locale"
                              :footer-props="footerProps">
                    <template v-slot:top>
                        <v-container fluid>
                            <v-row class="ma-0 pa-0" dense>
                                <v-spacer></v-spacer>
                                <v-col cols="3">
                                    <v-select :items="sessionDates"
                                              v-model="dateFilterValue"
                                              :label="$t('submit.Date')"
                                              single-line
                                              hide-details
                                              clearable>
                                    </v-select>
                                </v-col>
                                <v-col cols="3">
                                    <v-select :items="sessionRooms"
                                              v-model="roomFilterValue"
                                              :label="$t('submit.Room')"
                                              single-line
                                              hide-details
                                              clearable>
                                    </v-select>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="search"
                                                  append-icon="mdi-magnify"
                                                  :label="$t('submit.Search')"
                                                  single-line
                                                  hide-details
                                                  clearable>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>

                    <template v-slot:[`item.start`]="{ item }">
                        {{ formatLongDate(item.start, item.end) }}
                    </template>

                    <template v-slot:expanded-item="{ item }">
                        <v-card class="ma-3" flat color="transparent">
                            <v-row dense>
                                <v-col cols="12">
                                    <v-card outlined>
                                        <v-data-table dense
                                                      :items="item.presentations"
                                                      sort-by="order"
                                                      :headers="presentationHeaders"
                                                      hide-default-footer
                                                      :items-per-page="-1">
                                            <template v-slot:[`item.start`]="{ item }">
                                                {{ formatLongDate(item.start, item.end) }}
                                            </template>
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <v-btn v-if="item.presentationFileUri"
                                                       color="blue-grey"
                                                       class="ma-2 white--text"
                                                       @click="handleFileUploads(item)">
                                                        {{ $t('submit.ViewFiles') }}
                                                    <v-icon right
                                                            dark>
                                                         mdi-cloud-check
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                    </template>
                </v-data-table>
            </v-card>
            <v-row justify="center">
                <v-dialog v-model="dialog"
                          fullscreen
                          hide-overlay
                          transition="dialog-bottom-transition"
                          persistent>
                    <SubmitUploadFileDialog v-if="selectedPresentation" :item="selectedPresentation" :settings="submitSettings" :sessionMode="true" @close="handleClose" />
                </v-dialog>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
    import moment from 'moment';
    import {
        SubmitPresentationItem,
        SubmitSessionItem,
        SubmitSettings,
    } from '../../models/Submit';
    import { Action, Getter } from 'vuex-class';
    import { AppInsightsLogger } from '../../services/appInsightsLogger';
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { getFilenameFromURL } from '../../utils/getFilenameFromURL';
    import SubmitUploadFileDialog from '../../components/SubmitUploadFileDialog.vue'


    @Component({
        components: { SubmitUploadFileDialog, },
    })
    export default class SessionList extends Vue {
        /* PUBLIC PROPERTIES */
        /* PRIVATE PROPERTIES */
        private loading: boolean = true;
        private loaded: boolean = false;
        private submitSettings: SubmitSettings = {};
        private dialog: boolean = false;
        private dateFilterValue = null;
        private roomFilterValue = null;
        private search: string = '';
        private sessionItems: SubmitSessionItem[] = [];
        private selectedPresentation: SubmitPresentationItem | null = null;

        private sessionHeaders = [
            { text: this.$t('submit.SessionTitle'), value: 'title' },
            { text: this.$t('submit.Room'), value: 'roomName', filter: this.RoomFilter },
            { text: this.$t('submit.StartEnd'), value: 'start', filter: this.DateFilter },
            { text: '', value: 'actions', sortable: false },
            { text: '', value: 'data-table-expand' },
        ];
        private presentationHeaders = [
            { text: this.$t('submit.Order'), value: 'order' },
            { text: this.$t('submit.PresentationTitle'), value: 'title' },
            { text: this.$t('submit.Speaker'), value: 'speakerName' },
            { text: this.$t('submit.StartEnd'), value: 'start' },
            { text: '', value: 'actions', sortable: false },
        ];
        private footerProps = {
            itemsPerPageOptions: [100, 200, 500, -1],
            itemsPerPageText: this.$t('submit.RowsPerPage'),
            itemsPerPageAllText: this.$t('submit.All'),
        };

        /* VUEX GETTERS */
        /* VUEX ACTIONS */
        /* WATCHES */
        /* LOCAL GETTERS/SETTERS */
        get sessionDates(): string[] {
            if (!this.sessionItems || this.sessionItems.length < 1) {
                return [];
            } else {
                return [
                    ...new Set(
                        this.sessionItems.map((d) => {
                            return moment(d.start)
                                .locale(this.$i18n.locale)
                                .format('DD/MM/YYYY');
                        }),
                    ),
                ];
            }
        }

        get sessionRooms(): string[] {
            return [
                ...new Set(
                    this.sessionItems.map((d) => {
                        if (d.roomName) {
                            return d.roomName;
                        } else {
                            return this.$t('submit.NoRoom') as string; // TODO(david): translate
                        }
                    }),
                ),
            ];
        }

        /* LIFECYCLE METHODS */
        // private beforeCreate() {}
        // private created() {}
        // private beforeMount() {}
        private async mounted() {
            const platformId = sessionStorage.getItem('platformId') ?? '';
            const res = await Vue.$http.get<SubmitSettings>(`/api/platform/${platformId}/submit`);
            this.submitSettings = res.data
            if (this.submitSettings.closedText) {
                this.loaded = true;
            } else {
                await this.loadSessions();
            }
        }
        // private beforeUpdate() {}
        // private updated() {}
        // private activated() {}
        // private deactivated() {}
        // private beforeDestroy() {}
        // private destroyed() {}
        // private errorCaptured() {}
        /* PRIVATE METHODS*/
        private DateFilter(value, search, item) {
            if (!this.dateFilterValue) {
                return true;
            }
            return (
                moment(item.start).locale(this.$i18n.locale).format('DD/MM/YYYY') ===
                this.dateFilterValue
            );
        }

        private formatLongDate(start: Date, end: Date) {
            let s = '';
            let e = '';
            if (start) {
                s = moment(start).locale(this.$i18n.locale).format('ddd DD MMM HH:mm');
            }

            if (end) {
                e = moment(end).locale(this.$i18n.locale).format('HH:mm');
            }
            return `${s} - ${e}`;
        }

        private async handleFileUploads(item: SubmitPresentationItem) {
            this.selectedPresentation = item;
            this.dialog = true;
        }

        private handleClose() {
            this.selectedPresentation = null;
            this.dialog = false;
        }

        private async loadSessions() {
            const platformId = sessionStorage.getItem('platformId') ?? '';
            try {
                let url = `/api/v2/platform/${platformId}/submit/sessions`;
                const pData = await Vue.$http.get<SubmitSessionItem[]>(url);
                if (pData.data) {
                    this.sessionItems = pData.data;
                }
                this.loaded = true;
                this.loading = false;
            } catch (e: any) {
                AppInsightsLogger.logError(
                    'Submit Session List - created failed',
                    undefined,
                    true,
                );
                AppInsightsLogger.logException(e, false);
            }
        }

        private RoomFilter(value, search, item) {
            if (!this.roomFilterValue) {
                return true;
            }
            return value === this.roomFilterValue;
        }
    }
</script>

<style scoped></style>
