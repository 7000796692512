<template>
  <div v-if="items && items.length">
    <h2
      :style="{ color: textColour, cursor: 'pointer' }"
      class="ml-5 mb-1 font-weight-medium"
      @click="browseHeading"
    >
      {{ groupName }}
    </h2>
    <v-slide-group v-model="items" class="ml-2 pb-10" show-arrows="desktop">
      <v-slide-item v-for="i in items" :key="i.id" >
        <div @click.capture.stop="moreInfoExecute(i)">
          <SessionCard
            home-vod="true"
            :text-colour-override="textColour"
            :session="i"
            class="mr-2 pa-0"
            style="'{cursor: crosshair;}'"
          />
        </div>
      </v-slide-item>
      <v-card :height="cardHeight" flat color="transparent" class="start">
        <v-row align-content="center" class="fill-height ma-0 pa-0">
          <v-col>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="items.length == 10"
                  class="mx-2"
                  :color="textColour"
                  outlined
                  fab
                  :href="`/${eventCode}/vod?${groupType}=${groupId}`"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('common.More') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
      <template #prev>
        <v-card :height="cardHeight" flat color="transparent" class="start">
          <v-row align-content="center" class="fill-height ma-0 pa-0">
            <v-col>
              <span
                ><v-icon :color="textColour" large
                  >mdi-chevron-left</v-icon
                ></span
              >
            </v-col>
          </v-row>
        </v-card>
      </template>
      <template #next>
        <v-card :height="cardHeight" flat color="transparent" class="start">
          <v-row align-content="center" class="fill-height ma-0 pa-0">
            <v-col>
              <span
                ><v-icon :color="textColour" large
                  >mdi-chevron-right</v-icon
                ></span
              >
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-slide-group>
  </div>
</template>

<script lang="ts">
import SessionCard from '@/components/SessionCard.vue';
import { SessionItem } from '@/store/session/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    SessionCard,
  },
})
export default class HomeVodSessionGroup extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public items!: SessionItem[];

  @Prop()
  public groupId!: string;

  @Prop()
  public groupName!: string;

  @Prop()
  public groupType!: string;

  @Prop() textColour!: string;

  /* PRIVATE PROPERTIES */
  /* VUEX GETTERS */

  /* VUEX ACTIONS */
  /* WATCHES */
  /* LOCAL GETTERS/SETTERS */
  get cardHeight(): number {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 179;
    } else {
      return 224;
    }
  }
  /* PRIVATE METHODS*/
  get eventCode(): string {
    return sessionStorage.getItem('eventCode') ?? '';
  }

  private async browseHeading() {
    const uri = `/${this.eventCode}/vod?${this.groupType}=${this.groupId}`;
    await this.$router.push(uri);
  }

  private moreInfoExecute(session: SessionItem) {
    this.$emit('more', session);
  }
}
</script>

<style scoped>
.start {
  align-self: flex-start;
  align-content: center;
  align-items: center;
}
.v-slide-group__prev--disabled span {
  background: transparent;
  display: none;
}
.v-slide-group__next--disabled span {
  background: transparent;
  display: none;
}
</style>
